import { combineReducers } from 'redux';

import pendingReducer from './pendingReducer';
import rejectedReducer from './rejectedReducer';
import approvedReducer from './approvedReducer';
import bulkReducer from './bulkReducer';
import {masterReducer} from './masterList';
import {applications} from './allApplications';
import loader from './loader'
export default combineReducers({
  loader: loader,
  pending:pendingReducer,
  rejected:rejectedReducer,
  approved:approvedReducer,
  bulk:bulkReducer,
  masterList:masterReducer,
  applications:applications

});