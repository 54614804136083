import React from 'react';
import styled from 'styled-components'

const Handshake = () => {
    return (
        <Wrapper>
            <img src={require('../assets/Image/handshake-emoji.png').default} alt="" />
            <div className="text">
                  Partner {' >'} Excel {' >'} Grow
            </div>
            
        </Wrapper>
    )
}

export default Handshake

const Wrapper = styled.div`
    display:none;
    background-color: #EEF1FB;
    
    flex-direction: column;
    padding: 11px 15px;
    align-items: center;
    padding-bottom:30px;
    img{
        width:63px;
        object-fit: contain;
    }
    .text{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 126.3%;
        color: #333333;
    }

    @media only screen and (max-width: 600px){
        margin-top:20px;
        display:flex;
    }

`
