import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  FormLabel,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import { RiRadioButtonFill } from "react-icons/ri";
import { savePosp, panStatusCheck } from "../api";
import { useNavigate } from "react-router-dom";
import BecomeAgentStrip from "./BecomeAgentStrip";

const errObj = {
  dob: "",
};

const BasicInfo = ({ step, setStep, formik }) => {
  const navigate = useNavigate();

  const calTheme = createTheme({
    typography: {
      fontFamily: ["Outfit", "Sora"].join(","),
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            width: "350px",
            borderRadius: "10px",
            "&::-webkit-scrollbar": {
              height: "8px",
              width: "4px",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c9c6c6",
              borderRadius: "20px",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            width: "min-content",
          },
          list: {
            fontFamily: "Outfit",
            padding: "0px",
          },
        },
      },
    },
  });

  const [edu, setEdu] = useState(1);
  const [dateVal, setDateVal] = useState("");
  const [lastDob, setLastDob] = useState("");

  const [maxdob, setMaxdob] = useState("");
  const [showErr, setshowErr] = useState(false);
  const [showDOBErr, setshowDOBErr] = useState(false);

  const handleDateChange = async (val) => {
    // console.log(/^[1-9]/.test(moment(val).format('DD-MM-yyyy').split('-')[2]));
    setshowDOBErr(false)
    if (
      val &&
      val.isValid() &&
      new Date(maxdob).getTime() >=
        new Date(moment(val).format("yyyy-MM-DD")).getTime() &&
      /^[1-9]/.test(moment(val).format("DD-MM-yyyy").split("-")[2])
    ) {
      console.log("api called");
    }

    if (errObj.dob) formik.setFieldError("dob", errObj.dob);

    if (
      new Date(maxdob).getTime() <
      new Date(moment(val).format("yyyy-MM-DD")).getTime()
    ) {
      formik.setFieldError("dob", "Age should be more than 18 years");
      errObj.dob = "Age should be more than 18 years";
    }
    if (
      new Date(maxdob).getTime() >
      new Date(moment(val).format("yyyy-MM-DD")).getTime()
    ) {
      console.log("dob is valid");
      errObj.dob = "";
    }
    //    if(!moment(val).isValid()){
    //     errObj.dob="Please enter a valid DOB";
    // }

    formik.setFieldValue("dob", moment(val).format("yyyy-MM-DD"));

    //    return

    if (
      val &&
      val.isValid() &&
      new Date(maxdob).getTime() >=
        new Date(moment(val).format("yyyy-MM-DD")).getTime() &&
      /^[1-9]/.test(moment(val).format("DD-MM-yyyy").split("-")[2])
    ) {
      try {
        if(moment(val, "DD-MM-YYYY").format("yyyy-MM-DD") !== sessionStorage.getItem('dob')){
          formik.setFieldError("dob", "Enter Valid Birthdate as per PAN");
          errObj.dob = "Enter Valid Birthdate as per PAN";
          setshowDOBErr(true)
        }
        // const { data } = await panStatusCheck(
        //   formik.values.panNumber,
        //   moment(val).format("DD/MM/yyyy"),
        //   formik.values.firstName,
        //   formik.values.lastName
        // );

        // if (data && data.responseCode === 102) {
        //   formik.setFieldError("dob", data.responseMessage);
        //   errObj.dob = data.responseMessage;
        // }
        // if (data && data.responseCode === 101) {
        //   errObj.dob = "";
        //   formik.setFieldError("dob", "");
        // }
      } catch (error) {
        console.log("pan verify error");
        formik.setFieldError(
          "dob",
          "Something went wrong, Please try again later"
        );
      }
    }
  };

  const handleFirstname = (e) => {
    formik.setFieldValue(
      "firstName",
      e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
  };
  const handleLastname = (e) => {
    formik.setFieldValue(
      "lastName",
      e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
  };

  const handleminDob = () => {
    let date = new Date();
    let month = date.getMonth();

    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    let year = date.getFullYear() - 18;
    setDateVal(new Date(year - 1, month, dt - 1));
    setMaxdob(new Date(year, month, dt));
    // setMaxdob(`${month}/${dt}/${year}`);
  };

  const handlePinChange = (e) => {
    formik.setFieldValue(
      "pinCode",
      e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
  };

  const handleNextStep = async () => {
    formik.setFieldTouched("firstName", true);

    const srData = JSON.parse(sessionStorage.getItem("rData"));

    // if(new Date(maxdob).getTime() < new Date(moment(formik.values.dob).format('yyyy-MM-DD')).getTime()){
    //     errObj.dob='';
    //     formik.setFieldError('dob',"");
    // }

    const lastActiveAgent = JSON.parse(
      sessionStorage.getItem("lastActiveAgent")
    );
    const currentTime = new Date().getTime();
    const sessionTime = 30 * 60 * 1000;
    if (
      lastActiveAgent &&
      currentTime - Number(lastActiveAgent) > sessionTime &&
      (step > 0 || step <= 5)
    ) {
      sessionStorage.removeItem("lastActiveAgent");
      alert("session expired,session is valid for 30 Minutes");
      setStep(0);
      navigate("/");
      return;
    }
    sessionStorage.setItem("lastActiveAgent", new Date().getTime());

    const saveData2 = {
      applicantid: formik.values.applicantid,

      // initial: formik.values.initials,
      firstname: formik.values.firstName,
      lastname: formik.values.lastName,
      dob: formik.values.dob,
      address: formik.values.address,
      pincode: Number(formik.values.pinCode),
      city: formik.values.cityName,
      state: formik.values.state,
      educationQ: Number(formik.values.eduQual),
      others: formik.values.othersEdu,
      nomineeName: formik.values.nomineeName,
      gender: formik.values.gender,
      pageno: 2,
    };

    const redirect = JSON.parse(sessionStorage.getItem("redirect"));

    const saveData = {
      applicantid: formik.values.applicantid,
      partnerid: formik.values.partnerid,
      partnername: redirect ? redirect.partnerName : "NA",
      partneruserid: redirect ? redirect.partneruserid : "",
      pospcode: "",
      email: formik.values.email,
      mobileno: formik.values.mobNumber,
      pannumber: formik.values.panNumber,
      // initial: formik.values.initials,
      firstname: formik.values.firstName,
      lastname: formik.values.lastName ? formik.values.lastName : "-",
      dob: formik.values.dob,
      address: formik.values.address,
      pincode: formik.values.pinCode,
      city: formik.values.cityName,
      state: formik.values.state,
      educationQ: Number(formik.values.eduQual),
      others: formik.values.othersEdu,
      accountno: formik.values.accNumber,
      ifscCode: formik.values.ifsc,
      bankname: formik.values.bankName,
      bankbranch: formik.values.bankBranch,
      accounttype: formik.values.accType,
      accountholdername: formik.values.accHolderName,
      nomineeName: formik.values.nomineeName,
      gender: formik.values.gender,
      pageno: 2,
      managerUserId: formik.values.managerUserId
    };

    if (
      !formik.values.firstName ||
      formik.errors.firstName ||
      formik.errors.lastName ||
      formik.errors.dob ||
      errObj.dob ||
      formik.errors.pinCode ||
      formik.errors.cityName ||
      formik.errors.state ||
      formik.errors.address ||
      formik.errors.eduQual ||
      // formik.errors.initials ||
       formik.errors.nomineeName || formik.errors.gender
    ) {
      formik.setFieldTouched("firstName", true);
      setshowErr(true);
      return;
    }

    if (formik.values.eduQual === 5 && !formik.values.othersEdu) {
      formik.setFieldError("eduQual", "Please enter Educational Qualification");
      return;
    }

    try {
      await savePosp(saveData);
      if (srData && srData.rrn && srData.aid && srData.rpg === "1") {
        setStep(5);
        return;
      }
      setStep(2);
    } catch (error) {
      console.log("save basic info err");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    handleminDob();
  }, []);

  return (
    <>
      <BecomeAgentStrip />
    <Wrapper>
      <Grid
        container
        className="mainContainer"
        justifyContent="center"
        spacing={0}
      >
          <Grid item md={0.5} className="heading">
              <ArrowBackIosNewIcon
                  fontSize="large"
                  onClick={() => setStep(0)}
                />
          </Grid>
        <Grid container item xs={12} md={6} direction="row" spacing={2}>
          
          <Grid item md={10}>
            <Heading>
              {/* <div className="heading">
                <ArrowBackIosNewIcon
                  fontSize="large"
                  onClick={() => setStep(0)}
                />
                <p>Become a Hello Zindagi agent</p>
              </div> */}
              <p className="title">
                Basic Information
                <img
                  className="titleImageDesktop"
                  src={require("../assets/Image/basic-info-emoji.png").default}
                  alt=""
                />
                <img
                  className="titleImageMobile"
                  src={require("../assets/Image/basic-info-emoji.png").default}
                  alt=""
                />
              </p>
            </Heading>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={10}
            direction={{ xs: "column", md: "row" }}
            spacing={2}
          >
            {/* <Grid item md={3.5}>
              <ThemeProvider theme={calTheme}>
                <FormControl fullWidth>
                  <InputLabel>Title</InputLabel>
                  <Select label="Title" {...formik.getFieldProps("initials")}>
                    <MenuItem value={0} disabled>
                      Title
                    </MenuItem>
                    <MenuItem value={1} divider={true}>
                      Mr.{" "}
                    </MenuItem>
                    <MenuItem value={2} divider={true}>
                      Ms.
                    </MenuItem>
                    <MenuItem value={3}>Mrs.</MenuItem>
                  </Select>
                </FormControl>
              </ThemeProvider>
              {showErr && formik.errors.initials && (
                <p className="errorText">{formik.errors.initials}</p>
              )}
            </Grid> */}

            <Grid item md={12}>
              <TextField
                label="Enter First Name"
                variant="outlined"
                disabled={true}
                inputProps={{ maxLength: 20 }}
                value={formik.values.firstName}
                onChange={handleFirstname}
                fullWidth
              />
              {showErr && formik.errors.firstName && (
                <p className="errorText">{formik.errors.firstName}</p>
              )}
            </Grid>

            <Grid item md={12}>
              <TextField
                label="Enter Last Name"
                variant="outlined"
                disabled={true}
                inputProps={{ maxLength: 20 }}
                // {...formik.getFieldProps('lastName')}
                value={formik.values.lastName}
                onChange={handleLastname}
                fullWidth
              />
              {showErr && formik.errors.lastName && (
                <p className="errorText">{formik.errors.lastName}</p>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={10} className="dateInput">
            <LocalizationProvider dateAdapter={DateAdapter}>
              <ThemeProvider theme={calTheme}>
                <DatePicker
                  label="Date Of Birth (as per PAN)"
                  value={formik.values.dob}
                  disableHighlightToday={true}
                  name="dob"
                  // disabled
                  inputFormat="DD-MM-yyyy"
                  mask="__-__-____"
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  maxDate={moment(maxdob)}
                />
              </ThemeProvider>
            </LocalizationProvider>
            {(showDOBErr || showErr) && (
              <p className="errorText">
                {formik.errors.dob ? formik.errors.dob : errObj.dob}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={10} style={{paddingTop: '0'}}>
            <ThemeProvider theme={calTheme}>
              <div className="eduWrap">
                <FormControl component="fieldset" className="eduWrapDesktop">
                <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    {...formik.getFieldProps("gender")}
                    name="gender"
                  >
                    <FormControlLabel
                      value={"male"}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Male"
                      onClick={() => setEdu(1)}
                    />
                    <FormControlLabel
                      value={"female"}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Female"
                      onClick={() => setEdu(2)}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl fullWidth className="eduWrapMobile">
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    fullWidth
                    {...formik.getFieldProps("gender")}
                    label="Gender"
                  >
                    <MenuItem disabled value={0}>
                      <em>Select gender</em>
                    </MenuItem>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                  </Select>
                </FormControl>

                {showErr && formik.errors.gender && (
                  <p className="errorText">{formik.errors.gender}</p>
                )}
              </div>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              label="Pincode"
              variant="outlined"
              value={formik.values.pinCode}
              inputProps={{ maxLength: 6 }}
              onChange={handlePinChange}
              // onBlur={formik.handleBlur}
              fullWidth
            />
            {showErr && formik.errors.pinCode && (
              <p className="errorText" style={{ textTransform: "capitalize" }}>
                {formik.errors.pinCode}
              </p>
            )}
          </Grid>

          <Grid item xs={12} md={10}>
            <TextField
              label="City Name"
              variant="outlined"
              disabled
              {...formik.getFieldProps("cityName")}
              fullWidth
            />
            {/* {
                                showErr&&formik.errors.cityName&&
                                <p className="errorText">{formik.errors.cityName}</p>
                                } */}
          </Grid>
          <Grid item xs={12} md={10}>
            <Box sx={{ minWidth: 120 }}>
              <ThemeProvider theme={calTheme}>
                <FormControl fullWidth>
                  <TextField
                    label="State"
                    variant="outlined"
                    disabled
                    {...formik.getFieldProps("state")}
                    fullWidth
                  />
                  {/* {
                                    showErr&&formik.errors.state&&
                                    <p className="errorText">{formik.errors.state}</p>
                                } */}
                </FormControl>
              </ThemeProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <TextField
              label="Address for Communication"
              multiline
              rows={4}
              fullWidth
              {...formik.getFieldProps("address")}
            />
            {showErr && formik.errors.address && (
              <p className="errorText">{formik.errors.address}</p>
            )}
          </Grid>

          <Grid item xs={12} md={10}>
            <p className="eduTitle">Educational Qualifications</p>
            <ThemeProvider theme={calTheme}>
              <div className="eduWrap">
                <FormControl component="fieldset" className="eduWrapDesktop">
                  <RadioGroup
                    row
                    aria-label="eduQual"
                    {...formik.getFieldProps("eduQual")}
                    name="eduQual"
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Class X"
                      onClick={() => setEdu(1)}
                    />
                    <FormControlLabel
                      value={2}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Class XII"
                      onClick={() => setEdu(2)}
                    />
                    <FormControlLabel
                      value={3}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Graduate"
                      onClick={() => setEdu(3)}
                    />
                    <FormControlLabel
                      value={4}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Post Graduate"
                      onClick={() => setEdu(4)}
                    />
                    {/* <FormControlLabel
                      value={5}
                      control={
                        <Radio
                          checkedIcon={<RiRadioButtonFill />}
                          color="default"
                        />
                      }
                      label="Others"
                      onClick={() => setEdu(5)}
                    /> */}
                  </RadioGroup>
                  {/* {Number(formik.values.eduQual) === 5 && (
                    <TextField
                      style={{ marginTop: "7px" }}
                      id="outlined-multiline-static"
                      label="Specify Education"
                      fullWidth
                      name="specEduQual"
                      {...formik.getFieldProps("othersEdu")}
                    />
                  )} */}
                </FormControl>
                <FormControl fullWidth className="eduWrapMobile">
                  <InputLabel>Educational qualifications</InputLabel>
                  <Select
                    name="eduQual"
                    fullWidth
                    {...formik.getFieldProps("eduQual")}
                    label="Educational qualifications"
                  >
                    <MenuItem disabled value={0}>
                      <em>Select educational qualification</em>
                    </MenuItem>
                    <MenuItem value={1}>Class X</MenuItem>
                    <MenuItem value={2}>Class XII</MenuItem>
                    <MenuItem value={3}>Graduate</MenuItem>
                    <MenuItem value={4}>Post Graduate</MenuItem>
                    {/* <MenuItem value={5}>Others</MenuItem> */}
                  </Select>
                  {/* {Number(formik.values.eduQual) === 5 && (
                    <TextField
                      style={{ marginTop: "7px" }}
                      id="outlined-multiline-static"
                      label="Specify Education"
                      fullWidth
                      name="specEduQual"
                      {...formik.getFieldProps("othersEdu")}
                    />
                  )} */}
                </FormControl>

                {showErr && formik.errors.eduQual && (
                  <p className="errorText">{formik.errors.eduQual}</p>
                )}
              </div>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} md={10}>
            <Box sx={{ minWidth: 120 }}>
              <ThemeProvider theme={calTheme}>
                <FormControl fullWidth>
                  <TextField
                    label="Nominee Full Name"
                    variant="outlined"
                    {...formik.getFieldProps("nomineeName")}
                    fullWidth
                  />
                  {
                    showErr&&formik.errors.nomineeName&&
                    <p className="errorText">{formik.errors.nomineeName}</p>
                  }
                </FormControl>
              </ThemeProvider>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <Button
              className="submitBtn"
              variant="contained"
              onClick={handleNextStep}
            >
              Save and Continue
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={0} md={5} className="rightHero">
          <img src={require("../assets/Image/basic-hero.svg").default} alt="" />
        </Grid>
      </Grid>
    </Wrapper>
    </>
  );
};

export default BasicInfo;

const Wrapper = styled.div`
  padding: 0 10%;
  padding-bottom: 2%;
  .mainContainer{
    padding-top: 40px;
    .heading{
      .MuiSvgIcon-root {
      color: #2959E5;
      cursor: pointer;
    }
    }
  }
  a {
    text-decoration: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    /* margin-top:10px; */
    font-size: 18px;

    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #59ba9b;
        border-width: 1px;
      }
    }
  }

  .MuiOutlinedInput-root.Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px;
      border-color: #ed1c24;
    }
  }
  .textInp {
    font-family: "Outfit";
    font-size: 18px;
    border: 1px solid #bfc0c5;
    border-radius: 10px;
    padding: 15px 13px;
    margin-right: 20px;
    margin-top: 20px;
    outline-color: #ed1c2485;
  }

  .dateInput {
    width: 60%;
  }
  .eduTitle {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    /* margin-top: 20px; */
    color: #434552;
  }
  .MuiFormControlLabel-root {
    border: 1px solid #bfc0c5;
    padding: 3px 1px;
    border-radius: 8px;
    margin: 5px 8px;
    color: #787878;
    padding-right: 20px;
    margin-left: 0;
  }
  .errorText {
    font-family: "Outfit";
    color: #ed1c24;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 3px;
  }
  .eduWrap {
    margin: 8px 0;
    .MuiFormControlLabel-root {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 136.8%;
      color: #787878;
    }
    input[type="radio" i] {
      color: #ed1c24;
    }
  }
  .eduWrapMobile {
    display: none;
  }
  .submitBtn {
    padding: 10px 60px;
    border: none;
    outline: none;
    background: #2959E5;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    text-align: center;
    width: 100%;
    text-transform: none;
    font-family: "Sora";
    font-style: normal;
    font-weight: normal;
    :hover {
      background-color: #2959E5;
      box-shadow: 2px #2959E5;
    }
  }
  .rightHero {
    margin-top: 80px;
    align-content: baseline;
    justify-content: center;
    width: 100%;

    img {
      object-fit: contain;
      width: 75%;
      margin-left: auto;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #fcbc30;
      border-width: 1px;
    }
  }
  .MuiInputLabel-outlined.Mui-focused {
    color: #333333;
  }
  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #888888;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    height: 8px;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 5px;
  }

  .MuiCalendarPicker-root {
    background-color: red;
  }
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    border-radius: 20px !important;
  }

  .MuiButtonBase-root.MuiRadio-root {
    svg {
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 1% 4%;

    .mainContainer{
      .heading{
        display:none;
      }
    }
    .eduTitle {
      font-size: 18px;
    }
    .eduWrapMobile {
      display: inline-block;
    }
    .eduWrapDesktop {
      display: none;
    }

    .MuiOutlinedInput-root {
      font-size: 16px;
    }
    .MuiFormLabel-root.MuiInputLabel-root {
      font-size: 16px;
    }
    .rightHero {
      display: none;
    }
    .submitBtn {
      border-radius: 7px;
    }
  }
`;
const Heading = styled.div`
  /* padding-top: 40px; */

  .heading {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      color: #2959E5;
      margin-left: -46px;
      cursor: pointer;
    }

    p {
      font-family: "Outfit";
      font-size: 20px;
      padding-left: 10px;
      color: #c4c4c4;
    }
  }
  .title {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin: 10px 0;
    margin-top: 5px;
    display: flex;
    align-items: center;
    color: #434552;
    img {
      height: 25px;
      object-fit: contain;
    }
  }
  .titleImageMobile {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 18px;
    }
    .titleImageMobile {
      display: inline-block;
    }
    .titleImageDesktop {
      display: none;
    }
    .heading {
      display: none;
    }
  }
`;
