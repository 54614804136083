import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { Grid,InputLabel,OutlinedInput,Select,MenuItem} from '@mui/material';
import somefile from  '../../../assets/document/PrivacyPolicy.pdf';
import * as CryptoJS from 'crypto-js';
// import PDFViewer from 'pdf-viewer-reactjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

// import { Document, Page } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Lottie from 'react-lottie';


import image from  '../../../assets/Image/404.png';
import DownloadIcon from '@mui/icons-material/Download';
const ViewDoc = ({formik, isEdit, setUpdateEducation, updateEducation}) => {

    const [showPan, setShowPan] = useState(false);
    const [showCert, setShowCert] = useState(false);

    const [numPagesEdu, setNumPagesEdu] = useState(null);
    const [numPagesPan, setNumPagesPan] = useState(null);

  const [pageNumberEdu, setPageNumberEdu] = useState(1);
  const [pageNumberPan, setPageNumberPan] = useState(1);

    const encryptAES = (text, key) => {
        let val = CryptoJS.AES.encrypt(text, key).toString();
        console.log(val)
        return CryptoJS.AES.encrypt(text, key).toString();
      };

      function onDocumentLoadSuccessEdu(e) {
          console.log(e);
        setNumPagesEdu(e._pdfInfo.numPages);
      }
      function onDocumentLoadSuccessPan(e) {
          console.log(e);
          setNumPagesPan(e._pdfInfo.numPages);
      }

      const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: require('../../../assets/Image/loading.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

      function extractImageNameAndExtension(url) {
        // Split the URL by '/' and get the last segment
        const segments = url.split('/');
        const fileNameWithExtension = segments.pop() || '';
    
        // Decode in case the URL is encoded
        const decodedFileName = decodeURIComponent(fileNameWithExtension);
    
        // Split the name and extension
        const lastDotIndex = decodedFileName.lastIndexOf('.');
        const name = decodedFileName.substring(0, lastDotIndex);
        const extension = decodedFileName.substring(lastDotIndex + 1);
    
        return { name, extension };
    }
    
    const handleImageClick = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl, {
                // Adding headers to control the cache
                headers: {
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Pragma': 'no-cache',
                    'Expires': '0'
                }
            });
            const blob = await response.blob();
            const { name, extension } = extractImageNameAndExtension(imageUrl);
            const mimeType = `image/${extension}`; // Assuming the image is a JPEG
            const newBlob = new Blob([blob], { type: mimeType });
            const url = window.URL.createObjectURL(newBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${name}.${extension}`; // You can give it a dynamic name if needed
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (err) {
            console.error('Failed to download image', err);
        }
    };
    

    return (
        <Wrapper>
            <div className="heading">
                <img src={require('../../../assets/Image/view-doc-emoji.png').default} alt="" className="titleIcon" />
                <p>
                    View Documents:
                </p>
            </div>

            
            <MainContent>
                <Grid container direction="column" spacing={3} >

                        {/* GRID CONTAINER 1 START */}
                        <Grid container item spacing={2} >
                            <Grid item md={2.5} >
                                <InputLabel onClick={()=>encryptAES('abcdef','fg@123')} >
                                    PAN:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                style={{textTransform:'uppercase'}}
                                {...formik.getFieldProps('panNo')}
                                 />
                            </Grid>
                            <Grid item md={4} >
                                
                            </Grid>
                            <Grid item md={2.5} >
                                {
                                    formik.values.eduQual===5&&
                                    <>
                                    <InputLabel>
                                        Other Qualification:
                                    </InputLabel>
                                    <OutlinedInput 
                                    fullWidth 
                                    disabled
                                    size='small'
                                    {...formik.getFieldProps('otherEduQual')}
                                     />
                                    </>

                                }
                            </Grid>
                            
                            <Grid item md={3} >
                                <InputLabel>
                                    Education Qualification:
                                </InputLabel>
                                <Select
                                    defaultValue={1}
                                    fullWidth
                                    disabled={!isEdit}
                                    size='small'
                                    placeholder='-Select Education Qualification-'
                                    value={updateEducation}
                                    onChange={(e)=> setUpdateEducation(e.target.value)}
                                    // {...formik.getFieldProps('eduQual')}
                                >
                                    <MenuItem value={0} disabled >-Select Education-</MenuItem>
                                    <MenuItem value={1} divider={true} >Class X</MenuItem>
                                    <MenuItem value={2} divider={true} >Class XII</MenuItem>
                                    <MenuItem value={3} divider={true} >Graduate</MenuItem>
                                    <MenuItem value={4} >Post Graduate</MenuItem>
                                    {/* <MenuItem value={5} >Others</MenuItem> */}
                                    
                                  
                                </Select>
                            </Grid>
                        </Grid>
                        {/* GRID CONTAINER 1 END */}

                        {/* GRID CONTAINER 2 START */}
                        <Grid item container spacing={2} >
                        <Grid item md={4} >
                                <p className="viewTitle" onClick={()=>setShowPan(!showPan)} >
                                    View PAN Card Copy {!showPan ? <ExpandMoreIcon/> : <ExpandLessIcon/>}


                                </p>
                        
                            
                                {
                                    showPan&&
                                        <div className="panImageCont">
                                            {
                                              formik.values.panDoc ?
                                              <>
                                    {
                                        formik.values.panDoc && formik.values.panDoc.split('').reverse().join('').split('.')[0].split('').reverse().join('')==='pdf'?
                            //     <Document
                            //     onLoadSuccess={onDocumentLoadSuccessPan}
                            //     loading={  <Lottie options={defaultOptions}
                            //     height={200}
                            //     width={200}
                            //     isStopped={true}
                            //     isPaused={false}
                            //     />}
                                
                            //     file={{
                            //         url:`https://d2qt3b3csf1vza.cloudfront.net/${formik.values.panDoc}`,
                            //         httpHeaders:{
                            //             mode:'no-cors',
                            //             'Sec-Fetch-Mode': 'no-cors',
                            //         }
                            //     }}

                                
                            //   >
                            //       <Page pageNumber={pageNumberPan} />
                            //       <div className="pageControl">
                            //           {
                            //               pageNumberPan>1&&
                            //               <span className="prev" onClick={()=>setPageNumberPan(pageNumberPan-1)} >Prev</span>
                            //           }
                            //           <span className="page" >{pageNumberPan}</span>
                            //           {
                            //               pageNumberPan<numPagesPan&&
                            //               <span className="next" onClick={()=>setPageNumberPan(pageNumberPan+1)} >Next</span>

                            //           }
                            //       </div>
                            //   </Document>

                            <iframe src={formik.values.panDoc} title="education cert" height="360px"  />


                              :
                                <div>
                                    <div>
                                        <img src={formik.values.panDoc} alt="" style={{height: '100px', cursor: 'pointer'}} onClick={()=>window.location.assign(formik.values.panDoc)}/>
                                    </div>
                                    <div onClick={()=>handleImageClick(formik.values.panDoc, 'pan-card')} style={{border: '1px solid #00A1FC', borderRadius: '15px', width: 'fit-content', padding: '5px 10px', display: 'flex', alignItems: 'center', color: 'white', background: '#00A1FC', cursor: 'pointer'}}>
                                        <DownloadIcon />Download
                                    </div>
                                </div>
                                    }
                                    </> :
                                    <p>No PAN Available</p>
                                            }
                                </div>
                             
                                }
                                
                            </Grid>
                            <Grid item md={4} >

                            </Grid>
                            
                            <Grid item md={4} >
                                <p className="viewTitle" style={{textAlign:'right', justifyContent: 'end',}} onClick={()=>setShowCert(!showCert)} >
                                    View Education Qualification Certificate {!showCert ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
                                    </p>
                                   

{
                                    showCert&&
                                        <div className="certImageCont">
                                            {
                                              formik.values.eduDoc ?
                                              <>
                                             {console.log('formik.values.eduDoc', formik.values.eduDoc)}
                                    {
                                        formik.values.eduDoc && formik.values.eduDoc.split('').reverse().join('').split('.')[0].split('').reverse().join('')==='pdf'?
                            //     <Document
                            //     onLoadSuccess={onDocumentLoadSuccessEdu}
                            //     loading={  <Lottie options={defaultOptions}
                            //     height={200}
                            //     width={200}
                            //     isStopped={true}
                            //     isPaused={false}
                            //     />}

                            //     file={{
                            //         url:`https://d2qt3b3csf1vza.cloudfront.net/${formik.values.eduDoc}`,
                            //         httpHeaders:{
                            //             'Sec-Fetch-Mode': 'no-cors',
                            //         }
                            //     }}
                            //   >
                            //       <Page
                            //       width={300}
                            //       height={200}
                            //       scale={2}
                            //       pageNumber={pageNumberEdu} />
                            //       <div className="pageControl">
                            //           {
                            //               pageNumberEdu>1&&
                            //               <span className="prev" onClick={()=>setPageNumberEdu(pageNumberEdu-1)} >Prev</span>
                            //           }
                            //           <span className="page" >{pageNumberEdu}</span>
                            //           {
                            //               pageNumberEdu<numPagesEdu&&
                            //               <span className="next" onClick={()=>setPageNumberEdu(pageNumberEdu+1)} >Next</span>

                            //           }
                            //       </div>
                            //   </Document>
                            <iframe src={formik.values.eduDoc} title="education cert"  height="360px"  />
                              :

                              <div>
                                    <div>
                                        <img src={formik.values.eduDoc} alt="" style={{height: '100px', cursor: 'pointer'}} onClick={()=>window.location.assign(formik.values.eduDoc)}/>
                                    </div>
                                    <div onClick={()=>handleImageClick(formik.values.eduDoc, 'certificate')} style={{border: '1px solid #00A1FC', borderRadius: '15px', width: 'fit-content', padding: '5px 10px', display: 'flex', alignItems: 'center', color: 'white', background: '#00A1FC',cursor: 'pointer'}}>
                                        <DownloadIcon />Download
                                    </div>
                                </div>
                                

                                    }
                                    </> :
                                    <p>No Certificate Available</p>
                                            }
                                </div>
                             
                                }
                            </Grid>
                            
                        </Grid>
                        {/* GRID CONTAINER 2 END */}

                        
                </Grid>
            </MainContent>
            
        </Wrapper>
    )
}

export default ViewDoc

const Wrapper = styled.div`

    background-color:white;
    margin:20px auto;
    padding:25px;
    border-radius:12px;
    box-shadow: 0px 0px 3px 0px #7e7b7b8a;
    .heading{
        display:flex;
        align-items: center;
        gap:0 5px;
        margin-bottom:30px;
        margin-top:20px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color:#333333;
        }
        .titleIcon{
            height:25px;
            object-fit: contain;
        }
    }
    
`
const MainContent = styled.div`
    padding:10px;
    transition: all 1s ease-in-out;
    .MuiOutlinedInput-root{
        font-size:16px;
        color:#333333;
    }
    .MuiInputLabel-root{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #7C7C7C;
        padding-bottom:7px;
    }
    .MuiOutlinedInput-input.Mui-disabled{
        -webkit-text-fill-color:#333333;
        color:#333333;
        text-transform:uppercase;
    }
    .viewTitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        color: #00A1FC;
        padding-bottom:10px;
        cursor: pointer;
        white-space: normal;
        display: flex;
        align-items: center;
    }
    .panImageCont{
        width:100%;
        display: flex;
        justify-content: start;
        img{
            
            object-fit: contain;
            // max-height:150px;
            border-radius: 20px
        }
        p{
            font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        }
        .pageControl{
            text-align:center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
           position: relative;
            bottom: 51px;
            background-color: #efefef;
            width: min-content;
            margin: auto;
            padding: 5px 0px;
            border-radius: 3px;
            box-shadow: 1px 2px 0px 0px #c5c5c5;
           
            .next{
                text-transform: capitalize;
                cursor: pointer;
                padding:5px 5px;
                border-radius: 3px;
                transition: all 0.2s ease-in-out;
                :hover{
                    background-color: grey;
                    color:white;
                }
            }
            .prev{
                text-transform: capitalize;
                cursor: pointer;
                padding:5px 5px;
                border-radius: 3px;
                transition: all 0.2s ease-in-out;
                :hover{
                    background-color: grey;
                    color:white;
                }
            }
            .page{
                background-color: white;
                padding: 5px 14px;
                font-weight:600;
            }
        }
    }
    .certImageCont{
        width:100%;
        display: flex;
        justify-content: end;
        img{
            object-fit: contain;
            height:100px;
            // width: 200px;
            border-radius: 20px
        }
        p{
            font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        }
        .pageControl{
            text-align:center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
           position: relative;
            bottom: 51px;
            background-color: #efefef;
            width: min-content;
            margin: auto;
            padding: 5px 0px;
            border-radius: 3px;
            box-shadow: 1px 2px 0px 0px #c5c5c5;
           
            .next{
                text-transform: capitalize;
                cursor: pointer;
                padding:5px 5px;
                border-radius: 3px;
                transition: all 0.2s ease-in-out;
                :hover{
                    background-color: grey;
                    color:white;
                }
            }
            .prev{
                text-transform: capitalize;
                cursor: pointer;
                padding:5px 5px;
                border-radius: 3px;
                transition: all 0.2s ease-in-out;
                :hover{
                    background-color: grey;
                    color:white;
                }
            }
            .page{
                background-color: white;
                padding: 5px 14px;
                font-weight:600;
            }
        }
    }
`