import React from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';

const BecomeAgentStrip = () => {
  return (
      <Wrapper>
        <Grid container>
                <Grid item md={1} >
                
                </Grid>
                <Grid item md={11} >
                    <Content>
                        BECOME A COVERSURE POSP AND START EARNING
                    </Content>
                </Grid>
            </Grid>
      </Wrapper>
  )
}

export default BecomeAgentStrip

const Wrapper = styled.div`
border-bottom: 1px solid #C4C4C4;
padding:17px 10%;
@media only screen and (max-width: 600px){
    display:none;
}
`

const Content = styled.div`
display:block;
font-family: 'Outfit';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 129.3%;
letter-spacing: 0.125em;
color: #C4C4C4;


`