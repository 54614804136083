import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import pendingicon from '../../assets/Image/pending-icon.svg';
import rejectedIconGrey from '../../assets/Image/rejected-icon-grey.svg';
import rejectedIconRed from '../../assets/Image/rejected-icon-red.svg';
import approvedIconGrey from '../../assets/Image/approved-icon-grey.svg';
import approvedIconGreen from '../../assets/Image/approved-icon-green.svg';
import downloadIconblue from '../../assets/Image/download-blue.svg';
import downloadIconGrey from '../../assets/Image/download-gray.svg';
import uploadIconGrey from '../../assets/Image/bulk-upload-icon-grey.svg';
import uploadIconBlue from '../../assets/Image/bulk-upload-icon-blue.svg';

import {useLocation,Link,useParams,useNavigate} from 'react-router-dom';
import {useDispatch,useSelector} from 'react-redux';
import {getMaster} from '../../redux/actions/getMasterList';
import { Grid } from '@mui/material';


const HeaderAdmin = () => {
   
    const dispatch = useDispatch();
    const masterList = useSelector(state=>state.masterList.accountType);
    const location = useLocation();
    const navigate = useNavigate();

    const params = useParams();

    const [user, setUser] = useState({userId:0,userRole:0});


  
    const checkLogin=()=>{
        const authData = sessionStorage.getItem('authData');
        const token = sessionStorage.getItem('token');
        const userData = JSON.parse(sessionStorage.getItem('authData'));
        
        const lastActive = JSON.parse(sessionStorage.getItem('lastActive'));

        if(!token){
            navigate('/admin/login');
            return
        }
                
        const currentTime = new Date().getTime();
        
        const sessionTime = 20*60*1000;

        if(
            lastActive&&
             (currentTime-Number(lastActive))>sessionTime
        ){
            sessionStorage.removeItem('authData');
            sessionStorage.removeItem('token');
            sessionStorage.clear()
            alert('session expired, please login again to continue');
            navigate('/admin/login');
            return
        }
        
        setUser(userData);
        sessionStorage.setItem('lastActive',new Date().getTime());

    }

    const handleLogout=()=>{
        sessionStorage.removeItem('authData');
        sessionStorage.removeItem('token');
        sessionStorage.clear()
        navigate('/admin/login');
    }

    useEffect(()=>{
        checkLogin();
        if(masterList.length===0){
            dispatch(getMaster());
        }

    },[])

    return (
        <Wrapper>
            <Grid container spacing={1} justifyContent="space-around" style={{width: '100%'}} >

            <Grid item style={{paddingLeft: '0'}} md={1.5}>
                <LogoWrapper >
                    <a href="/admin/pending">
                        <img src={require('../../assets/Image/coverSureLogo.svg').default} alt="" />
                    </a>
                </LogoWrapper>
            </Grid>

            <Grid item style={{paddingLeft: '0'}} md={2}>
                <PendingTitle 
                className={location.pathname==='/admin/pending'||(params&&params.penId)?'tab activeTab':'tab'}
                style=
                    {{borderBottom: location.pathname==='/admin/pending'||(params&&params.penId)?'2px solid #ED1C24':'none', display: 'flex', justifyContent: 'center'}} 
                    >
                    <Link to='/admin/pending'>
                    <div className='container' >
                        <img src={pendingicon} alt="" className="pendingIcon" />
                        <p 
                        className={location.pathname==='/admin/pending'?'activeTitle':''}
                        style=
                        {{color:location.pathname==='/admin/pending'||(params&&params.penId)?'#ED1C24':'#727B85'}} 
                        >PENDING POSP APPROVAL</p>
                    </div>
                    </Link>
                </PendingTitle>
            </Grid>

            <Grid item style={{paddingLeft: '0'}} md={2} >
                <RejectedTitle
                className={location.pathname==='/admin/rejected'||(params&&params.rejId)?'tab activeTab':'tab'}
                style={{borderBottom:location.pathname==='/admin/rejected'||(params&&params.rejId)?'2px solid #ED1C24':'none', display: 'flex', justifyContent: 'center'}} >
                    <Link to='/admin/rejected'>
                        <div className='container' >
                        {
                            location.pathname==='/admin/rejected'||(params&&params.rejId)?
                                <img src={rejectedIconRed} alt="" className="rejectedIcon" />
                            :
                                <img src={rejectedIconGrey} alt="" className="rejectedIcon" />
                        }
                            <p className={location.pathname==='/admin/rejected'?'activeTitle':''} style={{color:location.pathname==='/admin/rejected'||(params&&params.rejId)?'#FF0000':'#727B85'}} >REJECTED POSP APPROVAL</p>
                        </div>
                    </Link>
                </RejectedTitle>
            </Grid>

            <Grid item style={{paddingLeft: '0'}} md={2.2}>
                <ApprovedTitle 
                className={location.pathname.includes('/admin/approved')||(params&&params.appId)?'tab activeTab':'tab'}
                style={{borderBottom:location.pathname.includes('/admin/approved')||(params&&params.appId)?'2px solid #ED1C24':'none', display: 'flex', justifyContent: 'center'}} >
                    <Link to='/admin/approved'>
                        <div className='container' >
                            {
                                location.pathname.includes('/admin/approved')||(params&&params.appId)?
                                <img src={approvedIconGreen} alt="" className="approvedIcon" />:
                                <img src={approvedIconGrey} alt="" className="approvedIcon" />
                            }
                            <p 
                            className={location.pathname.includes('/admin/approved')?'activeTitle':''}
                            style={{color:location.pathname.includes('/admin/approved')||(params&&params.appId)?'#ED1C24':'#727B85'}} >APPROVED POSP APPROVAL</p>
                        </div>
                    </Link>
                </ApprovedTitle>
            </Grid>
            <Grid item style={{paddingLeft: '0'}} md={1.8}>
                <DownloadTitle 
                className={location.pathname.includes('/admin/download-report') ? 'tab activeTab' : 'tab'}
                style={{borderBottom:location.pathname.includes('/admin/download-report') ? '2px solid #ED1C24' : 'none', display: 'flex', justifyContent: 'center'}} >
                    <Link to='/admin/download-report'>
                        <div className='container' >
                            {
                                location.pathname.includes('/admin/download-report')?
                                <img src={downloadIconblue} alt="" className="approvedIcon" />:
                                <img src={downloadIconGrey} alt="" className="approvedIcon" />
                            }
                            <p 
                            className={location.pathname.includes('/admin/download-report') ? 'activeTitle' : ''}
                            style={{color:location.pathname.includes('/admin/download-report') ? '#ED1C24' : '#727B85' }}>DOWNLOAD REPORT</p>
                        </div>
                    </Link>
                </DownloadTitle>
            </Grid>

{/* {
    user.userRole===2&&
            <Grid item style={{paddingLeft: '0'}} md={2}>
                <BulkUploadTitle 
                className={location.pathname==='/admin/bulk-upload'?'tab activeTab':'tab'}
                style={{borderBottom:location.pathname==='/admin/bulk-upload'?'2px solid #ED1C24':'none'}} >
                    <Link to='/admin/bulk-upload'>
                    <div className='container' >
                        {
                            location.pathname==='/admin/bulk-upload'?
                            <img src={uploadIconBlue} alt="" className="bulkuploadIcon" />:
                            <img src={uploadIconGrey} alt="" className="bulkuploadIcon" />
                        }
                            <p
                            className={location.pathname==='/admin/bulk-upload'?'activeTitle':''}
                            style={{color:location.pathname==='/admin/bulk-upload'?'#ED1C24':'#727B85'}} >UPLOAD BULK POSP DATA</p>
                    </div>
                        </Link>
                </BulkUploadTitle>
            </Grid>

} */}


            <Grid item style={{paddingLeft: '0'}} md={1}>
                <PowerIcon style={{ display: 'flex', justifyContent: 'center'}}>
                {/* <Link to='/admin/login'> */}
                    <PowerSettingsNewIcon style={{color:'#727B85',cursor:'pointer'}} onClick={()=>handleLogout()}  />
                    {/* </Link> */}
                </PowerIcon>

            </Grid>

            </Grid>
            
        </Wrapper>
    )
}

export default HeaderAdmin

const Wrapper = styled.div`
    font-family: Montserrat;
    display:flex;
    align-items: center;
    justify-content: space-around;
    height:77px;
    padding: 0 10px;
    @media only screen and (max-width: 1000px) {
        height: auto;
    }
    a{
        text-decoration:none;
        color:inherit;
    }
    .tab{
        /* padding:0 20px; */
        // margin:0 20px;
        /* padding:0px 10px; */
    }
    
`
const LogoWrapper = styled.div`
    display:flex;
    align-items: center;
    width:100%;
    height:100%;
    img{
        height:56px;
        object-fit: contain;
        @media only screen and (max-width: 1100px) {
            height: 45px;
        }
    }
`
const PendingTitle = styled.div`
    display:flex;
    width:100%;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 13px;
    height:100%;
    .container{
        display:flex;
        align-items: center;
        height:77px;
        p{
            /* text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2); */
        }
    }
    p{
        font-weight: 400;
    }
    .activeTitle{
        color:#ED1C24;
        text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2);

        /* box-shadow: 0 0 1px rgb(255 0 0); */

    }
    .pendingIcon{
        height:25px;
        padding-right: 10px;
    }
`
const RejectedTitle = styled.div`
    width:100%;
    display:flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 13px;
    height:100%;
    .container{
        display:flex;
        align-items: center;
        height:77px;
    }
    p{
        font-weight: 400;
    }
    .activeTitle{
        color:'#ED1C24';
        text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2);
    }
    .rejectedIcon{
        height:25px;
        padding-right: 10px;
    }
`
const ApprovedTitle = styled.div`
width:100%;
    display:flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 13px;
    height:100%;
    .container{
        display:flex;
        align-items: center;
        height:77px;
    }
    p{
        font-weight: 400;
    }
    .activeTitle{
        color:'#ED1C24';
        text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2);
    }
    .approvedIcon{
        height:25px;
        padding-right: 10px;
    }
`

const DownloadTitle = styled.div`
width:100%;
    display:flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 13px;
    height:100%;
    .container{
        display:flex;
        align-items: center;
        height:77px;
    }
    p{
        font-weight: 400;
    }
    .activeTitle{
        color:'#ED1C24';
        text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2);
    }
    .approvedIcon{
        height:25px;
        padding-right: 10px;
    }
`
const BulkUploadTitle = styled.div`
    display:flex;
    width:100%;
    align-items: center;
    display:flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-size: 13px;
    height:100%;
    .container{
        display:flex;
        align-items: center;
        height:77px;
    }
    p{
        font-weight: 400;
    }
    .activeTitle{
        color:'#ED1C24';
        text-shadow: 0px 2px 4px rgba(255, 0, 0, 0.2);
        
    }
    .bulkuploadIcon{
        height:25px;
        padding-right: 10px;
    }
`
const PowerIcon = styled.div`
    height:100%;
    display:flex;
    align-items: center;
    border-right:1px solid #80808073;
    border-left:1px solid #80808073;
    padding:0 34px;
    // margin-left:10px;
`



