import React from "react";
import styled from "styled-components";

const BenefitCard = ({ image, heading, text }) => {
  return (
    <Wrapper>
      <Image>
        <img src={image} alt="" />
      </Image>
      <Heading>
        <p>{heading}</p>
      </Heading>
      <Text>
        <p>{text}</p>
      </Text>
    </Wrapper>
  );
};

export default BenefitCard;

const Wrapper = styled.div`
  padding: 35px 30px;
  width: 260px;
  height: 370px;
  background-color: #ffff;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;
  border-radius: 10px;
  flex-shrink: 0;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 23%);
  :hover {
    box-shadow: 0px 0px 5px 0px #2959E5;
    transform: scale(1.009);
    /* z-index: -1; */
    img {
      opacity: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 23px 16px;
    width: 197px;
    height: 332px;
    border: 1px solid #808080b0;
    :hover {
      box-shadow: none;
    }
  }
`;
const Image = styled.div`
  display: flex;
  justify-content: center;
  height: 130px;
  img {
    transition: all ease-in-out 0.3s;
    margin: auto;
    opacity: 60%;
    /* width:50%; */
    height: 100%;
  }
`;
const Heading = styled.div`
  margin: 24px 0;
  /* color:#313131; */
  p {
    font-family: "Sora";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    /* text-align: left; */
    color: #434552;
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 16px;
    }
  }
`;

const Text = styled.div`
  p {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 142.8%;
    color: #434552;
  }
  @media only screen and (max-width: 600px) {
    p {
      font-size: 14px;
    }
  }
`;
