import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import {
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Box,
    Button,
    OutlinedInput,
    InputAdornment
    } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { createTheme,ThemeProvider } from '@mui/material/styles';
import doneIcon from '../assets/Image/done-tick.svg';
import {savePosp,uploadFile} from '../api/index';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BecomeAgentStrip from './BecomeAgentStrip';


const VerifyDetail = ({step,setStep,formik, managerDetail}) => {

const navigate = useNavigate();
   
    const accntType = useSelector(state=>state.masterList.accountType);
    

    const [maxdob, setMaxdob] = useState('');
    const [showErr, setshowErr] = useState(false);



    const calTheme = createTheme({
        typography:{
            fontFamily:[
                'Outfit',
                'Sora'
            ].join(',')
        },
        components:{
            MuiOutlinedInput:{
                root:{
                    "&:-webkit-autofill":{
                        color:'red'
                    }
                }
            },
            MuiPaper:{
                styleOverrides:{
                    root:{
                        width:'350px',
                        borderRadius:'10px',
                        "&::-webkit-scrollbar":{
                            height:"8px",
                            width:'4px',
                            borderRadius:'20px'
                        },
                        "&::-webkit-scrollbar-thumb":{
                            backgroundColor:'#c9c6c6',
                            borderRadius:'20px'
                        }
                        
                    }
                }
            },
            MuiMenu:{
                styleOverrides:{
                    list:{
                        fontFamily:'Outfit',
                        padding:'0px'
                    }
                }
            }
           
        }
    })




    const handlePanInpChange=(e)=>{
        formik.setFieldValue('panImage',e.target.files[0])
    }
    const handleEducertInpChange=(e)=>{
        formik.setFieldValue('eduCertImage',e.target.files[0]);
    }

    const handleminDob =()=>{
        let date = new Date();
        let month = date.getMonth();
        
        let dt = date.getDate();
        if(dt<10)
        {
          dt='0'+dt
        }
        let year = date.getFullYear()-18;
        // setMaxdob(`${year}/${month}/${dt}`);
        setMaxdob(new Date(year,month,dt));

    }



    const browseBtn =(e)=>{
        const panInp = document.getElementById('panCardV');
        const eduCertInp = document.getElementById('eduCertV');
        // console.log(e);
        if(e.target.name==='panCard'){
            panInp.click();
        }
        if(e.target.name==='eduCert'){
            eduCertInp.click();
        }
    }

    const handleDateChange = (val) => {
        formik.setFieldValue('dob',val);
        // setDateVal(val);
      };

      const handleAccNumberChange =(e)=>{ 
        formik.setFieldValue('accNumber',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));
    }

      const onChangeMobile = (e) => {
        formik.setFieldValue('mobNumber',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));    
      }

      const handleNextStep=async()=>{


           // --------checking for session expire----------
           const lastActiveAgent = JSON.parse(sessionStorage.getItem('lastActiveAgent'));
           const currentTime = new Date().getTime();
           const sessionTime = 30 * 60 * 1000;
           if(
               lastActiveAgent&&
              ( (currentTime-Number(lastActiveAgent))>sessionTime )
              &&(step>0||step<=5)
          ){
              sessionStorage.removeItem('lastActiveAgent');
              alert('session expired,session is valid for 30 Minutes');
              setStep(0);
              navigate('/');
              return
          }
          sessionStorage.setItem('lastActiveAgent',new Date().getTime());
          
       //    -------------session expire end--------------------------


        formik.setFieldTouched('firstName',true);
        const redirect = JSON.parse(sessionStorage.getItem('redirect'));

        console.log(formik.errors)

        if(
            !formik.values.firstName||
            formik.errors.email||
            formik.errors.mobNumber||
            formik.errors.panNumber||
            formik.errors.firstName||
            formik.errors.lastName||
            formik.errors.dob||
            formik.errors.address||
            formik.errors.pinCode||
            formik.errors.cityName||
            formik.errors.state||
            formik.errors.eduQual||
            formik.errors.state || formik.errors.nomineeName || formik.errors.gender
        ){
            formik.setFieldTouched('firstName',true);
            setshowErr(true);
            // return
        }

       
        
        const saveData = {
                applicantid:formik.values.applicantid,
                partnerid: formik.values.partnerid,
                partnername:redirect?redirect.partnerName:'NA',
                partneruserid:redirect?redirect.partneruserid:'',
                pospcode:'',
                email:formik.values.email,
                mobileno:formik.values.mobNumber,
                pannumber:formik.values.panNumber,
                initial:formik.values.initials,
                firstname:formik.values.firstName,
                lastname:formik.values.lastName?formik.values.lastName:'-',
                dob:formik.values.dob,
                address:formik.values.address,
                pincode:formik.values.pinCode,
                city:formik.values.cityName,
                state:formik.values.state,
                educationQ:Number(formik.values.eduQual),
                others:formik.values.othersEdu,
                accountno:formik.values.accNumber,
                ifscCode:formik.values.ifsc,
                bankname:formik.values.bankName,
                bankbranch:formik.values.bankBranch,
                accounttype:formik.values.accType,
                accountholdername:formik.values.accHolderName,
                nomineeName: formik.values.nomineeName,
                gender: formik.values.gender,
                pageno: 5,
                managerUserId: formik.values.managerUserId,
        }

        const formData = new FormData();
        formData.append("pan",formik.values.panImage);
        formData.append("edu",formik.values.eduCertImage);
        formData.append("applicatid",JSON.stringify({applicantid:formik.values.applicantid}));

        try {
            await savePosp(saveData);
            // await uploadFile(formData);
            setStep(5);
        } catch (error) {
            alert('something went wrong in API, skipping for Testing')
            console.log('verify doc err');
            setStep(5);
        }


        formik.setFieldTouched('firstName',true);
        setshowErr(true);






      }

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          handleminDob();

          
    },[])

    return (
        <>
        <BecomeAgentStrip />
        <Wrapper>
            <ThemeProvider theme={calTheme}>
                <Grid container className='mainContainer' spacing={2} justifyContent="center"  >
                <Grid item md={0.5} className="heading">
              <ArrowBackIosNewIcon
                  fontSize="large"
                  onClick={() => setStep(3)}
                />
          </Grid>
                    <Grid container item xs={12} md={5} direction='row' spacing={2} >
                        <Grid item>
                            <Heading>
                                {/* <div className="heading">
                                    <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(3)} />
                                    <p>Become a Hello Zindagi agent</p>
                                </div> */}
                                <p className='title' >
                                        Verify Details
                                    <img className='titleImageDesktop' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                <img className='titleImageMobile' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                </p>
                            </Heading>
                        </Grid>

                        <Grid item xs={12} md={10} >
                            <BasicInfo>
                                <div className="heading">
                                    <p>Basic Information: </p> <img src={require('../assets/Image/edit-icon.svg').default} alt="" onClick={()=>setStep(1)} />
                                </div>
                                <div className="content name">
                                   <span className="title">Name: </span> <span className='text' >{`${formik.values.firstName} ${formik.values.lastName}`}</span>
                                </div>
                                <div className="content address">
                                   <span className="title">Address: </span> <span className='text' >{`${formik.values.address}, ${formik.values.cityName},${formik.values.state}, ${formik.values.pinCode}`}</span>
                                </div>
                                <div className="content email">
                                   <span className="title">Email ID: </span> <span className='text' >{`${formik.values.email}`}</span>
                                </div>
                                <div className="content dob">
                                   <span className="title">Date of Birth: </span> <span className='text' >{`${moment(formik.values.dob).format("LL")}`}</span>
                                </div>
                                <div className="content dob">
                                   <span className="title">Gender: </span> <span className='text' style={{textTransform: 'capitalize'}} >{`${formik.values.gender}`}</span>
                                </div>
                                <div className="content mobile">
                                   <span className="title">Mobile Number: </span> <span className='text' >{`${formik.values.mobNumber}`}</span>
                                </div>
                                <div className="content eduqualification">
                                   <span className="title">Education Qualification: </span> <span className='text' >
                                       {Number(formik.values.eduQual)===1&&"Class X"}
                                       {Number(formik.values.eduQual)===2&&"Class XII"}
                                       {Number(formik.values.eduQual)===3&&"Graduate"}
                                       {Number(formik.values.eduQual)===4&&"Post Graduate"}
                                       {Number(formik.values.eduQual)===5&&formik.values.othersEdu}
                                       
                                    </span>
                                </div>
                                <div className="content dob">
                                   <span className="title">Nominee Name: </span> <span className='text' >{`${formik.values.nomineeName}`}</span>
                                </div>
                            </BasicInfo>
                        </Grid>
                        {
                            formik.values.accType!==0&&
                        <Grid item xs={12} md={10} >
                            <BankInfo>
                                <div className="heading">
                                    <p>Bank Details: </p> <img src={require('../assets/Image/edit-icon.svg').default} alt="" onClick={()=>setStep(2)} />
                                </div>
                                <div className="content name">
                                   <span className="title">Account Holder Name: </span> <span className='text' >{`${formik.values.accHolderName}`}</span>
                                </div>
                                <div className="content address">
                                   <span className="title">Account Number: </span> <span className='text' >{`${formik.values.accNumber}`}</span>
                                </div>
                                <div className="content email">
                                   <span className="title">Account Type: </span> 
                                   <span className='text' >
                                       {accntType&&accntType.length>0&&
                                       accntType.filter(el=>el.accountTypeid===formik.values.accType)[0].accountName}
                                    
                                    </span>
                                </div>
                                <div className="content dob">
                                   <span className="title">IFSC Code: </span> <span className='text' >{`${formik.values.ifsc}`}</span>
                                </div>
                                <div className="content dob">
                                   <span className="title">Bank Name: </span> <span className='text' >{`${formik.values.bankName}`}</span>
                                </div>
                                
                                
                            </BankInfo>
                        </Grid>

                        }

                        <Grid item xs={12} md={10} >
                            <BasicInfo>
                                <div className="heading">
                                    <p>Manager Details</p>
                                </div>
                                <div className="content name">
                                   <span className="title">Manager Code: </span> <span className='text' >{managerDetail?.userName}</span>
                                </div>
                                <div className="content address">
                                   <span className="title">Manager Name: </span> <span className='text' >{managerDetail?.firstName} {managerDetail?.lastName}</span>
                                </div>
                                <div className="content email">
                                   <span className="title">Location: </span> <span className='text' >{managerDetail?.city}</span>
                                </div>
                            </BasicInfo>
                        </Grid>
                       
                        
 

                        <Grid item xs={12} md={10} >
                            <input type="file" accept="image/*,.pdf" name='panCard' className='hiddenInp' id='panCardV' onChange={handlePanInpChange} />
                            <OutlinedInput
                               
                                // value={10}
                                fullWidth
                                className="panCard"
                                name='panCard'
                                disabled={true}
                                startAdornment=
                                {
                                <InputAdornment position="start">
                                    {formik.values.panImage?
                                    <img src={doneIcon} alt="" />:
                                    // <CheckCircleOutlineIcon fontSize='large' style={{color:'#00E733',fontSize:'25px'}} />:
                                    <UploadIcon fontSize='large' style={{color:'#A3A3A3',fontSize:'25px'}} />}
                                </InputAdornment>
                                        
                                }
                                endAdornment={
                                    <InputAdornment   position="end" >
                                        {formik.values.panImage&&
                                        <>
                                       <fieldset className="reupload" name='panCard' onClick={(e)=>browseBtn(e)} >
                                            Re-upload PAN
                                        </fieldset>
                                        </>
                                        }
                                    </InputAdornment>
                                    }

                                placeholder={formik.values.panImage?formik.values.panImage.name:'Upload PAN card copy'}
                                onClick={formik.values.panImage?null:browseBtn}
                            />
                        {showErr &&
                        <p className="errorText">
                            {formik.errors.panImage}
                        </p>
                        }
                        </Grid>


                        <Grid item xs={12} md={10} >
                        <input type="file" name='eduCert' className='hiddenInp' id='eduCertV'
                        accept="image/*,.pdf"
                         onChange={handleEducertInpChange} />

                            <OutlinedInput
                                
                                // value={10}
                                fullWidth
                                className="eduCert"
                                name='eduCert'
                                disabled={true}
                                startAdornment=
                                    {
                                    <InputAdornment position="start">
                                        {formik.values.eduCertImage?
                                         <img src={doneIcon} alt="" />:
                                        // <CheckCircleOutlineIcon fontSize='large' style={{color:'#00E733',fontSize:'25px'}} />:
                                        <UploadIcon fontSize='large' style={{color:'#A3A3A3',fontSize:'25px'}} />}
                                    </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end" >
                                            {formik.values.eduCertImage&&
                                            <>
                                            <fieldset className="reupload" name='eduCert' onClick={browseBtn} >
                                                Re-upload Education Certificate
                                            </fieldset>
                                            </>
                                            }
                                        </InputAdornment>
                                        }
                                placeholder={formik.values.eduCertImage?formik.values.eduCertImage.name:'Upload Education Certificate Copy'}
                                onClick={formik.values.eduCertImage?null:browseBtn}
                                
                            />
                            {showErr &&
                        <p className="errorText">
                            {formik.errors.eduCertImage}
                        </p>
                        }
                                        
                        </Grid>

                        <Grid item xs={12} md={10} >
                            {/* <Link to='/success'> */}
                                <Button className='submitBtn' variant="contained"
                                onClick={handleNextStep}
                                 >
                                    Save and Continue
                                </Button>

                            {/* </Link> */}
                        </Grid>
                            
                    </Grid>

                    <Grid container item xs={0} md={5} className='rightHero' >
                        <img src={require('../assets/Image/verify-details.svg').default} alt="" />
                    </Grid>

                </Grid>
                </ThemeProvider>
        </Wrapper>
        </>
    )
}

export default VerifyDetail

const Wrapper = styled.div`
 padding:0 8%;
 padding-bottom: 2%;
 .mainContainer{
    padding-top: 40px;
    .heading{
      .MuiSvgIcon-root {
      color: #2959E5;
      cursor: pointer;
    }
    }
  }
    a{
        text-decoration: none;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        /* margin-top:10px; */
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size:18px;
        :hover{
            .MuiOutlinedInput-notchedOutline{
                border-color: #59BA9B;
                border-width: 1px;
            }
        }
        :-webkit-autofill{
            color:red;
        }
    }
    .MuiOutlinedInput-root.Mui-error{
        .MuiOutlinedInput-notchedOutline{
                border-width: 1px;
                border-color: #ED1C24;
            }
    }
    .textInp{
        font-family: 'Outfit';
        font-size: 18px;
        border: 1px solid #BFC0C5;
        border-radius: 10px;
        padding: 15px 13px;
        margin-right: 20px;
        margin-top:20px;
        outline-color: #ED1C2485;
    }
    .panCard,.eduCert{
        background-color: #F1F1F1;
    }

    .panInput{
    /* margin-top:20px; */
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    .MuiOutlinedInput-input{
        text-transform: uppercase;
    }
}
.errorText{
        font-family: 'Outfit';
       color:#ED1C24;
       font-size:14px;
       margin-left:8px;
       margin-top:3px;
       
   }
    
   
    .dateInput{
        width: 60%;
    }
    .eduTitle{
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        /* margin-top: 5px; */
        color:#434552;
    }
    .MuiFormControlLabel-root{
        border:2px solid #BFC0C5;
        padding:3px 1px;
        border-radius: 8px;
        margin:5px 8px;
        color:#787878;
        padding-right:20px;
    }
    .eduWrap{
        margin-top:15px;
    }
    .eduWrapMobile{
        display:none;
    }
    .submitBtn{
        padding: 9px 60px;
        border:none;
        outline:none;
        border-radius: 10px;
        color:white;
        font-size: 20px;
        text-align: center;
        width: 100%;
        text-transform: none;
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        background-color: #2959E5;
            box-shadow: 2px #2959E5;
        :hover{
            background-color: #2959E5;
            box-shadow: 2px #2959E5;
        }
    }
    .rightHero{
        justify-content: center;
        margin-top: 80px;
        align-content: baseline;
        img{
            object-fit: contain;
        }
    }

    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: #FCBC30;
                border-width: 1px;
            }
    }
    .MuiInputLabel-outlined.Mui-focused{
        color:#333333;
    }

  
  
    .MuiFormLabel-root.MuiInputLabel-root{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color:#888888;
    }

    .hiddenInp{
        display:none;
    }
    .reupload{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 126.3%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #00C2FF;
        border:none;
    }

    @media only screen and (max-width: 600px){
        padding:1% 4%;

        .mainContainer{
      .heading{
        display:none;
      }
    }
        .eduTitle{
            font-size:18px;
        }
        .eduWrapMobile{
            display:inline-block;
        }
        .eduWrapDesktop{
            display:none;
        }

        .MuiOutlinedInput-root{
            font-size:16px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-size:16px;
        }

        .rightHero{
            display:none;
        }
        .submitBtn{
            border-radius:7px;
        }
    }

`
const Heading = styled.div`
/* padding-top: 40px; */
    .heading{
        display:flex;
        align-items: center;
            .MuiSvgIcon-root{
                color:#ED1C24;
                margin-left: -46px;
                cursor: pointer;
            }
      
        p{
            font-family: 'Outfit';
            font-size: 20px;
            padding-left:10px;
            color:#C4C4C4;
        }
    }
    .title{
        font-family: 'Sora';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin:10px 0;
        display: flex;
        align-items: center;
        gap:10px;
        color:#434552;
        img{
            height:22px;
            object-fit: contain;
        }
    }

    .titleImageMobile{
        display:none;
    }

    @media only screen and (max-width: 600px){
        .title{
            font-size:18px;
        }
        .titleImageMobile{
            display:inline-block;
         }
        .titleImageDesktop{
            display:none;
        }
        .heading{
            display:none;
        }
    }
    
`

const BasicInfo = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    /* width:100%; */
    padding:25px 25px;
    margin-left:3px;
    font-family: 'Outfit';
    font-style: normal;
    color:#434552;
    display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    .heading{
        display:flex;
        justify-content: space-between;
        margin-bottom:25px;
        p{
            font-weight: 600;
            font-size:20px;
        }
        img{
            object-fit: contain;
            width:24px;
            cursor:pointer;
        }
    }
    .content{
        
        font-size:16px;
        margin:8px 0;
        .title{
            font-weight:500;
            margin-right: 5px;
        }
        .text{
            font-weight: 300;
        }

    }

`
const BankInfo = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    /* width:100%; */
    padding:25px 25px;
    margin-left:3px;
    font-family: 'Outfit';
    font-style: normal;
    color:#434552;
    display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    .heading{
        display:flex;
        justify-content: space-between;
        margin-bottom:25px;
        p{
            font-weight: 600;
            font-size:20px;
        }
        img{
            object-fit: contain;
            width:24px;
            cursor:pointer;
        }
    }
    .content{
        
        font-size:16px;
        margin:8px 0;
        .title{
            font-weight:500;
            margin-right: 5px;
        }
        .text{
            font-weight: 300;
        }

    }

`