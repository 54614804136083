import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import HeaderAdmin from '../HeaderAdmin'
import BulkTable from './BulkTable';
import DropFile from './DropFile';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import { fetchFileUploadDetails } from '../../../api';
import { useNavigate } from 'react-router-dom';

const BulkUpload = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [user, setUser] = useState({userId:0,userRole:0});

  const getAuthData=()=>{
    const userData = JSON.parse(sessionStorage.getItem('authData'));
    if(userData.userRole!==2){
      navigate('/admin/pending');
    }
    setUser(userData);

  }

    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        
      });

     

      const fetchFileDetail=async()=>{
        try {
            const {data} = await fetchFileUploadDetails();
            setData(data.sort((a,b)=>b.fileuploadReqid-a.fileuploadReqid));
        } catch (error) {
           
        }
    }

    useEffect(()=>{
      fetchFileDetail();
      getAuthData();
  },[])

    return (
        <>
        <HeaderAdmin />
        <ThemeProvider theme={theme}>
        <Wrapper>
           
            <DropFile fetchFileDetail={fetchFileDetail}/>
            <BulkTable data={data} setData={setData} fetchFileDetail={fetchFileDetail} />
            
        </Wrapper>
        </ThemeProvider>
        </>
    )
}

export default BulkUpload

const Wrapper = styled.div`
    background-color:#F7F8FB;
`