import axios from "axios"

const CancelTokena = axios.CancelToken;
const cal1 = CancelTokena.source();

// const PAN_API_URL ="https://fgngapi.futuregroup.in/fgngstandard/api/v1/VerifyPANNumber";
// const PIN_CODE_API="https://fgngapi.futuregroup.in/fgngstandard/api/v1/GetPinCodeData";
// const IFSC_DETAIL_API="https://fgngapi.futuregroup.in/fgngstandard/api/v1/GetBankDetails";
// const SEND_OTP_API="https://fgngapi.futuregroup.in/api/v1/smsgateway/sendOtp";
// const VERIFY_OTP_API="https://fgngapi.futuregroup.in/api/v1/smsgateway/validateOtp";
// const SAVE_POSP_API="https://fgngapi.futuregroup.in/posp/api/v1/POSPApplication";
// const UPLOAD_DOC_API="https://fgngapi.futuregroup.in/posp/api/v1/posp/uploadfile";
// const SAVE_BULK_DATA_API="https://fgngapi.futuregroup.in/posp/api/v1/SaveBulkData";
// const FETCH_DETAILS_UPLOAD_ID="https://fgngapi.futuregroup.in/posp/api/v1/GetUploadStatus";
// const FETCH_ALL_FILE_UPLOAD_DETAILS="https://fgngapi.futuregroup.in/posp/api/v1/AllFileUploadStatus";
// const FETCH_ALL_POSP_MASTER="https://fgngapi.futuregroup.in/posp/api/v1/GetAllPOSPMasters";
// const USER_AUTH="https://fgngapi.futuregroup.in/posp/api/v1/authenticateUser";
// const GET_APPLICATION_BY_ID_PAN="https://fgngapi.futuregroup.in/posp/api/v1/GetApplicationByID";
// const GET_APPLICATION_BY_STATUS="https://fgngapi.futuregroup.in/posp/api/v1/GetAllApplications";
// const UPDATE_POSP="https://fgngapi.futuregroup.in/posp/api/v1/UpdatePOSPApplication";
// const PAN_AUTH= "https://fgngapi.futuregroup.in/fgngstandard/api/v1/authenticatePan";
// const PAN_STATUS_CHECK="https://fgngapi.futuregroup.in/fgngstandard/api/v1/checkPanStatus";

const currentUrl = window.location.href;
const isCoversurePosp = currentUrl.includes('https://posp.coversure.in');
const API_BASE_URL = isCoversurePosp ? "https://api.coversure.in" : "https://api.coversure.click"
const PAN_AUTH= `${API_BASE_URL}/third-party/customer-identity/pan/getBasicPanDetails`;
const PAN_STATUS_CHECK="https://vendor.hellozindagi.co/api/vis/pan/checkPanStatus";
const IFSC_DETAIL_API=`${API_BASE_URL}/third-party/customer-identity/bank/getBankAccountDetails`;
const SAVE_POSP_API=`${API_BASE_URL}/posp/POSPApplication`;
const UPLOAD_DOC_API=`${API_BASE_URL}/posp/uploadfile`;
const SAVE_BULK_DATA_API=`${API_BASE_URL}/posp/SaveBulkData`;
const FETCH_DETAILS_UPLOAD_ID=`${API_BASE_URL}/posp/GetUploadStatus`;
const FETCH_ALL_FILE_UPLOAD_DETAILS=`${API_BASE_URL}/posp/AllFileUploadStatus`;
const FETCH_ALL_POSP_MASTER=`${API_BASE_URL}/posp/GetAllPOSPMasters`;
const GET_APPLICATION_BY_ID_PAN=`${API_BASE_URL}/posp/GetApplicationByID`;
const GET_APPLICATION_BY_STATUS=`${API_BASE_URL}/posp/GetAllApplications`;
const UPDATE_POSP=`${API_BASE_URL}/posp/UpdatePOSPApplication`;
const REDIRECT_ASSIST_POSP=`${API_BASE_URL}/user/getCoverAssistSSOUrl`;
const PAN_API_URL ="https://fgngapi.futuregroup.in/fgngstandard/api/v1/VerifyPANNumber";
const PIN_CODE_API=`${API_BASE_URL}/catalog/pincode/pinCodeData`;
const SEND_OTP_API = `${API_BASE_URL}/user/coversure/sendOTP`;
const VERIFY_OTP_API=`${API_BASE_URL}/user/coversure/authenticateOTP`;
const USER_AUTH=`${API_BASE_URL}/posp/authenticateUser`;
const MANAGER_LIST=`${API_BASE_URL}/user/managerDetails`;
const GENERATE_REPORT=`${API_BASE_URL}/posp/admin/generateReport`;
const GET_REPORT_DETAIL=`${API_BASE_URL}/posp/admin/getReportDetails`;
const GET_MANAGER_ID_VERIFY=`${API_BASE_URL}/user/internal/getAgentBySPCode/`;
const GOOGLE_LOGIN_POSP_ADMIN = `${API_BASE_URL}/login/sso/posp/authenticateUser`;
const token = isCoversurePosp ? 
"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJDb3ZlcnN1cmUgVG9rZW4ifQ.F6RUeBBKuS1mK9rqu5EoZQNIAE8-DeeFBvkBX_ho87sbj8yIxlNcBpxh3o-pe9OE32pJJqVMJhePAcRcOttsGg" : 
"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJDb3ZlcnN1cmUgVG9rZW4ifQ.0y7g9fN3xmsG6k293HQr_dv6boRrnHGTjLO_UPf3waP6pQP_y2FhqrqiWnxUBms5Xp2ZeB3hpvXd5cNjrJ40sQ";
export const verifyPanDetails =(panNumber)=> axios({
    method: 'post',
    url: PAN_API_URL,
    headers:{
        Authorization: token
    },
    data:{
        pannumber:panNumber
    }
})

export const pinCode =(pincode)=> axios({
    method: 'GET',
    url:PIN_CODE_API + `/${pincode}`,
    headers:{
        Authorization: token
    },
    // data:{
    //     pincode
    // }
})

export const getIfscDetail=(accNumber, ifscCode)=> axios({
    method:'GET',
    url:IFSC_DETAIL_API + `?accountNumber=${accNumber}&ifscCode=${ifscCode}`,
    headers:{
        Authorization: token
    },
    // data:{
    //     ifscCode
    // }
})

export const sendotp =(number,otp)=>{

 return axios({
    method:'post',
    url:SEND_OTP_API + `/${number}`,
    data:{
        // mobile_number: number,
        // app_code: "2",
        // otp_code: otp,
        // sms_body: `Your OTP Verification Code is ${otp}. Do not share it with anyone - CoverSure`,
        // sender_id: "COVERSURE"
    }
})
}

export const verifyOtp = (number,otp,smsId)=> axios({
    method:'post',
    url:VERIFY_OTP_API,
    data:{
        mobileNumber: number,
        // unique_code: smsId,
        otp: otp
    }
})

export const verifyPhone = (number, pan)=> axios({
    method:'GET',
    url:`${API_BASE_URL}/posp/checkPOSPRegistration/` + number + '/' + pan,
    headers:{
        Authorization: token
    },
})


export const savePosp=(sData)=>{

   
    return axios({
        method:'post',
        url:SAVE_POSP_API,
        headers:{
            Authorization: token
        },
        data:sData
    })
}


export const uploadFile=(formData)=>{

   
    return axios({
        method:'post',
        url:UPLOAD_DOC_API,
        data:formData,
        
    })

}



export const saveBulk=(bData)=>{


    return axios({
        method:'post',
        url:SAVE_BULK_DATA_API,
        data:bData
    })
}


export const fetchDetailByUploadId=(id)=>{

    return axios({
        method:'post',
        url:FETCH_DETAILS_UPLOAD_ID,
        data:{
            uploadReqId:id
        }

    })
}

export const fetchFileUploadDetails=()=>{
    
    return axios({
        method:'get',
        url:FETCH_ALL_FILE_UPLOAD_DETAILS
    })
}

export const getAllPospMaster=()=>{

    return axios({
        method:'get',
        url:FETCH_ALL_POSP_MASTER
    })
}

export const userAuth =(userId,pwd)=>{

    return axios({
        method:'post',
        url:USER_AUTH,
        data:{
            userId:userId,
            pwd
        }
    })
}

export const getApplicationByIdPan=(appId,panNum)=>{

    return axios({
        method:'post',
        url:GET_APPLICATION_BY_ID_PAN,
        data:{
            applicationid:appId,
            panNumber:panNum
        }
    })
}

export const getAllApplicationByStatus=(statusId)=>{

    return axios({
        method:'post',
        url:GET_APPLICATION_BY_STATUS,
        data:{
            applicationStatus:statusId
        }
    })
}
export const updatePosp=(applicantid,applicationStatus,rejectionReason,managerUserId,conSig,consV)=>{


    return axios({
        method:'post',
        url:UPDATE_POSP,
        data:{
            applicantid,
            applicationStatus,
            rejectionReason,
            managerUserId,
            consentSigned:conSig,
            consentversion:consV
        }

    })
}

export const redirectAssist=(applicantid)=>{


    return axios({
        method:'post',
        url:REDIRECT_ASSIST_POSP + `?agentUserName=${applicantid}`,
        headers:{
            Authorization: token
        },
        data:{
        }

    })
}

export const panAuth =(pan)=>{

    return axios({
        method:"GET",
        url:PAN_AUTH + `?panNumber=${pan}`,
        data:{},
        headers:{
            Authorization: token
        },
    })
}

export const panStatusCheck=(pan,dob,fName,lName)=>{

    return axios({
        method:"POST",
        url:PAN_STATUS_CHECK,
        data:{
            pannumber:pan.toUpperCase(),
            dob,
            firstname:fName.toUpperCase(),
            lastname:lName.toUpperCase()
        }
    })
}

export const getManagerList=()=>{

    return axios({
        method:'get',
        url:MANAGER_LIST,
        data:{},
        headers:{
           Authorization:token
        },
    })
}

export const getReportList=()=>{
    return axios({
        method:'get',
        url:GET_REPORT_DETAIL,
        data:{},
        headers:{
            Authorization:token
        },
    })
}

export const generateReport=({
    raisedBy,
    from,
    to,
    pendingDataApproval,
    pendingBusinessApproval,
    approvedPOSP,
    rejectedPOSP
}) => {
    return axios({
        method:'post',
        url:GENERATE_REPORT,
        data:{
            "raisedBy": raisedBy,
            "from": from,
            "to": to,
            "pendingDataApproval": pendingDataApproval,
            "pendingBusinessApproval": pendingBusinessApproval,
            "approvedPOSP": approvedPOSP,
            "rejectedPOSP": rejectedPOSP
        },
        headers:{
            Authorization:token
        },
    })
}

export const getManagerVerify=(id)=>{

    return axios({
        method:'get',
        url:GET_MANAGER_ID_VERIFY + id,
        data:{},
        headers:{
           Authorization:token
        },
    })
}

export const getGoogleLogin = (id) => {
    return axios({
        method: 'post',
        url: GOOGLE_LOGIN_POSP_ADMIN,
        data: {
            authorizationCode: id,
        }
    })
}