import { LOADING,ERROR_LOADING } from '../constants'

const INITIAL_STATE = {
  data:[]
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING:
      return state;

    case ERROR_LOADING:
      return state

    default:
      return state;
  }
}
