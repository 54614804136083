import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import {
  Grid,
  Button,
  OutlinedInput,
  InputAdornment,
  FormControl,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import UploadIcon from "@mui/icons-material/Upload";

import { uploadFile } from "../api";
import { InputLabel } from "@mui/material";
import doneIcon from "../assets/Image/done-tick.svg";
import editIcon from "../assets/Image/edit-icon.svg";
import uploadIcon from "../assets/Image/upload-icon.svg";
import { useFormik } from "formik";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Input = muiStyled("input")({
  display: "none",
});

const UploadDoc = ({ step, setStep }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      applicantid: 0,
      panImage: "",
      eduCertImage: "",
    },
    validate: (values) => {
      let errors = {};

      //-------- validate pan Image--------
      if (!values.panImage) {
        errors.panImage = "Please select a file to upload";
      }

      if (!values.eduCertImage) {
        errors.eduCertImage = "Please select a file to upload";
      }

      return errors;
    },
  });

  const [showErr, setShowErr] = useState(false);

  const handlePanInpChange = (e) => {
    if (
      ((e.target.files[0] && e.target.files[0].type.split("/")[1] === "png") ||
        (e.target.files[0] &&
          e.target.files[0].type.split("/")[1] === "jpeg") ||
        (e.target.files[0] && e.target.files[0].type.split("/")[1] === "jpg") ||
        (e.target.files[0] &&
          e.target.files[0].type.split("/")[1] === "pdf")) &&
      e.target.files[0].size <= 1 * 1000 * 1000
    ) {
      formik.setFieldValue("panImage", e.target.files[0]);
    }
  };
  const handleEducertInpChange = (e) => {
    if (
      ((e.target.files[0] && e.target.files[0].type.split("/")[1] === "png") ||
        (e.target.files[0] &&
          e.target.files[0].type.split("/")[1] === "jpeg") ||
        (e.target.files[0] && e.target.files[0].type.split("/")[1] === "jpg") ||
        (e.target.files[0] &&
          e.target.files[0].type.split("/")[1] === "pdf")) &&
      e.target.files[0].size <= 1 * 1000 * 1000
    ) {
      formik.setFieldValue("eduCertImage", e.target.files[0]);
    }
  };

//   ----------------checking for application if-----------------
const checkParams = ()=>{
    const applicatId = query.get('applicantId');
    formik.setFieldValue('applicantid',Number(applicatId));
}

  const browseBtn = (e) => {
    const panInp = document.getElementById("panCard");
    const eduCertInp = document.getElementById("eduCert");

    if (e.target.name === "panCard") {
      panInp.click();
    }
    if (e.target.name === "eduCert") {
      eduCertInp.click();
    }
  };

  const handleNextStep = async () => {
    if (!formik.values.panImage || !formik.values.eduCertImage) {
      // formik.values.applicantid
      formik.setFieldTouched("panImage", true);
      setShowErr(true);
      return;
    }

    const formData = new FormData();

    formData.append("pan", formik.values.panImage);
    formData.append("edu", formik.values.eduCertImage);
    formData.append(
      "applicatid",
      JSON.stringify({ applicantid: formik.values.applicantid })
    );

    try {
      const { data } = await uploadFile(formData);
      if (data.responseCode !== 200) {
        throw new Error(data.responseMessage);
      }
      alert('Document uploaded');
      navigate('/')
    } catch (error) {
      alert("something went wrong in api");
      navigate('/')
      console.log("doc save err");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    checkParams();

  }, []);

  return (
    <>
      <Header />
      <Wrapper>
        <Grid
          container
          className="mainContainer"
          spacing={2}
          justifyContent="center"
        >
          <Grid container item xs={12} md={5} direction="row" spacing={0}>
            <Grid item>
              <Heading>
                <div className="heading">
                  {/* <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(2)} /> */}
                  <p>Become a CoverSure POSP</p>
                </div>
                <p className="title">
                  Upload Documents
                  <img
                    className="titleImageDesktop"
                    src={require("../assets/Image/bank-info-emoji.png").default}
                    alt=""
                  />
                  <img
                    className="titleImageMobile"
                    src={require("../assets/Image/bank-info-emoji.png").default}
                    alt=""
                  />
                </p>
              </Heading>
            </Grid>

            <Grid item xs={12} md={10}>
              {/* <TextField  variant="outlined" fullWidth
                        type='file'
                         /> */}

              <input
                type="file"
                name="panCardImage"
                accept=".jpeg,.png,.pdf"
                className="hiddenInp"
                id="panCard"
                onChange={handlePanInpChange}
              />
              <InputLabel f>Upload Pan Card Copy</InputLabel>
              <OutlinedInput
                fullWidth
                name="panCard"
                readOnly={true}
                startAdornment={
                  <InputAdornment position="start">
                    {formik.values.panImage ? (
                      <img src={doneIcon} alt="" />
                    ) : (
                      <img src={uploadIcon} alt="" name="panCard" />
                    )}
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    {formik.values.panImage && (
                      <>
                        <img
                          src={editIcon}
                          alt=""
                          name="panCard"
                          onClick={(e) => browseBtn(e)}
                        />
                      </>
                    )}
                  </InputAdornment>
                }
                placeholder={
                  formik.values.panImage
                    ? formik.values.panImage.name
                    : "Upload PAN card copy"
                }
                onClick={formik.values.panImage ? null : (e) => browseBtn(e)}
              />

              {showErr && <p className="errorText">{formik.errors.panImage}</p>}
            </Grid>

            <Grid item xs={12} md={10}>
              {/* <TextField variant="outlined" fullWidth
                    type='file'
                     /> */}

              <input
                type="file"
                accept="image/*,.pdf"
                name="eduCert"
                className="hiddenInp"
                id="eduCert"
                onChange={handleEducertInpChange}
              />
              <InputLabel>Upload Education Certificate</InputLabel>

              <OutlinedInput
                id="outlined-adornment-weight"
                // value={10}
                fullWidth
                name="eduCert"
                readOnly={true}
                startAdornment={
                  <InputAdornment position="start">
                    {formik.values.eduCertImage ? (
                      <img src={doneIcon} alt="" />
                    ) : (
                      <img src={uploadIcon} name="eduCert" alt="" />
                    )}
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    {formik.values.eduCertImage && (
                      <>
                        <img
                          src={editIcon}
                          alt=""
                          name="eduCert"
                          onClick={browseBtn}
                        />
                      </>
                    )}
                  </InputAdornment>
                }
                placeholder={
                  formik.values.eduCertImage
                    ? formik.values.eduCertImage.name
                    : "Upload Education Certificate Copy"
                }
                onClick={formik.values.eduCertImage ? null : browseBtn}
              />

              {showErr && (
                <p className="errorText">{formik.errors.eduCertImage}</p>
              )}
            </Grid>

            <Grid item xs={12} md={10}>
              <Button
                className="submitBtn"
                variant="contained"
                onClick={() => handleNextStep()}
                //  onClick={()=>console.log(formik.values)}
              >
                Save and Continue
              </Button>
            </Grid>
          </Grid>

          <Grid container item xs={0} md={5} className="rightHero">
            <img
              src={require("../assets/Image/upload-doc.png").default}
              alt=""
            />
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};

export default UploadDoc;

const Wrapper = styled.div`
  padding: 0 8%;
  padding-bottom: 2%;
  a {
    text-decoration: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    /* margin: 10px 0; */
    border-color: red;
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    background-color: #f1f1f1;

    .Mui-disabled {
      -webkit-text-fill-color: #434552;
    }
  }
  .textInp {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #bfc0c5;
    border-radius: 10px;
    padding: 15px 13px;
    /* margin-right: 20px; */
    /* margin-top:20px; */
    outline-color: #ff465a85;
  }

  .submitBtn {
    padding: 10px 60px;
    border: none;
    outline: none;
    background: #ff465a;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
    font-family: "Sora";
    font-style: normal;
    font-weight: normal;
    box-shadow: 0px 0px 2px 0px #ff465a;
    :hover {
      background-color: #ed1c24;
      box-shadow: 0px 4px 14px 4px #ff465a40;
    }
  }

  .errorText {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    color: red;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 3px;
  }
  .rightHero {
    justify-content: center;
    margin-top: 80px;
    align-content: baseline;
    width: 100%;

    img {
      object-fit: contain;
      /* width:50%; */
      max-height: 400px;
    }
  }

  .MuiInputLabel-root {
    color: #333333;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin-left: 3px;
  }

  .hiddenInp {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ababab;
  }

  @media only screen and (max-width: 1000px) {
    padding: 1% 2%;

    .MuiOutlinedInput-root {
      font-size: 16px;
    }
    .MuiFormLabel-root.MuiInputLabel-root {
      font-size: 16px;
    }

    .rightHero {
      display: none;
    }
    .submitBtn {
      margin-top: 22px;
    }
    .MuiInputLabel-root {
      margin-top: 22px;
      padding-bottom: 3px;
    }
  }
`;

const Heading = styled.div`
  padding-top: 40px;
  .heading {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      color: #ff465a;
      margin-left: -46px;
      cursor: pointer;
    }

    p {
      font-family: "Outfit";
      font-size: 20px;
      padding-left: 10px;
      color: #c4c4c4;
    }
  }
  .title {
    font-family: "Sora";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #434552;
    img {
      height: 22px;
      object-fit: contain;
    }
  }
  .titleImageMobile {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 18px;
    }
    .titleImageMobile {
      display: inline-block;
    }
    .titleImageDesktop {
      display: none;
    }
    .heading {
      display: none;
    }
  }
`;
