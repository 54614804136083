import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {composeWithDevTools} from 'redux-devtools-extension';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducers from './redux/reducers';
import { GoogleOAuthProvider } from '@react-oauth/google';
const store = createStore(
  reducers,
  {},
  composeWithDevTools(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

ReactDOM.render(
  <GoogleOAuthProvider clientId={window.location.href.includes('https://posp.coversure.in') ? '393778209311-udi231em06oc16hvhvbcn572fgfbeobk.apps.googleusercontent.com' :'843904164487-5jp9om7h2fs6hil99qetn30e1pop2gv3.apps.googleusercontent.com'}>
  <Provider store={store}>
    <Router >
      <App />
    </Router>
    </Provider>
    </GoogleOAuthProvider>,
  document.getElementById('root')
);


reportWebVitals();
