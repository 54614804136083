/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Box,
    Button,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import uploadIcon from '../assets/Image/upload-icon.svg';
import editIcon from '../assets/Image/edit-icon.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import doneIcon from '../assets/Image/done-tick.svg';
import { savePosp, updatePosp, uploadFile } from '../api/index';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
    verifyPanDetails,
    pinCode,
    getIfscDetail,
    panAuth,
    panStatusCheck,
    getApplicationByIdPan
} from "../api/index";
import Header from './Header';
import { useLocation, useNavigate } from 'react-router-dom';

const errObj = {
    dob: "",
};
const VerifyDetail = () => {


    const accountType = useSelector(state => state.masterList.accountType);
    const navigate = useNavigate()
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    console.log('')
    const [maxdob, setMaxdob] = useState('');
    const [showErr, setshowErr] = useState(false);
    const [lastPin, setLastPin] = useState("");
    const [lastPan, setLastPan] = useState("");
    const [lastIfsc, setLastIfsc] = useState("");
    const [panErr, setPanErr] = useState("");
    const [panFile, setPanFile] = useState('');
    const [eduFile, setEduFile] = useState('');
    const [showDOBErr, setshowDOBErr] = useState(false);
    const calTheme = createTheme({
        typography: {
            fontFamily: [
                'Outfit',
                'Sora'
            ].join(',')
        },
        components: {
            MuiOutlinedInput: {
                root: {
                    "&:-webkit-autofill": {
                        color: 'red'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        width: "350px",
                        borderRadius: "10px",
                        "&::-webkit-scrollbar": {
                            height: "8px",
                            width: "4px",
                            borderRadius: "20px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#c9c6c6",
                            borderRadius: "20px",
                        },
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        width: "min-content",
                    },
                    list: {
                        fontFamily: "Outfit",
                        padding: "0px",
                    },
                },
            },
        }
    })




    const handlePanInpChange = (e) => {
        formik.setFieldValue('panImage', e.target.files[0])
    }
    const handleEducertInpChange = (e) => {
        formik.setFieldValue('eduCertImage', e.target.files[0]);
    }

    const handleminDob = () => {
        let date = new Date();
        let month = date.getMonth();

        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt
        }
        let year = date.getFullYear() - 18;
        // setMaxdob(`${year}/${month}/${dt}`);
        setMaxdob(new Date(year, month, dt));

    }

    const panUpdate = async (e) => {
        formik.setFieldValue('panNumber', e.target.value)
        if (
            e.target.value.length === 10 &&
            /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(e.target.value)
        ) {
            const verifyPan = async () => {
                try {
                    const panA = await panAuth(e.target.value);
                    if (panA.data) {
                        if (panA.data.responseCode === 102) {
                            setPanErr(panA.data.responseMessage);
                        }
                        if (panA.data.status === 1) {
                            formik.setFieldValue('firstName', panA.data.data.firstName);
                            formik.setFieldValue('lastName', panA.data.data.lastName);
                            sessionStorage.setItem('dob', moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                            // formik.setFieldValue('dob', moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                            if(formik.values.dob && (moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD") !== formik.values.dob)){
                                formik.setFieldError("dob", "Enter Valid Birthdate as per PAN");
                                errObj.dob = "Enter Valid Birthdate as per PAN";
                                setshowDOBErr(true)
                            }
                            setPanErr("");
                        }
                    }
                    setLastPan(e.target.value);
                } catch {
                    console.log("panVerify error");
                }
            };
            if (lastPan !== e.target.value) {
                verifyPan();
            }
        }
    }

    const formik = useFormik({
        initialValues: {
            applicantid: 0,
            panNumber: "",
            email: "",
            partnerid: "",
            partnername: "",
            mobNumber: "",
            otp: "",
            tnc: false,
            // initials: 0,
            firstName: "",
            lastName: "",
            dob: null,
            pinCode: "",
            cityName: "",
            state: "",
            address: "",
            eduQual: 0,
            othersEdu: "",
            accHolderName: "",
            accNumber: "",
            ifsc: "",
            bankName: "",
            accType: 0,
            panImage: "",
            eduCertImage: "",
            partneruserid: "",
            nomineeName: "",
            gender: ""
        },
        validate: (values) => {
            let errors = {};

            // ----------PAN VALIDATE---------
            if (!values.panNumber) {
                errors.panNumber = "Please enter PAN number";
            }
            if (values.panNumber) {
                if (values.panNumber.length < 10) {
                    errors.panNumber = "Please enter a valid PAN number";
                }
                if (
                    values.panNumber.length === 10 &&
                    !/[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(values.panNumber)
                ) {
                    errors.panNumber = "Please enter PAN number in correct format";
                }

            }

            // if(panErr) errors.panNumber=panErr;
            // -------email validate--------
            if (!values.email) {
                errors.email = "Please enter email address";
            }
            if (values.email) {
                if (
                    !/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(
                        values.email
                    )
                ) {
                    errors.email = "Please enter a valid email address";
                }
            }
            // -------mobile number validate------------

            if (!values.mobNumber) {
                errors.mobNumber = "Please enter mobile number";
            }
            if (values.mobNumber) {
                if (!/^[6-9][0-9]{9}$/i.test(values.mobNumber)) {
                    errors.mobNumber = "Please enter a valid number";
                }
            }
            // ----------validate otp -----------
            if (!values.otp) {
                errors.otp = "Please enter OTP";
            }
            if (values.otp) {
                if (values.otp.length < 4) {
                    errors.otp = "Please enter 4 digit otp";
                }
            }
            //--------validate tnc----------
            if (values.tnc === false) {
                errors.tnc = "Please agree to terns & conditions to continue";
            }

            //----------- validate DOB ----------------
            if (!values.dob) {
                errors.dob = "Please enter DOB";
            }
            if (values.dob) {
                let date = new Date();
                let month = date.getMonth();

                let dt = date.getDate();
                if (dt < 10) {
                    dt = "0" + dt;
                }
                let year = date.getFullYear() - 18;


                if (!moment(values.dob).isValid()) {
                    errors.dob = "Please enter a valid DOB";
                }

                if (
                    new Date(year, month, dt).getTime() >= new Date(values.dob).getTime()
                ) {
                    const panVerify = async () => {
                        try {
                            const { data } = await panStatusCheck(
                                formik.values.panNumber,
                                moment(values.dob).format("DD/MM/yyyy"),
                                formik.values.firstName,
                                formik.values.lastName
                            );

                            if (data && data.responseCode === 102)
                                formik.setFieldError("dob", data.responseMessage);
                            if (data && data.responseCode === 101)
                                formik.setFieldError("dob", "");
                        } catch (error) {
                            console.log("pan verify error");
                            formik.setFieldError(
                                "dob",
                                "Something went wrong, Please try again later"
                            );
                        }
                    };
                    // panVerify();
                }
            }

            //--------------validate pin code ---------------
            if (!values.pinCode) {
                errors.pinCode = "Please enter your pin code";
            }

            //----------Validate city name-----------
            if (!values.cityName) {
                errors.cityName = "Please provide city name";
            }

            //----------Validate state --------------
            if (!values.state) {
                errors.state = "Please provide state name";
            }
            //---------validate address --------------
            if (!values.address) {
                errors.address = "Please enter your address";
            }

            if (!values.eduQual || values.eduQual === 0 || values.eduQual == 5) {
                if (values.eduQual == 5 && !values.othersEdu) {
                    errors.eduQual = "Please enter Education Qualification";
                } else if (values.eduQual != 5 && values.eduQual === 0) {
                    errors.eduQual = "Please select Education Qualification";
                }
            }
            //-------validate ifsc code ----------------

            if (!values.ifsc) {
                errors.ifsc = "Please enter IFSC Code";
            }

            if (values.ifsc) {
                if (!/^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(values.ifsc)) {
                    errors.ifsc = "Please enter IFSC Code in correct format";
                } else if (values.ifsc.length < 11) {
                    errors.ifsc = "Please enter valid IFSC Code";
                }
            }


            //----------validate acc holder name----------
            if (!values.accHolderName) {
                errors.accHolderName = "Please enter Account Holder Name";
            }

            if (values.accHolderName && values.accHolderName.length < 3) {
                errors.accHolderName = "Please enter complete Account Holder Name";
            }
            //--------------validate account number---------
            if (!values.accNumber) {
                errors.accNumber = "Please enter Account Number";
            }
            if (values.accNumber && values.accNumber.length < 6) {
                errors.accNumber = "Please enter valid Account Number";
            }

            //--------bank name----------
            if (!values.bankName) {
                errors.bankName = "Bank name is Required field";
            }
            //---------validate bank account type----------
            if (values.accType == 0) {
                errors.accType = "Please select Account Type";
            }

            //-------- validate pan Image--------
            if (!values.panImage) {
                errors.panImage = "Please select a file to upload";
            }

            if (!values.eduCertImage) {
                errors.eduCertImage = "Please select a file to upload";
            }
            if (!values.nomineeName) {
                errors.nomineeName = "Please provide Nominee Full Name";
            }
            if (!values.gender) {
                errors.gender = "Please select gender";
            }

            return errors;
        },
    });



    const browseBtn = (e) => {
        const panInp = document.getElementById('panCardV');
        const eduCertInp = document.getElementById('eduCertV');
        // console.log(e);
        if (e.target.name === 'panCard') {
            panInp.click();
        }
        if (e.target.name === 'eduCert') {
            eduCertInp.click();
        }
    }

    const handleAccNumberChange = async (e) => {
        formik.setFieldValue(
            "accNumber",
            e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
        );
        if (
            formik.values.ifsc &&
            formik.values.ifsc.length === 11 && formik.values?.accNumber && (formik.values.accNumber.length >= 6) &&
            /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(formik.values.ifsc)
        ) {

            try {
                const { data } = await getIfscDetail(formik.values.accNumber, formik.values.ifsc);
                formik.setFieldValue("bankName", data.data.bankName);
                formik.setFieldValue("accHolderName", data.data.fullName);
                setLastIfsc(formik.values.ifsc);
                setLastAccountNo(formik.values.accNumber)
            } catch (error) {
                console.log("ifsc detail fetch error");
            }
        }
    };

    const handleifscChange = async (e) => {
        formik.setFieldValue(
            "ifsc",
            e.target.value
        );
        if (
            formik.values.ifsc &&
            formik.values.ifsc.length === 11 && formik.values?.accNumber && (formik.values.accNumber.length >= 6) &&
            /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(formik.values.ifsc)
        ) {

            try {
                const { data } = await getIfscDetail(formik.values.accNumber, formik.values.ifsc);
                formik.setFieldValue("bankName", data.data.bankName);
                formik.setFieldValue("accHolderName", data.data.fullName);
                setLastIfsc(formik.values.ifsc);
                setLastAccountNo(formik.values.accNumber)
            } catch (error) {
                console.log("ifsc detail fetch error");
            }
        }
    }

    const handleAccHolderNameChange = (e) => {
        formik.setFieldValue(
            "accHolderName",
            e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
        );
    };

    const handleBankNameChange = (e) => {
        formik.setFieldValue(
            "bankName",
            e.target.value
        );
    };

    const onChangeMobile = (e) => {
        formik.setFieldValue('mobNumber', e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, ""));
    }

    const handleNextStep = async () => {
        const rrn = query.get('rrn');
        const lastActiveAgent = JSON.parse(sessionStorage.getItem('lastActiveAgent'));
        const currentTime = new Date().getTime();
        const sessionTime = 30 * 60 * 1000;
        if (
            lastActiveAgent &&
            ((currentTime - Number(lastActiveAgent)) > sessionTime)

        ) {
            sessionStorage.removeItem('lastActiveAgent');
            alert('session expired,session is valid for 30 Minutes');
            navigate('/');
            return
        }
        sessionStorage.setItem('lastActiveAgent', new Date().getTime());
        formik.setFieldTouched('firstName', true);

        if (
            !formik.values.firstName ||
            formik.errors.email ||
            formik.errors.mobNumber ||
            formik.errors.panNumber ||
            formik.errors.firstName ||
            formik.errors.lastName ||
            formik.errors.dob ||
            formik.errors.address ||
            formik.errors.pinCode ||
            formik.errors.cityName ||
            formik.errors.state ||
            formik.errors.eduQual ||
            formik.errors.state || formik.errors.nomineeName || formik.errors.gender
        ) {
            formik.setFieldTouched('firstName', true);
            setshowErr(true);
            return
        }

        const saveData = {
            applicantid: formik.values.applicantid,
            partnerid: 1,
            partnername: 'asas',
            pospcode: 'asd',
            email: formik.values.email,
            mobileno: formik.values.mobNumber,
            pannumber: formik.values.panNumber,
            initial: formik.values.initials,
            firstname: formik.values.firstName,
            lastname: formik.values.lastName,
            dob: formik.values.dob,
            address: formik.values.address,
            pincode: formik.values.pinCode,
            city: formik.values.cityName,
            state: formik.values.state,
            educationQ: formik.values.eduQual,
            accountno: formik.values.accNumber,
            ifscCode: formik.values.ifsc,
            bankname: formik.values.bankName,
            bankbranch: formik.values.bankBranch,
            accounttype: formik.values.accType,
            accountholdername: formik.values.accHolderName,
            nomineeName: formik.values.nomineeName,
            gender: formik.values.gender,
            pageno: 4
        }

        const formData = new FormData();
        formData.append("pan", formik.values.panImage);
        formData.append("edu", formik.values.eduCertImage);
        formData.append("applicatid", JSON.stringify({ applicantid: formik.values.applicantid }));

        try {
            await savePosp(saveData);
            await uploadFile(formData);
            await updatePosp(formik.values.applicantid, Number(rrn) === 500 ? 2 : 1);

            alert('Application updated successfully');
            navigate('/')
        } catch (error) {
            alert('something went wrong in API, skipping for Testing')
            console.log('verify doc err');
        }

        formik.setFieldTouched('firstName', true);
        setshowErr(true);
    }


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        handleminDob();
    }, [])

    const handleFirstname = (e) => {
        formik.setFieldValue(
            "firstName",
            e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
        );
    };
    const handleLastname = (e) => {
        formik.setFieldValue(
            "lastName",
            e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
        );
    };

    const handleDateChange = async (val) => {
        setshowDOBErr(false)
        if (
            val &&
            val.isValid() &&
            new Date(maxdob).getTime() >=
            new Date(moment(val).format("yyyy-MM-DD")).getTime() &&
            /^[1-9]/.test(moment(val).format("DD-MM-yyyy").split("-")[2])
        ) {
            console.log("api called");
        }

        if (errObj.dob) formik.setFieldError("dob", errObj.dob);

        if (
            new Date(maxdob).getTime() <
            new Date(moment(val).format("yyyy-MM-DD")).getTime()
        ) {
            formik.setFieldError("dob", "Age should be more than 18 years");
            errObj.dob = "Age should be more than 18 years";
        }
        if (
            new Date(maxdob).getTime() >
            new Date(moment(val).format("yyyy-MM-DD")).getTime()
        ) {
            console.log("dob is valid");
            errObj.dob = "";
        }

        formik.setFieldValue("dob", moment(val).format("yyyy-MM-DD"));

        if (
            val &&
            val.isValid() &&
            new Date(maxdob).getTime() >=
            new Date(moment(val).format("yyyy-MM-DD")).getTime() &&
            /^[1-9]/.test(moment(val).format("DD-MM-yyyy").split("-")[2])
        ) {
            try {
                if(moment(val, "DD-MM-YYYY").format("yyyy-MM-DD") !== sessionStorage.getItem('dob')){
                    formik.setFieldError("dob", "Enter Valid Birthdate as per PAN");
                    errObj.dob = "Enter Valid Birthdate as per PAN";
                    setshowDOBErr(true)
                }
                // const { data } = await panStatusCheck(
                //     formik.values.panNumber,
                //     moment(val).format("DD/MM/yyyy"),
                //     formik.values.firstName,
                //     formik.values.lastName
                // );

                // if (data && data.responseCode === 102) {
                //     formik.setFieldError("dob", data.responseMessage);
                //     errObj.dob = data.responseMessage;
                // }
                // if (data && data.responseCode === 101) {
                //     errObj.dob = "";
                //     formik.setFieldError("dob", "");
                // }
            } catch (error) {
                console.log("pan verify error");
                formik.setFieldError(
                    "dob",
                    "Something went wrong, Please try again later"
                );
            }
        }
    };

    const handlePinChange = (e) => {
        formik.setFieldValue(
            "pinCode",
            e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
        );
        if (e.target.value && e.target.value.length === 6) {
            const getPinData = async () => {
                try {
                    const { data: { data } } = await pinCode(e.target.value);
                    formik.setFieldValue("cityName", data.city);
                    formik.setFieldValue("state", data.state);
                    setLastPin(e.target.value);
                } catch (error) {
                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.responseCode === 202
                    ) {
                        formik.setFieldError(
                            "pinCode",
                            error.response.data.responseMessage.toLowerCase()
                        );
                    }
                    console.log("pincode detail fetching error");
                }
            };
            if (e.target.value !== lastPin) {
                getPinData();
            }
        }
    };

    function extractFilename(url) {
        const pathname = new URL(url).pathname;
        return pathname.split('/').pop();
    }

    const checkData = async () => {

        const rrn = query.get('rrn');
        const rpg = query.get('rpg');
        const aid = query.get('aid');
        const bpg = query.get('bpg');

        if (!aid) {
            alert('No Applicant id available');
            navigate('/');
            return
        }

        if (aid) {
            try {
                const { data } = await getApplicationByIdPan(aid);


                if (data && Number(data.applicationStatus) !== 4 && Number(rrn) !== 500) {
                    alert('Application status is not rejected');
                    navigate('/');
                    return
                }

                formik.setFieldValue('initials', data.initial);
                formik.setFieldValue('applicantid', data.applicantid);
                formik.setFieldValue('partnerid', data.partnerid);
                formik.setFieldValue('email', data.email);
                formik.setFieldValue('panNumber', data.pannumber);
                formik.setFieldValue('panImage', data.panfilePath);
                formik.setFieldValue('eduCertImage', data.edufilepath);
                formik.setFieldValue('mobNumber', data.mobileno);
                formik.setFieldValue('firstName', data.firstname);
                formik.setFieldValue('lastName', data.lastname);
                formik.setFieldValue('dob', data.dob);
                formik.setFieldValue('pinCode', data.pincode);
                formik.setFieldValue('cityName', data.city);
                formik.setFieldValue('state', data.state);
                formik.setFieldValue('address', data.address);
                formik.setFieldValue('accNumber', data.accountno);
                formik.setFieldValue('bankName', data.bankname);
                formik.setFieldValue('ifsc', data.ifscCode);
                formik.setFieldValue('accType', data.accounttype);
                formik.setFieldValue('accHolderName', data.accountholdername);
                formik.setFieldValue('branchName', data.bankbranch);
                formik.setFieldValue('eduQual', Number(data.educationQ));
                formik.setFieldValue('othersEdu', data.others);
                formik.setFieldValue('partnername', data.partnername);
                formik.setFieldValue('nomineeName', data.nomineeName);
                formik.setFieldValue('gender', data.gender);
            } catch (error) {
                alert('Something went wrong, please try again later');
                navigate('/');
            }
        }


        if (Number(rrn) === 1) {
            setEmailRej(true);
        }
        if (Number(rrn) === 2) {
            setEduRej(true);
        }
        if (Number(rrn) === 4) {
            setEduRej(true);
        }
        if (Number(rrn) === 3) {
            setPanRej(true);
        }
        if (Number(rrn) === 500) {
            setBulkRej(true);
        }

        const rData = {
            rrn,
            rpg,
            aid,
            bpg
        }

        // sessionStorage.setItem('rData',JSON.stringify(rData));



    }

    useEffect(() => {
        checkData();
    }, [])
    return (
        <>
            <Header step={0} />
            <Wrapper>
                <ThemeProvider theme={calTheme}>
                    <Grid container className='mainContainer' spacing={2} justifyContent="center"  >

                        <Grid container item xs={12} md={5} direction='row' spacing={2} >
                            <Grid item>
                                <Heading>
                                    <div className="heading">
                                        <ArrowBackIosNewIcon fontSize='large' onClick={() => navigate(`/update-details?aid=${query.get('aid')}`)} />
                                        <p>Become a CoverSure POSP</p>
                                    </div>
                                    <p className='title' >
                                        Verify Details
                                        <img className='titleImageDesktop' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                        <img className='titleImageMobile' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                    </p>
                                </Heading>
                            </Grid>
                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="PAN"
                                    variant="outlined"
                                    className="panInput"
                                    inputProps={{ maxLength: 10 }}
                                    value={formik.values.panNumber}
                                    onChange={panUpdate}
                                    fullWidth />
                                {
                                    showErr && formik.errors.panNumber &&
                                    <p className="errorText">{formik.errors.panNumber}</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <input type="file" accept="image/*,.pdf" name='panCard' className='hiddenInp' id='panCardV' onChange={handlePanInpChange} />
                                <OutlinedInput
                                    // value={10}
                                    fullWidth
                                    className="panCard fileUpload"
                                    name='panCard'
                                    readOnly={true}
                                    // disabled={true}
                                    startAdornment=
                                    {
                                        <InputAdornment position="start">
                                            {formik.values.panImage ?
                                                <img src={doneIcon} alt="" /> :
                                                // <CheckCircleOutlineIcon fontSize='large' style={{color:'#00E733',fontSize:'25px'}} />:
                                                <UploadIcon fontSize='large' style={{ color: '#A3A3A3', fontSize: '25px' }} />}
                                        </InputAdornment>

                                    }
                                    endAdornment={
                                        <InputAdornment position="end" onClick={browseBtn} >
                                            {formik.values.panImage &&
                                                <>
                                                    <p className="reupload" onClick={browseBtn} >
                                                        Re-upload PAN
                                                    </p>
                                                </>
                                            }
                                        </InputAdornment>
                                    }

                                    placeholder={formik.values.panImage ? formik.values.panNumber : 'Upload PAN card copy'}
                                    onClick={browseBtn}
                                />
                                {showErr &&
                                    <p className="errorText">
                                        {formik.errors.panImage}
                                    </p>
                                }
                            </Grid>
                            {/* <Grid container item xs={10} spacing={2}>
                                <Grid item xs={12}  >
                                    <ThemeProvider theme={calTheme}>
                                        <FormControl fullWidth>
                                            <InputLabel>Title</InputLabel>
                                            <Select label="Title" {...formik.getFieldProps("initials")}>
                                                <MenuItem value={0} disabled>
                                                    Title
                                                </MenuItem>
                                                <MenuItem value={1} divider={true}>
                                                    Mr.{" "}
                                                </MenuItem>
                                                <MenuItem value={2} divider={true}>
                                                    Ms.
                                                </MenuItem>
                                                <MenuItem value={3}>Mrs.</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </ThemeProvider>
                                    {showErr && formik.errors.initials && (
                                        <p className="errorText">{formik.errors.initials}</p>
                                    )}
                                </Grid>
                            </Grid> */}
                            <Grid container item xs={12} md={10} spacing={2} >
                                <Grid item xs={6}  >
                                    <TextField
                                        label="First Name"
                                        placeholder='Enter First Name'
                                        variant="outlined"
                                        disabled={true}
                                        inputProps={{ maxLength: 50 }}
                                        value={formik.values.firstName}
                                        onChange={handleFirstname}
                                        fullWidth
                                    />
                                    {showErr && formik.errors.firstName && (
                                        <p className="errorText">{formik.errors.firstName}</p>
                                    )}
                                </Grid>

                                <Grid item xs={6}  >
                                    <TextField
                                        label="Last Name"
                                        placeholder='Enter Last Name'
                                        variant="outlined"
                                        disabled={true}
                                        inputProps={{ maxLength: 50 }}
                                        value={formik.values.lastName}
                                        onChange={handleLastname}
                                        fullWidth
                                    />
                                    {showErr && formik.errors.lastName && (
                                        <p className="errorText">{formik.errors.lastName}</p>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={10} className='dateInput' >
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                    <ThemeProvider theme={calTheme}>
                                        <DatePicker
                                            label="Date Of Birth (as per PAN)"
                                            value={formik.values.dob}
                                            disableHighlightToday={true}
                                            name="dob"
                                            // disabled
                                            inputFormat="DD-MM-yyyy"
                                            mask="__-__-____"
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            maxDate={moment(maxdob)}
                                        />
                                    </ThemeProvider>
                                </LocalizationProvider>
                                {(showDOBErr || showErr) && (
                                    <p className="errorText">
                                        {formik.errors.dob ? formik.errors.dob : errObj.dob}
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={12} md={10} style={{paddingTop: '0'}}>
                                {/* <p className='eduTitle' >Educational Qualification</p> */}
                                <div className="eduWrap">


                                    <FormControl fullWidth className='eduWrapMobilee' >
                                        <InputLabel >Gender</InputLabel>
                                        <Select
                                            label="Gender"
                                            {...formik.getFieldProps('gender')}
                                            name="gender"
                                        >
                                            <MenuItem disabled value={0}>
                                                <em>Select gender</em>
                                            </MenuItem>
                                            <MenuItem value={'male'}>
                                                Male
                                            </MenuItem>
                                            <MenuItem value={'female'}>
                                                Female
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        showErr && formik.errors.gender &&
                                        <p className="errorText">{formik.errors.gender}</p>
                                    }

                                </div>

                            </Grid>
                            <Grid item xs={12} md={10} >
                                <TextField id="outlined-basic"
                                    label="Email ID"
                                    placeholder="Enter Email ID"
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('email')}
                                />
                                {
                                    formik.touched.email &&
                                    <p className="errorText">{formik.errors.email}</p>
                                }
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <TextField
                                    name='mobNumber'
                                    label="Mobile Number"
                                    variant="outlined"
                                    onChange={onChangeMobile}
                                    onBlur={formik.handleBlur}
                                    disabled
                                    value={formik.values.mobNumber}
                                    fullWidth />
                                {
                                    showErr && formik.errors.mobNumber &&
                                    <p className="errorText errorNum" >{formik.errors.mobNumber}</p>

                                }
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    label="Pincode"
                                    variant="outlined"
                                    value={formik.values.pinCode}
                                    inputProps={{ maxLength: 6 }}
                                    onChange={handlePinChange}
                                    // onBlur={formik.handleBlur}
                                    fullWidth
                                />
                                {showErr && formik.errors.pinCode && (
                                    <p className="errorText" style={{ textTransform: "capitalize" }}>
                                        {formik.errors.pinCode}
                                    </p>
                                )}
                            </Grid>
                            <Grid item xs={12} md={10} >
                                {/* <TextField
                                    label="City Name"
                                    variant="outlined"
                                    {...formik.getFieldProps('cityName')}
                                    fullWidth />
                                {
                                    showErr && formik.errors.cityName &&
                                    <p className="errorText">{formik.errors.cityName}</p>
                                } */}
                                <TextField
                                    label="City Name"
                                    variant="outlined"
                                    disabled
                                    {...formik.getFieldProps("cityName")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={10} >
                                <Box sx={{ minWidth: 120 }}>
                                    <ThemeProvider theme={calTheme}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="State"
                                                variant="outlined"
                                                disabled
                                                {...formik.getFieldProps("state")}
                                                fullWidth
                                            />
                                            {/* {
                                                        showErr&&formik.errors.state&&
                                                        <p className="errorText">{formik.errors.state}</p>
                                                    } */}
                                        </FormControl>
                                    </ThemeProvider>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="Address for Communication"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    {...formik.getFieldProps("address")}
                                />
                                {showErr && formik.errors.address && (
                                    <p className="errorText">{formik.errors.address}</p>
                                )}
                            </Grid>


                            <Grid item xs={12} md={10}>
                                <Box sx={{ minWidth: 120 }}>
                                <ThemeProvider theme={calTheme}>
                                    <FormControl fullWidth>
                                    <TextField
                                        label="Nominee Full Name"
                                        variant="outlined"
                                        {...formik.getFieldProps("nomineeName")}
                                        fullWidth
                                    />
                                    {
                                        showErr&&formik.errors.nomineeName&&
                                        <p className="errorText">{formik.errors.nomineeName}</p>
                                    }
                                    </FormControl>
                                </ThemeProvider>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={10}>
                                {/* <p className='eduTitle' >Educational Qualification</p> */}
                                <div className="eduWrap">


                                    <FormControl fullWidth className='eduWrapMobilee' >
                                        <InputLabel >Education Qualification</InputLabel>
                                        <Select
                                            label="Education Qualification"
                                            {...formik.getFieldProps('eduQual')}
                                            name="eduQual"
                                        >
                                            <MenuItem disabled value={0}>
                                                <em>Education Qualification</em>
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                Class X
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Class XII
                                            </MenuItem>
                                            <MenuItem value={3}>
                                                Graduate
                                            </MenuItem>
                                            <MenuItem value={4}>
                                                Post Graduate
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {
                                        showErr && formik.errors.eduQual &&
                                        <p className="errorText">{formik.errors.eduQual}</p>
                                    }

                                </div>

                            </Grid>

                            <Grid item xs={12} md={10} >
                                <input type="file" name='eduCert' className='hiddenInp' id='eduCertV'
                                    accept="image/*,.pdf"
                                    onChange={handleEducertInpChange} />

                                <OutlinedInput

                                    // value={10}
                                    fullWidth
                                    className="eduCert fileUpload"
                                    name='eduCert'
                                    readOnly={true}
                                    // disabled={true}
                                    startAdornment=
                                    {
                                        <InputAdornment position="start">
                                            {formik.values.eduCertImage ?
                                                <img src={doneIcon} alt="" /> :
                                                // <CheckCircleOutlineIcon fontSize='large' style={{color:'#00E733',fontSize:'25px'}} />:
                                                <UploadIcon fontSize='large' style={{ color: '#A3A3A3', fontSize: '25px' }} />}
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end" onClick={browseBtn}>
                                            {formik.values.eduCertImage &&
                                                <>
                                                    <p className="reupload" onClick={browseBtn} >
                                                        Re-upload Education Certificate
                                                    </p>
                                                </>
                                            }
                                        </InputAdornment>
                                    }
                                    placeholder={formik.values.eduCertImage ? 'Education Certificate' : 'Upload Education Certificate Copy'}
                                    onClick={browseBtn}

                                />
                                {showErr &&
                                    <p className="errorText">
                                        {formik.errors.eduCertImage}
                                    </p>
                                }

                            </Grid>



                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="Account Number"
                                    variant="outlined"
                                    inputProps={{ maxLength: 18 }}
                                    onChange={handleAccNumberChange}
                                    value={formik.values.accNumber}
                                    fullWidth
                                />
                                {showErr && formik.errors.accNumber && (
                                    <p className="errorText">{formik.errors.accNumber}</p>
                                )}
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="IFSC Code"
                                    inputProps={{ maxLength: 11 }}
                                    variant="outlined"
                                    onChange={handleifscChange}
                                    value={formik.values.ifsc}
                                    fullWidth
                                />
                                {showErr && formik.errors.ifsc && (
                                    <p className="errorText">{formik.errors.ifsc}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="Account holder’s name"
                                    variant="outlined"
                                    //  {...formik.getFieldProps('accHolderName')}
                                    value={formik.values.accHolderName}
                                    disabled
                                    onChange={handleAccHolderNameChange}
                                    inputProps={{ maxLength: 80 }}
                                    fullWidth
                                />
                                {showErr && formik.errors.accHolderName && (
                                    <p className="errorText">{formik.errors.accHolderName}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} md={10} >
                                <TextField
                                    label="Bank Name"
                                    // {...formik.getFieldProps('bankName')}
                                    onChange={handleBankNameChange}
                                    value={formik.values.bankName}
                                    disabled
                                    fullWidth
                                />
                                {showErr && formik.errors.bankName && (
                                    <p className="errorText">{formik.errors.bankName}</p>
                                )}
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel >Account Type</InputLabel>
                                        <Select

                                            label="Account Type"
                                            {...formik.getFieldProps('accType')}
                                        >
                                            <MenuItem disabled value={0}>
                                                <em>-Select Account Type-</em>
                                            </MenuItem>
                                            {
                                                accountType.map((type, ind) => (
                                                    <MenuItem value={type.accountTypeid}>
                                                        <p  >{type.accountName}</p>
                                                    </MenuItem>
                                                ))
                                            }

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>


                            <Grid item xs={12} md={10} >
                                {/* <Link to='/success'> */}
                                <Button className='submitBtn' variant="contained"
                                    onClick={handleNextStep}
                                >
                                    Save and Continue
                                </Button>

                                {/* </Link> */}
                            </Grid>


                        </Grid>

                        <Grid container item xs={0} md={5} className='rightHero' >
                            <img src={require('../assets/Image/verify-details.svg').default} alt="" />
                        </Grid>

                    </Grid>
                </ThemeProvider>
            </Wrapper>
        </>
    )
}

export default VerifyDetail

const Wrapper = styled.div`
 padding:0 8%;
 padding-bottom: 2%;
    a{
        text-decoration: none;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        /* margin-top:10px; */
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size:18px;
        color: #434552;
        :hover{
            .MuiOutlinedInput-notchedOutline{
                border-color: #59BA9B;
                border-width: 1px;
            }
        }
        :-webkit-autofill{
            color:red;
        }
    }
    .MuiOutlinedInput-root.Mui-error{
        .MuiOutlinedInput-notchedOutline{
                border-width: 1px;
                border-color: #ED1C24;
            }
    }
    .textInp{
        font-family: 'Outfit';
        font-size: 18px;
        border: 1px solid #BFC0C5;
        border-radius: 10px;
        padding: 15px 13px;
        margin-right: 20px;
        margin-top:20px;
        outline-color: #ED1C2485;
    }
    .panCard,.eduCert{
        background-color: #F1F1F1;
    }

    .panInput{
    /* margin-top:20px; */
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    .MuiOutlinedInput-input{
        text-transform: uppercase;
    }
}
.errorText{
        font-family: 'Outfit';
       color:red;
       font-size:14px;
       margin-left:8px;
       margin-top:3px;
       
   }
    
   
    .dateInput{
        width: 60%;
    }
    .eduTitle{
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        /* margin-top: 5px; */
        color:#434552;
    }
    .MuiFormControlLabel-root{
        border:2px solid #BFC0C5;
        padding:3px 1px;
        border-radius: 8px;
        margin:5px 8px;
        color:#787878;
        padding-right:20px;
    }
    .eduWrap{
        margin-top:15px;
    }
    .eduWrapMobile{
        display:none;
    }
    .submitBtn{
        padding: 9px 60px;
        border:none;
        outline:none;
        background: #2959E5;
        border-radius: 10px;
        color:white;
        font-size: 20px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        box-shadow:2px #2959E5;
        :hover{
            background-color: #2959E5;
        }
    }
    .rightHero{
        justify-content: center;
        margin-top: 80px;
        align-content: baseline;
        img{
            object-fit: contain;
        }
    }

    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: #FCBC30;
                border-width: 1px;
            }
    }
    .MuiInputLabel-outlined.Mui-focused{
        color:#333333;
    }

  
  
    .MuiFormLabel-root.MuiInputLabel-root{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color:#C4C4C4;
    }
    .fileUpload{
        border-radius: 10px;
        border: 1px dashed #ABABAB;
        background: #F1F1F1;
        color: #888;
        font-family: Outfit;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400 !important;
        
        .MuiOutlinedInput-notchedOutline{
            border-width: 0px !important;
        }
    }

    .hiddenInp{
        display:none;
    }
    .MuiInputLabel-root{
        color:#333333;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        margin-left:3px;
    }
    .reupload{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 126.3%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #00C2FF;
    }

    @media only screen and (max-width: 600px){
        padding:1% 2%;

        .eduTitle{
            font-size:18px;
        }
        .eduWrapMobile{
            display:inline-block;
        }
        .eduWrapDesktop{
            display:none;
        }

        .MuiOutlinedInput-root{
            font-size:16px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-size:16px;
        }

        .rightHero{
            display:none;
        }
        .submitBtn{
            border-radius:7px;
        }
    }

`
const Heading = styled.div`
padding-top: 40px;
    .heading{
        display:flex;
        align-items: center;
            .MuiSvgIcon-root{
                color: #2959E5;
                margin-left: -46px;
                cursor: pointer;
            }
      
        p{
            font-family: 'Outfit';
            font-size: 20px;
            padding-left:10px;
            color:#C4C4C4;
            font-weight: 400;
        }
    }
    .title{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        margin:10px 0;
        display: flex;
        align-items: center;
        gap:10px;
        color:#434552;
        img{
            height:22px;
            object-fit: contain;
        }
    }

    .titleImageMobile{
        display:none;
    }

    @media only screen and (max-width: 600px){
        .title{
            font-size:18px;
        }
        .titleImageMobile{
            display:inline-block;
         }
        .titleImageDesktop{
            display:none;
        }
        .heading{
            display:none;
        }
    }
    
`