import React from 'react';
import styled from 'styled-components';
import { Grid,Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Lottie from 'react-lottie';



const ErrorPage = () => {


    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: require('../assets/Image/404.json'),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return (
        <>
        <Header step={0} />
        <Wrapper>
             <Grid container direction='row' className="mainContainer" >
                <Grid item xs={10} >
                    <ErrorImage>

                    <Lottie options={defaultOptions}
              height={"44vh"}
              width={"397px"}
              isStopped={true}
              isPaused={false}
              />


                        {/* <img src={require('../assets/Image/404.gif').default} alt="" /> */}
                        <p className="errorHeading">
                            Looks like you are lost!
                        </p>
                        <p className="errorText" >
                            The page you requested could not be found
                        </p>
                    </ErrorImage>
                </Grid>
                <Grid item md={3} xs={10} >
                    <Link to='/'>
                        <Button className='submitBtn' variant="contained"
                         >
                             {/* <KeyboardBackspaceIcon fontSize='large' /> */}
                             <img src={require('../assets/Image/404-back.svg').default} alt="lost back to home" />
                                Back to Home
                        </Button>
                    </Link>
                </Grid>

            </Grid>

            <Footer />
        </Wrapper>
        </>
    )
}

export default ErrorPage

const Wrapper = styled.div`

a{
    text-decoration:none;
}


.submitBtn{
        font-family: 'Outfit';
        padding: 10px 25px;;
        border:none;
        outline:none;
        background: #2959E5;
        border-radius: 12px;
        color:white;
        font-size: 24px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
        display:flex;
        justify-content: space-around;
        align-items: center;
        white-space: nowrap;
        :hover{
            background-color: #ED1C24;
        }
    }
    .mainContainer{
        justify-content: center;
        margin-bottom:3%;
        text-align:center;
        /* height:80vh; */
    }
    .errorHeading{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 160%;
        color:#434552;
    }
    .errorText{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 160%;
        color: #434552;
        margin:2% 0;
    }
    @media only screen and (max-width: 600px){
        
        .submitBtn{
            margin-top:10px;
            padding: 8px 9px;
        }
        .errorHeading{
            font-size:32px;
        }
        .errorText{
            font-size: 16px;
        }

    }
`
const ErrorImage = styled.div`
    /* margin-top:-60px; */
    max-height: 100%;
    @media only screen and (max-width: 600px){
        img{
            width:100%;
        }
    }
`