import React,{useEffect} from 'react';
import styled from 'styled-components';
import {
    Grid,
    } from '@mui/material';
    import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
    
    import { createTheme,ThemeProvider } from '@mui/material/styles';
    import Header from './Header';
    import successTick from '../assets/Image/success-tick.svg';
    import CopyrightStrip from './CopyrightStrip';

const Success = ({step,setStep,formik}) => {


    const theme = createTheme({
        typography:{
          fontFamily:[
            'Outfit',
            'Sora'
          ].join(',')
        },
        
      })

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    },[])


    return (
        <>
            {/* <Header /> */}
            <ThemeProvider theme={theme}>
        <Wrapper>
             <Grid container className='mainContainer content' direction='column'  >
                
                <Grid item xs={12} md={6}   >
                    
                        <div className="heading">
                            {/* <a href="/">
                                <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(0)} />
                            </a> */}
                            
                            <div className='title' >
                                
                                {/* <CheckCircleOutlineIcon className='tickMobile' style={{color:'#34CB43E0'}} /> */}
                                
                                <img className='tickMobile' src={require('../assets/Image/verify-details-emoji.png').default} alt="registered succesfully icon" />
                                POSP Application Submitted Successfully
                                
                                <img className='image1' src={require('../assets/Image/verify-details-emoji.png').default} alt="registered succesfully icon" />
                            </div>
                        </div>
                </Grid>

                    <Grid container item xs={12} className='content2' direction={{md:'row',xs:'column-reverse'}}  >
                        {/* <div className="heading">
                            <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(4)} />
                            <p className='title' > Agent Registration Completed Successfully 
                            <img className='image1' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                            </p>
                        </div> */}
                        <Grid item container direction='column' xs={12} md={6} style={{paddingLeft:'25px'}} >
                                <Grid item>
                                    <p style={{paddingTop:'30px'}} > 
                                        {/* <DoneIcon /> */}
                                        <img src={successTick} alt="" />
                                        Your Application Number is {formik.values.applicantid}
                                    </p>
                                </Grid>
                                <Grid item>
                                    <p>
                                        {/* <DoneIcon /> */}
                                        <img src={successTick} alt="" />
                                        The estimated time for your POSP verification is 48 hours
                                    </p>

                                </Grid>
                                <Grid item>
                                    <p>
                                        {/* <DoneIcon /> */}
                                        <img src={successTick} alt="" />
                                        We will confirm the status on your registered email & phone number. 
                                        We sincerly thank you for your patience
                                    </p>
                                </Grid>
                        </Grid>
                        <Grid container item xs={0} md={6} className='rightHero' >
                             <img src={require('../assets/Image/success.svg').default} alt="registered succesfully icon" />
                        </Grid>

                         
                               
                    </Grid>
                                          
                        
                </Grid>
    
                {/* <Grid container item xs={0} md={6} className='rightHero' >
                    <img src={require('../assets/Image/success.png').default} alt="" />
                </Grid> */}
    

            
        </Wrapper>
        {/* <CopyrightStrip /> */}
        </ThemeProvider>
        </>
    )
}

export default Success;

const Wrapper = styled.div`
background-color: #EEF0FB;
padding:0 10%;
padding-bottom: 2%;
/* min-height:90vh; */
min-height:calc(70vh - 90px);
padding-top: 22px;
    a{
        text-decoration: none;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        margin: 10px 0;
        border-color: red;
        font-family: 'Outfit';
    }


.rightHero{
        justify-content: center;
        align-content: baseline;
        img{
            object-fit: contain;
            /* padding-top: 50px;   */
            /* height: 66vh; */
            margin-top:-61px;
        }
    }


    .content{
        padding-top:35px;
        p{
            display:flex;
            font-family: 'Outfit';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 160%;
            color: #434552;
            align-items: center;
            margin:5px 0;
            font-weight: 400;
        }
        .MuiSvgIcon-root{
            color: #4AA25B;
            height: 27px;
            width: 27px;
            font-size: 26px;
            margin-right: 20px;
        }
        .title{
            font-family: 'Outfit';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            margin:10px 0;
            display: flex;
            align-items: center;
            gap:10px;
            white-space: nowrap;
            color: #434552;
            img{
                height:22px;
            }
            .tickMobile{
                display:none;
            }
    }
    .heading{
        display:flex;
        align-items: center;
            .MuiSvgIcon-root{
                color:#2959E5;
                margin-left: -16px;
                cursor: pointer;
            }
      
        p{
            font-family: 'Sora';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            margin:10px 0;
        }
    }

    }
    .content2{
        p{
            img{
                padding-right:20px;
            }
        }
    }


    @media only screen and (max-width: 600px){
        padding:1% 2%;
        .rightHero{
            img{
                height:50vh;
                margin-top: 0px;
            }
        }
        .content{
            p{
                font-size:16px;
            }
            .title{
                white-space: normal;
                font-size:18px;
                margin-left: 26px;
                gap:0px 7px;
                
                .tickMobile{
                    display:inline-block;
                }
            }
            .heading{
                .image1{
                    display:none;
                }
                .MuiSvgIcon-root{
                    display:none;
                }
            }
        }
    }


`

