import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import FaceBook from "../assets/Image/facebook.svg";
import Instagram from "../assets/Image/instagram.svg";
import IosStore from "../assets/Image/ios-store.svg";
import LinkedIn from "../assets/Image/linkedin.svg";
import Mail from "../assets/Image/mail.svg";
import MapPin from "../assets/Image/map-pin.svg";
import PlayStore from "../assets/Image/play-store.svg";
import Twitter from "../assets/Image/twitter.svg";
import YouTube from "../assets/Image/youtube.svg";
import Logo from "../assets/Image/black-theme-logo.svg";
import IndianFlag from "../assets/Image/indian-flag.svg";
const Footer = () => {
    return (
        <Wrapper>
            {/* <MainText>
                <p style={{paddingBottom:'3px'}} >
                    Help Line no. 022-41475221 (9:00 AM - 9:00 PM), All days
                </p>
                <p>
                    FG&G Distribution Pvt. Ltd.
                </p>
                <p>
                    (A JV between Future Group and Assicurazioni Generali)
                </p>
                <p>
                    CIN: U66000MH2018PTC316154 | IRDA Registration number: CA0622
                </p>
                <p>
                    Category: Corporate Agent (Composite)
                </p>

            </MainText>
            <Terms>
                <a href={require('../assets/document/T&CAgreement.pdf').default} target="_blank" rel="noopener noreferrer">
                    <p>
                        Terms & Conditions
                    </p>
                </a>
                <a href={require('../assets/document/PrivacyPolicy.pdf').default} target="_blank" rel="noopener noreferrer">
                    <p>
                        Privacy Policy
                    </p>
                </a>

            </Terms> */}
            <Box className="footer-container">
                <Container maxWidth="xl">
                    <Grid container spacing={2} className="footer-container-wrapper">
                        <Grid item lg={4} xs={12} className="footer-grid-1">
                            <Box className="footer-logo">
                                <img src={Logo} alt="" />
                            </Box>
                            <Typography className="footer-desc" component={"p"}>
                                Get set to redefine your insurance experience with our best in
                                class insurance solutions that guarantee you peace of mind.
                            </Typography>
                            <Box className="app-download-icons-wrap">
                                <Box className="download-icon-wrap">
                                    <a
                                        target="_blank"
                                        // href={process.env.NEXT_PUBLIC_PLAY_STORE_APP_LINK}
                                        href="https://coversure.onelink.me/40Yt/i3n6x54p" rel="noreferrer"
                                    >
                                        <img src={PlayStore} alt="" />
                                    </a>
                                </Box>
                                <Box className="download-icon-wrap">
                                    <a target="_blank"
                                        // href={process.env.NEXT_PUBLIC_IOS_APP_LINK}
                                        href="https://coversure.onelink.me/40Yt/i3n6x54p" rel="noreferrer"
                                    >
                                        <img src={IosStore} alt="" />
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={4} xs={12} className="footer-grid-3">
                            <Typography className="footer-link-head-2" component={"p"}>
                                Legal
                            </Typography>
                            <Box className="footer-link">
                                <a href={'https://app-stag-public.s3.ap-south-1.amazonaws.com/posp/terms_condition/T%26C%2BPolicy%2BCoverSure%2B-%2BPOSP%2BPage.pdf'} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                            </Box>
                            <Box className="footer-link">
                                <a href={'/privacy-policy'} target="_blank" rel="noopener noreferrer">Privacy Policy </a>
                            </Box>
                        </Grid>
                        <Grid item lg={4} xs={12} className="footer-grid-4">
                          <Box className="icon-with-content-wrap">
                            {/* <Box className="address-icon-wrap">
                              <Image src={MapPin} alt="" />
                            </Box> */}
                            {/* <Typography className="footer-address-content" component={"p"}>
                            2nd floor, Birla Centurion, Pandurang Budhkar Marg, Century Mills Compound, Worli, Mumbai, Maharashtra 400030
                            </Typography> */}
                            <Typography className="footer-address-heading" component={"h1"}>
                            Claycove23 Insurance Tech Pvt. Ltd.
                            </Typography>
                            <Typography className="footer-address-content" component={"p"}>
                            CIN: U74999MH2022PTC396393
                            </Typography>
                            <Typography className="footer-address-content" component={"p"}>
                            IRDAI Reg No. CA0894
                            </Typography>
                            <Typography className="footer-address-content" component={"p"}>
                            Category: Corporate Agent (Composite)
                            </Typography>
                            <Typography className="footer-address-content" component={"p"}>
                            Validity: till 9th Jan’2027
                            </Typography>
                          </Box>
                            {/* <Box className="email-address-icon-wrap">
                                <Box className="icon-with-content-wrap">
                                    <Box className="footer-email-icon-wrap">
                                        <img src={Mail} alt="" />
                                    </Box>
                                    <Typography className="footer-address-content" component={"p"}>
                                        Write to us at
                                    </Typography>
                                </Box>
                                <Box className="mail-to-address">
                                    <a href="mailto:care@coversure.in">care@coversure.in</a>
                                </Box>
                            </Box> */}
                            {/* <Typography className="disclaimer-header isMobile" component={"p"}>
                                CoverSure is in process of obtaining IRDAI license as an insurance intermediary. The information provided on this Website is not intended to constitute a solicitation of insurance products or insurance services in any jurisdiction (it does not offer to buy or sell any insurance product or service). The Website does not endorse any insurance company or their products. The content and materials on this Website are for general informational purposes only.
                            </Typography> */}
                            <Box className="social-media-icons-wrap">
                                <Box className="media-icon-wrap">
                                    <a
                                        href="https://www.facebook.com/coversurein/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={FaceBook} alt="" />
                                    </a>
                                </Box>
                                <Box className="media-icon-wrap">
                                    <a
                                        href="https://www.instagram.com/coversure_in/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Instagram} alt="" />
                                    </a>
                                </Box>
                                <Box className="media-icon-wrap">
                                    <a
                                        href="https://twitter.com/Coversure_in"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={Twitter} alt="" />
                                    </a>
                                </Box>
                                <Box className="media-icon-wrap">
                                    <a
                                        href="https://www.youtube.com/@CoverSure_In"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={YouTube} alt="" />
                                    </a>
                                </Box>
                                <Box className="media-icon-wrap">
                                    <a
                                        href="https://www.linkedin.com/company/coversurein"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={LinkedIn} alt="" />
                                    </a>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} className="footer-container-wrapper">
                        <Grid item lg={12} xs={12} className="footer-grid-1">
                            <Typography className="disclaimer-header isWeb" component={"p"}>
                                CoverSure is in process of obtaining IRDAI license as an insurance intermediary. The information provided on this Website is not intended to constitute a solicitation of insurance products or insurance services in any jurisdiction (it does not offer to buy or sell any insurance product or service). The Website does not endorse any insurance company or their products. The content and materials on this Website are for general informational purposes only.
                            </Typography>
                        </Grid>
                    </Grid> */}
                </Container>
            </Box>
            <Box className="copy-right-wrapper">
            <Typography className="copy-right-header" component={"p"}>
              Copyright 2023. CoverSure. All Rights Reserved.
            </Typography>
            <Box className="made-in-india-wrap">
              <Typography className="made-in-india-header" component={"p"}>
                Made in India
              </Typography>
              <Box className="made-in-india-img-wrap">
                <img src={IndianFlag} alt="" />
              </Box>
            </Box>
          </Box>
        </Wrapper>
    )
}

export default Footer

const Wrapper = styled.div`
    padding:4% 8%;
    background-color: #060E38;
    font-family: 'Manrope' !important;
    font-style: normal;
    font-weight: 300;
    color:white;
    display: flex;
    flex-direction: column;
    gap:20px 10px;

    @media only screen and (max-width: 600px){

    }
    .footer-container {
      
        @media only screen and (max-width: 1200px){
          background: #060e38;
          padding: 0px 0 35px;
        }
        .footer-container-wrapper {
          .disclaimer-header{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            color: #b1b8c7;
            margin: 34px 0;
            font-family: 'Manrope' !important;
          }
          .isMobile{
            display: block;
            font-family: 'Manrope' !important;
            @media only screen and (min-width: 1200px) {
              display: none;
            }
          }
          .isWeb{
            display: none;
            font-family: 'Manrope' !important;
            @media only screen and (min-width: 1200px) {
              display: block;
            }
          }
          .footer-grid-1 {
            .footer-logo {
              width: 180px;
              display: flex;
              align-items: center;
      
              img {
                width: 100%;
              }
            }
      
            .footer-desc {
              font-size: 20px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
              color: #b1b8c7;
              max-width: 322px;
              margin: 25px 0;
              font-family: 'Manrope' !important;
      
              @media only screen and (max-width: 1700px) {
                font-size: 14px;
                line-height: 19px;
              }
      
              @media only screen and (max-width: 1200px) {
                font-size: 12px;
                line-height: 22px;
                max-width: unset;
              }
            }
      
            .app-download-icons-wrap {
              display: flex;
              align-items: center;
              justify-content: flex-start;
      
              .download-icon-wrap {
                width: 180px;
                a {
                  display: flex;
                  align-items: center;
                  margin-right: 12px;
                  font-family: 'Manrope' !important;
                }
      
                @media only screen and (max-width: 1700px) {
                  width: 135px;
                }
      
                @media only screen and (max-width: 1200px) {
                  width: 100px;
                }
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
      
          .footer-link-head-1 {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #426ef4;
            font-family: 'Manrope' !important;
            
            @media only screen and (max-width: 1700px) {
              font-size: 14px;
              line-height: 19px;
            }
      
            @media only screen and (max-width: 1200px) {
              padding-top: 16px;
              font-size: 12px;
              line-height: 16px;
            }
          }
      
          .footer-link-head-2 {
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #426ef4;
            font-family: 'Manrope' !important;
      
            @media only screen and (max-width: 1700px) {
              font-size: 14px;
              line-height: 19px;
            }
      
            @media only screen and (max-width: 1200px) {
              padding-top: 25px;
              font-size: 12px;
              line-height: 16px;
            }
          }
      
          .footer-link {
            margin-top: 12px;
            
            a {
              font-size: 20px;
              font-weight: 400;
              line-height: 32px;
              letter-spacing: 0em;
              text-align: left;
              color: #b1b8c7;
              font-family: 'Manrope' !important;
              text-decoration: none;
              @media only screen and (max-width: 1700px) {
                font-size: 14px;
                line-height: 19px;
              }
      
              @media only screen and (max-width: 1200px) {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
      
          .footer-grid-4 {
            .icon-with-content-wrap {
              // display: flex;
              display: block;
              align-items: flex-start;
      
              @media only screen and (max-width: 1200px) {
                padding-top: 25px;
              }
      
              .footer-email-icon-wrap {
                width: 20px;
                display: flex;
                align-items: center;
                margin-right: 6px;
                margin-top: 6px;
                font-family: 'Manrope' !important;
      
                @media only screen and (max-width: 1700px) {
                  margin-top: 0;
                }
      
                @media only screen and (max-width: 1200px) {
                  width: 16px;
                }
      
                img {
                  width: 100%;
                }
              }
      
              .address-icon-wrap {
                width: 20px;
                display: flex;
                align-items: center;
                margin-right: 6px;
                margin-top: 6px;
      
                @media only screen and (max-width: 1700px) {
                  margin-top: 0;
                }
      
                @media only screen and (max-width: 1200px) {
                  // width: 45px;
                  min-width: 16px;
                }
      
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .footer-address-heading{
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                color: #fff;
                margin-bottom: 10px;
                font-family: 'Manrope' !important;
                @include respond(max-x-lg) {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  color: #fff;
                  margin-bottom: 10px;
                  font-family: 'Manrope' !important;
                }
      
                @include respond(max-lg) {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px;
                  color: #fff;
                  margin-bottom: 10px;
                  font-family: 'Manrope' !important;
                }
              }
              .footer-address-content {
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
                color: #b1b8c7;
                // max-width: 285px;
                margin-top: 4px;
                font-family: 'Manrope' !important;
      
                @media only screen and (max-width: 1700px) {
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 16px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #b1b8c7;
                  // max-width: 285px;
                  margin-top: 4px;
                  font-family: 'Manrope' !important;
                }
      
                @media only screen and (max-width: 1200px) {
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 16px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #b1b8c7;
                  // max-width: 285px;
                  margin-top: 4px;
                  font-family: 'Manrope' !important;
                }
              }
            }
      
            .email-address-icon-wrap {
              margin-top: 25px;
      
              @media only screen and (max-width: 1200px) {
                margin-top: 0;
              }
      
              .mail-to-address {
                margin-top: 8px;
                a {
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 32px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #426ef4;
                  font-family: 'Manrope' !important;
      
                  @media only screen and (max-width: 1700px) {
                    font-size: 14px;
                    line-height: 19px;
                  }
      
                  @media only screen and (max-width: 1200px) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }
            }
      
            .social-media-icons-wrap {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              margin-top: 25px;
      
              .media-icon-wrap {
                display: flex;
                align-items: center;
                margin-right: 20px;
                //   width: 18px;
      
                img {
                  width: 100%;
                }
              }
            }
          }
        }
        
      }  
      .copy-right-wrapper {
        width: 100%;
        .copy-right-header {
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: center;
          color: #b1b8c7;
          margin-bottom: 8px;
          font-family: 'Manrope' !important;
    
          @media only screen and (max-width: 1200px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
    
        .made-in-india-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
    
          .made-in-india-header {
            font-size: 10px;
            font-weight: 800;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
            font-family: 'Manrope' !important;
            background: linear-gradient(
              91.53deg,
              #ff9a00 5.9%,
              #ffffff 55.36%,
              #048f17 103.8%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            padding-right: 6px;
    
            @media only screen and (max-width: 1200px) {
              font-size: 14px;
              line-height: 19px;
            }
          }
    
          .made-in-india-img-wrap {
            display: flex;
            align-items: center;
            width: 52px;
    
            img {
              width: 100%;
            }
          }
        }
      }    
`
const MainText = styled.div`
    p{
        font-size: 16px;
        line-height: 126.3%;
    }
    @media only screen and (max-width: 600px){
        p{
            font-size: 12px;
        }
    }
`
const Terms = styled.div`
a{
        text-decoration: none;
        color:inherit;
    }
    p{
        text-decoration: underline;
        font-size: 14px;
        line-height: 126.3%;
        cursor: pointer;
    }
    @media only screen and (max-width: 600px){
        p{
            font-size: 12px;
        }
    }
`