import React from 'react';
import styled from 'styled-components';
import { Grid,InputLabel,OutlinedInput,Select,MenuItem} from '@mui/material';
import {useSelector} from "react-redux";

const ViewBankInfo = ({formik}) => {

    const accountType = useSelector(state=>state.masterList.accountType);
    return (
        <Wrapper>
            <div className="heading">
            <img src={require('../../../assets/Image/bank-info-emoji.png').default} alt="" className="titleIcon" />
                <p>
                    Bank Information:
                </p>
            </div>
            <MainContent>
                <Grid container direction="column" spacing={3} >

                        {/* GRID CONTAINER 1 START */}
                        <Grid container item spacing={2} >
                            <Grid item md={3} >
                                <InputLabel>
                                    Account Number:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth
                                disabled
                                size='small'
                                placeholder='Account Number'
                                {...formik.getFieldProps('accNo')}
                                 />
                            </Grid>
                            <Grid item md={3.8} >
                                <InputLabel>
                                    Bank Name: 
                                </InputLabel>
                                <OutlinedInput
                                    defaultValue={0}
                                    fullWidth
                                    disabled
                                    size='small'
                                    placeholder='Bank Name'
                                    {...formik.getFieldProps('bankName')}
                                />
                                    
                            </Grid>
                            <Grid item md={3} >
                                <InputLabel>
                                    IFSC Code:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small' 
                                {...formik.getFieldProps('ifscCode')}
                                />
                            </Grid>
                            <Grid item md={2.2} >
                                <InputLabel>
                                    Account Type:
                                </InputLabel>
                                <Select
                                    defaultValue={1}
                                    fullWidth
                                    disabled
                                    size='small'
                                    placeholder='-Select Account Type-'
                                    {...formik.getFieldProps('accType')}
                                >
                                    <MenuItem value={0} disabled >-Select Account Type-</MenuItem>

                                    {
                                        accountType.map((type,ind)=>(
                                            <MenuItem value={type.accountTypeid}>
                                                <p  >{type.accountName}</p>
                                            </MenuItem>
                                        ))
                                    }
                                    {/* <MenuItem value={1} divider={true} >Savings</MenuItem>
                                    <MenuItem value={2} >Current</MenuItem> */}
                                    
                                  
                                </Select>
                            </Grid>
                        </Grid>
                        {/* GRID CONTAINER 1 END */}

                        {/* GRID CONTAINER 2 START */}
                        <Grid item container spacing={2} >
                            <Grid item md={4} >
                                <InputLabel>
                                    Account Holder Name:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small' 
                                placeholder='Account Holder Name'
                                {...formik.getFieldProps('accHolderName')}
                               
                                />
                            </Grid>
                            {/* <Grid item md={3} >
                                <InputLabel>
                                    Branch Name:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                placeholder='Branch Name'
                                size='small'
                                {...formik.getFieldProps('branchName')}
                                 />
                            </Grid> */}
                            
                        </Grid>
                        {/* GRID CONTAINER 2 END */}

                        
                </Grid>
            </MainContent>
            
        </Wrapper>
    )
}

export default ViewBankInfo

const Wrapper = styled.div`
    /* height:200px; */
    /* width:80%; */
    background-color:white;
    margin:20px auto;
    padding:25px;
    border-radius:12px;
    box-shadow: 0px 0px 3px 0px #7e7b7b8a;
    .heading{
        display:flex;
        align-items: center;
        gap:0 5px;
        margin-bottom:30px;
        margin-top:20px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color:#333333;
        }
        .titleIcon{
            height:24px;
            object-fit: contain;
        }
    }
    
`
const MainContent = styled.div`
    padding:10px;
    .MuiOutlinedInput-root{
        font-size:16px;
        color:#333333;
    }
    .MuiInputLabel-root{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #7C7C7C;
        padding-bottom:7px;
    }
    .MuiOutlinedInput-input.Mui-disabled{
        -webkit-text-fill-color:#333333;
        color:#333333;
    }
`