import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography } from '@mui/material';
import { Link,useNavigate  } from 'react-router-dom';
import {getGoogleLogin, userAuth} from '../../api/index';
import useAuth from '../../useAuth';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../redux/actions/loader';
import {
	useGoogleLogin,
} from '@react-oauth/google';
import InfoIcon from '../../assets/Image/info.svg';
import LoginGoogleSearch from '../../assets/Image/login-google-serach-icon.svg';
const Login = () => {
    const dispatch = useDispatch()
    const isAuthenticated = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
        navigate('/admin/pending');
        }
    }, [isAuthenticated, navigate]);

    const [pass, setPass] = useState(true);
    const [auth, setAuth] = useState({
        userName:'',
        pwd:''
    })

    const [showErr, setShowErr] = useState(false);
    const [loginErr, setLoginErr] = useState('');
    
    const [err, setErr] = useState({
        userName:'',
        pwd:''
    })

    const [loginDataStatus, setLoginData] = useState({ loading: false, hasError: false, data: null });

	const handleGoogleLoginSuccess = async (res) => {
		// handle BE request here
		try {
			if (!res || !res.code) {
				throw Error('Not sufficient data');
			}

                dispatch(toggleLoader(true));
                const response = await getGoogleLogin(res.code);
                const userData ={
                    userId:response.data.data.userId,
                    userRole:response.data.data.userRole,
                    loginAt:new Date().getTime(),
                }
                setLoginData({
                	...loginDataStatus,
                	loading: false,
                	hasError: false,
                	data: res,
                });
                sessionStorage.setItem('authData',JSON.stringify(userData));
                sessionStorage.setItem('token',response.data.data.token);
                sessionStorage.setItem('lastActive',new Date().getTime());
                dispatch(toggleLoader(false));
                navigate('/admin/pending');
		} catch (error) {
			setLoginData({
				...loginDataStatus,
				loading: false,
				hasError: true,
				data: null,
			});
            dispatch(toggleLoader(false));
            if(error.response&&error.message&&error.response.data&&error.response.data.responseMessage){
                setLoginErr(error.message&&error.response.data&&error.response.data.responseMessage);
            }else{
                setLoginErr('Something went wrong, please try again later');
            }
            console.log('auth api error');
            
		}
	};

	const handleGoogleLoginFailure = () => {
		console.log('Google Fail')
        setLoginData({ ...loginDataStatus, loading: false, hasError: true });
	};

	const loginFun = useGoogleLogin({
		onSuccess: handleGoogleLoginSuccess,
		onError: handleGoogleLoginFailure,
		onNonOAuthError: (e) =>{
			console.log('Google Error', e);
            setLoginData({ ...loginDataStatus, loading: false });
        },
		flow: 'auth-code',
		ux_mode: 'popup',
	});
	const handleGoogleLogin = async () => {
		if (loginDataStatus.loading) return;
		loginFun();
	};

    const handleInpChange=(e)=>{
        if(e.target.name==="userName"){
            setAuth({...auth,userName:e.target.value});
            if(!e.target.value){
                setErr({...err,userName:"Please enter email address"});
            }
            if(e.target.value){
                setErr({...err,userName:""});
            }
            // if(e.target.value&&!/[a-z0-9]{1,10}@[a-z0-9]{1,10}[.]{1,1}[a-z0-9]{2,10}/gm.test(e.target.value)){
            //     setErr({...err,userName:"Please enter valid email"});
            // }else if(/[a-z0-9]{1,10}@[a-z0-9]{1,10}[.]{1,1}[a-z0-9]{2,10}/gm.test(e.target.value)){
            //     setErr({...err,userName:""});
            // }
        }
        if(e.target.name==="pwd"){
            setAuth({...auth,pwd:e.target.value});
            if(!e.target.value){
                setErr({...err,pwd:"Please enter password"});
            }
            if(e.target.value){
                setErr({...err,pwd:""});
            }
        }
    }

    const loginUser =async()=>{
        setLoginErr('');
        
        if(!auth.userName){
            setErr({...err,userName:"Please enter email address"});
        }
        if(!auth.pwd){
            setErr({...err,pwd:"Please enter password"});
        }
        
       
        if(err.userName||err.pwd||!auth.userName||!auth.pwd){
            setShowErr(true);
            return
        }

                
        try {
            dispatch(toggleLoader(true));
            const {data} = await userAuth(auth.userName,auth.pwd);
            const userData ={
                userId:data.userId,
                userRole:data.userRole,
                loginAt:new Date().getTime(),
            }
            sessionStorage.setItem('authData',JSON.stringify(userData));
            sessionStorage.setItem('token',data.token);
            sessionStorage.setItem('lastActive',new Date().getTime());
            dispatch(toggleLoader(false));
            navigate('/admin/pending');
        } catch (error) {
            if(error.response&&error.message&&error.response.data&&error.response.data.responseMessage){
                setLoginErr(error.message&&error.response.data&&error.response.data.responseMessage);
            }else{
                setLoginErr('Something went wrong, please try again later');
            }
            console.log('auth api error');
        }
    }


    return (
        <Wrapper>
            <LeftArea>
                <Logo>
                    <img src={require('../../assets/Image/coverSureLogo.svg').default} alt="" />
                </Logo>
                <LeftHero>
                    <img src={require('../../assets/Image/login-hero.svg').default} alt="" />
                </LeftHero>

            </LeftArea>
            <RightArea>
                <div className="heading">
                    <p>
                        Sign in to CoverSure POSP
                    </p>
                    <img src={require('../../assets/Image/lock-icon.png').default} alt="" className="titleEmoji" />
                    
                </div>
                <div className="inputCont">
                    
                        <InputLabel>
                            Email Address
                        </InputLabel>
                        <OutlinedInput
                        placeholder="E-mail"
                            value={auth.userName}
                            startAdornment={<InputAdornment position="start"><EmailIcon /></InputAdornment>}
                            name="userName"
                            onChange={handleInpChange}
                        />
                        {showErr&&
                        <p className='errText' >{err.userName}</p>
                        }
                       
                        <InputLabel className='label2'>
                            <p>
                                Password
                            </p>
                            {/* <p className='forgotPass' >
                            Forgot Password?
                            </p> */}

                        </InputLabel>
                        <OutlinedInput
                        type={pass?'password':'text'}
                            value={auth.pwd}
                            startAdornment={<InputAdornment position="start"><LockIcon /></InputAdornment>}
                            endAdornment={<InputAdornment position="end"><VisibilityIcon onClick={()=>setPass(!pass)} style={{cursor:'pointer'}} /></InputAdornment>}
                            name="pwd"
                            onChange={handleInpChange}
                            placeholder="Password"
                        />
                        {showErr&&
                        <p className='errText' >{err.pwd}</p>
                        }

                        { loginErr&&
                        <p className='errText' style={{textAlign:'center'}} >{loginErr}</p>
                        }
                        
                {/* <Link to='/admin/pending' > */}
                    <Button variant='contained' 
                    // style={{width:'54%'}} 
                    size='large' 
                    disableElevation
                    fullWidth
                    onClick={loginUser}
                    >
                        Sign In
                    </Button>
                    <div style={{ textAlign: 'center', padding: '8px 0', fontFamily: 'Helvetica' }}>Or</div>
                    <Box className="login-button-wrapper">
					<Box className="sign-in-btn-container" onClick={handleGoogleLogin}>
						<Box className="google-search-img-wrap">
							<img src={LoginGoogleSearch} alt="" />
						</Box>
						<Typography className="google-search-btn" component={'h3'}>
							{loginDataStatus?.loading ? 'Logging in...' : 'Login with Google'}
						</Typography>
					</Box>
					{loginDataStatus.hasError ? (
						<Box className="error-message">
							<Box className="image">
								<img src={InfoIcon} alt=""/>
							</Box>
							<Typography className="message-content">
								You are not authorised to access this dashboard. Please contact
								the Admin or Product team for assistance.
							</Typography>
						</Box>
					) : (
						<></>
					)}
				</Box>
                {/* </Link> */}
                </div>
                

            </RightArea>
            
        </Wrapper>
    )
}

export default Login

const Wrapper = styled.div`
    display:flex;
    justify-content: center;
   a{
       text-decoration: none;
       
   }
   .login-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    .sign-in-btn-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f1f1f4;
        padding: 12px 0;
        cursor: pointer;
        width: 100%;
        border-radius: 10px;

        .google-search-img-wrap {
            width: 25px;
            display: flex;
            align-items: center;
            padding-right: 12px;

            img {
                width: 100%;
            }
        }

        .google-search-btn {
            font-family: Helvetica;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
            color: #1a1a1c;
        }
    }
    .error-message {
        width: 90%;
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        .image {
            margin-right: 10px;
        }
        .message-content {
            font-size: 14px;
            font-family: 'Poppins';
            letter-spacing: 0.56px;
            color: #f02929;
        }
    }
}
`
const LeftArea = styled.div`
    flex:0.5;
    background-color:#f7f8fb;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    text-align: center;

    @media only screen and (max-width: 1000px) {
        display: none;
    }
`
const Logo = styled.div`
    height:100px;
    // padding-left: 15%;
    padding-top: 6%;
    img{
        height:100%;
        object-fit: contain;
    }

`
const LeftHero = styled.div`
    display:flex;
    margin-top: -5%;
    justify-content: center;
    align-items: center;
`
const RightArea = styled.div`
    flex:0.5;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .MuiSvgIcon-root{
        color:#B4B4B4;
    }
    .MuiOutlinedInput-root{
        border-radius: 10px;
        margin-top: 2px;
        margin-bottom: 7px;
    }
    .MuiInputLabel-root{
        color:#333333;
        font-size: 16px;
        
    }
    .heading{
        display:flex;
        align-items: center;
        // width: 58%;
        gap:3px;
        margin-bottom: 40px;
        p{
            font-family: Helvetica;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 142.8%;
            color: #333333;
            white-space: nowrap;
        }
        .titleEmoji{
            height:35px;
            object-fit: contain;
        }
    }
    
    .inputCont{
        display: flex;
        flex-direction: column;
        width: 56%;
    }
    .label2{
        margin-top: 24px;
        width:126%;
        display: flex;
        justify-content: space-between;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 126.3%;
        color: #333333;
        .forgotPass{
            color: #C4C4C4;
            font-family: Helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
    .MuiButton-root{
       margin-top: 24px;
        padding:10px 0;
        border-radius: 10px;
        text-transform:capitalize;
        background-color:#E0E8FE;
        font-family: Helvetica;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 126.3%;
        text-align: center;
        color: #333;

        :hover{
            background-color:#dbdde3;
        }
    }

    .errText{
        color:red;
        font-family: Helvetica;
        font-style: normal;
        margin-bottom:13px;
        margin-left:8px;
    }
`


