import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import {
    Grid,
    Button,
    OutlinedInput,
    InputAdornment,
    FormControl,
    Dialog, DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import UploadIcon from '@mui/icons-material/Upload';

import { uploadFile } from '../api';
import { InputLabel } from '@mui/material';
import doneIcon from '../assets/Image/done-tick.svg';
import editIcon from '../assets/Image/edit-icon.svg';
import uploadIcon from '../assets/Image/upload-icon.svg';
import { useNavigate } from 'react-router-dom';
import BecomeAgentStrip from './BecomeAgentStrip';
import { getManagerVerify } from '../api';
import '../App.css';
import managerImage from './manager_icon.svg';
import checkImage from './check.svg';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../redux/actions/loader'

const Input = muiStyled('input')({
    display: 'none',
});


const UploadDoc = ({ step, setStep, formik, setManagerDetail }) => {

    const navigate = useNavigate();
    const [showErr, setShowErr] = useState(false);
    const [panFile, setPanFile] = useState('');
    const [eduFile, setEduFile] = useState('');
    const [open, setOpen] = useState(false);
    const [managerId, setManagerId] = useState('')
    const [managerData, setManagerData] = useState({})
    const [isError, setIsError] = useState(null)
    const [getResponse, setGetResponse] = useState(false)
    const dispatch = useDispatch()
    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setOpen(false);
    };

    const verifyManager = async () => {
        setGetResponse(true)
        try {
            dispatch(toggleLoader(true))
            const res = await getManagerVerify(managerId)
            dispatch(toggleLoader(false))
            console.log(res)
            if (res?.status === 200) {
                setManagerData(res?.data?.data)
                setManagerDetail(res?.data?.data)
                setIsError(false)
            }
        } catch (res) {
            dispatch(toggleLoader(false))
            setIsError(true)
        }
    }

    const continueManager = async () => {
        handleClose()

        formik.setFieldValue('managerUserId', managerData?.userName)
        setStep(4);
    }
    const handlePanInpChange = (e) => {


        if (

            (
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'png') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpeg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'pdf')
            ) &&
            e.target.files[0].size <= 10 * 1000 * 1000
        ) {
            formik.setFieldValue('panImage', e.target.files[0]);
            setPanFile('');
        } else {
            setPanFile('Please use a valid file type, eg..png, .jpeg,.jpg,.pdf');
            formik.setFieldValue('panImage', '');
            setShowErr(true);

        }


    }
    const handleEducertInpChange = (e) => {
        if (
            (
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'png') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpeg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'pdf')
            ) &&
            e.target.files[0].size <= 10 * 1000 * 1000
        ) {
            formik.setFieldValue('eduCertImage', e.target.files[0]);
            setEduFile('');
        } else {
            setEduFile('Please use a valid file type, eg..png, .jpeg,.jpg,.pdf');
            setShowErr(true);
            formik.setFieldValue('eduCertImage', '');
        }
    }



    const browseBtn = (e) => {


        const panInp = document.getElementById('panCard');
        const eduCertInp = document.getElementById('eduCert');


        if (e.target.name === 'panCard') {
            panInp.click();
        }
        if (e.target.name === 'eduCert') {
            eduCertInp.click();
        }
    }


    const handleNextStep = async () => {


        // --------checking for session expire----------
        const lastActiveAgent = JSON.parse(sessionStorage.getItem('lastActiveAgent'));
        const currentTime = new Date().getTime();
        const sessionTime = 30 * 60 * 1000;
        if (
            lastActiveAgent &&
            ((currentTime - Number(lastActiveAgent)) > sessionTime)
            && (step > 0 || step <= 5)
        ) {
            sessionStorage.removeItem('lastActiveAgent');
            alert('session expired,session is valid for 30 Minutes');
            setStep(0);
            navigate('/');
            return
        }
        sessionStorage.setItem('lastActiveAgent', new Date().getTime());

        //    -------------session expire end--------------------------

        if (!formik.values.panImage ||
            !formik.values.eduCertImage
        ) {
            // formik.values.applicantid
            formik.setFieldTouched('panImage', true);
            setShowErr(true);
            return
        }

        const srData = JSON.parse(sessionStorage.getItem('rData'));
        const formData = new FormData();


        formData.append("pan", formik.values.panImage);
        formData.append("edu", formik.values.eduCertImage);
        formData.append("applicatid", JSON.stringify({ applicantid: formik.values.applicantid }));

        try {
            const { data } = await uploadFile(formData);

            // if(data.responseCode!==200){
            //     throw new Error(data.responseMessage);
            // }

            // if(srData&&srData.rrn&&srData.aid&&srData.rpg==="3"){
            //     setStep(5);
            //     return
            // }

            if(formik?.values?.managerUserId !== null){
                console.log('23456')
                setStep(4);
            }else{
            handleClickOpen()
            }


        } catch (error) {
            console.log(error.message);
            console.log('something went wrong in api,');
            // setStep(4);
        }
    }


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [])

    return (
        <>
            <BecomeAgentStrip />
            <Wrapper>
                <Grid container className='mainContainer' spacing={2} justifyContent="center"  >

                    <Grid item md={0.5} className="heading">
                        <ArrowBackIosNewIcon
                            fontSize="large"
                            onClick={() => setStep(2)}
                        />
                    </Grid>
                    <Grid container item xs={12} md={5} direction='row' spacing={0} >
                        <Grid item  >
                            <Heading>
                                {/* <div className="heading">
                            <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(2)} />
                            <p>Become a Hello Zindagi agent</p>
                        </div> */}
                                <p className='title' >

                                    Upload Documents
                                    <img className='titleImageDesktop' src={require('../assets/Image/bank-info-emoji.png').default} alt="" />
                                    <img className='titleImageMobile' src={require('../assets/Image/bank-info-emoji.png').default} alt="" />
                                </p>
                            </Heading>
                        </Grid>

                        <Grid item xs={12} md={10}  >

                            <input type="file" name='panCardImage' accept=".jpeg,.png,.pdf,.jpg" className='hiddenInp' id='panCard'
                                onChange={handlePanInpChange}
                            />
                            <InputLabel f >Upload Pan Card Copy</InputLabel>
                            <OutlinedInput
                                fullWidth
                                name='panCard'
                                readOnly={true}
                                startAdornment=
                                {
                                    <InputAdornment position="start">
                                        {formik.values.panImage ?
                                            <img src={doneIcon} alt="" /> :
                                            <img src={uploadIcon} alt="" name='panCard' />
                                        }

                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        {formik.values.panImage &&
                                            <>
                                                <img src={editIcon} alt="" name='panCard' onClick={(e) => browseBtn(e)} />
                                            </>
                                        }
                                    </InputAdornment>
                                }
                                placeholder={formik.values.panImage ? formik.values.panImage.name ? formik.values.panImage.name : formik.values.panImage.split('/').pop() : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                onClick={formik.values.panImage ? null : (e) => browseBtn(e)}
                            />

                            {showErr &&
                                <p className="errorText">
                                    {panFile || formik.errors.panImage}
                                </p>
                            }
                        </Grid>


                        <Grid item xs={12} md={10}  >

                            <input type="file" accept=".jpeg,.png,.pdf,.jpg" name='eduCert' className='hiddenInp' id='eduCert' onChange={handleEducertInpChange} />
                            <InputLabel >Upload Education Certificate</InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                // value={10}
                                fullWidth
                                name='eduCert'
                                readOnly={true}
                                startAdornment=
                                {
                                    <InputAdornment position="start">
                                        {formik.values.eduCertImage ?
                                            <img src={doneIcon} alt="" /> :
                                            <img src={uploadIcon} name='eduCert' alt="" />
                                        }
                                    </InputAdornment>
                                }

                                endAdornment={
                                    <InputAdornment position="end">
                                        {formik.values.eduCertImage &&
                                            <>
                                                <img src={editIcon} alt="" name='eduCert' onClick={browseBtn} />
                                            </>
                                        }
                                    </InputAdornment>
                                }
                                placeholder={formik.values.eduCertImage ? formik.values.eduCertImage.name ? formik.values.eduCertImage.name : formik.values.eduCertImage.split('/').pop() : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                onClick={formik.values.eduCertImage ? null : browseBtn}
                            />


                            {showErr &&
                                <p className="errorText">
                                    {eduFile || formik.errors.eduCertImage}
                                </p>
                            }

                        </Grid>

                        <Grid item xs={12} md={10} >
                            <Button className='submitBtn' variant="contained"
                                onClick={() => handleNextStep()}
                            //  onClick={()=>console.log(formik.values)}
                            >
                                Save and Continue
                            </Button>
                        </Grid>


                    </Grid>

                    <Grid container item xs={0} md={5} className='rightHero' >
                        <img src={require('../assets/Image/upload-doc.png').default} alt="" />
                    </Grid>

                </Grid>
                <Dialog open={open} onClose={handleClose} className="manager-select-dialog" onBackdropClick={false}>
                    <DialogTitle>Manager’s Employee Code</DialogTitle>
                    <DialogContent>
                        <div className='manager-block'>
                            <img src={managerImage} alt="" />
                            <TextField
                                id="outlined-basic"
                                label="Employee Code"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }}
                                InputProps={{
                                    endAdornment: getResponse && !isError ? <InputAdornment position="end">
                                        <img src={checkImage} alt="" />
                                    </InputAdornment> : '',
                                }}
                                onChange={(e) => {
                                    setGetResponse(false);
                                    setIsError(null);
                                    setManagerId(e.target.value)
                                }}
                                value={managerId}
                            />
                        </div>
                        {getResponse ? <div className='info-manager'>
                            {
                                isError ? <div className='info-error-div'>
                                    Manager code does not exists.
                                    <br />
                                    Please enter the valid code to continue
                                </div> : <div>
                                    <div className='info-div'>
                                        <span style={{ color: '#0966D2' }}>Manager Name:</span> {managerData?.firstName} {managerData?.lastName}
                                    </div>
                                    <div className='info-div'>
                                        <span style={{ color: '#0966D2' }}>Location:</span> {managerData?.city}
                                    </div>
                                </div>
                            }
                        </div> : ''}

                    </DialogContent>
                    <DialogActions>
                        {
                            isError === false ? <Button variant="contained" className='agent-button' onClick={continueManager}>Continue</Button> : <Button variant="contained" className='agent-button' onClick={verifyManager}>Verify Agent</Button>
                        }
                    </DialogActions>
                </Dialog>
            </Wrapper>
        </>
    )
}

export default UploadDoc

const Wrapper = styled.div`

padding:0 8%;
padding-bottom: 2%;
.mainContainer{
    padding-top: 40px;
    .heading{
      .MuiSvgIcon-root {
      color: #2959E5;
      cursor: pointer;
    }
    }
  }
    a{
        text-decoration: none;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        /* margin: 10px 0; */
        border-color: red;
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
        background-color: #F1F1F1;
        
        .Mui-disabled{
            -webkit-text-fill-color:#434552;
        }
    }
    .textInp{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        border: 1px solid #BFC0C5;
        border-radius: 10px;
        padding: 15px 13px;
        /* margin-right: 20px; */
        /* margin-top:20px; */
        outline-color: #ED1C2485;
        
    }
   
    .submitBtn{
        padding: 10px 60px;
        border:none;
        outline:none;
        border-radius: 10px;
        color:white;
        font-size: 20px;
        text-align: center;
        width: 100%;
        text-transform: none;
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        background-color: #2959E5;
        box-shadow: 2px #2959E5;
        :hover{
            background-color: #2959E5;
            box-shadow: 2px #2959E5;
        }
    }

    .errorText{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
       color:#ED1C24;
       font-size:14px;
       margin-left:8px;
       margin-top:3px;
       
   }
    .rightHero{
        justify-content: center;
        margin-top: 80px;
        align-content: baseline;
        width:100%;
        
        img{
            object-fit: contain;
            /* width:50%; */
            max-height: 400px;
        }
    }

    .MuiInputLabel-root{
        color:#333333;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        margin-left:3px;
    }

   
    .hiddenInp{
        display:none;
    }
    

    .MuiOutlinedInput-notchedOutline{
        border:1px solid #ABABAB;
    }

   
    @media only screen and (max-width: 1000px){
        padding:1% 4%;

        .mainContainer{
            .heading{
                display:none;
            }
        }
        .MuiOutlinedInput-root{
            font-size:16px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-size:16px;
        }

        .rightHero{
            display:none;
        }
        .submitBtn{
            margin-top:22px;
        }
        .MuiInputLabel-root{
            margin-top:22px;
            padding-bottom: 3px;
        }
    }
`

const Heading = styled.div`
/* padding-top: 40px; */
    .heading{
        display:flex;
        align-items: center;
            .MuiSvgIcon-root{
                color:#ED1C24;
                /* margin-left: -46px; */
                cursor: pointer;
            }
      
        p{
            font-family: 'Outfit';
            font-size: 20px;
            padding-left:10px;
            color:#C4C4C4;
        }
    }
    .title{
        font-family: 'Sora';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin:10px 0;
        display: flex;
        align-items: center;
        gap:10px;
        color:#434552;
        img{
            height:22px;
            object-fit: contain;
        }
    }
    .titleImageMobile{
        display:none;
    }
    @media only screen and (max-width: 600px){
        .title{
            font-size:18px;
        }
        .titleImageMobile{
            display:inline-block;
         }
        .titleImageDesktop{
            display:none;
        }
        .heading{
            display:none;
        }
    }

    
`

