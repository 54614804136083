import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DateIcon from '../../assets/Image/date.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './newDateRange.css';

const RangeSelector = ({ handleClose, handleSubmit, value, disabled, setSelectDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(()=>{
    if(value.start && value.end){
      setStartDate(value.start)
      setEndDate(value.end)
    }
  },[])
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setSelectDate({
      start: value.start,
      end: value.end,
    });
  };

  const handleSelect = () => {
    handleSubmit({
      start: startDate ?startDate : '',
      end: endDate ? endDate : '',
    });
  };

  return (
    <>
      <DialogContent>
        <Box className="content">
          <Box className="fields">
            <Box className="static-value">
              <Typography component={'div'}></Typography>
              <Typography component={'div'}></Typography>
            </Box>
            <Box className="selector">
              <DatePicker
                disabled={disabled}
                selected={startDate}
                onChange={handleChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                monthsShown={2}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: '#434552' }}>
          Cancel
        </Button>
        <Button onClick={() => handleSelect()}>Submit</Button>
      </DialogActions>
    </>
  );
};

const NewDateRangeComponent = ({ label, value, name, onChange, disabled }) => {
  const [open, setOpen] = useState(false);
  const {start, end} = value
  const [selectDate, setSelectDate] = useState({ start,end });
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (v) => {
    onChange(name, v);
    handleClose();
  };

  return (
    <Box className="new-date-range-component">
      <Box className="label">
        <Typography
          variant="text5_2"
          color={'text2'}
          onClick={() => handleClickOpen()}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#434552',
            fontFamily: 'Outfit',
            fontWeight: '600',
            fontSize: '14px',
            cursor: 'pointer',
          }}
        >
          <img src={DateIcon} alt="" style={{ marginRight: '5px' }} />
          {label} - {start ? moment(start).format('DD MMM YYYY') : 'DD MMM YYYY'} to {end ? moment(end).format('DD MMM YYYY') : 'DD MMM YYYY'}
        </Typography>
      </Box>

      <Dialog open={open} onClose={handleClose} className="date-range-dialog">
        <RangeSelector
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          value={{ end, start }}
          disabled={disabled}
          setSelectDate={setSelectDate}
        />
      </Dialog>
    </Box>
  );
};

export { NewDateRangeComponent };
