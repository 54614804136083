import React,{useState} from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


const Faq = () => {

    const [expanded, setExpanded] = useState({1:false,2:false,3:false,4:false});

  return (
    <Wrapper>

        <Heading>
            <p>
                <img className="faqEmoji" src={require('../assets/Image/faq-emoji.svg').default} alt="" />
                 Frequently Asked Questions
            </p>
        </Heading>

        <Content>
            <Accordion disableGutters elevation={0} >
                <AccordionSummary expandIcon={expanded[1]?<RemoveCircleOutlinedIcon color="disabled" />:<AddCircleOutlineIcon color="disabled" />} 
                onClick={()=>setExpanded({...expanded,1:!expanded[1]})} 
                >
                <p className="title">Who can become a POSP agent?</p>
                </AccordionSummary>
                <AccordionDetails>
                <p className="content">
                    Any individual who is 18 years old, has passed Std X and
                    possess a PAN can become a POSP.
                </p>
                </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0}>
                <AccordionSummary expandIcon={expanded[2]?<RemoveCircleOutlinedIcon color="disabled" />:<AddCircleOutlineIcon color="disabled" />} 
                onClick={()=>setExpanded({...expanded,2:!expanded[2]})} 
                >
                <p className="title">What documents are required for registration?</p>
                </AccordionSummary>
                <AccordionDetails>
                <p className="content">
                    At the time of registration, all details as required in the application 
                    form along with copy of PAN and educational qualification would be required
                </p>
                </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0}>
                <AccordionSummary expandIcon={expanded[3]?<RemoveCircleOutlinedIcon color="disabled" />:<AddCircleOutlineIcon color="disabled" />}
                onClick={()=>setExpanded({...expanded,3:!expanded[3]})} 
                >
                    <p className="title">
                    Is training compulsory or can I get POSP certification without training ?
                    </p>
                </AccordionSummary>
                <AccordionDetails>
                <p className="content">
                 Training and examination is mandatory as prescribed by IRDAI.<br/>
                 POSP certification cannot be done without training and examination
                </p>
                </AccordionDetails>
                </Accordion>

                <Accordion disableGutters elevation={0}>
                <AccordionSummary expandIcon={expanded[4]?<RemoveCircleOutlinedIcon color="disabled" />:<AddCircleOutlineIcon color="disabled" />} 
                 onClick={()=>setExpanded({...expanded,4:!expanded[4]})}
                >
                    <p className="title">
                        Will I get product training ?
                    </p>
                </AccordionSummary>
                <AccordionDetails>
                <p className="content">
                    Yes, POSP product training shall be provided separately to enable you to perform optimally. You will also get technology support in the entire insurance buying
                    journey where the policies are issued instantaneously to the customers
                </p>
                </AccordionDetails>
                </Accordion>
        </Content>
      
    </Wrapper>
  );
};

export default Faq;

const Wrapper = styled.div`
    /* width: 84%; */
    margin: auto;
    padding: 70px 10%;

    @media only screen and (max-width: 600px){
        /* width: 90%; */
    }

`

const Heading = styled.div`
    p{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color:#434552;
        text-align:center;
        padding-bottom: 42px;
        .faqEmoji{
            display:none;
        }
    }
    @media only screen and (max-width: 600px){
        p{
            font-size:18px;
            padding-bottom: 18px;
            display: flex;
             align-items: center;
             /* white-space: nowrap; */
            .faqEmoji{
                display:inline-block;
                padding-right:5px;
            }
        }
    }
`
const Content = styled.div`
    .title{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 126.3%;
        color: #434552;
        margin:10px 0;
    }
    .content{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: #516370;
        margin-top:0px;
        margin-bottom:20px;
    }
    .MuiAccordionSummary-root{
        padding:0;
        
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: rotate(0deg);
    }

    .MuiAccordionDetails-root{
        padding:1px 0px 2px;
    }

    .MuiAccordion-root{
        .Mui-expanded{
            
        }
        :before{
            background-color:#EAEEF3;
            height:1.5px;
        }
    }
    .MuiPaper-root{
        .MuiAccordion-root.Mui-expanded{
            /* margin:0; */
        }
        
    }

    @media only screen and (max-width: 600px){
        .title{
            /* margin: 8px 0; */
            font-size: 16px;
        }
        .content{
            font-size: 14px;
        }
    }

`