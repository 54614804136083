import React,{useState,useMemo, useEffect,useRef} from 'react';
import styled from 'styled-components'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper} from '@mui/material';
import {OutlinedInput,InputAdornment,Grid} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton  } from '@mui/material';
import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";
import { fetchFileUploadDetails,fetchDetailByUploadId } from '../../../api';
import moment from 'moment';
import Pagination from '../Pagination';


const BulkTable = ({fetchFileDetail,data,setData}) => {



    const [showSearch, setShowSearch] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchinp, setSearchinp] = useState('');
   


    const handleClick = () => {
        setOpen(!open);
      };
      const handleClose = () => {
        setOpen(false);
      };

      
        
      const handleDownloadFile =async(id,filepath)=>{

        // console.log(filepath);
        // window.open('http://google.com')
          try {
            
            const {data} = await fetchDetailByUploadId(id);
            console.log(data);

            if(data.status!='FAIL'){

                const splitFile = data.fileDownloadPath.split('.com/')[1];
                console.log(data.fileDownloadPath)
                window.open( `https://d2qt3b3csf1vza.cloudfront.net/${data.fileDownloadPath}`);
                return   
            }
            alert(data.responseMessage);

          } catch (error) {
              console.log('somethig went wrong in file download');
              alert('somethig went wrong in file download');
          }
      }


  const COLUMNS=[
    {
        Header:'Sr No',
        accessor:'srNo',
        disableSortBy:true,
        Cell:(props)=>(
            <>
            
            {
               (Number(props.row.id)+1)}
            </>
        )
    },
    {
        Header:'FILE NO',
        accessor:'fileuploadReqid',
        disableSortBy:true
    },
    
    {
        Header:()=>(
            <>
            UPLOAD DATE
            <img 
            style={{paddingLeft:'3px'}}
            src={require('../../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('uploadReqOn')} />
            </>
        ),
        accessor:'uploadReqOn',
        disableSortBy:true,
        Cell:(props)=>(
            <>
            {
                moment(props.row.original.uploadReqOn).format("LL")
            }
            </>
    )
    },
    {
        Header:()=>(
            <>
            CURRENT STATUS
            <img 
            style={{paddingLeft:'3px'}}
            src={require('../../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('uploadstatus')} />
            </>
        ),
        accessor:'uploadstatus',
        disableSortBy:true,
        Cell:(props)=>(
            <div className="completedCont"   >
                    {props.value===1&&
                    <>
                    <p>
                    SUBMITTED
                   </p>
                    <img src={require('../../../assets/Image/in-progress.svg').default} alt="" />
                    </>
                    }
                    {props.value===2&&
                    <>
                    <p>
                    IN PROGRESS
                   </p>
                    {/* <img src={require('../../../assets/Image/in-progress.svg').default} alt="" /> */}
                    <img src={require('../../../assets/Image/bulk-loading.svg').default} alt="" />
                    </>
                   }
                    {props.value===3&&
                    <>
                    COMPLETED
                    <CheckCircleIcon style={{color:'#727B854D',fontSize:'22px',paddingLeft:'5px'}} />
                    </>
                    }
            </div>
        )
    },
    {
        Header:()=>(
            <>
            TOTAL <br/> COUNT
            </>
        ),
        accessor:'totalcount',
        disableSortBy:true
    },
    {
        Header:()=>(
            <>
            SUCCESS <br/> COUNT
            </>
        ),
        accessor:'successcount',
        disableSortBy:true
    },
    {
        Header:()=>(
            <>
            FAILED <br/> COUNT
            </>
        ),
        accessor:'failedcount',
        disableSortBy:true
    },
    {
        Header:"PARTNER NAME",
        accessor:'partnername',
        disableSortBy:true,
        Cell:(props)=>(
            <div>
                {props.value?props.value:"No Data"}
            </div> 
        
    ),
    },
    {
        Header:"DOWNLOAD",
        accessor:'filename',
        disableSortBy:true,
        Cell:(props)=>
        <div onClick={()=>console.log(props)} >
            {
                props.row.original.uploadstatus===3&&
            <IconButton size='small' >
            {/* <a href={`https://d2qt3b3csf1vza.cloudfront.net/${props.row.original.filepath}`}> */}
            <DownloadForOfflineIcon style={{color:'#6CCAFF',fontSize:'29px'}}
            onClick={()=>handleDownloadFile(props.row.original.fileuploadReqid,props.row.original.filepath)}
              />

            {/* </a> */}
            </IconButton>

            }
            </div>
    },
    {
        accessor:'email',
        disableSortBy:true,
        Cell:()=>(
                <div>
                    
                </div> 
            
        ),
        Header:()=>(
            
                <Grid container
                style={{
                    width:'120px'
                }}
                 >
                    <Grid item xs={6} className={showSearch?'searchicon':''} 
                    onClick={()=>setShowSearch(!showSearch)}
                     >
                        
                        {
                            showSearch?<img src={require('../../../assets/Image/search-icon-red.svg').default}  alt="" />:
                                        <img src={require('../../../assets/Image/search-icon.svg').default}  alt="" />
                        }
                        
                    </Grid>
                    <Grid item xs={6}
                       
                     >
                        
                            <div className='filterContainer' >
                            
                            <img src={require('../../../assets/Image/reload-icon.svg').default} alt="" onClick={fetchFileDetail} />
                           
                            </div>

                    </Grid>
                </Grid>
            
        )
    }
]


    const columns = useMemo(()=> COLUMNS,[showSearch]);
    const tableData = useMemo(()=>data,[data]);

    const tableInstance = useTable({
        columns: columns,
        data: tableData,
      },useFilters,useGlobalFilter,useSortBy,usePagination);

      const{getTableProps,getTableBodyProps,
        headerGroups,
        rows,
        page,
        pageCount,
        pageOptions,
        setPageSize,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,setGlobalFilter,setFilter,setAllFilters,
        preFilteredRows,toggleSortBy
      } = tableInstance;

      const {globalFilter,filters,pageIndex,pageSize} = state;

      const handlesearchResult=(e)=>{
        if(e.code==="Enter"&&!searchinp){
          setGlobalFilter('');
        }
        if(searchinp&&e.code==="Enter"){
          setGlobalFilter(searchinp);
        }
    }

  useEffect(()=>{
      const autoFetchInternal = setInterval(()=>{
        fetchFileDetail();
      },30*1000)
      return()=>{
          clearInterval(autoFetchInternal)
      }
  },[])


    return (
        <Wrapper enter={require('../../../assets/Image/enter.svg').default} >



<TableContainer component={Paper} style={{backgroundColor:'#F7F8FB'}} >
      <Table  aria-label="bulk upload file table">
        <TableHead style={{backgroundColor:'#EBEEF2',fontWeight:'600'}} >
            {headerGroups.map((headerGroup)=> (
                    <TableRow {...headerGroup.getHeaderGroupProps()} style={{height:'63px'}}  >
                    {
                        headerGroup.headers.map(column=> (
                        <TableCell align="left" {...column.getHeaderProps(column.getSortByToggleProps)} 
                        style={{
                            padding:'0px 0px',
                            paddingLeft:'16px'
                        }}
                        >
                            {column.render('Header')}                                                   
                                
                        </TableCell>
                        ))
                    }
                    </TableRow>
                ) )}
          
        </TableHead>


{
    showSearch&&
        <TableRow>
                <TableCell colSpan={10} className="searchInput" >
                    <OutlinedInput 
                    value={searchinp}
                    onKeyPress={handlesearchResult}
                    onChange={e=>setSearchinp(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            {/* <SearchIcon fontSize='large' />  */}
                            <img src={require('../../../assets/Image/search-icon.svg').default}  alt="" />
                        </InputAdornment>
                        }
                    endAdornment={
                        <InputAdornment position="end">
                            <ClearIcon fontSize='large'
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={()=>setShowSearch(!showSearch)}
                             /> 
                        </InputAdornment>
                        }
                    fullWidth />
                </TableCell>

        </TableRow>
}


                            



    
{rows.length!==0?
                  <TableBody {...getTableBodyProps()} >
                    {
                      rows.map(row=>{
                        prepareRow(row)
                        return (
                            <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <TableCell align="left" {...cell.getCellProps()}
                                  style={{
                                    padding:'12px 5px 12px 16px',
                                      fontWeight: '500',
                                      color: '#333'
                                  }}
                                   >
                                   {cell.render('Cell')}
                                                                                  
                                  </TableCell>
                                )
                              } )}                     
                            </TableRow>
                        )
                      })
                    }
                  </TableBody>:
                  <TableRow >
                  <TableCell colSpan={10} style={{textAlign:'center'}} >
                  No Data Availabe
                  </TableCell>
                  
                </TableRow>
            }   
           



        
      </Table>
    </TableContainer>

    {/* {
        rows.length>0&&
        <Pagination 
            page={page}
            nextPage={nextPage} 
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
           />
    } */}


            
        </Wrapper>
    )
}

export default BulkTable


const Wrapper = styled.div`
    margin-top:80px;
    
    .completedCont{
        display:flex;
        align-items: center;
        /* justify-content: center; */
        gap:5px;
        min-height:35px;
        img{
            padding-left: 8px;
            width:21px;
            object-fit: contain;
        }
    }
    .MuiTableCell-head{
        font-weight: 600;
font-size: 16px;
line-height: 20px;

color: #333333;
    }

    .searchInput{
        
        padding:10px 10%;
        background-color: #D8DDE3;
        .MuiTableRow-root{
            background-color: #D8DDE3;
        }
        .MuiOutlinedInput-root{
            background-color: white;
            border-radius: 32px;
            outline:none;
            border:none;
            padding:0 30px;
            .MuiOutlinedInput-notchedOutline{
                border:1px solid #d8dde3;
            }

            .MuiOutlinedInput-input{
                padding-right:290px;
            }
            :after{
                /* content:'press Enter to Search  ↵ '; */
                content: ${props=> `url(${props.enter})`};
                position:absolute;
                display:block;
                color:red;
                right:170px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #727B85;
            }

        }
    }

    .filterContainer{
        img{
            cursor: pointer;
        }
    }

`



