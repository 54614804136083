import React from 'react';
import styled from 'styled-components';
import { Grid,InputLabel,OutlinedInput,TextField,Select,MenuItem } from '@mui/material';
import { createTheme,ThemeProvider } from '@mui/material/styles';
import {useMatch,useParams} from 'react-router-dom'

const ViewBasicInfo = ({formik}) => {

    const match = useMatch('/admin/approved/:id');
    const params = useParams();

    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        components:{
            MuiMenu:{
                styleOverrides:{
                    list:{
                        padding:'0px'
                    }
                }
            }
        }
        
      })


    return (
        <Wrapper>
            <ThemeProvider theme={theme}>
            <div className="heading">
                <img src={require('../../../assets/Image/basic-info-emoji.png').default} alt="" className="titleIcon" />
                <p>
                    Basic Information:
                </p>
            </div>
           {
               match&&
                <div className="basicHeading">
                    <Grid container>
                        <Grid item md={4} >
                            <span>POSP Number: </span> {formik.values.pospNumber}
                        </Grid>
                        <Grid item md={4} >
                            <span>Application Number: </span> {params.appId}
                        </Grid>
                        <Grid item md={4} >
                            <span  >Partner Name: </span> {formik.values.partnername?.toUpperCase()}
                        </Grid>

                    </Grid>
                </div>

           }
            <MainContent>
                <Grid container direction="column" spacing={3} >

                        {/* GRID CONTAINER 1 START */}
                        <Grid container item spacing={2} >
                            {/* <Grid item md={1.5} >
                                <InputLabel>
                                    Salutation:
                                </InputLabel>
                                <Select
                                    defaultValue={1}
                                    fullWidth
                                    disabled
                                    size='small'
                                    MenuProps={{
                                        style:{
                                            paddingTop:'0px',
                                            paddingBottom:'0px'
                                        }
                                    }}
                                    placeholder='-Select salutation-'
                                    {...formik.getFieldProps('salutation')}
                                >
                                    <MenuItem value={0} disabled >-salutation</MenuItem>
                                    <MenuItem value={1} divider={true} >Mr. </MenuItem>
                                    <MenuItem value={2} divider={true} >Ms.</MenuItem>
                                    <MenuItem value={3} >Mrs.</MenuItem>
                                  
                                </Select>
                            </Grid> */}
                            <Grid item md={3.5} >
                                <InputLabel>
                                    First Name:
                                </InputLabel>
                                <OutlinedInput
                                 fullWidth
                                 disabled
                                 size='small'
                                 {...formik.getFieldProps('firstName')}
                                 />
                            </Grid>
                            <Grid item md={3.4} >
                                <InputLabel>
                                    Last Name:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('lastName')}
                                 />
                            </Grid>
                            <Grid item md={3.5} >
                                <InputLabel>
                                    Email:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('email')}
                                 />
                            </Grid>
                            <Grid item md={1.5} >
                                <InputLabel>
                                    Gender:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('gender')}
                                 />
                            </Grid>
                        </Grid>
                        
                        {/* GRID CONTAINER 1 END */}

                        {/* GRID CONTAINER 2 START */}
                        <Grid item container spacing={2} >
                        <Grid item md={3.4} >
                                <InputLabel>
                                    DOB:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('dob')}
                                 />
                            </Grid>
                        <Grid item md={2.5} >
                                <InputLabel>
                                    Mobile Number:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('mobNumber')}
                                 />
                            </Grid>
                            <Grid item md={2.5} >
                                <InputLabel>
                                    Pincode:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('pincode')}
                                 />
                            </Grid>
                            <Grid item md={3.5} >
                                <InputLabel>
                                    City:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('cityName')}
                                 />
                            </Grid>
                            {/* <Grid item md={3.5} >
                                <InputLabel>
                                    State:
                                </InputLabel>
                                <OutlinedInput 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('state')}
                                 />
                            </Grid> */}
                        </Grid>
                        {/* GRID CONTAINER 2 END */}

                        {/* GRID CONTAINER 3 START */}

                        <Grid container item>
                            <Grid item md={3.4} >
                                    <InputLabel>
                                        State:
                                    </InputLabel>
                                    <OutlinedInput 
                                    fullWidth 
                                    disabled
                                    size='small'
                                    {...formik.getFieldProps('state')}
                                    />
                                </Grid>

                        </Grid>
                        {/* GRID CONTAINER 3 END HERE */}

                        {/* GRID CONTAINER 4 START */}
                        <Grid container item >
                            <Grid item xs={11.8} >
                                <InputLabel>
                                    Address (House No, Building, Street, Area):
                                </InputLabel>
                                <TextField 
                                variant="outlined" 
                                multiline 
                                disabled
                                fullWidth 
                                className="addInp" 
                                {...formik.getFieldProps('address')}
                                 />
                            </Grid>
                        </Grid>
                        <Grid container item >
                            <Grid item xs={11.8} >
                                <InputLabel>
                                Nominee Full Name:
                                </InputLabel>
                                <TextField 
                                fullWidth 
                                disabled
                                size='small'
                                {...formik.getFieldProps('nomineeName')}
                                 />
                            </Grid>
                        </Grid>
                        {/* GRID CONTAINER 4 END HERE */}
                </Grid>
            </MainContent>
            </ThemeProvider>
        </Wrapper>
    )
}

export default ViewBasicInfo

const Wrapper = styled.div`
    /* height:200px; */
    /* width:80%; */
    background-color:white;
    margin:20px auto;
    padding:25px;
    border-radius:12px;
    box-shadow: 0px 0px 3px 0px #7e7b7b8a;
    .heading{
        display:flex;
        align-items: center;
        gap:0 5px;
        margin-bottom:30px;
        margin-top:20px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color:#333333;
        }
    }
    .titleIcon{
        height:24px;
        object-fit: contain;
    }
    .basicHeading{
        background-color: #C4C4C44F;
        border-radius: 8px;
        font-family: Montserrat;
        font-style: normal;
        font-size: 16px;
        padding: 13px 20px;
        margin-bottom: 13px;
        color:#333333c8;
        span{
            font-weight: 600;
            color: #333333;

        }
    }
    
`
const MainContent = styled.div`
    padding:10px;
    .MuiOutlinedInput-root{
        font-size:16px;
        color:#333333;
    .MuiInputLabel-root{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #7C7C7C;
        padding-bottom:7px;
    }
    .MuiOutlinedInput-input.Mui-disabled{
        -webkit-text-fill-color:#333333;
        color:#333333;
    }
}
   

    .MuiInputBase-inputMultiline{
        min-height:70px;
    }
    
    .MuiMenu-list{
            padding-top:0;
            padding-bottom:0;
        }
    
`