import React, { createRef } from "react";
import moment from "moment";

import styled from "styled-components";
import headerLogo from "../assets/Image/coverSureLogo.svg";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";

const AppointmentTnC = ({ step, setStep, user }) => {
  return (
    <>
      <Wrapper>
        <Document>
          <Page>
            <div>
              <img src={headerLogo} alt="" className="headerLogo" />
              <h3 className="docHeading">APPOINTMENT LETTER</h3>
              <div className="salutation">
                <div className="leftSalu">
                  <p className="mr"> {user.name ? user.name : "John Doe"} </p>
                  <p className="mr">
                    {" "}
                    Address: {user.address
                      ? user.address
                      : "John Doe Address"}{" "}
                  </p>
                </div>
                <div className="rightSalu">
                  <p className="date">
                    {" "}
                    {user.applicationDate ? user.applicationDate : ""}{" "}
                  </p>
                </div>
              </div>
              <h3 className="subject">
                Subject: Your Appointment as a Point of Sales Person for the Company.
              </h3>
              <p>
                This is with reference to the application made by you for enrolling yourself to act as a Point of Sales Person (POSP). This is to confirm that you have successfully completed the prescribed training and have also passed the examination specified for Point of Sales examination conducted by Claycove23 Insurance Tech Pvt. Ltd. (herein referred to as “Company”) under the Guidelines on Point of Sales Persons. Your personal details are as under:
              </p>
              <h4 style={{ textTransform: "uppercase" }}> PAN: {user.pan}</h4>
              <p>
                We are pleased to appoint you as a Point of Sales Person for Claycove23 Insurance Tech Pvt. Ltd., to market products categorized and identified under the POS Guidelines.
              </p>
              <p>
                The terms and conditions mentioned below shall apply to all transaction(s) between us as may be specified from time to time by the Company in addition to the relevant laws of India, in general, and in particular to the provisions of the Insurance Act, 1938, and the Insurance Regulatory and Development Authority Act, 1999 and the Rules and Regulations specified there under as amended from time to time.
              </p>
              <h4>Date of appointment: {user.doa} </h4>
              <h4>POSP Code: : {user.pospCode ? user.pospCode : "NA"}</h4>
              <ol type="i">
                <h4>Duties</h4>
                <li>
                  i. As a POSP, you shall adhere to the code of conduct
                  prescribed by IRDAI.{" "}
                </li>
                <li>
                  ii. Disseminate the correct and requisite information in respect of insurance products offered for sale by the Company and take into account the needs of the Prospect while recommending the products.
                </li>
                <li>
                  iii. Explain to the prospective customer the nature of information required in the proposal form by the
                  insurance company, and also the importance of disclosure of material information in the purchase of an
                  insurance product and shall discourage suppression of any information which is relevant to the
                  insurance company for the purpose of underwriting.
                </li>
                <li>
                  iv. Obtain the requisite documents at the time of filing the proposal form with the insurance company; and
                  other documents subsequently asked for by the insurance company for completion of the proposal.
                </li>
                <li>
                  v. Inform promptly to the client about the acceptance or rejection of the proposal by the insurance
                  company.
                </li>
                <li>
                  vi. If collecting a premium on a policy of insurance on behalf of the Company, you shall deposit with, or
                  dispatch by post to, or through digital payments medium to the Company / Insurer, the premium so
                  collected in full without deduction of your commission within twenty-four hours of the collections
                  excluding bank and postal holidays. Further you shall issue an acknowledgement (as per format
                  provided by the Company) on collection of premiums.
                </li>
                <li>
                  vii. Observe and adhere and also bring the same to the notice of the Prospect/Policy holder, the provisions
                  of Section 41 of the Insurance Act, 1938.
                </li>
                <li>
                  viii. Provide all collected customer information to the Company and extend cooperation for any
                  investigation or information of your sourced customers.
                </li>
                <li>
                  ix. Promptly notify the Company of receipt by you or service of any complaint, notice or claim concerning
                  or in relation to any matter governed by this appointment and shall promptly deliver a copy to the
                  Company.
                </li>
                <li>
                  x. You shall act solely as an independent contractor, subject to the control and guidance of the Company,
                  and as such, shall have control on: all matters, its time and effort in the placement of the Policies
                  offered hereunder. Nothing herein contained shall be construed to create the relationship of employer
                  and employee between you and the Company.
                </li>
                <li>
                  xi. You shall indemnify and hold the Company and its officers, employees harmless from all expenses, costs,
                  causes of action, claims, demands, liabilities and damages, including reasonable attorney&#39;s fees,
                  resulting from or growing out of any unauthorized act or transaction or any negligent act, omission or
                  transaction by you.
                </li>
                <li>
                  xii. Change of Address. you shall notify Company in writing of any change of address and/or communication
                  at least thirty (30) days prior to the effective date of such change.
                </li>
                <li>
                  xiii. You shall not engage or employ anyone as canvassers or agents for soliciting the insurance business.
                </li>
                <li>
                  xiv. You will ensure the compliance of KYC/AML guidelines issued from time to time and obtain the
                  necessary documents in this regard.
                </li>
                <li>
                  xv. Any financial penalty levied by IRDAI based on the violations and non-compliance by you of the
                  applicable laws and regulations shall be borne by you and not the Company. Similarly, in case of any
                  suspension, cancellation or withdrawal of license of the Company because of any breaches/non-
                  compliance on your account, you shall indemnify the Company for consequential losses specifically
                  arising from violation of IRDAI Guidelines, IRDAI Registration of Corporate Agents Regulations, 2015 and
                  guidelines related to POSP’s for Insurers (Life, Non-Life & Health) as updated from time to time.
                </li>
                <li>
                  xvi. In case you wish to work for another company, you shall obtain a no objection certificate from the
                  Company and post discontinuance of your service uploaded on IIB you are required to obtain a fresh
                  letter from the new insurer/ insurance intermediary in order to act as Point of Sales Person for that
                  entity.
                </li>
              </ol>
              <ol>
                <h4>Commission:</h4>
                <li>
                  i) The Company shall pay commission to you through neft in your bank account. Commissions shall be paid
                  for policies sourced by you and not for any other policies.
                </li>
                <li>
                  ii) The Company shall communicate the commission rates for each product from time to time.
                </li>
                <li>
                  iii) The commission shall be payable on the issued premium net of taxes i.e. on premium exclusive of GST.
                </li>
                <li>
                  iv) The commission shall be processed on a fortnightly basis i.e. cycle 1: 1 st to 15 th of every month and cycle
                  2: 16 th to 30 th / 31 st of every month.
                </li>
                <li>
                  v) The commission shall be paid post completion of freelook period.
                </li>
                <li>
                  vi) Incase of any withdrawal / freelook / cancellation of the policy the paid commission shall be recovered
                  either through subsequent payable commission or through refund from your side.
                </li>
                <li>
                  vii) Commission may be put on hold in case of investigation by the Company for fraud, quality of business,
                  etc. and would be duly informed to you.
                </li>
                <li>
                  viii) Commission shall be subject to deductions of tax under the provision of Income-tax Act, 1961 and any
                  other applicable law, as amended from time to time.
                </li>
              </ol>

              <ol>
                <h4>Code of Conduct: </h4>
                <p>
                  As a POSP appointed by the Company, you shall adhere to the following code of conduct prescribed by IRDAI to
                  the Company being a corporate agent:
                </p>
                <li>
                  <h4>I. General Code of Conduct:</h4>
                  <ol>
                    <li>
                      <h5>
                        1. A Every corporate agent shall follow recognised standards of professional conduct and discharge their
                        duties in the interest of the policyholders. While doing so –
                      </h5>
                      <ol>
                        <li>
                          a) conduct its dealings with clients with utmost good faith and integrity at all times;
                        </li>
                        <li>b) act with care and diligence;</li>
                        <li>
                          c) ensure that the client understands his relationship with the corporate agent and on whose
                          behalf the corporate agent is acting;
                        </li>
                        <li>
                          d) treat all information supplied by the prospective clients as completely confidential to
                          themselves and to the insurer(s) to which the business is being offered;
                        </li>
                        <li>
                          e) take appropriate steps to maintain the security of confidential documents in their possession;
                        </li>
                        <li>
                          f) No director of a company or a partner of a firm or the chief executive or a principal officer or a
                          specified person shall hold similar position with another corporate agent;
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h5>2. Every Corporate Agent shall</h5>
                      <ol>
                        <li>
                          a) be responsible for all acts of omission and commission of its principal officer and every specified
                          person;
                        </li>
                        <li>
                          b) ensure that the principal officer and all specified persons are properly trained, skilled and
                          knowledgeable in the insurance products they market;
                        </li>
                        <li>
                          c) ensure that the principal officer and the specified person do not make to the prospect any
                          misrepresentation on policy benefits and returns available under the policy;
                        </li>
                        <li>
                          d) ensure that no prospect is forced to buy an insurance product;
                        </li>
                        <li>
                          e) give adequate pre-sales and post-sales advice to the insured in respect of the insurance product;
                        </li>
                        <li>
                          f) extend all possible help and cooperation to an insured in completion of all formalities and
                          documentation in the event of a claim;
                        </li>
                        <li>
                          g) give due publicity to the fact that the corporate agent does not underwrite the risk or act as an
                          insurer;
                        </li>
                        <li>
                          h) enter into agreements with the insurers in which the duties and responsibilities of both are
                          defined.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>II. Pre-sale Code of Conduct:</h4>
                  <ol>
                    <h5>
                      3. Every corporate agent or principal officer or a specified person shall also follow the code of conduct
                      specified below:
                    </h5>
                    <li>
                      i. Every corporate agent/ principal officer/specified person shall:
                      <ol>
                        <li>
                          a) identify himself and disclose his registration/certificate to the prospect on demand;
                        </li>
                        <li>
                          b) disseminate the requisite information in respect of insurance products offered for sale
                          by the insurers with whom they have arrangement and take into account the needs of
                          the prospect while recommending a specific insurance plan;
                        </li>
                        <li>
                          c) disclose the scales of commission in respect of the insurance product offered for sale, if
                          asked by the prospect;
                        </li>
                        <li>
                          d) indicate the premium to be charged by the insurer for the insurance product offered for
                          sale;
                        </li>
                        <li>
                          e) explain to the prospect the nature of information required in the proposal form by the
                          insurer, and also the importance of disclosure of material information in the purchase of
                          an insurance contract;
                        </li>
                        <li>
                          f) bring to the notice of the insurer any adverse habits or income inconsistency of the
                          prospect, in the form of a Confidential Report along with every proposal submitted to
                          the insurer, and any material fact that may adversely affect the underwriting decision of
                          the insurer as regards acceptance of the proposal, by making all reasonable enquiries
                          about the prospect;
                        </li>
                        <li>
                          g) inform promptly the prospect about the acceptance or rejection of the proposal by the
                          insurer;
                        </li>
                        <li>
                          h) obtain the requisite documents at the time of filing the proposal form with the insurer;
                          and other documents subsequently asked for by the insurer for completion of the
                          proposal;
                        </li>
                      </ol>
                    </li>
                    <li>
                      ii. No corporate agent/ principal officer / specified person shall:
                      <ol>
                        <li>
                          a) solicit or procure insurance business without holding a valid registration/ certificate;
                        </li>
                        <li>
                          b) induce the prospect to omit any material information in the proposal form;
                        </li>
                        <li>
                          c) induce the prospect to submit wrong information in the proposal form or documents
                          submitted to the insurer for acceptance of the proposal;
                        </li>
                        <li>
                          d) behave in a discourteous manner with the prospect;
                        </li>
                        <li>
                          e) interfere with any proposal introduced by any other specified person or any insurance
                          intermediary;
                        </li>
                        <li>
                          f) offer different rates, advantages, terms and conditions other than those offered by the
                          insurer;
                        </li>
                        <li>
                          g) force a policyholder to terminate the existing policy and to effect a new proposal from
                          him within three years from the date of such termination;
                        </li>
                        <li>
                          h) No corporate agent shall have a portfolio of insurance business from one person or one
                          organization or one group of organizations under which the premium is in excess of fifty
                          percent of total premium procured in any year;
                        </li>
                        <li>
                          i) become or remain a director of any insurance company, except with the prior approval
                          of the Authority;
                        </li>
                        <li>
                          j) indulge in any sort of money laundering activities;
                        </li>
                        <li>
                          k) indulge in sourcing of business by themselves or through call centers by way of
                          misleading calls or spurious calls;
                        </li>
                        <li>
                          l) undertake multi-level marketing for soliciting and procuring of insurance products;
                        </li>
                        <li>
                          m) engage untrained and unauthorised persons to bring in business;
                        </li>
                        <li>
                          n) provide insurance consultancy or claims consultancy or any other insurance related
                          services except soliciting and servicing of insurance products as per the certificate of
                          registration.
                        </li>
                        <li>
                          o) Engage, encourage, enter into a contract with or have any sort of arrangement with any
                          person other than a specified person, to refer, solicit, generate lead, advise, introduce,
                          find or provide contact details of prospective policyholders in furtherance of the
                          distribution of the insurance product;
                        </li>
                        <li>
                          p) Pay or allow the payment of any fee, commission, incentive by any other name
                          whatsoever for the purpose of sale, introduction, lead generation, referring or finding to
                          any person or entity
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>III. Post-Sale Code of Conduct:</h4>
                  <ol>
                    <li>
                      <h5>4. Every Corporate Agent shall:</h5>
                      <ol>
                        <li>
                          a) advise every individual policyholder to effect nomination or assignment or change of address or
                          exercise of options, as the case may be, and offer necessary assistance in this behalf, wherever
                          necessary;
                        </li>

                        <li>
                          b) with a view to conserve the insurance business already procured through him, make every
                          attempt to ensure remittance of the premiums by the policyholders within the stipulated time,
                          by giving notice to the policyholder orally and in writing.
                        </li>
                        <li>
                          c) ensure that its client is aware of the expiry date of the insurance even if it chooses not to offer
                          further cover to the client;
                        </li>
                        <li>
                          d) ensure that renewal notices contain a warning about the duty of disclosure including the
                          necessity to advise changes affecting the policy, which have occurred since the policy inception
                          or the last renewal date;
                        </li>
                        <li>
                          e) ensure that renewal notices contain a requirement for keeping a record (including copies of
                          letters) of all information supplied to the insurer for the purpose of renewal of the contract;
                        </li>
                        <li>
                          f) ensure that the client receives the insurer's renewal invitation well in time before the expiry
                          date.
                        </li>
                        <li>
                          g) render necessary assistance to the policyholders or claimants or beneficiaries in complying with
                          the requirements for settlement of claims by the insurer;
                        </li>
                        <li>
                          h) explain to its clients their obligation to notify claims promptly and to disclose all material facts
                          and advise subsequent developments as soon as possible;
                        </li>
                        <li>
                          i) advise the client to make true, fair and complete disclosure where it believes that the client has
                          not done so. If further disclosure is not forthcoming it shall consider declining to act further for
                          the client;
                        </li>
                        <li>
                          j) give prompt advice to the client of any requirements concerning the claim;
                        </li>
                        <li>
                          k) forward any information received from the client regarding a claim or an incident that may give
                          rise to a claim without delay, and in any event within three working days;
                        </li>
                        <li>
                          l) advise the client without delay of the insurer's decision or otherwise of a claim; and give all
                          reasonable assistance to the client in pursuing his claim.
                        </li>
                        <li>
                          m) shall not demand or receive a share of proceeds from the beneficiary under an insurance
                          contract;
                        </li>
                        <li>
                          n) ensure that letters of instruction, policies and renewal documents contain details of complaints
                          handling procedures;
                        </li>
                        <li>
                          o) accept complaints either by phone or in writing;
                        </li>
                        <li>
                          p) acknowledge a complaint within fourteen days from the receipt of correspondence, advise the
                          member of staff who will be dealing with the complaint and the timetable for dealing with it;
                        </li>
                        <li>
                          q) ensure that response letters are sent and inform the complainant of what he may do if he is
                          unhappy with the response;
                        </li>
                        <li>
                          r) ensure that complaints are dealt with at a suitably senior level;
                        </li>
                        <li>
                          s) have in place a system for recording and monitoring complaints.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
              <h4> Confidentiality:</h4>
              <p>
                You shall maintain strict confidentiality of the information received from any Prospect/Policy holder and/or the
                Company in the course of business (hereinafter referred to as “Confidential Information”) and ensure the
                following:
              </p>
              <p>
                a. You agree that the Confidential Information is to be considered confidential and proprietary to the Company
                and you shall hold the same in confidence, and shall not use the Confidential Information other than for the
                purposes of business with the Company, and shall disclose it only to its officers, directors, or employees of the
                Company with a specific need to know. You shall not disclose, publish or otherwise reveal any of the
                Confidential Information received from the Company and/or the Prospect to any other third party whatsoever
                except with the specific prior written authorisation of the Company.
              </p>
              <p>
                b. The Confidential Information furnished in tangible form shall not be duplicated by you except in the course of
                business. Upon the request of the Company, you shall return all the Confidential Information received in written
                or tangible form, including copies, or reproductions or other media containing such Confidential Information,
                within ten (10) days of such request. Any documents or other media developed by you containing Confidential
                Information may be destroyed by you. You shall provide a written certificate to the Company regarding
                destruction within ten (10) days thereafter.
              </p>
              <footer>
                <div>
                  For Claycove23 Insurance Tech Private Limited
                </div>
                <br/>
                <div>
                  <h5>Rohan Gaikwad</h5>
                  <h5>Principal Officer</h5>
                </div>
              </footer>
            </div>
          </Page>
        </Document>
      </Wrapper>
    </>
  );
};

export default AppointmentTnC;

const Wrapper = styled.div`
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  scroll-behavior: smooth;

  h3 {
    font-weight: 500;
  }
  h4 {
    font-weight: 500;
  }
  h5 {
    font-weight: 500;
  }

  li {
    margin: 20px 0px;
  }
  ol {
    margin-bottom: 20px;
  }
  p {
    margin: 20px 0px;
  }

  .headerLogo {
    width: 150px;
    object-fit: contain;
    margin: auto;
    display: flex;
    margin-bottom: 6px;
  }

  .docHeading {
    text-align: center;
  }
  .salutation {
    display: flex;
    justify-content: space-between;
  }

  .subject {
    text-align: center;
  }

  .footerLogo {
    width: 60px;
    object-fit: contain;
  }
`;
