import React from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  container: {

  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    fontSize: 24,
    fontFamily: 'Lato Bold',
    height: '60',
    objectFit: 'contain',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    fontSize: 11,
    marginTop: '10px'
  },
  pan: {
    fontSize: 11,
    marginTop: '10px',
    textTransform: 'uppercase'
  },
  salutation: {
    fontSize: 10,
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  rightSalu: {

  },
  subject: {
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
    textAlign: 'center',
    marginTop: '10px'
  }
});

const Intro = ({ user }) => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
      <Text style={styles.info}>

        This is with reference to the application made by you for enrolling yourself to act as a Point of Sales Person (POSP). This is to confirm that you have successfully completed the prescribed training and have also passed the examination specified for Point of Sales examination conducted by Claycove23 Insurance Tech Pvt. Ltd. (herein referred to as “Company”) under the Guidelines on Point of Sales Persons. Your personal details are as under:

      </Text>
      <Text style={styles.pan}>
        PAN: {user.pan}
      </Text>
    </View>
    <View>
      <View style={styles.info}>
        <View style={styles.info}>
          <Text >
            We are pleased to appoint you as a Point of Sales Person for Claycove23 Insurance Tech Pvt. Ltd., to market products categorized and identified under the POS Guidelines.
          </Text>
          <Text >
            The terms and conditions mentioned below shall apply to all transaction(s) between us as may be specified from time to time by the Company in addition to the relevant laws of India, in general, and in particular to the provisions of the Insurance Act, 1938, and the Insurance Regulatory and Development Authority Act, 1999 and the Rules and Regulations specified there under as amended from time to time.
          </Text>
        </View>
        <View>
          <Text >
            Date of appointment: {user.doa}
          </Text>
          <Text >
            POSP Code: {user.pospCode}
          </Text>

        </View>

      </View>
    </View>


  </View>
);

export default Intro;