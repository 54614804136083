import React,{useState,useMemo,useEffect} from 'react';
import styled from 'styled-components';
import HeaderAdmin from './HeaderAdmin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import {OutlinedInput,InputAdornment,Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { createTheme,ThemeProvider,styled as styles } from '@mui/material/styles';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterMoment'
import {Modal,Box,TextField} from '@mui/material';
import { Link,useNavigate } from 'react-router-dom';
import { useTable,useSortBy,useGlobalFilter,useFilters,usePagination } from "react-table";
import { getAllApplicationByStatus } from '../../api';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import { useDispatch,useSelector } from 'react-redux';
import {getApplication} from '../../redux/actions/allApplication';
import Pagination from './Pagination';

import CheckIcon from '@mui/icons-material/Check';


const PendingPosp = ({showSearch,setShowSearch}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const pendingApplication = useSelector(state=>state.applications.pending);

    
       
    const [open, setOpen] = useState(false);
    const [searchinp, setSearchinp] = useState('');
    const [value, setValue] = useState([null, null]);
    // const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        components:{
            MuiModal:{
                styleOverrides:{
                    root:{
                        backgroundColor:'transparent',
                        "&:focus-visible":{
                            outline:"none"
                        }
                    }
                }
            }
        }
        
      })

      const dateTheme = createTheme({
          typography:{
            fontFamily:[
              'Montserrat',
             
            ].join(','),
            color:'#141414'
          },
          
          components:{
            MuiPaper:{
                styleOverrides:{
                    root:{
                        borderRadius:'12px'
                    }
                }
            },
            MuiTypography:{
                styleOverrides:{
                    subtitle1:{
                        fontWeight:700,
                        fontSize:'20px'
                    },
                    caption:{
                        color:'#141414',
                        fontWeight:600
                    }
                }
            },
            MuiDateRangePickerDay:{
                styleOverrides:{
                    
                    root:{
                        borderRadius:"0px",
                        "&:first-of-type":{
                            borderTopLeftRadius:"8px",
                            borderBottomLeftRadius:"8px",
                            
                        },
                        "&:last-of-type":{
                            borderTopRightRadius:'8px',
                            borderBottomRightRadius:'8px'
                        }
                    },
                    rangeIntervalPreview :{
                        border:'1px solid red',
                    },
                    rangeIntervalDayHighlight :{
                        border:'1px solid red',
                    },
                    dayInsideRangeInterval:{
                        backgroundColor:'red'
                    },
                    day:{
                        "&.Mui-selected": {
                            "backgroundColor": "red"
                          }
                    }
                    
                }
            },
            MuiButtonBase:{
                styleOverrides:{
                    root:{
                        // border:'1px solid green',
                        borderRadius:"8px",
                        
                    }
                    
                }
            },
            MuiPickersDay:{
                styleOverrides:{
                    root:{
                        borderRadius:"8px",
                        "&.Mui-selected": {
                            "backgroundColor": "#0085FF"
                          }
                    }
                }
            },
           
            
          }
      })

const fetchPendingApplication=async()=>{
    try {
        setLoading(true);
        const pendD = await getAllApplicationByStatus(1);
        // setData(pendD.data&&pendD.data.applications);
        setLoading(false);
    } catch (error) {
        setLoading(false);
    }
}
  
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkLogin=()=>{
      const authData = sessionStorage.getItem('authData');

      if(!authData){
          navigate('/admin/login');
      }
  }

  const handleSearch = () => {
      setShowSearch(!showSearch)
  }

  const COLUMNS=[
    {
        Header:'Sr No',
        accessor:'srNo',
        disableSortBy:true,
        Cell:(props)=>(
            <>
            
            {
                loading?<Skeleton variant="text" />:(Number(props.row.id)+1)}
            </>
        )
    },
    {
        Header:()=>(
            <div style={{textTransform:'capitalize'}}>
            NAME 
            <img 
            style={{paddingLeft:'5px'}}
            src={require('../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('firstname')} />
            </div>
        ),
        accessor:'firstname',
        disableSortBy:true,
        defaultCanSort:true,
        Cell:(props)=>(
            <div style={{textTransform:'capitalize'}}>
            
            {
                props.row.original.firstname?
                `${props.row.original.firstname} ${props.row.original.lastname}`:'-'
            }
            </div>
        )
    },
    
    {
        Header:()=>(
            <>
            APPLICATION NO  
            <img
            style={{paddingLeft:'5px'}}
             src={require('../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('applicantid')} />
            </>
        ),
        accessor:'applicantid',
        disableSortBy:true,
        defaultCanSort:true,
      
    },
    {
        Header:()=>(
            <span style={{display:'inline-flex'}} >
            APPLICATION DATE	  
            <img style={{paddingLeft:'5px'}} src={require('../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('createdOn')} />
            </span>
        ),
        accessor:'applicationDate',
        disableSortBy:true,
        disableMultiSort:true,
        defaultCanSort:true,
        filter: 'dateBetween',
        
        Cell:(props)=>(
            <>
            {
                props.row.original.applicationDate ? moment(props.row.original.applicationDate).format("LL") : '-'
            }
            </>
    )
    },
    {
        Header:()=>(
            <>
            PARTNER NAME
            <img 
            style={{paddingLeft:'5px',textTransform:'uppercase'}}
            src={require('../../assets/Image/sort-icon.svg').default} alt="" onClick={()=>toggleSortBy('partnername')} />
            </>
        ),
        accessor:'partnername',
        disableSortBy:true,
        defaultCanSort:true,
        Cell:(props)=>(
            <div style={{textTransform:'uppercase'}} >
            {props.value?props.value:'-'}
            </div>
        )
    },
    {
        accessor:'applicationStatus',
        disableSortBy:true,
        
        Cell:(props)=>(
                <Link to={`/admin/pending/${props.row.original.applicantid}`}>
                    <Button variant="contained" size="small" style={{width: '100px'}}>view</Button>
                </Link> 
            
        ),
        Header:()=>(
            
                <Grid container
                style={{
                    marginBottom:'-4px',
                    height:'62px'
                }}
                 >
                    <Grid item xs={6} className={showSearch?'searchicon':''} 
                    onClick={handleSearch}
                    style={{
                    backgroundColor:showSearch?'rgb(216, 221, 227)':'', 
                    borderRadius:'10px 10px 0px 0px',
                    padding:'14px 6px 14px 6px',
                    display:'flex',
                    justifyContent:'center'
                }}
                     >
                        
                        {
                            showSearch?<img src={require('../../assets/Image/search-icon-red.svg').default} style={{width:'20px'}} alt="" />:
                                        <img src={require('../../assets/Image/search-icon.svg').default} style={{width:'20px'}} alt="" />
                        }
                    </Grid>
                    <Grid item xs={6}
                        style={{
                            padding:'14px 6px 14px 6px'
                        }}
                     >
                        
                            <div className='filterContainer' >
                                <FilterListIcon
                                onClick={()=>handleClick()}
                                 />

                            </div>

                    </Grid>
                </Grid>
            
        )
    }
]




const filterTypes = useMemo(
    () => ({
        dateBetween: dateBetweenFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id];
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true;
            });
        }
    }),
    []
  );

  function dateBetweenFilterFn(rows, id, filterValues) {
    console.log(filterValues);
    let sd1 = new Date(filterValues[0]).setHours(0,0,0);
    let sd = new Date(sd1);
    let ed1 = new Date(filterValues[1]).setHours(23,59,0);
    let ed = new Date(ed1);
    return rows.filter(r => {
        var time = new Date(r.values[id]);
        
        if (filterValues.length === 0) return rows;
        return (time >= sd && time <= ed);
    });
}

dateBetweenFilterFn.autoRemove = val => !val;

    const columns = useMemo(()=> COLUMNS,[showSearch]);
    const tableData = useMemo(()=>pendingApplication,[pendingApplication]);

    const tableInstance = useTable({
        columns: columns,
        data: tableData,
        filterTypes,
      },useFilters,useGlobalFilter,useSortBy,usePagination);

      const{getTableProps,getTableBodyProps,
        headerGroups,
        rows,
        page,
        pageCount,
        pageOptions,
        setPageSize,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,setGlobalFilter,setFilter,setAllFilters,
        preFilteredRows,toggleSortBy
      } = tableInstance;

      const {globalFilter,filters,pageIndex,pageSize} = state;
   
  
      const handlesearchResult=(e)=>{
          if(e.code==="Enter"&&!searchinp){
            setGlobalFilter('');
          }
          if(searchinp&&e.code==="Enter"){
            setGlobalFilter(searchinp);
          }
      }


      useEffect(() => {
        checkLogin();
        // fetchPendingApplication();
        dispatch(getApplication());
      }, [])

    return (
        <>
            <HeaderAdmin />
            <ThemeProvider theme={theme}>
            <Wrapper enter={require('../../assets/Image/enter.svg').default} >

            {/* <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateRangePicker
                calendars={1}
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                    <>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                    </>
                )}
                />
            </LocalizationProvider> */}



            <TableContainer component={Paper} style={{backgroundColor:'#F7F8FB'}} >
      <Table {...getTableProps()}  aria-label="simple table">
        <TableHead style={{backgroundColor:'#EBEEF2',fontWeight:'600'}} >

        {headerGroups.map((headerGroup)=> (
                <TableRow {...headerGroup.getHeaderGroupProps()} style={{height:'63px'}}  >
                {
                    headerGroup.headers.map(column=> (
                    <TableCell align="left" {...column.getHeaderProps(column.getSortByToggleProps)} 
                    style={{
                        padding:'0px 0px',
                        paddingLeft:'16px'
                    }}
                     >
                        {column.render('Header')}                                                   
                            
                    </TableCell>
                    ))
                }
                </TableRow>
            ) )}

        </TableHead>


{
    showSearch&&
        <TableRow>
                <TableCell colSpan={6} className="searchInput" >
                    <OutlinedInput
                    value={searchinp}
                    onKeyPress={handlesearchResult}
                    onChange={e=>setSearchinp(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            {/* <SearchIcon fontSize='large' />  */}
                            <img src={require('../../assets/Image/search-icon.svg').default} style={{width:'25px'}} alt="" />
                        </InputAdornment>
                        }
                        
                        placeholder='Search for Values'

                    endAdornment={
                        <InputAdornment position="end">
                            <ClearIcon fontSize='large'
                            style={{
                                cursor:'pointer'
                            }}
                            onClick={()=>setShowSearch(!showSearch)}
                             /> 
                        </InputAdornment>
                        }
                    fullWidth />
                </TableCell>

        </TableRow>
}
                            <Modal
                                open={open}
                                onClose={()=>handleClose()}
                                BackdropProps={{style:{
                                    backgroundColor:'#000000',
                                    opacity: '50%'
                                }}}
                                style={{
                                    width:'260px',
                                    position: "absolute",
                                    right:'50px',
                                    top:'120px',
                                    borderRadius:'10px',
                                    bottom:'inherit',
                                    left:'inherit',
                                    paddingBottom:'0px',
                                    
                                }}
                            >
                                <div >
                                        <div className="sortTitle"
                                        style={{
                                            width:'70px',
                                            marginLeft:'auto',
                                            backgroundColor:'white',
                                            padding:'7px 12px 9px 10px',
                                            textAlign:'center',
                                            border:'none',
                                            borderRadius:'16px 16px 0px 0px',
                                            fontWeight:600,
                                            fontSize:'14px',
                                            fontFamily:'Montserrat',
                                            color:'#333333',
                                            boxShadow:'0px 1px 2px 0px #000E3340',
                                            
                                        }}
                                        >
                                            SORT BY :
                                        </div>
                                    <div className="items" 
                                    style={{
                                        backgroundColor:'white',
                                        border:'none',
                                        borderRadius:'20px 0px 20px 20px',
                                        boxShadow:'0px 1px 2px 0px #000E3340',
                                        padding:'0px 0px 0px 0px'
                                        }}>
                                        {/* <MenuItem style={{justifyContent:'center',color:'#333333'}} 
                                        onClick={()=>{
                                            toggleSortBy('partnername');
                                            handleClose();
                                        }}
                                        >
                                            Partner</MenuItem>
                                        <hr/> */}
                                        {/* <LocalizationProvider dateAdapter={DateAdapter}>
                                        <ThemeProvider theme={dateTheme}>
                                            <DateRangePicker
                                            calendars={1}
                                            value={value}
                                            onChange={(newValue) => {
                                                
                                                setValue([newValue[0]&&newValue[0]._d,newValue[1]&&newValue[1]._d]);
                                                newValue[0]&&newValue[1]&&
                                                 setFilter('createdOn',[newValue[0]&&newValue[0]._d,newValue[1]&&newValue[1]._d]);

                                                // value[0]&&value[1]&&
                                                //  setFilter('createdOn',[value[0]&&value[0],value[1]&&value[1]]);
                                            }}
                                            renderInput={(startProps, endProps) => (
                                                
                                                <MenuItem ref={startProps.inputRef} {...startProps.inputProps} style={{justifyContent:'center',color:'#333333',width:'100%'}} >Custom Date</MenuItem>
                                            
                                            )}
                                            />
                                            </ThemeProvider>
                                        </LocalizationProvider>
                                        {/* <MenuItem style={{justifyContent:'center',color:'#333333'}} onClick={()=>handleClose()}>Custom Date</MenuItem> */}
                                        {/* <hr/>  */}
                                        <MenuItem style={{justifyContent:'center',color:'#333333'}} onClick={()=>{
                                            handleClose();
                                            if(filters.find(el=>el.id==='applicationStatus' && el.value==='2')){
                                                setFilter('applicationStatus','');
                                            }else{
                                                setFilter('applicationStatus','2');
                                            }
                                            }}>{filters.find(el=>el.id==='applicationStatus' && el.value==='2') && <CheckIcon style={{color: 'green', margin: '0 5px'}}/>}Data Approved
                                        </MenuItem>
                                        <hr/>
                                        <MenuItem style={{justifyContent:'center',color:'#333333'}} onClick={()=>{
                                            handleClose();
                                            if(filters.find(el=>el.id==='applicationStatus' && el.value==='1')){
                                                setFilter('applicationStatus','');
                                            }else{
                                                setFilter('applicationStatus','1');
                                            }
                                            }}>{filters.find(el=>el.id==='applicationStatus' && el.value==='1') && <CheckIcon style={{color: 'green', margin: '0 5px'}}/>}Pending Both Approved
                                        </MenuItem>
                                        

                                    </div>

                                </div>
                            </Modal>

        {rows.length!==0&&
                  <TableBody {...getTableBodyProps()} >
                    {
                      page.map(row=>{
                        prepareRow(row)
                        return (
                            <TableRow 
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            style={{borderBottom:'1px solid rgba(224, 224, 224, 1)'}}
                            {...row.getRowProps()} >
                              {row.cells.map((cell)=> {
                                return (
                                  <TableCell align="left" {...cell.getCellProps()}
                                  style={{
                                      padding:'12px 5px 12px 16px',
                                      fontWeight: '500',
                                      color: '#333'
                                    }}
                                   >
                                     
                                   {cell.render('Cell')}
                                                                                  
                                  </TableCell>
                                )
                              } )}                     
                            </TableRow>
                        )
                      })
                    }
                  </TableBody>
                 
                }

      {
          rows.length===0&&
          <TableRow >
                  <TableCell colSpan={6}  style={{textAlign:'center'}} >
                    No Record Found
                  </TableCell>
                  
                </TableRow>
        //   <p>No Data Availabe</p>
    }
      </Table>
    </TableContainer>
    {
        rows.length>0&&
        <Pagination 
            page={page}
            nextPage={nextPage} 
            previousPage={previousPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageOptions={pageOptions}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            rows={rows}
            pageIndex={pageIndex}
           />
    }

   
      
                
            </Wrapper>
            </ThemeProvider>
        </>
    )
}

export default PendingPosp

const Wrapper = styled.div`

    a{
        text-decoration:none;
        color:inherit;
    }
 
::-webkit-scrollbar {
    height:8px;
    width:8px;
  }
  ::-webkit-scrollbar-track {
    height:8px;
    width:8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 5px; 
  }

    .searchCont{
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: 0;
    }
    .searchCont{
        padding-bottom:0;
        img{
            width:20px;
        }
    }
    .searchCont .searchicon{
        background:#d8dde3;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        max-width: 60px;
        .MuiSvgIcon-root{
            font-size:28px;
        }
    }
    .searchCont .MuiGrid-root.MuiGrid-item{
        padding:0px;
        padding:10px 0px 4px 0px;
    }
    .MuiButton-contained{
        background: #0085FF;
        border-radius: 5px;
        text-transform: none;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 126.3%;
        text-align: center;
        color: #FFFFFF;
        /* width: 72%; */
        padding: 2px 0px;
        :hover{
            background: #0056A5;
        }
    }
    .searchInput{
        
        padding:10px 10%;
        background-color: #D8DDE3;
        .MuiTableRow-root{
            background-color: #D8DDE3;
        }
        .MuiOutlinedInput-root{
            background-color: white;
            border-radius: 32px;
            outline:none;
            border:none;
            padding:0 30px;
            .MuiOutlinedInput-notchedOutline{
                border:1px solid #d8dde3;
            }

            .MuiOutlinedInput-input{
                padding-right:300px;
            }
            :after{
                /* content:'press Enter to Search  ↵ '; */
                content: ${props=> `url(${props.enter})`};
                position:absolute;
                display:block;
                color:red;
                right:170px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #727B85;
            }

        }
        
    }

    .MuiTableRow-root.MuiTableRow-head{
        .MuiTableCell-root{
            font-weight:600;
            color: #333;
            font-family: Montserrat;
            font-size: 16px;
        }
    }
  
   

  
  
`