/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Button,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { getIfscDetail, savePosp } from "../api/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BecomeAgentStrip from "./BecomeAgentStrip";
import { getMaster } from "../redux/actions/getMasterList";
import DoneIcon from '@mui/icons-material/Done';
const BankDetail = ({ step, setStep, formik }) => {
  const navigate = useNavigate();
  const accountType = useSelector((state) => state.masterList.accountType);
  const [lastIfsc, setLastIfsc] = useState("");
  const [lastAccountNo, setLastAccountNo] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [bankError, setBankError] = useState(false)
  const dispatch = useDispatch()
  const calTheme = createTheme({
    typography: {
      fontFamily: ["Outfit", "Sora"].join(","),
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            width: "375px",
            borderRadius: "10px",
            "&::-webkit-scrollbar": {
              height: "8px",
              width: "4px",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c9c6c6",
              borderRadius: "20px",
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            fontFamily: "Outfit",
            padding: "0px",
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            "&::-webkit-scrollbar": {
              height: "8px",
              width: "4px",
              borderRadius: "20px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#c9c6c6",
              borderRadius: "20px",
            },
          },
        },
      },
    },
  });

  const [showErr, setshowErr] = useState(false);

  const handleSkip = () => {
    // --------checking for session expire----------
    const lastActiveAgent = JSON.parse(
      sessionStorage.getItem("lastActiveAgent")
    );
    const currentTime = new Date().getTime();
    const sessionTime = 30 * 60 * 1000;
    if (
      lastActiveAgent &&
      currentTime - Number(lastActiveAgent) > sessionTime &&
      (step > 0 || step <= 5)
    ) {
      sessionStorage.removeItem("lastActiveAgent");
      alert("session expired,session is valid for 30 Minutes");
      navigate("/");
      return;
    }
    sessionStorage.setItem("lastActiveAgent", new Date().getTime());

    //    -------------session expire--------------------------

    formik.setFieldValue("bankName", "");
    formik.setFieldValue("ifsc", "");
    formik.setFieldValue("accType", 0);
    formik.setFieldValue("accNumber", "");
    formik.setFieldValue("accHolderName", "");
    setStep(3);
  };

  const handleAccNumberChange = (e) => {
    formik.setFieldValue(
      "accNumber",
      e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
    setBankError(false)
    setOtpSent(false)
  };

  const handleIFSCChange = (e) => {
    formik.setFieldValue(
      "ifsc",
      e.target.value
    );
    setBankError(false)
    setOtpSent(false)
  };

  const handleAccHolderNameChange = (e) => {
    formik.setFieldValue(
      "accHolderName",
      e.target.value.replace(/[0-9$&/+,:;=?@#|'<>.^*()%!_-]/gi, "")
    );
  };

  const handleBankNameChange = (e) => {
    formik.setFieldValue(
      "bankName",
      e.target.value
    );
  };

  const handleNextStep = async () => {
    // --------checking for session expire----------
    const lastActiveAgent = JSON.parse(
      sessionStorage.getItem("lastActiveAgent")
    );
    const currentTime = new Date().getTime();
    const sessionTime = 30 * 60 * 1000;
    if (
      lastActiveAgent &&
      currentTime - Number(lastActiveAgent) > sessionTime &&
      (step > 0 || step <= 5)
    ) {
      sessionStorage.removeItem("lastActiveAgent");
      alert("session expired,session is valid for 30 Minutes");
      setStep(0);
      navigate("/");
      return;
    }
    sessionStorage.setItem("lastActiveAgent", new Date().getTime());

    //    -------------session expire--------------------------

    const srData = JSON.parse(sessionStorage.getItem("rData"));

    if (
      !formik.values.accNumber ||
      !formik.values.ifsc ||
      !formik.values.bankName ||
      !formik.values.accType ||
      formik.errors.accHolderName ||
      formik.errors.accType ||
      formik.errors.bankName ||
      formik.errors.ifsc ||
      formik.errors.accNumber
    ) {
      setshowErr(true);
      return;
    }

    const saveData2 = {
      applicantid: formik.values.applicantid,
      accountno: formik.values.accNumber,
      ifscCode: formik.values.ifsc,
      bankname: formik.values.bankName,
      bankbranch: formik.values.bankBranch,
      accounttype: formik.values.accType,
      accountholdername: formik.values.accHolderName,
      pageno: 3,
    };

    const redirect = JSON.parse(sessionStorage.getItem("redirect"));

    const saveData = {
      applicantid: formik.values.applicantid,
      partnerid: formik.values.partnerid,
      partnername: redirect ? redirect.partnerName : "NA",
      partneruserid: redirect ? redirect.partneruserid : "",
      pospcode: "",
      email: formik.values.email,
      mobileno: formik.values.mobNumber,
      pannumber: formik.values.panNumber,
      initial: formik.values.initials,
      firstname: formik.values.firstName,
      lastname: formik.values.lastName ? formik.values.lastName : "-",
      dob: formik.values.dob,
      address: formik.values.address,
      pincode: formik.values.pinCode,
      city: formik.values.cityName,
      state: formik.values.state,
      educationQ: Number(formik.values.eduQual),
      others: formik.values.othersEdu,
      accountno: formik.values.accNumber,
      ifscCode: formik.values.ifsc,
      bankname: formik.values.bankName,
      bankbranch: formik.values.bankBranch,
      accounttype: formik.values.accType,
      accountholdername: formik.values.accHolderName,
      nomineeName: formik.values.nomineeName,
      gender: formik.values.gender,
      pageno: 3,
      managerUserId: formik.values.managerUserId
    };

    try {
      await savePosp(saveData);

      if (srData && srData.rrn && srData.aid && srData.rpg === "2") {
        setStep(5);
        return;
      }
      setStep(3);
    } catch (error) {
      console.log("bank save error");
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleAccDetail = async () => {
    formik.setFieldValue("bankName", 'Fetching...');
    formik.setFieldValue("accHolderName", 'Fetching...');
    try {
      const { data } = await getIfscDetail(formik.values.accNumber, formik.values.ifsc);
      if(data.data !== null){
        if(data.data.bankName && data.data.fullName){
        formik.setFieldValue("bankName", data.data.bankName);
        formik.setFieldValue("accHolderName", data.data.fullName);
        setOtpSent(true);
        setLastIfsc(data.data.bankName);
        setLastAccountNo(data.data.fullName)
        setBankError(false)
        }else{
          formik.setFieldValue("bankName", '');
          formik.setFieldValue("accHolderName", '');
          setOtpSent(true);
          setLastIfsc('');
          setLastAccountNo('')
          setBankError(true)
          setOtpSent(false)
        }
      }else{
        formik.setFieldValue("bankName", '');
        formik.setFieldValue("accHolderName", '');
        setOtpSent(true);
        setLastIfsc('');
        setLastAccountNo('')
        setBankError(true)
        setOtpSent(false)
      }
    } catch (error) {
      console.log("ifsc detail fetch error");
    }
  }

  // useEffect(async()=>{
  //   if (
  //     formik.values.ifsc &&
  //     formik.values.ifsc.length === 11 && formik.values?.accNumber && (formik.values.accNumber.length >= 6) &&
  //     /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(formik.values.ifsc)
  //   ) {

  //       try {
  //         const { data } = await getIfscDetail(formik.values.accNumber, formik.values.ifsc);
  //         formik.setFieldValue("bankName", data.data.bankName);
  //         formik.setFieldValue("accHolderName", data.data.fullName);
  //         setLastIfsc(formik.values.ifsc);
  //         setLastAccountNo(formik.values.accNumber)
  //       } catch (error) {
  //         console.log("ifsc detail fetch error");
  //       }
  //   }
  // },[formik.values.ifsc, formik.values.accNumber])
  return (
    <>
    <BecomeAgentStrip/>
  
    <Wrapper>
      <Grid
        container
        className="mainContainer"
        spacing={0}
        justifyContent="center"
      >
        <Grid item md={0.5} className="heading">
              <ArrowBackIosNewIcon
                  fontSize="large"
                  onClick={() => setStep(1)}
                />
          </Grid>
        <Grid container item xs={12} md={5} spacing={2} direction="row">
          <Grid item>
            <Heading>
              {/* <div className="heading">
                <ArrowBackIosNewIcon
                  fontSize="large"
                  onClick={() => setStep(1)}
                />
                <p>Become a Hello Zindagi agent</p>
              </div> */}
              <p className="title">
                Bank Details
                <img
                  className="titleImageDesktop"
                  src={require("../assets/Image/bank-info-emoji.png").default}
                  alt=""
                />
                <img
                  className="titleImageMobile"
                  src={require("../assets/Image/bank-info-emoji.png").default}
                  alt=""
                />
              </p>
            </Heading>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Account Number"
              variant="outlined"
              inputProps={{ maxLength: 18 }}
              onChange={handleAccNumberChange}
              value={formik.values.accNumber}
              fullWidth
            />
            {bankError ?  <p className="errorText">Enter Valid Account Number</p> : showErr && formik.errors.accNumber && (
              <p className="errorText">{formik.errors.accNumber}</p>
            )}
          </Grid>
          <Grid className="mobileInp" container item xs={12} md={12} spacing={1}  >
              <Grid item xs={8}>
                <TextField
                  label="IFSC Code"
                  inputProps={{ maxLength: 11 }}
                  onChange={handleIFSCChange}
                  value={formik.values.ifsc}
                  variant="outlined"
                  fullWidth
                />
                
              </Grid>
              <Grid item xs={4}>
                <Button className='otpBtn'
                  variant="contained"
                  fullWidth
                  disabled={formik.errors.ifsc || formik.values.ifsc === '' || formik.errors.accNumber || formik.values.accNumber === '' ? true : false}
                  onClick={handleAccDetail}
                >{otpSent &&<DoneIcon style={{color:"rgb(14 125 89)", marginRight: '5px'}} />}  Verify</Button>
              </Grid>
              {bankError ? <p className="errorText ifscError" style={{ marginLeft: '16px'}}>Enter Valid IFSC code</p> : showErr && formik.errors.ifsc && (
                  <p className="errorText" style={{marginLeft: '16px'}}>{formik.errors.ifsc}</p>
                )}
              {
                  // showErr&&formik.errors.mobNumber&&
                  formik.touched.mobNumber&&
                  <p className="errorText errorNum" >{formik.errors.mobNumber}</p>

              }
          
          </Grid>
          <Grid item xs={12} md={12} className="grid-field">
            <div 
              style={{
                fontSize: '18px',
                fontWeight: 'normal', 
                fontFamily: 'Outfit', 
                color: 'rgba(120, 120, 120, 1)',
                marginRight:'5px'
              }}>Account holder’s name:</div>{' '}
            <div style={{
                fontSize: '18px',
                fontWeight: 'normal', 
                fontFamily: 'Outfit', 
              }}>{formik.values.accHolderName}</div>
            {/* <TextField
              label="Account holder’s name"
              variant="outlined"
              //  {...formik.getFieldProps('accHolderName')}
              value={formik.values.accHolderName}
              disabled
              onChange={handleAccHolderNameChange}
              inputProps={{ maxLength: 80 }}
              fullWidth
            />
            {showErr && formik.errors.accHolderName && (
              <p className="errorText">{formik.errors.accHolderName}</p>
            )} */}
          </Grid>

          <Grid item xs={12} md={12} className="grid-field">
            <div style={{
                fontSize: '18px',
                fontWeight: 'normal', 
                fontFamily: 'Outfit', 
                color: 'rgba(120, 120, 120, 1)',
                marginRight:'5px'
            }}>Bank Name:</div>{' '}
            <div style={{
                fontSize: '18px',
                fontWeight: 'normal', 
                fontFamily: 'Outfit', 
              }}>{formik.values.bankName}</div>
            {/* <TextField
              label="Bank Name"
              // {...formik.getFieldProps('bankName')}
              onChange={handleBankNameChange}
              value={formik.values.bankName}
              disabled
              fullWidth
            />
            {showErr && formik.errors.bankName && (
              <p className="errorText">{formik.errors.bankName}</p>
            )} */}
          </Grid>

          <Grid item xs={12} md={12}>
            <Box sx={{ minWidth: 120 }}>
              <ThemeProvider theme={calTheme}>
                <FormControl fullWidth>
                  <InputLabel>Account Type</InputLabel>
                  <Select
                    label="Account Type"
                    {...formik.getFieldProps("accType")}
                  >
                    <MenuItem disabled value={0}>
                      <em>-Select Account Type-</em>
                    </MenuItem>
                    {/* <MenuItem value={1}>
                                        <p>Savings</p>
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <p>Current</p>
                                    </MenuItem> */}
                    {accountType.map((type, ind) => (
                      <MenuItem value={type.accountTypeid}>
                        <p>{type.accountName}</p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </Box>
            {showErr && formik.errors.accType && (
              <p className="errorText">{formik.errors.accType}</p>
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
              className="submitBtn"
              variant="contained"
              onClick={handleNextStep}
            >
              Save and Continue
            </Button>
            {/* <p
              className="skip"
              onClick={handleSkip}
              // onClick={()=>console.log(formik.values)}
            >
              Skip for now
            </p> */}
          </Grid>
        </Grid>

        <Grid container item xs={0} md={5} className="rightHero">
          <img
            src={require("../assets/Image/bank-details.svg").default}
            alt=""
          />
        </Grid>
      </Grid>
    </Wrapper>
    </>
  );
};

export default BankDetail;

const Wrapper = styled.div`
  padding: 0 8%;
  padding-bottom: 2%;
  .mainContainer{
    padding-top: 40px;
    .heading{
      .MuiSvgIcon-root {
      color: #2959E5;
      cursor: pointer;
    }
    }
  }
  a {
    text-decoration: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    /* margin: 10px 0; */

    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;

    font-size: 18px;
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: #59ba9b;
        border-width: 1px;
      }
    }
  }
  .grid-field{
    display: flex;
    @media only screen and (max-width: 600px) {
      display: block;
    }
  }
  .textInp {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    border: 1px solid #bfc0c5;
    border-radius: 10px;
    padding: 15px 13px;
    margin-right: 20px;
    /* margin-top:20px; */
    outline-color: #ed1c2485;
  }

  .errorText {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    color: #ed1c24;
    font-size: 14px;
    margin-left: 8px;
    margin-top: 3px;
  }
  .ifscError{
    margin-top: -1%;
    @media only screen and (max-width: 1100px) {
      margin-top: -2%
    }
    @media only screen and (max-width: 1000px) {
      margin-top: 0.5%
    }
  }

  .eduTitle {
    font-family: "Sora";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
  }
  .MuiFormControlLabel-root {
    border: 2px solid #bfc0c5;
    padding: 3px 1px;
    border-radius: 8px;
    margin: 5px 8px;
    color: #787878;
    padding-right: 20px;
  }
  .eduWrap {
    margin: 15px 0;
  }
  .submitBtn {
    padding: 10px 60px;
    border: none;
    outline: none;
    background: #2959E5;
    border-radius: 10px;
    color: white;
    font-size: 20px;
    text-align: center;
    width: 100%;
    text-transform: none;
    font-family: "Sora";
    font-style: normal;
    font-weight: normal;
    box-shadow: 2px #2959E5;

    :hover {
      background-color: #2959E5;
      box-shadow: 2px #2959E5;
    }
  }

  .rightHero {
    justify-content: center;
    margin-top: 20px;
    align-content: baseline;
    width: 100%;

    img {
      object-fit: contain;
      width: 100%;
      max-height: 580px;
    }
  }

  .skip {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;

    color: #434552;
    text-align: center;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
    border-bottom: 2px dotted #434552;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: #fcbc30;
      border-width: 1px;
    }
  }
  .MuiInputLabel-outlined.Mui-focused {
    color: #333333;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: "Outfit";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #888888;
  }
  .mobileInp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .otpBtn{
        font-size: 18px;
        padding: 12px 13px;
        background: #E6EBEE;
        border-radius: 8px;
        color: #525151;
        outline:none;
        border:none;
        text-transform: capitalize;
        box-shadow: 0px 1px 0px 1px #E6EBEE;
        white-space: nowrap;
        font-family:'Outfit';
        font-style: normal;
        font-weight: 400;
    }
    .Mui-disabled{
        color: #88888873;  
        background: #E6EBEE;
        box-shadow: 0px 1px 0px 1px #e6ebee;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 1% 4%;

    .mainContainer{
      .heading{
        display:none;
      }
    }

    .MuiOutlinedInput-root {
      font-size: 16px;
    }
    .MuiFormLabel-root.MuiInputLabel-root {
      font-size: 16px;
    }

    .rightHero {
      display: none;
    }
    .submitBtn {
      border-radius: 7px;
    }
    .mobileInp{
      .otpBtn{
          font-size: 16px;
          font-weight: 400;
      }
    }
  }
`;

const Heading = styled.div`
  /* padding-top: 40px; */
  .heading {
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      color: #2959E5;
      /* margin-left: -46px; */
      cursor: pointer;
    }

    p {
      font-family: "Outfit";
      font-size: 20px;
      padding-left: 10px;
      color: #c4c4c4;
    }
  }
  .title {
    font-family: "Sora";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #434552;
    img {
      height: 22px;
      object-fit: contain;
    }
  }

  .titleImageMobile {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .title {
      font-size: 18px;
    }
    .titleImageMobile {
      display: inline-block;
    }
    .titleImageDesktop {
      display: none;
    }

    .heading {
      display: none;
    }
  }
`;
