import React from 'react';
import styled from 'styled-components';
import BenefitCard from './BenefitCard';

const Benefits = () => {
    return (
        <Wrapper>
            <Heading>
                <p className='title' >Benefits of Becoming a POSP <img src={require('../assets/Image/cowboy-emoji.png').default} alt="" /></p>
            </Heading>
            <CardSection>
                <BenefitCard 
                    image={require('../assets/Image/benefit1.svg').default}
                    heading='Noble Profession'
                    text='As a POSP, you can get insurance related knowledge & experience which in turn can help deliver the larger objective of 
                    insuring people and their assets'
                 />
                <BenefitCard 
                    image={require('../assets/Image/benefit2.svg').default}
                    heading='No cost involved'
                    text='The training and examination shall be conducted in-house and the applicant need 
                        not bear any expense for the same'
                 />
                <BenefitCard 
                    image={require('../assets/Image/benefit3.svg').default}
                    heading='Minimal training'
                    text='Unlike a regular agent which requires higher number of hours of training, a POSP shall undergo 
                        only 15 hours of training and become a skilled and certified insurance professional'
                 />
                <BenefitCard 
                    image={require('../assets/Image/benefit4.svg').default}
                    heading='Unlimited Income Opportunity'
                    text='The sky is the limit, you can earn commission on every policy sourced 
                        by you. The higher the business the greater shall be the commission income'
                 />
                <BenefitCard 
                    image={require('../assets/Image/benefit5.svg').default}
                    heading='Technology advantage'
                    text='You would be able to leverage the 
                    technological developments at CoverSure to enable sourcing of 
                    insurance and help in scalability'
                 />
                
            </CardSection>
            <BenefitHero>
                <img src={require('../assets/Image/benefit-cartn.svg').default} alt="" />
            </BenefitHero>
            
        </Wrapper>
    )
}

export default Benefits

const Wrapper = styled.div`
@media only screen and (max-width: 600px){
    background-color: #EEF0FB;
}
`
const Heading = styled.div`
    p{
        font-family: 'Sora';
        font-size: 32px;
        text-align:center;
        padding:15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
        color:#434552;
        font-weight:700;
        img{
            height:28px;
        }
    }
    @media only screen and (max-width: 600px){
        p{
            font-size:18px;
            img{
                height:20px;
            }
        }

    }
`
const CardSection = styled.div`
    padding:30px 10px;
    display:flex;
    
    gap:20px;
    overflow: auto;
    border-radius:10px;
    margin: 0 25px;
    @media only screen and (min-width: 1800px){
        justify-content: center;
    }

    ::-webkit-scrollbar {
        height:5px;
        border-radius:1px;
    }
    ::-webkit-scrollbar-track {
        
    }
    ::-webkit-scrollbar-thumb {
        background-color:#c9c6c6;
        border-radius: 10px;
       
    }

    @media only screen and (max-width: 600px){
        padding-bottom: 5px;
        margin:0px 6px;
        ::-webkit-scrollbar {
        height:9px;
        width:4px;
        border-radius:2px;
        display:none;
    }
    ::-webkit-scrollbar-track {
        
    }
    ::-webkit-scrollbar-thumb {
        background-color:#c9c6c6;
        border-radius: 2px;
        display:none;
    }

    }

`
const BenefitHero = styled.div`
display:none;

@media only screen and (max-width: 600px){
    display: flex;
    justify-content: center;
}

`
