import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import './style.css';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& > * + *': {
      marginLeft: '16px'
    }
  },
  loader: {
    color: 'red' // Replace 'red' with the desired color value
  }
}));

function Loader(props) {
  const classes = useStyles();

  return (
    <div
      className={
        props.is_loading ? 'loader_block' : 'loader_block hide-display'
      }>
      <div className={classes.root}>
        <CircularProgress
         style={{ color: '#5093C9' }} 
          disableShrink={props?.is_loading === true ? false : true}
        />
      </div>
    </div>
  );
}

export default Loader;
