import React,{useEffect} from 'react';
import styled from 'styled-components';
import LinearProgress from '@mui/material/LinearProgress';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useDispatch,useSelector} from 'react-redux';
import {getMaster} from '../redux/actions/getMasterList'
import { Link,useLocation,useNavigate } from 'react-router-dom';


const Header = ({step,setStep}) => {

    const dispatch = useDispatch();
    const masterList = useSelector(state=>state.masterList.accountType);
    const navigate = useNavigate();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }

     
      const query = useQuery();

      const setRedirectionDetails=()=>{
          const partnerName = query.get('pname');
          const partneruserid = query.get('puid');
          const rejectedReason = query.get('rrn');
          const rejPage = query.get('rpg');
          const applicatId = query.get('aid');
          const bulkPage = query.get('bpg');

        //   sessionStorage.setItem('rrn',rejectedReason);
        //   sessionStorage.setItem('rpg',rejPage);
        //   sessionStorage.setItem('aid',applicatId);
        //   sessionStorage.setItem('bpg',bulkPage);

          const rData = {
              rrn:rejectedReason,
              rpg:rejPage,
              aid:applicatId,
              bpg:bulkPage
          }

        //   sessionStorage.setItem('rData',JSON.stringify(rData));

          const srData = JSON.parse(sessionStorage.getItem('rData'))
          
        //   const rrn = sessionStorage.getItem('rrn');
        //   const rpg = sessionStorage.getItem('rpg');
        //   const aid = sessionStorage.getItem('aid');
        //   const bpg = sessionStorage.getItem('bpg');


        //   if(srData&&srData.rrn&&srData.rpg){
        //     setStep(Number(srData.rpg));
        //   }

          const lastActiveAgent = JSON.parse(sessionStorage.getItem('lastActiveAgent'));
          const redData ={
              partnerName:partnerName,
              partneruserid:partneruserid
          }

          const rejApp = {
            rejApplicantId:applicatId,
            rejectedReason:rejectedReason,
            rejPage:rejPage
          }

          window.sessionStorage.setItem('redirect',JSON.stringify(redData));
          window.sessionStorage.setItem('rejectedApplicant',JSON.stringify(rejApp));

        //   if(rejectedReason&&rejPage&&(Number(rejPage)<5&&Number(rejPage)>0)){
        //     setStep(Number(rejPage))
        //   }

          const currentTime = new Date().getTime();
            const sessionTime = 10*60*1000;

        //     if(
        //         lastActiveAgent&&
        //        ( (currentTime-Number(lastActiveAgent))>sessionTime )
        //        &&(step>0||step<=5)
        //    ){
        //        sessionStorage.removeItem('lastActiveAgent');
        //        alert('session expired,session is valid for 10 Minutes');
        //        navigate('/');
        //        return
        //    }

          sessionStorage.setItem('lastActiveAgent',new Date().getTime());

      }


useEffect(()=>{
    if(masterList.length===0){
        dispatch(getMaster());
    }
    setRedirectionDetails();


},[])

    return (
        <>
        <Container>

        <Wrapper>
                <Logo  >
                    {
                        step>0&&
                        <a href='/'>
                            <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(step>0?(step-1):step)} />
                        </a>
                    }
                    {/* <a href='/' onClick={()=>{
                        setStep(0);
                        navigate('/');

                    }} > */}
                        <img className='logoImage' src={require('../assets/Image/coverSureLogo.svg').default} alt="logo" onClick={()=> navigate('/')} />
                    {/* </a> */}
                </Logo>
            {
                step>0 && step<5 &&

            <TimelineWrapper>
                <Stepper alternativeLabel  >
                    <Step active={step===1?true:false} expanded={true} onClick={()=>setStep(step>1?1:step)} >
                        <StepLabel className="stepLevel" sx={{color:'red',whiteSpace:'nowrap'}} >
                        BASIC INFO
                            
                        </StepLabel>
                        
                    </Step>
                    <Step active={step===2?true:false} expanded={true} onClick={()=>setStep(step>2?2:step)} >
                        <StepLabel>BANK DETAILS</StepLabel>
                        
                    </Step >
                    <Step active={step===3?true:false} expanded={true} onClick={()=>setStep(step>3?3:step)} >
                        <StepLabel>UPLOAD DOCS</StepLabel>
                        
                    </Step>
                    <Step active={step===4?true:false} expanded={true} className="isDesktop" >
                        <StepLabel >CONFIRM DETAILS</StepLabel>
                        
                    </Step>
                </Stepper>
            </TimelineWrapper>
            }
        </Wrapper>
        <div className="progress">
            <LinearProgress variant="determinate"
            value={step===5?0:step*25} 
            sx={{ width: '100%'}}
            style={{
                height:step===0||step===5?'0px':'8px',
                backgroundColor:'#EAEAEA'
            }}
            // color='primary'
             />
        </div>
        </Container>
        </>
    )
}

export default Header

const Wrapper = styled.div`
    display: flex;
    /* padding: 18px 15px; */
    padding:0px 10%;
    padding-right:6%;
    height:90px;
    /* background-color: rgba(241, 242, 244, 0.8); */
    /* background-color:#ffffffdb;
    position: sticky;
    top: 0; */
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid rgb(223,227,231);
    /* backdrop-filter: blur(16px); */

    /* .MuiLinearProgress-determinate{
        height:8px;
        background-color: #dfe1e3;
    .MuiLinearProgress-bar{
      background-color: #ED1C24;
      border-radius: 8px;
    }
  } */
  z-index:20;

  @media only screen and (max-width: 600px){
      padding: 9px 3px;
      padding-right:6%;
      height: 62px;
      /* .MuiLinearProgress-determinate{
          height:15px;
          background-color: #dfe1e3;
          .MuiLinearProgress-bar{
            background-color: #2959E5;
            border-radius: 0px;
        }
    } */
    
}


`

const Container = styled.div`
    background-color:#ffffffdb;
    position: sticky;
    top: 0;
    backdrop-filter: blur(16px);
    z-index:30;
    @media only screen and (max-width: 600px){
        .MuiLinearProgress-determinate{
            height:1px;
        }
    }
`

const Logo = styled.div`
/* padding: 0 3%; */
    .logoImage{
        height:60px;
        object-fit: contain;
        cursor: pointer
    }
    .MuiSvgIcon-root{
        display:none;
        color:#2959E5;
        /* margin-left: -46px; */
        cursor: pointer;
    }

    @media only screen and (max-width: 800px){
      padding-left:1%;
      display: flex;
    align-items: center;
      .MuiSvgIcon-root{
          display:inline-block;
      }
      .logoImage{
          height:40px;
          padding-left: 8px;
      }
  }
  
`
const TimelineWrapper = styled.div`
    flex:0.9;
    .isMobile{
        display: none;
        @media only screen and (max-width: 600px){
            display: block;
        }
    }
    .isDesktop{
        display: block;
        @media only screen and (max-width: 600px){
            display: none;
        }
    }
    .MuiStepper-root,.MuiStepLabel-label{
        font-family: 'Outfit';
    }
    .MuiStepLabel-label{
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0.16em;

    }
    .MuiSvgIcon-root{
        color:#696B78;
    }
    .MuiSvgIcon-root.Mui-active{
        color:#08AA61;
        font-size:16px;
    }
    .MuiStepLabel-label{
        color:#696B78;
    }
    .MuiStepLabel-label.Mui-active{
        color:#08AA61;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0.16em;     
    }
    .MuiStepContent-root{
        font-size: 20px;
        border-left: 2px dashed #bdbdbd;
    }
    .MuiStepIcon-root{
        font-size:16px;
    }
    .MuiStepper-alternativeLabel{
       
       
        .MuiStepConnector-line{
            border-top-style: dashed;
            border-top-width: 2px;
        }
        .MuiStepIcon-text{
            display:none;
        }
    }
    .MuiStepConnector-root{
        top:7px;
        left:calc(-50% + 10px);
        right:calc(50% + 10px);
    }

    @media only screen and (max-width: 600px){
        .MuiStepLabel-label{
            font-size:9px;
        }
        .MuiStepLabel-label.Mui-active{
            font-size:9px;
        }
        .MuiSvgIcon-root.Mui-active{
            font-size:16px;
        }
        .MuiSvgIcon-root.MuiStepIcon-root{
            font-size:9px;
            z-index: 99;
        }
        /* .MuiStep-alternative{
            padding-left: 0px;
            padding-right: 0px;
        } */
        .MuiStepConnector-alternativeLabel{
            padding-left: 7px;
            padding-right: 0px;
            top: 5px;
            left: calc(-80% + 10px);
            right: calc(41% + 10px);
            .MuiStepConnector-line {
                border-top-width: 1px 
            }
        }
        .MuiStep-root{
            padding-left:5px;
            padding-right:5px;
        }
    }
`