import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Pagination = ({
    page,nextPage,
    previousPage,pageCount,pageOptions,
    canPreviousPage,canNextPage,
    rows,pageSize,setPageSize,
    pageIndex
}) => {

    const [pageNo, setPageNo] = useState();
    
    console.log({
        page:page,
        pageCount:pageCount,
        pageOptions:pageOptions,
        canNextPage:canNextPage,
        canPreviousPage:canPreviousPage,
        rows:rows,
        pageSize:pageSize,
        pageIndex:pageIndex
    });

   const currentPageSize=()=>{
        setPageNo()
   }

   useEffect(()=>{
        setPageSize(20)
   },[])

  return (
    <Wrapper>

        <div className="detail">
            <span className='currentCount' > 



{/* {
    (pageIndex+1)===(Math.floor(rows.length/pageSize)+1 )?
     (   (rows.length%pageSize)+ ( (pageIndex+1) -1 )*pageSize ):pageSize*(pageIndex+1)

} */}



{/* ----------- */}



            {((pageIndex+1)-1)*pageSize+1} - {(rows.length%pageSize)>0?
            (pageIndex+1)===(Math.floor(rows.length/pageSize)+1 )?
            (   (rows.length%pageSize)+ ( (pageIndex+1) -1 )*pageSize ):pageSize*(pageIndex+1):
            pageSize*(pageIndex+1)
       
             }



            {/* {((pageIndex+1)-1)*pageSize+1} - {
            pageSize*(pageIndex+1)
            }  */}






            </span> 
            out of
            <span className='totalCount' > {rows.length} </span>

        </div>
        <div className="action">
            <div className="leftArr">
                <ChevronLeftIcon
                 onClick={()=>previousPage()}
                style={{
                    fontSize:'30px',
                    color:canPreviousPage?'#606367':'rgb(96 99 103 / 55%)'
                }}
                />
            </div>
            {/* <div className="pageNo">
                1
            </div> */}
            <div className="rightArr">
                <ChevronRightIcon
                onClick={()=>nextPage()}
                style={{
                    fontSize:'30px',
                    color:canNextPage?'#606367':'rgb(96 99 103 / 55%)'
                }}
                />
            </div>

        </div>
        
    </Wrapper>
  )
}

export default Pagination

const Wrapper = styled.div`
position:sticky;
bottom: 0;
    font-family: 'Montserrat';
    background-color: #F7F8FB;
    display: flex;
    justify-content: center;
    align-items: center;
padding:30px 0px 30px 0px;
    .detail{
        color:#606367;
        font-weight: 500;
        font-size: 14px;
        .currentCount{
            padding-right: 8px;
        }
    }

    .action{
        display: flex;
    justify-content: center;
    align-items: center;
    padding:0px 25px;
    gap:10px;
    }
    .pageNo{
        font-size: 25px;
    }
`