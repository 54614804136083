import { LOADING,ERROR_LOADING,FETCH_DATA } from '../constants'

const INITIAL_STATE = {
  all:[],
  pending:[],
  rejected:[],
  approved:[],
  dataApproved:[],
  businessApproved:[],
  partialData:[]
};

export const applications= (state = INITIAL_STATE, action)=>{
  switch (action.type) {
    case LOADING:
      return state;

    case ERROR_LOADING:
      return state

    case FETCH_DATA:
      return {
        ...state,
        all:action.payload,
        pending:action.payload.filter(el=>el.applicationStatus===1||el.applicationStatus===2).sort((a,b)=>b.applicantid-a.applicantid),
        rejected:action.payload.filter(el=>el.applicationStatus===4).sort((a,b)=>b.applicantid-a.applicantid),
        approved:action.payload.filter(el=>el.applicationStatus===3).sort((a,b)=>b.applicantid-a.applicantid),
        dataApproved:action.payload.filter(el=>el.applicationStatus===2).sort((a,b)=>b.applicantid-a.applicantid),
        businessApproved:action.payload.filter(el=>el.applicationStatus===3).sort((a,b)=>b.applicantid-a.applicantid),
        partialData:action.payload.filter(el=>el.applicationStatus===5).sort((a,b)=>b.applicantid-a.applicantid),
      }

    default:
      return state;
  }
}
