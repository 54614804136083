import React from 'react';
import styled from 'styled-components';
import {Button} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton  } from '@mui/material';
import {saveBulk} from '../../../api/index';
import { getExt } from '../../../utils/fileUtil';

const DropFile = ({fetchFileDetail}) => {

    const handleDrop=async(e)=>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('bulkfile',e.dataTransfer.files[0]);
        formData.append('uploadedby',2);

        

        if(
            (e.dataTransfer.files[0]&&getExt(e.dataTransfer.files[0].name)!=='xlsx')||
            (e.dataTransfer.files[0]&&e.dataTransfer.files[0].size>1*1000*1000)
        ){
            alert('Please use xlsx file upto 10 MB size');
            return
        }

        

        try {
            await saveBulk(formData);
            fetchFileDetail();
            alert('file uploaded');
        } catch (error) {
            console.log('bulk upload error');
            
        }
    }

    const handleDragOver=(e)=>{
        e.preventDefault();
    }

    const handleBrowseBtn=()=>{
        let bulkBrowseBtn = document.getElementById('bulkBrowseBtn');
        bulkBrowseBtn.click();
    }

    const handleBrowseInp=async(e)=>{
       

        if(
            (e.target.files[0]&&getExt(e.target.files[0].name)!=='xlsx')||
           (e.target.files[0]&&e.target.files[0].size>1*1000*1000) 
        ){
            alert('Please use xlsx file upto 10 MB size');
            return
        }



        const formData = new FormData();
        formData.append('bulkfile',e.target.files[0]);
        formData.append('uploadedby',2);

        try {
            await saveBulk(formData);
            fetchFileDetail();
            alert('file uploaded');
        } catch (error) {
            console.log('bulk upload error');
            
        }
    }

    return (
        <Wrapper>
            <DownloadSection>
                <Button size="small" style={{minWidth:'auto'}} >
                    {/* <a href={require('../../../assets/document/Upload_Bulk_Sample.xlsx').default} download="Upload_Sample.xlsx" > */}
                    <a href={'https://insuretech-prod-public-assets.s3.ap-south-1.amazonaws.com/posp/Upload_Sample_New.xlsx'} download="Upload_Sample.xlsx"  >
                        <img src={require('../../../assets/Image/excel-icon-green.svg').default} alt="" />
                    </a>
                </Button>
                <p>
                    Download Template<br/>
                    (Sample File)
                </p>
                
            </DownloadSection>
            <DragAreaCont onDrop={handleDrop} onDragOver={handleDragOver} >
                <div className="excel">
                        <img src={require('../../../assets/Image/xls-icon.png').default} alt="" />
                    
                </div>
                <input type="file" name="bulk-file" id="bulkBrowseBtn" accept='.xlsx' onChange={handleBrowseInp} />
                <div className="text">
                    <p>
                        Drag and drop your excel files here
                    </p>
                </div>
                <div className="orText">
                    <p>
                        OR
                    </p>
                </div>
                <div className="browseBtn">
                    <Button variant="outlined" startIcon={<CloudUploadIcon/> } onClick={handleBrowseBtn} >
                        Browse files
                    </Button>

                </div>

               
            </DragAreaCont>
            
        </Wrapper>
    )
}

export default DropFile

const Wrapper = styled.div`
   
`
const DownloadSection = styled.div`
   
    margin-right:100px;
    padding:30px;
    /* border:2px solid black; */
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 0 9px;
    img{
        object-fit: contain;
        height:30px;
    }
    p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #969EA6;
    }
`
const DragAreaCont = styled.div`
    height: 365px;
    width: 70%;
    margin: auto;
    padding: 15px;
    background-color:#EBEEF2;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23727B85B3' stroke-width='6' stroke-dasharray='20' stroke-dashoffset='45' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    #bulkBrowseBtn{
        display:none;
    }
    .excel{
        /* border: 3px dashed #727B85; */
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23727B85B3' stroke-width='4' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        padding: 15px;
        border-radius: 10px;
        width:40px;
        img{
            object-fit: contain;
            width:100%;
        }
    }
    .text{
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #333333;
        }
    }
    .orText{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #C4C4C4;
        display:flex;
        p{
            :before{
                content: '';
                width: 138px;
                border-bottom: 1px solid #C4C4C4;
                position: absolute;
                margin-top: 14px;
                margin-left: -148px;
               
            }
            :after{
                content: '';
                width: 138px;
                border-bottom: 1px solid #C4C4C4;
                position: absolute;
                margin-top: 13px;
                margin-left: 8px;
               
            }
        }
    }
    .browseBtn{
        .MuiButton-outlined{
            color:#ED1C24;
            background-color:none;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            border: 3px solid #ED1C24;
            text-transform: capitalize;
            border-radius: 10px;
            :hover{
                color:white;
                background-color: #ED1C24;
            }
            
        }
    }
`