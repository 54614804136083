import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Box,
    Button,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import doneIcon from '../assets/Image/done-tick.svg';
import { savePosp, uploadFile } from '../api/index';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import editIcon from '../assets/Image/edit-icon.svg';
import uploadIcon from '../assets/Image/upload-icon.svg';
import { getApplicationByIdPan, updatePosp } from '../api/index'


import Header from './Header';
import { NoEncryption } from '@mui/icons-material';

let error = false;
const errorObj = {
    edu: '',
    email: ''
}

const UpdateDetail = () => {

    const accountType = useSelector(state => state.masterList.accountType);
    const navigate = useNavigate();


    const [maxdob, setMaxdob] = useState('');
    const [panFile, setPanFile] = useState('');
    const [eduFile, setEduFile] = useState('');
    const [showErr, setShowErr] = useState(false);
    const [emailRej, setEmailRej] = useState(false);
    const [eduRej, setEduRej] = useState(false);
    const [panRej, setPanRej] = useState(false);
    const [bulkRej, setBulkRej] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [newEdu, setNewEdu] = useState('');
    const [newOtherEdu, setNewOtherEdu] = useState('');
    const [errObj, setErrObj] = useState({
        edu: '',
        email: '',
        otherEdu: '',
        eduFile: ''
    })




    const formik = useFormik({
        initialValues: {
            applicantid: 0,
            panNumber: '',
            email: '',
            partnerid: '',
            partnername: '',
            mobNumber: '',
            tnc: false,
            // initials: 0,
            firstName: '',
            lastName: '',
            dob: null,
            pinCode: '',
            cityName: '',
            state: '',
            address: '',
            eduQual: 0,
            othersEdu: '',
            accHolderName: '',
            accNumber: '',
            ifsc: '',
            bankName: '',
            accType: 0,
            panImage: '',
            eduCertImage: '',
            nomineeName: '',
            gender: '',
        },
        validate: (values) => {

            let errors = {};

            // -------email validate--------
            if (!values.email) {
                errors.email = 'Please enter email address'
            }
            if (values.email) {
                if (!/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(values.email)) {
                    errors.email = 'Please enter a valid email address';
                }
            }

            return errors
        }
    })


    const calTheme = createTheme({
        typography: {
            fontFamily: [
                'Outfit',
                'Sora'
            ].join(',')
        },
        components: {
            MuiOutlinedInput: {
                root: {
                    "&:-webkit-autofill": {
                        color: 'red'
                    }
                },

            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        width: '350px',
                        borderRadius: '10px',
                        "&::-webkit-scrollbar": {
                            height: "8px",
                            width: '4px',
                            borderRadius: '20px'
                        },
                        "&::-webkit-scrollbar-thumb": {
                            backgroundColor: '#c9c6c6',
                            borderRadius: '20px'
                        }

                    }
                }
            },
            MuiMenu: {
                styleOverrides: {
                    list: {
                        fontFamily: 'Outfit',
                        padding: '0px'
                    }
                }
            }

        }
    })

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    const query = useQuery();


    const handlePanInpChange = (e) => {

        if (

            (
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'png') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpeg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'pdf')
            ) &&
            e.target.files[0].size <= 1 * 1000 * 1000
        ) {
            formik.setFieldValue('panImage', e.target.files[0]);
            setPanFile('');
        } else {
            setPanFile('Please use a valid file type, eg..png, .jpeg,.jpg,.pdf');
            formik.setFieldValue('panImage', '');
            setShowErr(true);

        }


    }
    const handleEducertInpChange = (e) => {
        if (
            (
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'png') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpeg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'jpg') ||
                (e.target.files[0] && e.target.files[0].type.split('/')[1] === 'pdf')
            ) &&
            e.target.files[0].size <= 1 * 1000 * 1000
        ) {
            formik.setFieldValue('eduCertImage', e.target.files[0]);
            setErrObj({ ...errObj, eduFile: "" })
            setEduFile('');
        } else {
            setEduFile('Please use a valid file type, eg..png, .jpeg,.jpg,.pdf');
            setErrObj({ ...errObj, eduFile: "Please use a valid file type, eg..png, .jpeg,.jpg,.pdf" })
            setShowErr(true);
            formik.setFieldValue('eduCertImage', '');
        }
    }


    const handleminDob = () => {
        let date = new Date();
        let month = date.getMonth();

        let dt = date.getDate();
        if (dt < 10) {
            dt = '0' + dt
        }
        let year = date.getFullYear() - 18;
        // setMaxdob(`${year}/${month}/${dt}`);
        setMaxdob(new Date(year, month, dt));

    }

    const checkData = async () => {

        const rrn = query.get('rrn');
        const rpg = query.get('rpg');
        const aid = query.get('aid');
        const bpg = query.get('bpg');

        if (!aid) {
            alert('No Applicant id available');
            navigate('/');
            return
        }

        if (aid) {
            try {
                const { data } = await getApplicationByIdPan(aid);

                console.log(data);

                if (data && Number(data.applicationStatus) !== 4 && Number(rrn) !== 500) {
                    alert('Application status is not rejected');
                    navigate('/');
                    return
                }

                // formik.setFieldValue('initials', data.initial);
                formik.setFieldValue('applicantid', data.applicantid);
                formik.setFieldValue('partnerid', data.partnerid);
                formik.setFieldValue('email', data.email);
                formik.setFieldValue('panNumber', data.pannumber);
                formik.setFieldValue('mobNumber', data.mobileno);
                formik.setFieldValue('firstName', data.firstname);
                formik.setFieldValue('lastName', data.lastname);
                formik.setFieldValue('dob', data.dob);
                formik.setFieldValue('pinCode', data.pincode);
                formik.setFieldValue('cityName', data.city);
                formik.setFieldValue('state', data.state);
                formik.setFieldValue('address', data.address);
                formik.setFieldValue('accNumber', data.accountno);
                formik.setFieldValue('bankName', data.bankname);
                formik.setFieldValue('ifsc', data.ifscCode);
                formik.setFieldValue('accType', data.accounttype);
                formik.setFieldValue('accHolderName', data.accountholdername);
                formik.setFieldValue('branchName', data.bankbranch);
                formik.setFieldValue('eduQual', Number(data.educationQ));
                formik.setFieldValue('othersEdu', data.others);
                formik.setFieldValue('partnername', data.partnername);
                formik.setFieldValue('nomineeName', data.nomineeName);
                formik.setFieldValue('gender', data.gender);


            } catch (error) {
                alert('Something went wrong, please try again later');
                navigate('/');
            }
        }


        if (Number(rrn) === 1) {
            setEmailRej(true);
        }
        if (Number(rrn) === 2) {
            setEduRej(true);
        }
        if (Number(rrn) === 4) {
            setEduRej(true);
        }
        if (Number(rrn) === 3) {
            setPanRej(true);
        }
        if (Number(rrn) === 500) {
            setBulkRej(true);
        }

        const rData = {
            rrn,
            rpg,
            aid,
            bpg
        }

        // sessionStorage.setItem('rData',JSON.stringify(rData));



    }



    const browseBtn = (e) => {

        const panInp = document.getElementById('panCard');
        const eduCertInp = document.getElementById('eduCert');


        if (e.target.name === 'panCard') {
            panInp.click();
        }
        if (e.target.name === 'eduCert') {
            eduCertInp.click();
        }
    }

    const handleEduChange = (e) => {
        setNewEdu(e.target.value);
        // if(e.target.value===formik.values.eduQual){
        //     setErrObj({...errObj,edu:'Please select different option'});
        // }else{
        //     setErrObj({...errObj,edu:''});
        // }
    }

    const handleAccNumberChange = (e) => {
        formik.setFieldValue('accNumber', e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi, ""));
    }

    const handleEmailchange = (e) => {
        setNewEmail(e.target.value);

        if (!/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(e.target.value)) {
            setErrObj({ ...errObj, email: "Please enter a valid email address" })
        }
        else if (e.target.value === formik.values.email) {
            setErrObj({ ...errObj, email: "Please enter new email" })
        } else {
            setErrObj({ ...errObj, email: "" });
        }

    }

    const handleNextStep = async () => {

        const lastActiveAgent = JSON.parse(sessionStorage.getItem('lastActiveAgent'));
        const currentTime = new Date().getTime();
        const sessionTime = 30 * 60 * 1000;
        if (
            lastActiveAgent &&
            ((currentTime - Number(lastActiveAgent)) > sessionTime)

        ) {
            sessionStorage.removeItem('lastActiveAgent');
            alert('session expired,session is valid for 30 Minutes');
            navigate('/');
            return
        }
        sessionStorage.setItem('lastActiveAgent', new Date().getTime());

        if (emailRej) {
            if (!newEmail) {
                setErrObj({ ...errObj, email: "Please provide email address" });
                return
            } else if (newEmail === formik.values.email) {
                setErrObj({ ...errObj, email: "Please provide new email address" });
                return
            }

            if (errObj.email) {
                return
            }
        }


        if (eduRej) {
            if (!newEdu) {
                setErrObj({ ...errObj, edu: "Education is required field" });
                return
            } else if (Number(newEdu) === 5 & !newOtherEdu) {
                setErrObj({ ...errObj, otherEdu: "Please enter education qualification" });
                return
            } else if (!formik.values.eduCertImage) {
                setErrObj({ ...errObj, eduFile: "Please select education file" });
                return
            }

            setErrObj({ ...errObj, edu: "", eduFile: '' });
        }

        if (panRej) {
            if (!formik.values.panImage) {
                setErrObj({ ...errObj, pan: "Please select pan card image" });
                setPanFile('Please select a file to upload');
                // showErr(true);
                return
            }
        }

        if (bulkRej) {
            if (!formik.values.panImage) {
                setErrObj({ ...errObj, pan: "Please select pan card image" });
            } else if (!formik.values.eduCertImage) {
                setErrObj({ ...errObj, eduFile: "Please select pan card image" });
            }

            if (!formik.values.panImage || !formik.values.eduCertImage) {
                return
            }
        }



        // return

        const rrn = query.get('rrn');

        formik.setFieldTouched('firstName', true);
        const redirect = JSON.parse(sessionStorage.getItem('redirect'));


        if (
            !formik.values.firstName ||
            formik.errors.email
        ) {
            formik.setFieldTouched('firstName', true);
            setShowErr(true);
            // return
        }



        const saveData = {
            applicantid: formik.values.applicantid,
            partnerid: formik.values.partnerid,
            partnername: formik.values.partnername,
            pospcode: '',
            email: emailRej ? newEmail : formik.values.email,
            mobileno: formik.values.mobNumber,
            pannumber: formik.values.panNumber,
            initial: formik.values.initials,
            firstname: formik.values.firstName,
            lastname: formik.values.lastName,
            dob: formik.values.dob,
            address: formik.values.address,
            pincode: formik.values.pinCode,
            city: formik.values.cityName,
            state: formik.values.state,
            educationQ: eduRej ? Number(newEdu) : Number(formik.values.eduQual),
            others: Number(newEdu) === 5 ? newOtherEdu : formik.values.othersEdu,
            accountno: formik.values.accNumber,
            ifscCode: formik.values.ifsc,
            bankname: formik.values.bankName,
            bankbranch: formik.values.bankBranch,
            accounttype: formik.values.accType,
            accountholdername: formik.values.accHolderName,
            nomineeName: formik.values.nomineeName,
            gender: formik.values.gender,
            pageno: 5
        }

        const formData = new FormData();
        formData.append("pan", formik.values.panImage ? formik.values.panImage : null);
        formData.append("edu", formik.values.eduCertImage ? formik.values.eduCertImage : null);
        formData.append("applicatid", JSON.stringify({ applicantid: formik.values.applicantid }));


        // return


        console.log('api called');
        console.log(saveData)
        // return

        try {
            await savePosp(saveData);
            if (
                (Number(rrn) === 2) ||
                (Number(rrn) === 3) ||
                (Number(rrn) === 4) ||
                (Number(rrn) === 500)
            ) {

                await uploadFile(formData);
            }
            await updatePosp(formik.values.applicantid, Number(rrn) === 500 ? 2 : 1);

            alert('Application updated successfully');
            navigate('/')
        } catch (error) {
            alert('something went wrong updating application')
            console.log('verify doc err');

        }


        formik.setFieldTouched('firstName', true);
        setShowErr(true);


    }

    useEffect(() => {
        checkData();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        handleminDob();

    }, [])

    return (
        <>
            <Header step={0} />
            <Wrapper>
                <ThemeProvider theme={calTheme}>
                    <Grid container className='mainContainer' spacing={2} justifyContent="center"  >

                        <Grid container item xs={12} md={5} direction='row' spacing={2} >
                            <Grid item>
                                <Heading>
                                    {/* <div className="heading">
                                    <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(3)} />
                                    <p>Become a Hello Zindagi agent</p>
                                </div> */}
                                    <p className='title' >
                                        Update Details
                                        <img className='titleImageDesktop' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                        <img className='titleImageMobile' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                                    </p>
                                </Heading>
                            </Grid>

                            <Grid item xs={12} md={10} >
                                <BasicInfo>
                                    <div className="heading">
                                        <p>Basic Information: </p>
                                        <img 
                                            src={require('../assets/Image/edit-icon.svg').default} 
                                            alt="" 
                                            onClick={()=>{
                                                navigate(`/verify-details?aid=${query.get('aid')}`)
                                            }} 
                                        />
                                    </div>
                                    <div className="content name">
                                        <span className="title">Name: </span> <span className='text' >{`${formik.values.firstName} ${formik.values.lastName}`}</span>
                                    </div>
                                    <div className="content address">
                                        <span className="title">Address: </span> <span className='text' >{`${formik.values.address}, ${formik.values.cityName},${formik.values.state}, ${formik.values.pinCode}`}</span>
                                    </div>
                                    <div className="content email">
                                        <span className="title">Email ID: </span> <span className='text' >{`${formik.values.email}`}</span>
                                    </div>
                                    {
                                        emailRej &&
                                        <Grid item container className="content email" alignItems="center" >
                                            <Grid container item md={11} xs={11} alignItems="center"  >
                                                {/* <Grid item md={3} xs={3} >
                                            <span className="title">Email ID: </span>
                                        </Grid> */}
                                                <Grid item md={11} xs={8} >
                                                    <OutlinedInput
                                                        size='small'
                                                        value={newEmail}
                                                        onChange={handleEmailchange}
                                                        disabled={emailRej ? false : true}
                                                        placeholder="Please enter new email address"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                {/* <span className='text' >{`${formik.values.email}`}</span> */}
                                            </Grid >
                                            {
                                                emailRej &&
                                                <Grid item md={1} xs={1} >
                                                    <img src={require('../assets/Image/edit-icon.svg').default} alt=""
                                                    // onClick={()=>setEmailRej(!emailRej) }
                                                    />
                                                </Grid>
                                            }
                                            {
                                                <p className="errorText">{errObj.email}</p>
                                            }

                                        </Grid>

                                    }
                                    <div className="content dob">
                                        <span className="title">Date of Birth: </span> <span className='text' >{`${moment(formik.values.dob).format("LL")}`}</span>
                                    </div>
                                    <div className="content dob">
                                        <span className="title">Gender: </span> <span className='text' style={{textTransform: 'capitalize'}} >{`${formik.values.gender}`}</span>
                                    </div>
                                    <div className="content mobile">
                                        <span className="title">Mobile Number: </span> <span className='text' >{`${formik.values.mobNumber}`}</span>
                                    </div>
                                    <div className="content eduqualification">
                                        <span className="title">Education Qualification: </span> <span className='text' >
                                            {Number(formik.values.eduQual) === 1 && "Class X"}
                                            {Number(formik.values.eduQual) === 2 && "Class XII"}
                                            {Number(formik.values.eduQual) === 3 && "Graduate"}
                                            {Number(formik.values.eduQual) === 4 && "Post Graduate"}
                                            {Number(formik.values.eduQual) === 5 && formik.values.othersEdu}

                                        </span>
                                    </div>
                                    <div className="content dob">
                                        <span className="title">Nominee Name: </span> <span className='text' >{`${formik.values.nomineeName}`}</span>
                                    </div>
                                </BasicInfo>
                            </Grid>
                            {
                                eduRej &&
                                <Grid item xs={12} md={10}>
                                    <p className='eduTitle' >Educational Qualification</p>
                                    <div className="eduWrap">
                                        <FormControl fullWidth className='eduWrapMobilee' >
                                            <InputLabel >Education Qualification</InputLabel>
                                            <Select
                                                label="Education Qualification"
                                                value={newEdu}
                                                name="eduQual"
                                                onChange={handleEduChange}
                                            >
                                                <MenuItem disabled value={0}>
                                                    <em>Education Qualification</em>
                                                </MenuItem>
                                                <MenuItem value={1}>
                                                    Class X
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Class XII
                                                </MenuItem>
                                                <MenuItem value={3}>
                                                    Graduate
                                                </MenuItem>
                                                <MenuItem value={4}>
                                                    Post Graduate
                                                </MenuItem>
                                                <MenuItem value={5}>
                                                    Others
                                                </MenuItem>
                                            </Select>
                                            {
                                                Number(newEdu) === 5 &&
                                                <TextField
                                                    style={{ marginTop: '7px' }}
                                                    id="outlined-multiline-static"
                                                    label="Specify Education"
                                                    fullWidth
                                                    name="specEduQual"
                                                    value={newOtherEdu}
                                                    // {...formik.getFieldProps('othersEdu')}
                                                    onChange={(e) => setNewOtherEdu(e.target.value)}

                                                />

                                            }
                                        </FormControl>
                                        {
                                            errObj.edu &&
                                            <p className="errorText">{errObj.edu} </p>
                                        }
                                    </div>
                                </Grid>

                            }


                            {
                                panRej &&
                                <Grid item xs={12} md={10}  >

                                    <input type="file" name='panCardImage' accept=".jpeg,.png,.pdf,.jpg" className='hiddenInp' id='panCard'
                                        onChange={handlePanInpChange}
                                    />
                                    <InputLabel f >Upload Pan Card Copy</InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        name='panCard'
                                        readOnly={true}
                                        startAdornment=
                                        {
                                            <InputAdornment position="start">
                                                {formik.values.panImage ?
                                                    <img src={doneIcon} alt="" /> :
                                                    <img src={uploadIcon} alt="" name='panCard' />
                                                }

                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {formik.values.panImage &&
                                                    <>
                                                        <img src={editIcon} alt="" name='panCard' onClick={(e) => browseBtn(e)} />
                                                    </>
                                                }
                                            </InputAdornment>
                                        }
                                        placeholder={formik.values.panImage ? formik.values.panImage.name : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                        onClick={formik.values.panImage ? null : (e) => browseBtn(e)}
                                    />

                                    {

                                        <p className="errorText">{errObj.pan}</p>
                                    }

                                </Grid>

                            }


                            {
                                eduRej &&
                                <Grid item xs={12} md={10}  >
                                    <input type="file" accept=".jpeg,.png,.pdf,.jpg" name='eduCert' className='hiddenInp' id='eduCert' onChange={handleEducertInpChange} />
                                    <InputLabel >Upload Education Certificate</InputLabel>

                                    <OutlinedInput
                                        id="outlined-adornment-weight"
                                        // value={10}
                                        fullWidth
                                        name='eduCert'
                                        readOnly={true}
                                        startAdornment=
                                        {
                                            <InputAdornment position="start">
                                                {formik.values.eduCertImage ?
                                                    <img src={doneIcon} alt="" /> :
                                                    <img src={uploadIcon} name='eduCert' alt="" />
                                                }
                                            </InputAdornment>
                                        }

                                        endAdornment={
                                            <InputAdornment position="end">
                                                {formik.values.eduCertImage &&
                                                    <>
                                                        <img src={editIcon} alt="" name='eduCert' onClick={browseBtn} />
                                                    </>
                                                }
                                            </InputAdornment>
                                        }
                                        placeholder={formik.values.eduCertImage ? formik.values.eduCertImage.name : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                        onClick={formik.values.eduCertImage ? null : browseBtn}
                                    />



                                    <p className="errorText">
                                        {errObj.eduFile}
                                    </p>


                                </Grid>

                            }

                            {
                                bulkRej &&
                                <>

                                    <Grid item xs={12} md={10}  >

                                        <input type="file" name='panCardImage' accept=".jpeg,.png,.pdf" className='hiddenInp' id='panCard'
                                            onChange={handlePanInpChange}
                                        />
                                        <InputLabel f >Upload Pan Card Copy</InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            name='panCard'
                                            readOnly={true}
                                            startAdornment=
                                            {
                                                <InputAdornment position="start">
                                                    {formik.values.panImage ?
                                                        <img src={doneIcon} alt="" /> :
                                                        <img src={uploadIcon} alt="" name='panCard' />
                                                    }

                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {formik.values.panImage &&
                                                        <>
                                                            <img src={editIcon} alt="" name='panCard' onClick={(e) => browseBtn(e)} />
                                                        </>
                                                    }
                                                </InputAdornment>
                                            }
                                            placeholder={formik.values.panImage ? formik.values.panImage.name : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                            onClick={formik.values.panImage ? null : (e) => browseBtn(e)}
                                        />

                                        {showErr &&
                                            <p className="errorText">
                                                {errObj.pan}
                                            </p>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={10}  >
                                        <input type="file" accept=".jpeg,.png,.pdf" name='eduCert' className='hiddenInp' id='eduCert' onChange={handleEducertInpChange} />
                                        <InputLabel >Upload Education Certificate</InputLabel>

                                        <OutlinedInput
                                            id="outlined-adornment-weight"
                                            // value={10}
                                            fullWidth
                                            name='eduCert'
                                            readOnly={true}
                                            startAdornment=
                                            {
                                                <InputAdornment position="start">
                                                    {formik.values.eduCertImage ?
                                                        <img src={doneIcon} alt="" /> :
                                                        <img src={uploadIcon} name='eduCert' alt="" />
                                                    }
                                                </InputAdornment>
                                            }

                                            endAdornment={
                                                <InputAdornment position="end">
                                                    {formik.values.eduCertImage &&
                                                        <>
                                                            <img src={editIcon} alt="" name='eduCert' onClick={browseBtn} />
                                                        </>
                                                    }
                                                </InputAdornment>
                                            }
                                            placeholder={formik.values.eduCertImage ? formik.values.eduCertImage.name : 'Allowed format: jpg,jpeg,png,pdf upto 10 MB'}
                                            onClick={formik.values.eduCertImage ? null : browseBtn}
                                        />


                                        {showErr &&
                                            <p className="errorText">
                                                {errObj.eduFile}
                                            </p>
                                        }

                                    </Grid>

                                </>

                            }



                            <Grid item xs={12} md={10} >
                                {/* <Link to='/success'> */}
                                <Button className='submitBtn' variant="contained"
                                    onClick={handleNextStep}
                                >
                                    Update details
                                </Button>

                                {/* </Link> */}
                            </Grid>

                        </Grid>

                        <Grid container item xs={0} md={5} className='rightHero' >
                            <img src={require('../assets/Image/verify-details.svg').default} alt="" />
                        </Grid>

                    </Grid>
                </ThemeProvider>
            </Wrapper>
        </>
    )
}

export default UpdateDetail

const Wrapper = styled.div`
 padding:0 2%;
 padding-bottom: 2%;
    a{
        text-decoration: none;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        /* margin-top:10px; */
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size:18px;
        :hover{
            .MuiOutlinedInput-notchedOutline{
                border-color: #59BA9B;
                border-width: 1px;
            }
        }
        :-webkit-autofill{
            color:red;
        }
    }
    .MuiOutlinedInput-root.Mui-error{
        .MuiOutlinedInput-notchedOutline{
                border-width: 1px;
                border-color: #ED1C24;
            }
    }
    .MuiOutlinedInput-root.Mui-disabled{
        .MuiOutlinedInput-notchedOutline{
                border-width: 0px;
                border-color: #ED1C24;
            }
    }
    .textInp{
        font-family: 'Outfit';
        font-size: 18px;
        border: 1px solid #BFC0C5;
        border-radius: 10px;
        padding: 15px 13px;
        margin-right: 20px;
        margin-top:20px;
        outline-color: #ED1C2485;
    }
    .panCard,.eduCert{
        background-color: #F1F1F1;
    }

    .panInput{
    /* margin-top:20px; */
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    .MuiOutlinedInput-input{
        text-transform: uppercase;
    }
}
.errorText{
        font-family: 'Outfit';
       color:red;
       font-size:14px;
       margin-left:8px;
       margin-top:3px;
       /* margin:auto; */
   }
    
   
    .dateInput{
        width: 60%;
    }
    .eduTitle{
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        /* margin-top: 5px; */
        color:#434552;
    }
    .MuiFormControlLabel-root{
        border:2px solid #BFC0C5;
        padding:3px 1px;
        border-radius: 8px;
        margin:5px 8px;
        color:#787878;
        padding-right:20px;
    }
    .eduWrap{
        margin-top:15px;
    }
    .eduWrapMobile{
        display:none;
    }
    .submitBtn{
        padding: 9px 60px;
        border:none;
        outline:none;
        background: #2959E5;
        border-radius: 10px;
        color:white;
        font-size: 20px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
        font-family: 'Sora';
        font-style: normal;
        font-weight: normal;
        box-shadow:2px #2959E5;
        :hover{
            background-color: #2959E5;
            box-shadow:2px #2959E5;
        }
    }
    .rightHero{
        justify-content: center;
        margin-top: 80px;
        align-content: baseline;
        img{
            object-fit: contain;
        }
    }

    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: #FCBC30;
                border-width: 1px;
            }
    }
    .MuiInputLabel-outlined.Mui-focused{
        color:#333333;
    }

  
  
    .MuiFormLabel-root.MuiInputLabel-root{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color:#888888;
    }

    .hiddenInp{
        display:none;
    }
    .reupload{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 126.3%;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: #00C2FF;
        border:none;
    }

    @media only screen and (max-width: 600px){
        padding:1% 2%;

        .eduTitle{
            font-size:18px;
        }
        .eduWrapMobile{
            display:inline-block;
        }
        .eduWrapDesktop{
            display:none;
        }

        .MuiOutlinedInput-root{
            font-size:16px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-size:16px;
        }

        .rightHero{
            display:none;
        }
        .submitBtn{
            border-radius:7px;
        }
    }

`
const Heading = styled.div`
padding-top: 40px;
    .heading{
        display:flex;
        align-items: center;
            .MuiSvgIcon-root{
                color:#ED1C24;
                margin-left: -46px;
                cursor: pointer;
            }
      
        p{
            font-family: 'Outfit';
            font-size: 20px;
            padding-left:10px;
            color:#C4C4C4;
        }
    }
    .title{
        font-family: 'Sora';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        margin:10px 0;
        display: flex;
        align-items: center;
        gap:10px;
        color:#434552;
        white-space: nowrap;
        img{
            height:22px;
            object-fit: contain;
        }
    }

    .titleImageMobile{
        display:none;
    }

    @media only screen and (max-width: 600px){
        .title{
            font-size:18px;
        }
        .titleImageMobile{
            display:inline-block;
         }
        .titleImageDesktop{
            display:none;
        }
        .heading{
            display:none;
        }
    }
    
`

const BasicInfo = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    /* width:100%; */
    padding:25px 25px;
    margin-left:3px;
    font-family: 'Outfit';
    font-style: normal;
    color:#434552;
    display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    .heading{
        display:flex;
        justify-content: space-between;
        margin-bottom:25px;
        p{
            font-weight: 600;
            font-size:20px;
        }
        img{
            object-fit: contain;
            width:24px;
            cursor:pointer;
        }
    }
    .content{
        
        font-size:16px;
        margin:8px 0;
        .title{
            font-weight:500;
            white-space: nowrap;
            margin-right:8px;
        }
        .text{
            font-weight: 300;
        }

    }

`
const BankInfo = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border: 1px solid #BEBEBE;
    border-radius: 10px;
    /* width:100%; */
    padding:25px 25px;
    margin-left:3px;
    font-family: 'Outfit';
    font-style: normal;
    color:#434552;
    display:flex;
        flex-direction: column;
        justify-content: space-evenly;
    .heading{
        display:flex;
        justify-content: space-between;
        margin-bottom:25px;
        p{
            font-weight: 600;
            font-size:20px;
        }
        img{
            object-fit: contain;
            width:24px;
            cursor:pointer;
        }
    }
    .content{
        
        font-size:16px;
        margin:8px 0;
        .title{
            font-weight:500;
            white-space: nowrap;
        }
        .text{
            font-weight: 300;
        }

    }

`