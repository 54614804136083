import React from 'react';
import moment from 'moment';

import { Link, Text, View, StyleSheet,Image } from '@react-pdf/renderer';
import headerLogo from "../../assets/Image/CoverSure.png";

const styles = StyleSheet.create({
  container: {
       
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    fontSize: 24,
    fontFamily: 'Lato Bold',
    height:'60',
    objectFit:'contain',
    margin:'auto',
    display: 'flex',
    justifyContent:'center'
  },
  title: {
    fontSize: 11,
    textAlign:'center',
    margin:'20px 0'
  },
  salutation: {
    fontSize: 10,
    display: 'flex',
    margin:'10px 0',
    flexDirection: 'row',
    justifyContent:'space-between',
    marginTop:'10px'
  },
  leftSalu:{
    marginTop:'10px'
    
  },
  rightSalu:{
    marginTop:'10px'
  },
  subject:{
    fontSize: 11,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
    textAlign:'center',
    marginTop:'10px'
  }
});

const Header= ({user}) => (


<View style={styles.container}>
      <View style={styles.detailColumn}>
        <Image 
          style={styles.logo}
          src={headerLogo}
        />
        <Text style={styles.title}>APPOINTMENT LETTER</Text>
      </View>
      <View>
    <View style={styles.salutation}>
          <View style={styles.leftSalu}>
            <Text style={{marginTop:'8px',textTransform:'capitalize'}} > {user.name} </Text>
            <Text style={{marginTop:'8px'}} >Address: {user.address} </Text>
          </View>
          <View style={styles.rightSalu}>
            <Text > { user.applicationDate?user.applicationDate:"" } </Text>
          </View>
      </View>
    </View>
    <View style={styles.subject}>
            <Text >Subject: Your Appointment as a Point of Sales Person for the Company </Text>
    </View>
    
  </View>
);

export default Header;