import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import {TextField,Grid,Button } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { sendOTP } from '../utils/sms';
import { verifyOtp,savePosp,getApplicationByIdPan, panAuth, verifyPhone } from '../api';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import partnerData from '../partnerData.json';
import moment from 'moment';

let controller;
let otpResent=false;

const HeroSection = ({step,setStep,formik}) => {


    const [smsid, setSmsid] = useState('');
    const [lastPan, setLastPan] = useState('');
    const [showErr, setshowErr] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [otpErr, setotpErr] = useState(false);
    const [prevStep,setPrevStep] = useState(0);
    const [panErr, setPanErr] = useState('');
    const [applicationStatus, setApplicationStatus] = useState('');
    const [resentOtp, setResentOtp] = useState(true);

    const onChangeMobile = (e) => {
        formik.setFieldTouched('mobNumber',true);
        formik.setFieldValue('mobNumber',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));
      }

    const onChangeOtp = (e) => {
        setotpErr('')
        formik.setFieldTouched('otp',true);

        formik.setFieldValue('otp',e.target.value.replace(/[a-zA-Z$&/+,:;=?@#|'<>.^*()%!_-]/gi,""));
      }

      const onChangePan=async(e)=>{
        formik.setFieldTouched('panNumber',true);
        // formik.setFieldTouched('mobNumber',true);
        // formik.setFieldTouched('otp',true);

                formik.setFieldValue('panNumber',e.target.value);

                if(!e.target.value){
                    formik.setFieldError('panNumber',"Please enter PAN number");
                }

                if(e.target.value.length<10){
                    formik.setFieldError('panNumber',"Please enter a valid PAN number");
                }

                if(e.target.value.length===10&&!/[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(e.target.value)){
                    formik.setFieldError('panNumber',"Please enter PAN number in correct format")
                }

        if(e.target.value.length===10&&/[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(e.target.value)&& lastPan!==e.target.value){


            try {
                // const {data} = await getApplicationByIdPan('',e.target.value);
                const data = {};
                formik.setFieldTouched('panNumber',true);

                formik.setFieldError('panNumber','');

                // formik.setFieldValue('initials',data.initial || '');
                // formik.setFieldValue('applicantid',data.applicantid || '');
                // formik.setFieldValue('email',data.email || '');
                // formik.setFieldValue('mobNumber',data.mobileno || '');
                // formik.setFieldValue('firstName',data.firstname || '');
                // formik.setFieldValue('lastName',data.lastname || '');
                // formik.setFieldValue('dob',data.dob || '');
                // formik.setFieldValue('pinCode',data.pincode || '');
                // formik.setFieldValue('cityName',data.city || '');
                // formik.setFieldValue('state',data.state || '');
                // formik.setFieldValue('address',data.address || '');
                // formik.setFieldValue('accNumber',data.accountno || '');
                // formik.setFieldValue('bankName',data.bankname || '');
                // formik.setFieldValue('ifsc',data.ifscCode || '');
                // formik.setFieldValue('accType',data.accounttype || '');
                // formik.setFieldValue('accHolderName',data.accountholdername || '');
                // formik.setFieldValue('branchName',data.bankbranch || '');
                // formik.setFieldValue('eduQual',Number(data.educationQ || '') || '');
                // formik.setFieldValue('othersEdu',data.others || '');
                // formik.setFieldValue('partnerName',data.partnername || '');
                // setApplicationStatus(data.applicationStatus || '');

                // if(Number(data.applicationStatus)!==5){
                //     setshowErr(true);
                //     setPanErr("You have already submitted application");
                // }

                // setPrevStep(data.pageno);

                const panA = await panAuth(e.target.value.toUpperCase());
                if(panA.data){
                    if(panA.data.responseCode===102){
                        formik.setFieldError('panNumber',panA.data.responseMessage);
                        setPanErr(panA.data.responseMessage);
                    }
                    if(panA.data.status===1){
                        formik.setFieldValue('firstName',panA.data.data.firstName);
                        formik.setFieldValue('lastName',panA.data.data.lastName);
                        sessionStorage.setItem('dob', moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                        // formik.setFieldValue('dob',moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))

                            setPanErr('');
                        formik.setFieldError('panNumber','');
                    }


                }
                setLastPan(e.target.value);

            } catch (error) {

                // formik.setFieldTouched('panNumber',true);
                // formik.setFieldTouched('mobNumber',true);
                formik.setFieldTouched('otp',true);

                // formik.setFieldValue('initials',0);
                formik.setFieldValue('applicantid',0);
                formik.setFieldValue('email','');
                formik.setFieldValue('mobNumber','');
                formik.setFieldValue('firstName','');
                formik.setFieldValue('lastName','');
                formik.setFieldValue('dob',null);
                formik.setFieldValue('pincode','');
                formik.setFieldValue('cityName','');
                formik.setFieldValue('state','');
                formik.setFieldValue('address','');
                formik.setFieldValue('accNo','');
                formik.setFieldValue('bankName','');
                formik.setFieldValue('ifsc','');
                formik.setFieldValue('accType',0);
                formik.setFieldValue('accHolderName','');
                formik.setFieldValue('branchName','');
                formik.setFieldValue('eduQual',0);
                formik.setFieldValue('othersEdu','');
                formik.setFieldValue('partnerName','');
                formik.setFieldValue('nomineeName','');
                formik.setFieldValue('gender','');
                formik.setFieldValue('managerUserId', null);
                setPrevStep(0);

                try {
                    const panA = await panAuth(e.target.value.toUpperCase());
                    if(panA.data){
                        if(panA.data.responseCode===102){
                            formik.setFieldError('panNumber',panA.data.responseMessage);
                            setPanErr(panA.data.responseMessage);
                        }
                        if(panA.data.status===1){
                            formik.setFieldValue('firstName',panA.data.data.firstName);
                            formik.setFieldValue('lastName',panA.data.data.lastName);
                            sessionStorage.setItem('dob', moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                            // formik.setFieldValue('dob',moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                                setPanErr('');
                        }


                    }

                } catch (error) {
                    setPanErr('Something went wrong,Please try again later');
                }



                console.log('failed API to check DRAFT STATUS')


            }
            setLastPan(e.target.value);
            // if(Number(applicationStatus)!==5){
            //     setshowErr(true);
            //     setPanErr("You have already submitted application");
            // }

        }

      }



    const handleSendOtp =async()=>{
        setotpErr('')
        if(formik.errors.panNumber||panErr){
            setshowErr(true);
            return
        }

        formik.setFieldTouched('mobNumber',true);
        formik.setFieldTouched('otp',true);


        if(otpResent){

            return
        }


        // return



        formik.setFieldValue('otp','');

        if(formik.values.mobNumber.toString().length===10){

            controller = new AbortController();
                try {
                    otpResent=true;
                    const {data} = await sendOTP(Number(formik.values.mobNumber));

                    controller = new AbortController();


                    setResentOtp(false);
                    setSmsid(data.status === 1);
                    otpResent=true;
                    setOtpSent(true);
                    formik.setFieldError('mobNumber',"");

                    setTimeout(()=>{
                        setResentOtp(true);
                        otpResent=false;
                    },30000)

                } catch (error) {
                    console.log('send_sms failed',error);
                    if(JSON.parse(JSON.stringify(error))?.status === 401){
                        alert("It seems you've reached the maximum number of OTP attempts. Please retry after sometime.")
                    }
                    setTimeout(()=>{
                        setResentOtp(true);
                        otpResent=false;
                    },30000)
                }
        }

    }





    const handleNextStep =async()=>{
        const res = await verifyPhone(Number(formik.values.mobNumber), formik.values.panNumber)
        if(res?.data?.userStatus === false){

        formik.setFieldTouched('panNumber',true);
        formik.setFieldTouched('email',true);
        formik.setFieldTouched('mobNumber',true);


        const redirect = JSON.parse(sessionStorage.getItem('redirect'));
        const rejData = JSON.parse(sessionStorage.getItem('rejectedApplicant'));
        const srData = JSON.parse(sessionStorage.getItem('rData'));

        if(rejData.rejPage){
            setPrevStep(Number(rejData.rejPage));
            console.log(rejData.rejPage);
            console.log(Number(rejData.rejPage));
        }

        const partner = partnerData.find(el=>el.partnerName===redirect?.partnerName?.toLowerCase());


        formik.setFieldValue('partneruserid',redirect?.partneruserid);
        formik.setFieldValue('partnerid',partner?.partnerId);


        // if(formik.values.applicantid!==0&& (Number(applicationStatus)!==5 ) ){
        //     setPanErr("You have already submitted application");
        //     setshowErr(true);
        //     return
        // }


        if(srData&&srData.rrn&&srData.aid&&srData.rpg==="0"){
            setStep(5);
        }

        // if(Number(formik.values.applicantid)!==0 && Number(applicationStatus)!==5){
        //     setshowErr(true);
        //     setPanErr("You have already submitted application");
        //     return
        // }

        if(!smsid){
            setshowErr(true);
            formik.setFieldError('mobNumber',"Please send OTP first");
        }



        if(!formik.values.panNumber||
            formik.errors.panNumber||
            formik.errors.email||
            formik.errors.mobNumber||
            formik.errors.otp||
            formik.errors.tnc||
            panErr
            ){
            formik.setFieldTouched('panNumber',true);
            setshowErr(true);
            return
        }

        // const redirect = JSON.parse(sessionStorage.getItem('redirect'));

        const saveData = {
            applicantid:formik.values.applicantid,
            partnerid:partner?partner.partnerId:0,
            partnername:redirect?redirect.partnerName:'',
            email:formik.values.email,
            mobileno:formik.values.mobNumber,
            pannumber:formik.values.panNumber,
            partneruserid:redirect?redirect.partneruserid:'',
            pageno:1
        }



        if(smsid){
            try {
                setotpErr('');
                // Verify OTP then proceed next
                const response =  await verifyOtp(

                    formik.values.mobNumber,
                    formik.values.otp,
                    );
                 if(response?.data?.status === 'FAILED')
                    {
                        setshowErr(true)
                        setotpErr(true);
                        //setotpErr('Invalid OTP, please try again')
                        formik.setFieldError('otp',"Invalid OTP, please try again");
                        //formik.setFieldError('otp',"Invalid OTP, please try again");
                }
                else{
                    //------sending Data to save in db-----
// if(rejData.rejPage==0){
//     setStep(4);
//     return
// }

if(srData&&srData.rrn&&srData.aid&&srData.rpg==="0"){
    setStep(5);
}

                    if(prevStep===0){
                        const {data} = await savePosp(saveData);

                        if(rejData.rejPage==0){
                            setStep(4);
                            return
                        }


                        formik.setFieldValue('applicantid',data.applicationId);

                        setStep(1);
                    }else{
                        setStep(Number(prevStep));
                    }


                } // else close tag



            } catch (error) {
                setotpErr(true);
                if(error.response&&error.response.data&&error.response.data.status==="FAILURE"){
                    formik.setFieldError('otp',"Invalid OTP, please try again");
                }else if(JSON.parse(JSON.stringify(error))?.status === 401){
                    setotpErr('Incorrect mobile number or OTP')
                }else{
                    setotpErr('Something went wrong, Please try again later')
                }
                console.log('otp_verify failed',error.message);
            }
        }
        if(!smsid){
            formik.setFieldError('mobNumber',"Please send OTP first");
        }
// setStep(1);
}else{
    if(res?.data?.userStatus && res?.data?.resumeJourney){
        setStep(res?.data?.userData?.pageno)
        formik.setValues({
            applicantid: res?.data?.userData?.applicantid || 0,
            panNumber: res?.data?.userData?.pannumber || '',
            email: res?.data?.userData?.email || '',
            partnerid: res?.data?.userData?.partnerid || '',
            partnername: res?.data?.userData?.partnerName || '',
            mobNumber: res?.data?.userData?.mobileno || '',
            otp: formik.values.otp,
            tnc: true,
            firstName: formik.values.firstName,
            lastName: formik.values.lastName,
            dob: res?.data?.userData?.dob || null,
            pinCode: res?.data?.userData?.pincode || "",
            cityName: res?.data?.userData?.city || "",
            state: res?.data?.userData?.state || "",
            address: res?.data?.userData?.address || "",
            eduQual: res?.data?.userData?.educationQ || 0,
            othersEdu: res?.data?.userData?.others || "",
            accHolderName: res?.data?.userData?.accountholdername || "",
            accNumber: res?.data?.userData?.accountno || "",
            ifsc: res?.data?.userData?.ifscCode || "",
            bankName: res?.data?.userData?.bankname || "",
            accType: res?.data?.userData?.accounttype || 0,
            panImage: res?.data?.userData?.panfilePath || "",
            eduCertImage: res?.data?.userData?.edufilepath || "",
            partneruserid: res?.data?.userData?.partneruserid || "",
            nomineeName: res?.data?.userData?.nomineeName || "",
            gender: res?.data?.userData?.gender || "",
            managerUserId: res?.data?.userData?.managerUserId || null,
        })
    }else{
        alert("It seems you've already done Registration")
    }
}

    }


    useEffect(()=>{
        // formik.setFieldTouched('panNumber',true);
        const rejData = JSON.parse(sessionStorage.getItem('rejectedApplicant'));
        setPrevStep(Number(rejData.rejPage));
    },[])



    return (
        <Wrapper>
            <HeroText>
                <p className='textBig'>Become a CoverSure POSP</p>
                <p className='textSmall'>You’re just 1 step away from your dream career.</p>
            </HeroText>
            <FormWrapper>
                <Grid container direction={{xs:'column-reverse',md:'row'}}  >
                    {/* <Grid item container xs={8}  > */}
                    <Grid container item xs={12} md={5}  >
                        <SignupForm>
                                <p className="title">
                                    <span className="signup" >Sign-up {'& '} </span> <span className='redFormHeading'> Start Earning today!
                                        <span className="emoji">
                                            <img src={require('../assets/Image/victory-hand.png').default} alt="" />
                                        </span>
                                    </span>
                                </p>
                                <Grid item className="panInput" xs={12} md={12} >
                                    <TextField id="outlined-basic" label="Enter PAN Card Number (AAAAA2222A)"
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{maxLength:10}}
                                    // {...formik.getFieldProps('panNumber')}
                                    value={formik.values.panNumber}
                                    onChange={onChangePan}
                                    />
                                    {
                                        formik.touched.panNumber&&
                                        <p className="errorText">{formik.errors.panNumber||panErr}</p>
                                    }

                                    {/* {
                                        panErr?
                                        <p className="errorText">{panErr}</p>
                                        :
                                        showErr&&
                                        <p className="errorText">{formik.errors.panNumber}</p>
                                    } */}

                                </Grid>
                                <Grid item className="emailInput" xs={12} md={12} >
                                    <TextField id="outlined-basic" label="Enter Email ID"
                                    variant="outlined"
                                    fullWidth
                                    {...formik.getFieldProps('email')}
                                    />
                                    {
                                        // showErr&&formik.errors.email&&
                                        formik.touched.email&&
                                        <p className="errorText">{formik.errors.email}</p>
                                    }
                                </Grid>
                                <Grid className="mobileInp" container item xs={12} md={12} spacing={1}  >
                                        <Grid item xs={8} >
                                            <TextField
                                            label="Mobile Number"
                                            variant="outlined"
                                            inputProps={{maxLength:10}}
                                            fullWidth
                                            name='mobNumber'
                                            onChange={onChangeMobile}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={4}  >

                                            <Button className='otpBtn'
                                            variant="contained"
                                            fullWidth
                                            disabled={formik.errors.mobNumber || formik.values.mobNumber === '' ? true : false}
                                            onClick={handleSendOtp}
                                            >{otpSent &&<DoneIcon style={{color:"rgb(14 125 89)", marginRight: '5px'}} />}  Send OTP</Button>
                                        </Grid>
                                        {
                                            // showErr&&formik.errors.mobNumber&&
                                            formik.touched.mobNumber&&
                                            <p className="errorText errorNum" >{formik.errors.mobNumber}</p>

                                        }

                                </Grid>
                                <Grid className='otpInpCont' item xs={12} md={12} >
                                    <TextField id="outlined-basic"
                                    label="Enter the 6-Digit OTP sent your Mobile"
                                    variant="outlined"
                                    fullWidth
                                    disabled={smsid?false:true}
                                    inputProps={{maxLength:6}}
                                    onChange={onChangeOtp}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.otp}
                                    />
                                    {
                                        showErr&&smsid&&formik.errors.otp&&
                                    <p className="errorText">{formik.errors.otp}</p>
                                    }

                                </Grid>
                                <Grid className="submitCon" container item xs={12} md={12} >
                                    {otpErr&&
                                        <p className="errorText otpErr">{otpErr}</p>
                                    }
                                    <Grid className="terms" item xs={12}>
                                    <div className="termsCheck" >

                                        <Checkbox checked={formik.values.tnc}
                                        checkedIcon={<CheckBoxOutlinedIcon/>}
                                        onChange={(e)=>{
                                            formik.setFieldValue('tnc',e.target.checked)
                                        }} color="default"  />

                                        <a target="_blank" href={'https://app-stag-public.s3.ap-south-1.amazonaws.com/posp/terms_condition/T%26C%2BPolicy%2BCoverSure%2B-%2BPOSP%2BPage.pdf'}  rel="noopener noreferrer">
                                            <p className='termText'>I agree to the <span className='tnc' >Terms & Conditons</span> </p>

                                        </a>
                                    </div>
                                    {
                                        showErr&&formik.errors.tnc&&
                                        <p className="errorText tncErr">{formik.errors.tnc}</p>
                                    }
                                    </Grid>
                                    <Grid item xs={12} >
                                            <Button className='submitBtn'
                                             disabled={
                                                 (formik.errors.panNumber||
                                                    panErr||
                                                    formik.errors.mobNumber||
                                                    formik.errors.email||
                                                    formik.errors.tnc || formik.values.panNumber === '' || 
                                                    formik.values.mobNumber === '' || formik.values.email === '' ||
                                                    formik.values.tnc === false
                                                    )
                                                 ?true:false
                                                 }
                                                    variant="contained"
                                            onClick={handleNextStep}
                                            >
                                                Start Earning
                                            </Button>
                                    </Grid>
                                </Grid>
                        </SignupForm>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item xs={12} md={7} style={{marginLeft:'auto'}}  >
                        <HomeHeroImage>
                            <img src={require('../assets/Image/home-hero.svg').default} alt="hero home" className="homeHero" />
                        </HomeHeroImage>
                    </Grid>

                </Grid>
            </FormWrapper>
        </Wrapper>
    )
}

export default HeroSection

const Wrapper = styled.div`
    padding:0 10%;
    @media only screen and (max-width: 1200px) {
        padding:0 7%;
    }
    a{
        text-decoration: none;
        color:inherit;
    }
    .MuiOutlinedInput-root{
        border-radius: 8px;
        :hover{
            .MuiOutlinedInput-notchedOutline{
                border-color: #59BA9B;
                border-width: 1px;
            }
        }
    }
    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
                border-color: #FCBC30;
            }
    }
    .MuiInputLabel-outlined.Mui-focused{
        color:#333333;
    }
    .MuiFormLabel-root.MuiInputLabel-root{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        color:#888888;
    }
   .MuiOutlinedInput-notchedOutline{
       padding:0 6px;
   }

   .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
                border-color: #FCBC30;
                border-width: 1px;
            }
    }


   .errorText{
       color:#ED1C24;
       font-size:14px;
       margin-left:8px;
   }
   .otpErr{
       text-align:center;
       margin:auto;
       font-size:16px;
   }
   .tncErr{
        text-align:center;
        margin:auto;
        margin-top: -7px;

       padding-bottom:10px;
   }
   .errorNum{
       margin-left:8px;
   }

        @media only screen and (max-width: 600px) {
            padding:1% 4%;
        }
`
const HeroText = styled.div`
padding-top: 40px;
    .textBig{
        font-family: 'Sora';
        font-size: 40px;
        color:#313131;
        font-weight: 700;
    }
    .textSmall{
        font-family: 'Outfit';
        font-size: 24px;
        /* color:#333333; */
        line-height:31.03px;
        padding-top: 20px;
        color:#516370;
        font-weight:300;
    }

    @media only screen and (max-width: 600px) {
        padding-top:10px;
        .textBig{
            font-size: 24px;
        }
        .textSmall{
            font-size: 15px;
        }
    }
`
const FormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
`
const SignupForm = styled.div`
 font-family: 'Outfit';
 font-size: 24px;
 margin-top: 30px;



.title{
    white-space:nowrap;
    display: flex;
    align-items: center;
    color:#434552;
    font-weight:500;
}
.redFormHeading{
    color:#2959E5;
    display: flex;
    align-items: center;
    padding-left: 4px;
    .emoji{
        img{
            height:30px;
        }
    }
}
.textInp{
    font-family: 'Outfit';
    font-size: 18px;
    line-height: 23px;
    border: 1px solid #BFC0C5;
    border-radius: 10px;
    padding: 15px 13px;
    /* margin-right: 20px; */
    outline-color: #FCBC30;
    height:15px;
    }

.panInput{
    margin-top:20px;
    display:flex;
    align-items:center;
    flex-wrap: wrap;
    .MuiOutlinedInput-input{
        text-transform: uppercase;
    }
}
.emailInput{
    margin-top:20px;
}
.mobileInp{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .otpBtn{
        font-size: 18px;
        padding: 12px 13px;
        background: #E6EBEE;
        border-radius: 8px;
        color: #525151;
        outline:none;
        border:none;
        text-transform: capitalize;
        box-shadow: 0px 1px 0px 1px #E6EBEE;
        white-space: nowrap;
        font-family:'Outfit';
        font-style: normal;
        font-weight: 400;
    }
    .Mui-disabled{
        color: #88888873;  
        background: #E6EBEE;
        box-shadow: 0px 1px 0px 1px #e6ebee;
    }
}
.otpInpCont{
    margin-top:20px;
    .Mui-disabled{
        opacity:50%;
    }
}
.submitCon{
    margin-top:20px;
    a{
        text-decoration: none;
    }
    .terms{
        display:flex;
        flex-direction:column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color:#434552;
        margin-bottom: 10px;
        .tnc{
            text-decoration:underline;
            cursor: pointer;
        }

    }
    .termsCheck{
        display:flex;

        align-items: center;
        justify-content: center;
        font-size: 12px;
        color:#434552;
    }
    .submitBtn{
        padding: 10px 60px;
        border:none;
        outline:none;
        background: #2959E5;
        border-radius: 10px;
        color:white;
        font-size: 20px;
        text-align: center;
        width: 100%;
        text-transform: capitalize;
        font-family: 'Sora';
        font-style: normal;
        font-weight: 700;
        box-shadow:0px 0px 2px 0px #2959E5;
        /* box-shadow: 0px -4px 10px 0px #ED1C2440; */

        :hover{
            background-color: #2959E5;
            box-shadow: 4px #2959E5;
        }
    }
    .submitBtn.Mui-disabled{
            opacity: 50%;
        }
}

.MuiOutlinedInput-root{
        border-radius: 8px;
        /* margin-top:10px; */
        font-family: 'Outfit';
        font-style: normal;
        font-weight: normal;
        font-size:18px;
        /* color: #888888; */
    }
    .MuiOutlinedInput-input{
        /* padding: 12.5px 14px; */
    }



@media only screen and (max-width: 600px){
    .title{
       color:#333333;
       font-size:16px;
    }
    .redFormHeading{
        color:#333333;
    }
    .emoji{
        display:none;
    }
    .MuiTextField-root{
        font-size:16px;
    }
    .mobileInp{
        .otpBtn{
            font-size: 16px;
            font-weight: 400;
        }
    }
    .MuiOutlinedInput-root{
            font-size:16px;
        }
        .MuiFormLabel-root.MuiInputLabel-root{
            font-size:16px;
        }
}

`

const HomeHeroImage = styled.div`
    img{
        width:100%;
    }

    @media only screen and (max-width: 600px){
        display:flex;
        padding:5px;
        align-items: center;
        justify-content: center;
        img{
            width:80%;
        }
    }
`