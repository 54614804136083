/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import { createTheme,ThemeProvider } from '@mui/material/styles';

import HeaderAdmin from '../../HeaderAdmin';
import ViewHeading from '../ViewHeading';
import ViewBasicInfo from '../ViewBasicInfo';
import ViewBankInfo from '../ViewBankInfo';
import ViewDoc from '../ViewDoc';
import ApproveReject from '../ApproveReject';
import SaveArea from '../SaveArea';
import {useFormik} from 'formik';
import { useParams,useNavigate } from 'react-router-dom';
import {getApplicationByIdPan,savePosp,updatePosp} from "../../../../api/index";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../redux/actions/loader';

const Index = () => {

  const [accept, setAccept] = useState(0);
  const [reject, setReject] = useState(0);
  const [user, setUser] = useState({userId:0,userRole:0});
  const dispatch = useDispatch()
  const params = useParams();
  const navigate = useNavigate();
  const [updateEducation, setUpdateEducation] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [dataSet, setData] = useState({})
  const getAuthData=()=>{
    const userData = JSON.parse(sessionStorage.getItem('authData'));
    setUser(userData);

  }

    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        components:{
          MuiMenu:{
              styleOverrides:{
                  list:{
                      padding:'0px'
                  }
              }
          }
      }
      })


      const formik = useFormik({
        initialValues:{
          salutation:1,
          firstName:'',
          lastName:'',
          email:'',
          dob:'',
          mobNumber:'',
          pincode:'',
          cityName:'',
          state:'',
          address:'',
          accNo:'',
          bankName:'',
          ifscCode:'',
          accType:1,
          accHolderName:'',
          branchName:'',
          panNo:'',
          partnerName:'',
          pospNumber:'',
          eduQual:1,
          otherEduQual:'',
          approve:0,
          bApprove:'',
          rejReason:0,
          eduDoc:'',
          panDoc:'',
          managerUserId: null,
          nomineeName: '',
          gender: '',
        },
        validate:values=>{
          let errors={};


          return errors;
        }
      })


      const fetchApplication= async()=>{
        try {
          dispatch(toggleLoader(true));
          const {data} = await getApplicationByIdPan(params.penId);
          dispatch(toggleLoader(false));
          setData(data)
          formik.setFieldValue('salutation',data.initial);
          formik.setFieldValue('firstName',data.firstname);
          formik.setFieldValue('lastName',data.lastname);
          formik.setFieldValue('email',data.email);
          formik.setFieldValue('dob',data.dob?moment(data.dob).format("LL"):'');
          formik.setFieldValue('mobNumber',data.mobileno);
          formik.setFieldValue('pincode',data.pincode);
          formik.setFieldValue('cityName',data.city);
          formik.setFieldValue('state',data.state);
          formik.setFieldValue('address',data.address);
          formik.setFieldValue('accNo',data.accountno>0?data.accountno:'');
          formik.setFieldValue('bankName',data.bankname);
          formik.setFieldValue('ifscCode',data.ifscCode);
          formik.setFieldValue('accType',data.accounttype);
          formik.setFieldValue('accHolderName',data.accountholdername);
          formik.setFieldValue('branchName',data.bankbranch);
          formik.setFieldValue('panNo',data.pannumber);
          formik.setFieldValue('eduQual',data.educationQ);
          formik.setFieldValue('otherEduQual',data.others);
          formik.setFieldValue('partnerName',data.partnername);
          formik.setFieldValue('pospNumber',data.pospcode);
          formik.setFieldValue('eduDoc',data.edufilepath);
          formik.setFieldValue('panDoc',data.panfilePath);
          formik.setFieldValue('approve',data.applicationStatus);
          formik.setFieldValue('rejReason',data.rejectionReason);
          formik.setFieldValue('managerUserId', data.managerUserId);
          formik.setFieldValue('nomineeName', data.nomineeName);
          formik.setFieldValue('gender', data.gender);
          setAccept(Number(data.applicationStatus));
          setUpdateEducation(data.educationQ)
          // if(data.applicationStatus>=4){
          // }
          
        } catch (error) {
          console.log('application detail fetch error');
          
        }
      }
      
      const handleApprove=async(appStatus,rejReason)=>{
        if(accept===0&&reject===0){
          alert('please take action, APPROVE OR REJECT TO SAVE')
          return;
        }
        if((reject===2)&& formik.values.rejReason === 0){
          alert('Please select the valid Rejection reason')
          return;
        }
        if((accept===3) && (formik.values.managerUserId === null || formik.values.managerUserId === '')){
          alert('Please Select Manager from the list')
          return;
        }
          try {
            dispatch(toggleLoader(true));
              const {data} = await updatePosp(params.penId,accept,accept===4?formik.values.rejReason:0, accept===3 ? formik.values.managerUserId : '');
              dispatch(toggleLoader(false));
              navigate('/admin/pending');
          } catch (error) {
            alert('Please select the valid Rejection reason');
            console.log('update api failed');
          }
      }


      useEffect(()=>{
        fetchApplication();
        getAuthData();
      },[])

      const submitHandle = async () => {
        setIsEdit(false)
        const finalData = dataSet;
        finalData['educationQ'] = updateEducation
        dispatch(toggleLoader(true));
        await savePosp(finalData);
        dispatch(toggleLoader(false));
        alert('Application updated successfully');
      }

    return (
        <>
        <HeaderAdmin />
        <ThemeProvider theme={theme}>
            <Wrapper>
                    <ViewHeading name={`${formik.values.firstName} ${formik.values.lastName}`} formik={formik} isEdit={isEdit} setIsEdit={setIsEdit} submitHandle={submitHandle} setUpdateEducation={setUpdateEducation}  userRole={user.userRole}/>
                <MainContent>
                        <ViewBasicInfo formik={formik} />
                        <ViewBankInfo formik={formik} />
                        <ViewDoc formik={formik} isEdit={isEdit} setUpdateEducation={setUpdateEducation} updateEducation={updateEducation}/>
                        {
                          user.userRole===2 &&
                          <>
                        <ApproveReject 
                        formik={formik} 
                        accept={accept} 
                        setAccept={setAccept}
                         setReject={setReject}
                         reject={reject}
                           />
                           <SaveArea formik={formik} handleApprove={handleApprove}  />  
                        
                        </>  
                           }
                </MainContent>
                
            </Wrapper>
        </ThemeProvider>
        </>
    )
}

export default Index

const Wrapper = styled.div`
    background-color: #f7f8fb;
    padding-bottom: 50px;
    
`
const MainContent = styled.div`
     width:85%;
    margin:auto;
`