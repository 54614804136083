import React,{useEffect} from 'react';
import styled from 'styled-components';
import { createTheme,ThemeProvider } from '@mui/material/styles';

import HeaderAdmin from '../../HeaderAdmin';
import ViewHeading from '../ViewHeading';
import ViewBasicInfo from '../ViewBasicInfo';
import ViewBaankInfo from '../ViewBankInfo';
import ViewDoc from '../ViewDoc';
import RejectedReason from '../RejectedReason';
import { useParams,useNavigate } from 'react-router-dom';
import {useFormik} from 'formik';
import {getApplicationByIdPan} from "../../../../api/index";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../../../../redux/actions/loader';
const Index = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        components:{
          MuiMenu:{
              styleOverrides:{
                  list:{
                      padding:'0px'
                  }
              }
          }
      }
        
      })


      const formik = useFormik({
        initialValues:{
          salutation:1,
          firstName:'',
          lastName:'',
          email:'',
          dob:'',
          mobNumber:'',
          pincode:'',
          cityName:'',
          state:'',
          address:'',
          accNo:'',
          bankName:'',
          ifscCode:'',
          accType:1,
          accHolderName:'',
          partnerName:'',
          pospNumber:'',
          branchName:'',
          panNo:'',
          eduQual:0,
          otherEduQual:'',
          rejReason:0,
          rejDate:'',
          nomineeName: '',
          gender: '',
        },
        validate:values=>{
          let errors={};
 
          
          return errors;
        }
      })

      const fetchApplication= async()=>{
        try {
          dispatch(toggleLoader(true));
          const {data} = await getApplicationByIdPan(params.rejId);
          dispatch(toggleLoader(false));
          if(data.applicationStatus===1){
            navigate(`/admin/pending/${params.rejId}`);
          }
          formik.setFieldValue('salutation',data.initial);
          formik.setFieldValue('firstName',data.firstname);
          formik.setFieldValue('lastName',data.lastname);
          formik.setFieldValue('email',data.email);
          formik.setFieldValue('dob',data.dob?moment(data.dob).format("LL"):'');
          formik.setFieldValue('mobNumber',data.mobileno);
          formik.setFieldValue('pincode',data.pincode);
          formik.setFieldValue('cityName',data.city);
          formik.setFieldValue('state',data.state);
          formik.setFieldValue('address',data.address);
          formik.setFieldValue('accNo',data.accountno>0?data.accountno:'');
          formik.setFieldValue('bankName',data.bankname);
          formik.setFieldValue('ifscCode',data.ifscCode);
          formik.setFieldValue('accType',data.accounttype);
          formik.setFieldValue('accHolderName',data.accountholdername);
          formik.setFieldValue('branchName',data.bankbranch);
          formik.setFieldValue('panNo',data.pannumber);
          formik.setFieldValue('eduQual',data.educationQ);
          formik.setFieldValue('otherEduQual',data.others);
          formik.setFieldValue('partnerName',data.partnername);
          formik.setFieldValue('pospNumber',data.pospcode);
          formik.setFieldValue('rejReason',data.rejectionReason);
          formik.setFieldValue('eduDoc',data.edufilepath);
          formik.setFieldValue('panDoc',data.panfilePath);
          formik.setFieldValue('approve',data.applicationStatus);
          formik.setFieldValue('rejDate',data.modifiedOn);
          formik.setFieldValue('nomineeName', data.nomineeName);
          formik.setFieldValue('gender', data.gender);
        } catch (error) {
          console.log('application detail fetch error')
          
        }
      }

      useEffect(()=>{
        fetchApplication();
      },[])

    return (
        <>
        <HeaderAdmin />
        <ThemeProvider theme={theme}>
            <Wrapper>
                    <ViewHeading name={`${formik.values.firstName} ${formik.values.lastName}`} formik={formik} />
                <MainContent>
                        <ViewBasicInfo formik={formik} />
                        <ViewBaankInfo formik={formik} />
                        <ViewDoc formik={formik} />
                        <RejectedReason formik={formik} />
                </MainContent>
                
            </Wrapper>
        </ThemeProvider>
        </>
    )
}

export default Index

const Wrapper = styled.div`
    background-color: #f7f8fb;
    padding-bottom: 50px;
    
`
const MainContent = styled.div`
     width:85%;
    margin:auto;
`