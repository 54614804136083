import React from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  container: {
    marginBottom: '10px'

  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    fontSize: 24,
    fontFamily: 'Lato Bold',
    height: '60',
    objectFit: 'contain',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    fontSize: 11,
    marginTop: '10px'
  },
  infoText: {
    fontSize: 11,
    marginTop: '10px'
  },
  infoTextH1: {
    marginLeft: '10px',
    marginTop: '10px',
    fontSize: 12,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  infoTextH2: {
    fontSize: 11,
    marginLeft: '16px',
    marginTop: '10px'
  },
  infoTextH3: {
    fontSize: 11,
    marginLeft: '25px',
    marginTop: '10px'
  },
  salutation: {
    fontSize: 10,
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rightSalu: {

  },
  subject: {
    fontSize: 12,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  }
});

const Intro = () => (
  <View style={styles.container}>
    <View>
      <View style={styles.info}>
        <View>
          <Text style={styles.subject} >
            Code of Conduct:
          </Text>
          <Text style={styles.infoText} >
            As a POSP appointed by the Company, you shall adhere to the following code of conduct prescribed by IRDAI to
            the Company being a corporate agent:
          </Text>
        </View>
        <View>
          <Text style={styles.infoTextH1} >
            I.	General Code of Conduct:
          </Text>
        </View>
        <View>
          <Text style={styles.infoTextH2} >
            1. A Every corporate agent shall follow recognised standards of professional conduct and discharge their
            duties in the interest of the policyholders. While doing so –
          </Text>
          <Text style={styles.infoTextH3} >
            a) conduct its dealings with clients with utmost good faith and integrity at all times;
          </Text>
          <Text style={styles.infoTextH3} >
            b) act with care and diligence;
          </Text>
          <Text style={styles.infoTextH3} >
            c) ensure that the client understands his relationship with the corporate agent and on whose
            behalf the corporate agent is acting;
          </Text>
          <Text style={styles.infoTextH3} >
            d) treat all information supplied by the prospective clients as completely confidential to
            themselves and to the insurer(s) to which the business is being offered;
          </Text>
          <Text style={styles.infoTextH3} >
            e) take appropriate steps to maintain the security of confidential documents in their possession;
          </Text>
          <Text style={styles.infoTextH3} >
            f)	No director of a company or a partner of a firm or the chief executive or a principal officer or a
            specified person shall hold similar position with another corporate agent;
          </Text>
          <Text style={styles.infoTextH2} >
            2.	Every Corporate Agent shall
          </Text>
          <Text style={styles.infoTextH3} >
            a)	be responsible for all acts of omission and commission of its principal officer and every specified
            person;
          </Text>
          <Text style={styles.infoTextH3} >
            b)	ensure that the principal officer and all specified persons are properly trained, skilled and
            knowledgeable in the insurance products they market;
          </Text>
          <Text style={styles.infoTextH3} >
            c)	ensure that the principal officer and the specified person do not make to the prospect any
            misrepresentation on policy benefits and returns available under the policy;
          </Text>
          <Text style={styles.infoTextH3} >
            d)	ensure that no prospect is forced to buy an insurance product;
          </Text>
          <Text style={styles.infoTextH3} >
            e)	give adequate pre-sales and post-sales advice to the insured in respect of the insurance product;
          </Text>
          <Text style={styles.infoTextH3} >
            f)	extend all possible help and cooperation to an insured in completion of all formalities and
            documentation in the event of a claim;
          </Text>
          <Text style={styles.infoTextH3} >
            g)	give due publicity to the fact that the corporate agent does not underwrite the risk or act as an
            insurer;
          </Text>
          <Text style={styles.infoTextH3} >
            h)	enter into agreements with the insurers in which the duties and responsibilities of both are
            defined.
          </Text>
          <Text style={styles.infoTextH2} >
            II.	Pre-sale Code of Conduct:
          </Text>
          <Text style={styles.infoTextH3} >
            3. Every corporate agent or principal officer or a specified person shall also follow the code of conduct
            specified below:
          </Text>
          <Text style={styles.infoTextH3} >
            i.	Every corporate agent/ principal officer/specified person shall:
          </Text>
          <Text style={styles.infoTextH3} >
            a)	identify himself and disclose his registration/certificate to the prospect on demand;
          </Text>
          <Text style={styles.infoTextH3} >
            b)	disseminate the requisite information in respect of insurance products offered for sale
            by the insurers with whom they have arrangement and take into account the needs of
            the prospect while recommending a specific insurance plan;
          </Text>
          <Text style={styles.infoTextH3} >
            c)	disclose the scales of commission in respect of the insurance product offered for sale, if
            asked by the prospect;
          </Text>
          <Text style={styles.infoTextH3} >
            d)	indicate the premium to be charged by the insurer for the insurance product offered for
            sale;
          </Text>
          <Text style={styles.infoTextH3} >
            e)	explain to the prospect the nature of information required in the proposal form by the
            insurer, and also the importance of disclosure of material information in the purchase of
            an insurance contract;
          </Text>
          <Text style={styles.infoTextH3} >
            f)	bring to the notice of the insurer any adverse habits or income inconsistency of the
            prospect, in the form of a Confidential Report along with every proposal submitted to
            the insurer, and any material fact that may adversely affect the underwriting decision of
            the insurer as regards acceptance of the proposal, by making all reasonable enquiries
            about the prospect;
          </Text>
          <Text style={styles.infoTextH3} >
            g)	inform promptly the prospect about the acceptance or rejection of the proposal by the
            insurer;
          </Text>
          <Text style={styles.infoTextH3} >
            h)	obtain the requisite documents at the time of filing the proposal form with the insurer;
            and other documents subsequently asked for by the insurer for completion of the
            proposal;
          </Text>
          <Text style={styles.infoTextH2} >
            ii.	No corporate agent/ principal officer / specified person shall:
          </Text>
          <Text style={styles.infoTextH3} >
            a)	solicit or procure insurance business without holding a valid registration/ certificate;
          </Text>
          <Text style={styles.infoTextH3} >
            b)	induce the prospect to omit any material information in the proposal form;
          </Text>
          <Text style={styles.infoTextH3} >
            c)	induce the prospect to submit wrong information in the proposal form or documents
            submitted to the insurer for acceptance of the proposal;
          </Text>
          <Text style={styles.infoTextH3} >
            d)	behave in a discourteous manner with the prospect;
          </Text>
          <Text style={styles.infoTextH3} >
            e)	interfere with any proposal introduced by any other specified person or any insurance
            intermediary;
          </Text>
          <Text style={styles.infoTextH3} >
            f)	offer different rates, advantages, terms and conditions other than those offered by the
            insurer;
          </Text>
          <Text style={styles.infoTextH3} >
            g)	force a policyholder to terminate the existing policy and to effect a new proposal from
            him within three years from the date of such termination;
          </Text>
          <Text style={styles.infoTextH3} >
            h)  No corporate agent shall have a portfolio of insurance business from one person or one
            organization or one group of organizations under which the premium is in excess of fifty
            percent of total premium procured in any year;
          </Text>
          <Text style={styles.infoTextH3} >
            i)	become or remain a director of any insurance company, except with the prior approval
            of the Authority;
          </Text>
          <Text style={styles.infoTextH3} >
            j)	indulge in any sort of money laundering activities;
          </Text>
          <Text style={styles.infoTextH3} >
            k)	indulge in sourcing of business by themselves or through call centers by way of
            misleading calls or spurious calls;
          </Text>
          <Text style={styles.infoTextH3} >
            l)	undertake multi-level marketing for soliciting and procuring of insurance products;
          </Text>
          <Text style={styles.infoTextH3} >
            m)	engage untrained and unauthorised persons to bring in business;
          </Text>
          <Text style={styles.infoTextH3} >
            n)	provide insurance consultancy or claims consultancy or any other insurance related
            services except soliciting and servicing of insurance products as per the certificate of
            registration.
          </Text>
          <Text style={styles.infoTextH3} >
            o)	Engage, encourage, enter into a contract with or have any sort of arrangement with any
            person other than a specified person, to refer, solicit, generate lead, advise, introduce,
            find or provide contact details of prospective policyholders in furtherance of the
            distribution of the insurance product;
          </Text>
          <Text style={styles.infoTextH3} >
            p)	Pay or allow the payment of any fee, commission, incentive by any other name
            whatsoever for the purpose of sale, introduction, lead generation, referring or finding to
            any person or entity
          </Text>
          <Text style={styles.infoTextH1} >
            III.	Post-Sale Code of Conduct:
          </Text>
          <Text style={styles.infoTextH2} >
            4.	Every Corporate Agent shall:
          </Text>
          <Text style={styles.infoTextH3} >
            a)	advise every individual policyholder to effect nomination or assignment or change of address or
            exercise of options, as the case may be, and offer necessary assistance in this behalf, wherever
            necessary;
          </Text>
          <Text style={styles.infoTextH3} >
            b)	with a view to conserve the insurance business already procured through him, make every
            attempt to ensure remittance of the premiums by the policyholders within the stipulated time,
            by giving notice to the policyholder orally and in writing.
          </Text>
          <Text style={styles.infoTextH3} >
            c)	ensure that its client is aware of the expiry date of the insurance even if it chooses not to offer
            further cover to the client;
          </Text>
          <Text style={styles.infoTextH3} >
            d)	ensure that renewal notices contain a warning about the duty of disclosure including the
            necessity to advise changes affecting the policy, which have occurred since the policy inception
            or the last renewal date;
          </Text>
          <Text style={styles.infoTextH3} >
            e)	ensure that renewal notices contain a requirement for keeping a record (including copies of
            letters) of all information supplied to the insurer for the purpose of renewal of the contract;
          </Text>
          <Text style={styles.infoTextH3} >
            f)	ensure that the client receives the insurer's renewal invitation well in time before the expiry
            date.
          </Text>
          <Text style={styles.infoTextH3} >
            g)	render necessary assistance to the policyholders or claimants or beneficiaries in complying with
            the requirements for settlement of claims by the insurer;
          </Text>
          <Text style={styles.infoTextH3} >
            h)	explain to its clients their obligation to notify claims promptly and to disclose all material facts
            and advise subsequent developments as soon as possible;
          </Text>
          <Text style={styles.infoTextH3} >
            i)	advise the client to make true, fair and complete disclosure where it believes that the client has
            not done so. If further disclosure is not forthcoming it shall consider declining to act further for
            the client;
          </Text>
          <Text style={styles.infoTextH3} >
            j)	give prompt advice to the client of any requirements concerning the claim;
          </Text>
          <Text style={styles.infoTextH3} >
            k)	forward any information received from the client regarding a claim or an incident that may give
            rise to a claim without delay, and in any event within three working days;
          </Text>
          <Text style={styles.infoTextH3} >
            l)	advise the client without delay of the insurer's decision or otherwise of a claim; and give all
            reasonable assistance to the client in pursuing his claim.
          </Text>
          <Text style={styles.infoTextH3} >
            m)	shall not demand or receive a share of proceeds from the beneficiary under an insurance
            contract;
          </Text>
          <Text style={styles.infoTextH3} >
            n)	ensure that letters of instruction, policies and renewal documents contain details of complaints
            handling procedures;
          </Text>
          <Text style={styles.infoTextH3} >
            o)	accept complaints either by phone or in writing;
          </Text>
          <Text style={styles.infoTextH3} >
            p)	acknowledge a complaint within fourteen days from the receipt of correspondence, advise the
            member of staff who will be dealing with the complaint and the timetable for dealing with it;
          </Text>
          <Text style={styles.infoTextH3} >
            q)	ensure that response letters are sent and inform the complainant of what he may do if he is
            unhappy with the response;
          </Text>
          <Text style={styles.infoTextH3} >
            r)	ensure that complaints are dealt with at a suitably senior level;
          </Text>
          <Text style={styles.infoTextH3} >
            s)	have in place a system for recording and monitoring complaints.
          </Text>


        </View>

      </View>
    </View>

  </View>
);

export default Intro;