import React from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  container: {

  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9
  },
  linkColumn: {
    flexDirection: 'column',
    flexGrow: 2,
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  },
  logo: {
    fontSize: 24,
    fontFamily: 'Lato Bold',
    height: '60',
    objectFit: 'contain',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  info: {
    fontSize: 11,
    marginTop: '10px'
  },
  infoText: {
    fontSize: 11,
    marginTop: '10px'
  },
  salutation: {
    fontSize: 10,
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  rightSalu: {

  },
  subject: {
    fontSize: 12,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
    marginTop: '10px'
  }
});

const Duties = () => (
  <View style={styles.container}>

    <View>
      <View style={styles.info}>

        <View>
          <Text style={styles.subject} >
            Duties:
          </Text>
        </View>

        <View style={styles.info} >
          <Text style={styles.infoText} >
            i. As a POSP, you shall adhere to the code of conduct
            prescribed by IRDAI.{" "}
          </Text>
          <Text style={styles.infoText} >
            ii.	Disseminate the correct and requisite information in respect of insurance products offered for sale by the Company and take into account the needs of the Prospect while recommending the products.
          </Text>
          <Text style={styles.infoText} >
            iii. Explain to the prospective customer the nature of information required in the proposal form by the
            insurance company, and also the importance of disclosure of material information in the purchase of an
            insurance product and shall discourage suppression of any information which is relevant to the
            insurance company for the purpose of underwriting.
          </Text>
          <Text style={styles.infoText} >
            iv. Obtain the requisite documents at the time of filing the proposal form with the insurance company; and
            other documents subsequently asked for by the insurance company for completion of the proposal.
          </Text>
          <Text style={styles.infoText} >
            v. Inform promptly to the client about the acceptance or rejection of the proposal by the insurance
            company.
          </Text>
          <Text style={styles.infoText} >
            vi.	If collecting a premium on a policy of insurance on behalf of the Company, you shall deposit with, or
            dispatch by post to, or through digital payments medium to the Company / Insurer, the premium so
            collected in full without deduction of your commission within twenty-four hours of the collections
            excluding bank and postal holidays. Further you shall issue an acknowledgement (as per format
            provided by the Company) on collection of premiums.
          </Text>
          <Text style={styles.infoText} >
            vii. Observe and adhere and also bring the same to the notice of the Prospect/Policy holder, the provisions
            of Section 41 of the Insurance Act, 1938.
          </Text>
          <Text style={styles.infoText} >
            viii.	Provide all collected customer information to the Company and extend cooperation for any
            investigation or information of your sourced customers.
          </Text>
          <Text style={styles.infoText} >
            ix.	Promptly notify the Company of receipt by you or service of any complaint, notice or claim concerning
            or in relation to any matter governed by this appointment and shall promptly deliver a copy to the
            Company.
          </Text>
          <Text style={styles.infoText} >
            x. You shall act solely as an independent contractor, subject to the control and guidance of the Company,
            and as such, shall have control on: all matters, its time and effort in the placement of the Policies
            offered hereunder. Nothing herein contained shall be construed to create the relationship of employer
            and employee between you and the Company.
          </Text>
          <Text style={styles.infoText} >
            xi.	You shall indemnify and hold the Company and its officers, employees harmless from all expenses, costs,
            causes of action, claims, demands, liabilities and damages, including reasonable attorney&#39;s fees,
            resulting from or growing out of any unauthorized act or transaction or any negligent act, omission or
            transaction by you.
          </Text>
          <Text style={styles.infoText} >
            xii. Change of Address. you shall notify Company in writing of any change of address and/or communication
            at least thirty (30) days prior to the effective date of such change.
          </Text>
          <Text style={styles.infoText} >
            xiii.	You shall not engage or employ anyone as canvassers or agents for soliciting the insurance business.
          </Text>
          <Text style={styles.infoText} >
            xiv. You will ensure the compliance of KYC/AML guidelines issued from time to time and obtain the
            necessary documents in this regard.
          </Text>
          <Text style={styles.infoText} >
            xv.	Any financial penalty levied by IRDAI based on the violations and non-compliance by you of the
            applicable laws and regulations shall be borne by you and not the Company. Similarly, in case of any
            suspension, cancellation or withdrawal of license of the Company because of any breaches/non-
            compliance on your account, you shall indemnify the Company for consequential losses specifically
            arising from violation of IRDAI Guidelines, IRDAI Registration of Corporate Agents Regulations, 2015 and
            guidelines related to POSP’s for Insurers (Life, Non-Life & Health) as updated from time to time.
          </Text>
          <Text style={styles.infoText} >
            xvi. In case you wish to work for another company, you shall obtain a no objection certificate from the
            Company and post discontinuance of your service uploaded on IIB you are required to obtain a fresh
            letter from the new insurer/ insurance intermediary in order to act as Point of Sales Person for that
            entity.
          </Text>
        </View>

        <View>
          <Text style={styles.subject} >
            Commission:
          </Text>
          <Text style={styles.infoText} >
            i)	The Company shall pay commission to you through neft in your bank account. Commissions shall be paid
            for policies sourced by you and not for any other policies.
          </Text>
          <Text style={styles.infoText} >
            ii)	The Company shall communicate the commission rates for each product from time to time.
          </Text>
          <Text style={styles.infoText} >
            iii) The commission shall be payable on the issued premium net of taxes i.e. on premium exclusive of GST.
          </Text>
          <Text style={styles.infoText} >
            iv)	The commission shall be processed on a fortnightly basis i.e. cycle 1: 1 st to 15 th of every month and cycle
            2: 16 th to 30 th / 31 st of every month.
          </Text>
          <Text style={styles.infoText} >
            v) The commission shall be paid post completion of freelook period.
          </Text>
          <Text style={styles.infoText} >
            vi)	Incase of any withdrawal / freelook / cancellation of the policy the paid commission shall be recovered
            either through subsequent payable commission or through refund from your side.
          </Text>
          <Text style={styles.infoText} >
            vii) Commission may be put on hold in case of investigation by the Company for fraud, quality of business,
            etc. and would be duly informed to you.
          </Text>
          <Text style={styles.infoText} >
            viii) Commission shall be subject to deductions of tax under the provision of Income-tax Act, 1961 and any
            other applicable law, as amended from time to time.
          </Text>
        </View>

      </View>
    </View>


  </View>
);

export default Duties;