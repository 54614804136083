import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

import Header from "./Header";
import AppointmentTnC from "./AppointmentTnC";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CopyrightStrip from "./CopyrightStrip";

import { getApplicationByIdPan, redirectAssist, updatePosp } from "../api/index";
import moment from "moment";

// PDF generating import

import DemoPdf from "./DemoPdf";
import Appointment from "./AppointmentLetter/index";

const DownloadAppointment = ({ step, setStep }) => {
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [accept, setAccept] = useState(false);
  const [user, setUser] = useState({
    name: "",
    address: "",
    pan: "",
    doa: "",
    pospCode: "",
    applicationDate: "",
  });

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const getDetails = async () => {
    const applicantId = query.get("aid");

    try {
      if (!applicantId) {
        alert("applicant id is missing, naviagting to homepage");
        navigate("/");
        return;
      }

      const { data } = await getApplicationByIdPan(applicantId);

      if (Number(data.applicationStatus) !== 3) {
        alert("application status not approved (3), naviagting to homepage");
        navigate('/')
        return;
      }

      setUser({
        ...user,
        name: `${data.firstname} ${data.lastname}`,
        address: data.address,
        pan: data.pannumber,
        pospCode: data.certificationno,
        doa: moment(data.appointmentDate).format("LL"),
        applicationDate: moment(data.appointmentDate).format("LL"),
      });
    } catch (error) {
      console.log("something went wrong");
      error.response&&error.response.data&&alert(error.response.data.responseMessage);
      navigate('/');
    }
  };

  const acceptConsent = async (val) => {
    const applicantId = query.get("aid");

    // if(val){
    try {
      await updatePosp(applicantId, "", "", 1, 1);
      setAccept(val);
    } catch (error) {
      console.log("consent signed api error");
    }
    // }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getDetails();
  }, []);

  return (
    <>
      <Header step={0} />

      {/* <PDFViewer width={800} height={600} >
                  <Appointment  />
              </PDFViewer> */}

      <Wrapper>
        <Grid
          container
          className="mainContainer content"
          direction={{ md: "column" }}
        >
          <Grid item xs={12} md={5}>
            <div className="heading">
              <ArrowBackIosNewIcon fontSize="large" onClick={() => navigate(`/`)}/>

              <div className="title">
                <CheckCircleOutlineIcon
                  className="tickMobile"
                  style={{ color: "#34CB43E0" }}
                />
                Download your Appointment Letter
                <img
                  className="image1"
                  src={require("../assets/Image/victory-hand.png").default}
                  alt=""
                />
              </div>
            </div>
          </Grid>

          <Grid
            container
            item
            md={12}
            className="dfgh"
            direction={{ md: "row", xs: "column-reverse" }}
            style={{marginTop: '20px'}}
          >
            {/* <div className="heading">
                            <ArrowBackIosNewIcon fontSize='large' onClick={()=>setStep(4)} />
                            <p className='title' > Agent Registration Completed Successfully 
                            <img className='image1' src={require('../assets/Image/verify-details-emoji.png').default} alt="" />
                            </p>
                        </div> */}
            <Grid item container direction={{ md: "row" }} xs={12} md={7}>
              {/* <Grid item md={12} >
                  <p style={{ paddingTop: "40px" }} className='text' >Dear Jane Doe,</p>
                </Grid> */}
              <Grid item md={12} className="termsBox">
                <AppointmentTnC user={user} />
                {/* <PDFViewer 
                  width={500}
                  height={300}
                  borderTopWidth={0}
                  borderLeftWidth={0}
                  borderRightWidth={0}
                  
                 >
                  <Appointment  />
                </PDFViewer> */}
              </Grid>

              {/* <Grid item md={8} className="terms">
                <Checkbox
                  onChange={(e) => acceptConsent(e.target.checked)}
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  color="default"
                />
                <p className="termText">
                  I agree to the{" "}
                  <a
                    href={
                     // require("../assets/document/T&CAgreement.pdf").default
                     'https://insuretech-prod-public-assets.s3.ap-south-1.amazonaws.com/posp/t%26c/T%26CAgreement.f43821f1.pdf'
                    }
                    download
                    target="_blank"
                    rel="noreferrer"
                    className="tnc"
                  >
                    {" "}
                    Terms & Conditons
                  </a>{" "}
                </p>
              </Grid> */}
              <Grid item md={8} xs={12} style={{ margin: "auto", marginTop: '10px' }}>
                {/* {accept ? ( */}
                  <PDFDownloadLink
                    document={<Appointment user={user} />}
                    fileName="Appointment Letter.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button
                          className="submitBtn"
                          variant="contained"
                          fullWidth
                          // onClick={()=>handleDownload()}
                        >
                          <SaveOutlinedIcon style={{ marginRight: "20px" }} />
                          Download
                        </Button>
                      ) : (
                        <Button
                          className="submitBtn"
                          variant="contained"
                          fullWidth
                          onClick={async()=>{const applicantId = query.get("aid");
                            // if(val){
                            try {
                             const res = await updatePosp(applicantId, "", "", 1, 1);
                             if(res?.status === 200){
                               const apiRes = await redirectAssist(res?.data?.certificateNumber)
                               window.open(apiRes?.data?.data, '_blank');
                             }
                            } catch (error) {
                              console.log("consent signed api error");
                            }
                          }}
                        >
                          <SaveOutlinedIcon style={{ marginRight: "20px" }} />
                          Agree & Download
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                  <Grid item md={11} className="terms">
                    <p className="termText" style={{marginTop: '5px'}}>
                      By proceeding further your agree to CoverSure’s POSP{" "}
                      <a
                        href={
                        // require("../assets/document/T&CAgreement.pdf").default
                        'https://app-stag-public.s3.ap-south-1.amazonaws.com/posp/terms_condition/T%26C%2BPolicy%2BCoverSure%2B-%2BPOSP%2BPage.pdf'
                        }
                        download
                        target="_blank"
                        rel="noreferrer"
                        className="tnc"
                      >
                        {" "}
                        Terms & Conditons
                      </a>{" "}mentioned under appointment letter
                    </p>
                  </Grid>
                {/* ) : (
                  <Button
                    className="submitBtn"
                    variant="contained"
                    style={{ opacity: "60%" }}
                    fullWidth
                    // onClick={()=>handleDownload()}
                  >
                    <SaveOutlinedIcon style={{ marginRight: "20px" }} />
                    Download
                  </Button>
                )} */}

                {/* <Button className='submitBtn'
                        variant="contained"
                        fullWidth
                        // onClick={()=>handleDownload()}
                         >
                             <SaveOutlinedIcon style={{marginRight:'20px'}} />
                             Download
                        </Button> */}
              </Grid>
            </Grid>
            <Grid container item xs={0} md={5} className="rightHero">
              <img
                src={require("../assets/Image/appointment.svg").default}
                alt=""
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container item xs={0} md={6} className='rightHero' >
                    <img src={require('../assets/Image/success.png').default} alt="" />
                </Grid> */}
      </Wrapper>
      {/* <CopyrightStrip /> */}
    </>
  );
};

export default DownloadAppointment;

const Wrapper = styled.div`
  background-color: #EEF0FB;
  padding: 0 10%;
  padding-bottom: 2%;
  min-height: calc(86vh - 80px);
  padding-top: 3%;

  a {
    text-decoration: none;
    color: inherit;
  }

  .termsBox {
    height: 60vh;
    overflow: auto;
    padding: 8px 5px;
    ::-webkit-scrollbar {
      height: 8px;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      /* width:50px; */
      background-color: #c9c6c63d;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c9c6c6;
    }
  }

  a {
    text-decoration: none;
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    margin: 10px 0;

    font-family: "Outfit";
  }

  .rightHero {
    display: flex;
    justify-content: center;
    align-content: baseline;
    img {
      object-fit: contain;
      /* padding-top: 50px;   */
      /* height: 66vh; */
    }
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .content {
    padding-top: 35px;
    .kjhkj {
      display: flex;
      font-family: "Outfit";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 160%;
      color: #434552;
      align-items: center;
    }
    .MuiSvgIcon-root {
      /* height: 44px;
            width: 44px; */
      font-size: 26px;
      /* margin-right: 20px; */
    }
    .title {
      font-family: "Sora";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      /* margin:10px 0; */
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 17px;
      white-space: nowrap;
      img {
        height: 30px;
      }
      .tickMobile {
        display: none;
      }
    }
    .text {
      padding: 15px 0;
      font-family: "Outfit";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 126.3%;
      /* padding-top:30px; */
      display: flex;

      color: #434552;
      align-items: center;
    }

    .submitBtn {
      margin: auto;
      margin-top: 6px;
      /* width: 60%; */
      padding: 10px 60px;
      border: none;
      outline: none;
      background: #2959E5;
      border-radius: 8px;
      color: white;
      font-size: 20px;
      text-align: center;

      text-transform: capitalize;
      font-family: "Outfit";
      font-style: normal;
      font-weight: normal;
      box-shadow: 0px 0px 2px 0px #2959E5;
      white-space: nowrap;
      :hover {
        background-color: #2959E5;
      }
      .MuiSvgIcon-root {
        font-size: 30px;
      }
    }

    .heading {
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        color: #2959E5;
        margin-left: -26px;
        cursor: pointer;
      }

      p {
        font-family: "Sora";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        margin: 10px 0;
      }
    }
  }
  .terms {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #4D5970;
    font-family: "Manrope";
    font-style: normal;
    font-weight: normal;
    .tnc {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 1% 2%;
    .rightHero {
      display: none;
      img {
        height: 50vh;
      }
    }
    .termsBox {
      height: 58vh;
    }
    .content {
      p {
        font-size: 16px;
      }
      .title {
        white-space: normal;
        font-size: 18px;
        margin-left: 18px;
        gap: 8px;
        .tickMobile {
          display: inline-block;
        }
      }

      .heading {
        .image1 {
          display: none;
        }
      }
    }
  }
`;
