import React from 'react';
import {Routes,Route,Outlet} from 'react-router-dom';
import Login from './Login';
import PendingPosp from './PendingPosp';
import RejectedPosp from './RejectedPosp';
import ApprovedPosp from './ApprovedPosp';
import ViewPending from './viewPosp/ViewPending';
import ViewRejected from './viewPosp/ViewRejected';
import ViewApproved from './viewPosp/ViewApproved';
import BulkUpload from './BulkUpload/BulkUpload';
import DownloadReport from './DownloadReport';


const AdminHome = () => {
    return (
        <>
        <Routes>
                
                        <Route path='/admin/pending' element={<Login />}  />
                        <Route path='login' element={<Login />} />
                        <Route path='pending'  >
                            <Route path='' element={<PendingPosp />} />
                            <Route path=':penId' element={<ViewPending/>} />
                        </Route>
                        <Route path='rejected'  >
                            <Route path='' element={<RejectedPosp />} />
                            <Route path=':rejId' element={<ViewRejected/>} />
                        </Route>
                        <Route path='approved' >
                            <Route path='' element={<ApprovedPosp />} />
                            <Route path=':appId' element={<ViewApproved/>} />
                        </Route>
                        <Route path='bulk-upload' >
                            <Route path='' element={<BulkUpload />} />
                        </Route>
                        <Route path='download-report' >
                            <Route path='' element={<DownloadReport />} />
                        </Route>
               

        </Routes>
                    
           
            
        </>
    )
}

export default AdminHome
