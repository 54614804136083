import React,{useState,useEffect} from 'react';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



const PospInfo = () => {
  
  let matches = useMediaQuery('(max-width:600px)');
  const [show, setShow] = useState(useMediaQuery('(max-width:600px)'));

  console.log("media query",useMediaQuery('(max-width:600px)'),new Date().getTime());
  // console.log("show value",show);
  
  const handleShow =()=>{
    console.log("matches",matches);
  }

  const handleReadmore=()=>{
    console.log("handle read more clicked")
    setShow(!show);
    if(matches){
      matches=false
    }else{
      matches=true
    }
    // console.log(matches)
  }
 
  // useEffect(()=>{
  //   // handleShow();
    
  // },[matches])

 

    return (
      <Wrapper>
        <Heading>
          <p>What is a POSP?</p>
        </Heading>
        <Collapse in={!show} collapsedSize={150}>
        <PospText>
          <p>
          IRDAI as part of its developmental agenda has rolled out the POSP (Point of Sales Person) model in order to facilitate the growth and penetration of insurance business in India by providing easy access to insurance. 
          </p>
          <p>
          POSP means an individual who is 18 years of age, minimum qualification of Std.X, has undergone the required training and passed the examination as specified by IRDAI. <br/>The POSP can sell products which are very simple to understand, and every benefit is predefined and disclosed upfront.
          </p>
        </PospText>
      </Collapse>
      <div className="readMore" onClick={()=>handleReadmore()} >
        {
          !show?
          <div className="readMoreItem">
            <p>
            Read Less 
            </p>
          <KeyboardArrowUpIcon />
          </div>
          :
          <div className="readMoreItem" >
            <p className="readMoreItem">
            Read More 
            </p>
          <KeyboardArrowDownIcon />
          </div>
        }
      </div>
      </Wrapper>
    );
}

export default PospInfo

const Wrapper = styled.div`
    color:#434552;
    background-color: #EEF0FB;
    padding: 65px 8%;
    margin: 80px 0;
    .readMore{
      display:none;
      font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 129.3%;
    letter-spacing: 0.025em;
    color: #2959E5;
    text-align: center;
    /* padding-top: 15px; */
    
    }

    @media only screen and (max-width: 600px){
      padding: 41px 8%;
      margin: 30px 0;
      background-color: white;
      .readMore{
        display:block;
        .readMoreItem{
          text-transform: uppercase;
      display:flex;
      justify-content: center;
    }
      }
    }
`
const Heading = styled.div`
    p{
        font-family: 'Sora';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        text-align:center;
        color:#434552;
    }
    @media only screen and (max-width: 600px){
      p{
        font-size:18px;
      }
    }
`
const PospText = styled.div`
    
    p{
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        margin:40px 0;
        color:#516370;
        
    }

    @media only screen and (max-width: 600px){
      
      p{
        font-size: 16px;
        margin: 22px 0;
      }
    }

`