
import {getAllApplicationByStatus} from './../../api/index';
import { FETCH_DATA } from '../constants';
import { toggleLoader } from './loader';

export const getApplication=()=>async(dispatch)=>{
    try {
        dispatch(toggleLoader(true));
        const {data} = await getAllApplicationByStatus(0);
        dispatch(toggleLoader(false));
        dispatch({type:FETCH_DATA,payload:data.applications});
    } catch (error) {
        console.log('getAllApplicationByStatus 0 api error')
    }
}