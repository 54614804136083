import {getAllPospMaster,getAllApplicationByStatus} from './../../api/index';
import { GET_MASTER_LIST,FETCH_DATA } from '../constants';
import { toggleLoader } from './loader';


export const getMaster =()=>async(dispatch)=>{
    try {
        dispatch(toggleLoader(true));
        const {data} = await getAllPospMaster();
        dispatch(toggleLoader(false));
        dispatch({type:GET_MASTER_LIST,payload:data});
        
    } catch (error) {
        console.log('master lis api error');
    }
}

