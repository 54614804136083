/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import HeaderAdmin from './HeaderAdmin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { Grid, ListItemText } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTable, usePagination } from "react-table";

import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { generateReport, getReportList } from '../../api';
import { useDispatch } from 'react-redux';
import Pagination from './Pagination';
import { NewDateRangeComponent } from './newDateRange';
import Checkbox from '@mui/material/Checkbox';
import refresh from '../../assets/Image/refresh.svg'
import { toggleLoader } from '../../redux/actions/loader';
import '../../App.css';
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#0085FF',
    backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#0085FF',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}
const DownloadReport = ({ showSearch, setShowSearch }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentDate = new Date();
    const [dateRange, setDateRange] = useState({
        start: currentDate,
        end: currentDate,
    });
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [listing, setListing] = useState([])
    const [isGenerated, setIsGenerated] = useState(false)
    const theme = createTheme({
        typography:{
          fontFamily:[
            'Montserrat',
           
          ].join(',')
        },
        
      })

    const checkLogin = () => {
        const authData = sessionStorage.getItem('authData');

        if (!authData) {
            navigate('/admin/login');
        }
    }

    const getListing = async () => {
        setIsGenerated(true)
            try {
                dispatch(toggleLoader(true));
                const res = await getReportList()
                if(res?.status === 200){
                console.log('res', res)
                setListing(res?.data)
                dispatch(toggleLoader(false));
                }
            } catch(error) {
                dispatch(toggleLoader(false));
            }
    }

    useEffect(()=>{},[isGenerated])
    const COLUMNS = [
        {
            Header: 'Report ID',
            accessor: 'id',
            disableSortBy: true,
        },
        {
            Header: () => (
                <>
                    Requested on
                    
                </>
            ),
            accessor: 'created_at',
            disableSortBy: false,
        },

        {
            Header: () => (
                <>
                    Status
                    
                </>
            ),
            accessor: 'status',
            disableSortBy: false
        },
        {
            Header: () => (
                <>
                    Rows 
                    
                </>
            ),
            accessor: 'rows_count',
            disableSortBy: false,
        },
        {
            Header: () => (
                <>
                    Format
                    
                </>
            ),
            accessor: 'format',
            disableSortBy: false,
            
        },
        {
            Header: () => (
                <>
                    Raised by
                    
                </>
            ),
            accessor: 'raised_by',
            disableSortBy: false,
        },
        {
            Header: () => (
                
                    <div className='refresh-button' onClick={getListing} >
                    <img src={refresh} alt="" style={{marginRight: '5px'}}/>
                    Refresh
                </div>
            ),
            accessor: 'filepath',
            disableSortBy: true,
            Cell: (props) =>(
                
                props?.row?.original?.filepath ? <a href={props?.row?.original?.filepath} target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small" style={{
                        padding: '6px 16px'
                    }}>Download</Button>
                </a> : <></>

            )
        }
    ]


    const columns = useMemo(() => COLUMNS, [showSearch]);

    const tableInstance = useTable({
        columns: columns,
        data: listing,
    }, usePagination);

    const { getTableProps, getTableBodyProps,
        headerGroups,
        rows,
        page,
        pageCount,
        pageOptions,
        setPageSize,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        prepareRow,
        state,
    } = tableInstance;


    const { pageIndex, pageSize } = state;

    useEffect(() => {
        checkLogin();
        getListing()
    }, [])

    const renderDateInput = (
        label,
        val,
        handleFun,
    ) => {
        return (
            <NewDateRangeComponent
                name="dateRange"
                value={val}
                label={label}
                onChange={handleFun}
            />
        );
    };

    const handleDateRangeChange = (
        name,
        value,
    ) => {
        setDateRange(value);
    };

    const list = [
        {
            id: 1,
            name: 'Pending Data Approval'
        },
        {
            id: 2,
            name: 'Pending Business Approval'
        },
        {
            id: 3,
            name: 'Approved POSPs'
        },
        {
            id: 4,
            name: 'Rejected POSPs'
        },
    ]

    const handleToggle = (value, label) => {
        const currentIndex = selectedIds.indexOf(value);
        const currentLabelIndex = selectedLabels.indexOf(label);
        const newChecked = [...selectedIds];
        const newLabelChecked = [...selectedLabels];
        if (currentIndex === -1) {
            newChecked.push(value);
            newLabelChecked.push(label);
        } else {
            newChecked.splice(currentIndex, 1);
            newLabelChecked.splice(currentLabelIndex, 1);
        }
        setSelectedLabels(newLabelChecked);
        setSelectedIds(newChecked);
    };


    const reportGenerated = async () => {
        try {
            dispatch(toggleLoader(true));
              const res = await generateReport({
                raisedBy: JSON.parse(sessionStorage.getItem('authData'))?.userId,
                from: moment(dateRange?.start).format('YYYY-MM-DD 00:00:00'),
                to: moment(dateRange?.end).format('YYYY-MM-DD 00:00:00'),
                pendingDataApproval: selectedIds?.includes(1) ? 1 : 0,
                pendingBusinessApproval: selectedIds?.includes(2) ? 2 : 0,
                approvedPOSP: selectedIds?.includes(3) ? 3 : 0,
                rejectedPOSP: selectedIds?.includes(4) ? 4 : 0,
              });
              
                dispatch(toggleLoader(false));
                
                getListing()
              
          } catch (error) {
            dispatch(toggleLoader(false));
            console.log('update api failed');
          }
    }

    return (
        <>
            <HeaderAdmin />

                <ThemeProvider theme={theme}>

                    <Wrapper enter={require('../../assets/Image/enter.svg').default} >
                        <Grid container className="filter-container" spacing={2}>

                            <Grid
                                item
                                className="filter-box "
                                xs={12}
                                sm={4}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                <div className='filter-heading'>Select Application Date Range</div>

                                <div>{renderDateInput('Raised on', dateRange, handleDateRangeChange)}</div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={5}
                                lg={6.2}
                                xl={4}
                                className="filter-box"
                            >
                                <div className='filter-heading'>Select Application Status</div>
                                <div className='listing-view'>
                                    {list.map(({ id, name }) => {
                                        return (
                                            <MenuItem key={id} onClick={() => handleToggle(id, name)}>
                                                <BpCheckbox checked={selectedIds.indexOf(id) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        );
                                    })}
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={2}
                                md={3}
                                lg={2}
                                xl={2}
                                className="filter-box"
                            >
                                <Button className='generate-button' onClick={reportGenerated}>Generate Report</Button>
                            </Grid>
                        </Grid>
                        <TableContainer component={Paper} style={{ backgroundColor: '#F7F8FB', width: '100%' }} >
                            <Table {...getTableProps()} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#EBEEF2', fontWeight: '600', paddingBottom: '0' }} >

                                    {headerGroups.map((headerGroup) => (
                                        <TableRow {...headerGroup.getHeaderGroupProps()} style={{ height: '63px',  }} >
                                            {
                                                headerGroup.headers.map(column => (
                                                    <TableCell align="left" {...column.getHeaderProps(column.getSortByToggleProps)}
                                                        style={{
                                                            padding: '0px 0px',
                                                            paddingLeft: '16px',
                                                            width: '150px'
                                                        }}
                                                    >
                                                        {column.render('Header')}

                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    ))}

                                </TableHead>


                                {rows.length !== 0 ?
                                    <TableBody {...getTableBodyProps()} >
                                        {
                                            page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        {...row.getRowProps()} >
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <TableCell align="left" {...cell.getCellProps()}
                                                                    style={{
                                                                        padding: '12px 5px 12px 16px',
                                                                        fontWeight: '500',
                                                                        color: '#333'
                                                                    }}
                                                                >
                                                                    {cell.render('Cell')}
                                                                </TableCell>
                                                            )
                                                        })}
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody> :

                                    <TableRow >
                                        <TableCell colSpan={6} style={{ textAlign: 'center' }} >
                                            No Record Found
                                        </TableCell>
                                    </TableRow>
                                }

                            </Table>
                        </TableContainer>
                        {
                            rows.length > 0 &&
                            <Pagination
                                page={page}
                                nextPage={nextPage}
                                previousPage={previousPage}
                                pageCount={pageCount}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                pageOptions={pageOptions}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                rows={rows}
                                pageIndex={pageIndex}
                            />
                        }
                    </Wrapper>
                </ThemeProvider>
        </>
    )
}

export default DownloadReport

const Wrapper = styled.div`
    background: #F7F8FB;
    height: auto;
    padding: 20px;
    .filter-container{
        padding: 0px 0px 50px;
        width: 100% !important;
        margin-left: 0 !important;
        justify-content: center;
        .filter-box{
            display: flex;
            flex-direction: column;
            justify-content: end;
            .generate-button{
                background: #0966D2 !important;
                color: white !important;
                padding: 10px 16px !important;
                text-transform: capitalize !important;
                font-size: 14px;
                font-weight: 600;
                font-family: Outfit;
                width: fit-content;
                border-radius: 6px;
                margin-left: 10px;
            }
            .filter-heading{
                font-size: 14px;
                font-weight: 600;
                font-family: Outfit;
                color: #434552;
                padding-bottom: 5px;
                padding-left: 4px;
            }
            .listing-view{
                display: flex;
                flex-wrap: wrap;
                box-shadow: 0px 14px 10px 0px #BDC4D066;
                background: #FFFFFF;
                border-radius: 12px;
                width: fit-content;
                .MuiTypography-root{
                    font-family: Outfit;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #434552;
                }
                .MuiMenuItem-root{
                    padding-left: 0 !important;
                    padding-right: 5px !important;
                }
            }
        }
    }
    a{
        text-decoration:none;
        color:inherit;
    }
    ::-webkit-scrollbar {
        height:8px;
        width:8px;
    }
    ::-webkit-scrollbar-track {
        height:8px;
        width:8px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #acacac;
        border-radius: 5px; 
    }
    .searchCont{
        display:flex;
        justify-content: space-evenly;
        align-items: center;
        border-bottom: 0;
    }
    .searchCont{
        padding-bottom:0;
        img{
            width:20px;
        }
    }
    .searchCont .searchicon{
        background:#d8dde3;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        max-width: 60px;
        .MuiSvgIcon-root{
            font-size:28px;
        }
    }
    .searchCont .MuiGrid-root.MuiGrid-item{
        padding:0px;
        padding:10px 0px 4px 0px;
    }
    .MuiButton-contained{
        background: #0085FF;
        border-radius: 5px;
        text-transform: none;
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 126.3%;
        text-align: center;
        color: #FFFFFF;
        /* width: 72%; */
        padding: 2px 0px;
        :hover{
            background: #0056A5;
        }
    }
    .searchInput{
        padding:10px 10%;
        background-color: #D8DDE3;
        .MuiTableRow-root{
            background-color: #D8DDE3;
        }
        .MuiOutlinedInput-root{
            background-color: white;
            border-radius: 32px;
            outline:none;
            border:none;
            padding:0 30px;
            .MuiOutlinedInput-notchedOutline{
                border:1px solid #d8dde3;
            }

            .MuiOutlinedInput-input{
                padding-right:300px;
            }
            :after{
                /* content:'press Enter to Search  ↵ '; */
                content: ${props => `url(${props.enter})`};
                position:absolute;
                display:block;
                color:red;
                right:170px;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: #727B85;
            }
        }
    }

    .MuiTableRow-root.MuiTableRow-head{
        background: rgb(235, 238, 242);
        height: calc(100vh - 200px)
        .MuiTableCell-root{
            font-weight:600;
            color: #333;
            font-family: Montserrat;
            font-size: 14px;
        }
    }

    .MuiMenuItem-root:hover{
        border-radius: 12px;
    }

`