import React from 'react';

import { Link, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import logo from '../../assets/Image/CoverSure.png';


const styles = StyleSheet.create({
  container: {

  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9
  },
  linkColumn: {
    flexDirection: 'row',
    alignItems: 'center'

  },
  logoFooter: {
    fontSize: 21,
    height: '50',
    objectFit: 'contain',
    flexDirection: 'row',

  },
  info: {
    fontSize: 11,
    margin: '15px 0'
  },
  infoText: {
    fontSize: 11,
    margin: '5px 0'
  },
  infoTextH1: {
    marginLeft: '10px',
    fontSize: 12,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  },
  infoTextH2: {
    fontSize: 11,
    marginLeft: '16px',
    marginTop: '10px'
  },
  infoTextH3: {
    fontSize: 11,
    marginLeft: '25px',
    marginTop: '10px'
  },
  salutation: {
    fontSize: 10,
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  rightSalu: {

  },
  subject: {
    fontSize: 12,
    color: 'black',
    textDecoration: 'none',
    fontFamily: 'Lato Bold',
  }
});

const Condidentiality = () => (
  <View style={styles.container}>
    <View style={styles.detailColumn}>
<br/>
      <Text style={styles.subject} >
        Confidentiality:
      </Text>

      <Text style={styles.info}>
        You shall maintain strict confidentiality of the information received from any Prospect/Policy holder and/or the
        Company in the course of business (hereinafter referred to as “Confidential Information”) and ensure the
        following:
      </Text>
      <Text style={styles.info}>
        a. You agree that the Confidential Information is to be considered confidential and proprietary to the Company
        and you shall hold the same in confidence, and shall not use the Confidential Information other than for the
        purposes of business with the Company, and shall disclose it only to its officers, directors, or employees of the
        Company with a specific need to know. You shall not disclose, publish or otherwise reveal any of the
        Confidential Information received from the Company and/or the Prospect to any other third party whatsoever
        except with the specific prior written authorisation of the Company.
      </Text>
      <Text style={styles.info}>
        b. The Confidential Information furnished in tangible form shall not be duplicated by you except in the course of
        business. Upon the request of the Company, you shall return all the Confidential Information received in written
        or tangible form, including copies, or reproductions or other media containing such Confidential Information,
        within ten (10) days of such request. Any documents or other media developed by you containing Confidential
        Information may be destroyed by you. You shall provide a written certificate to the Company regarding
        destruction within ten (10) days thereafter.
      </Text>
    </View>
    <View style={styles.linkColumn}  >
      <Text style={styles.info} >
        For Claycove23 Insurance Tech Private Limited
      </Text>

    </View>
    <View>
      <Text style={styles.subject} >
        Rohan Gaikwad
      </Text>
      <Text style={styles.subject} >
        Principal Officer
      </Text>
    </View>
    <View>
    </View>


  </View>
);

export default Condidentiality;