import { LOADER } from "../constants";
export default function loader(state = { isLoading: false }, action) {
  switch (action.type) {
    case LOADER: {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    default:
      return { ...state };
  }
}
