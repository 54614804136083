import React,{useState} from "react";
import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Controller, Scene } from 'react-scrollmagic';

const SignupDemo = () => {

  const [active,setActive] = useState(0);
  const [mactive, setMactive] = useState(1);


window.onscroll=()=>{
  console.log(window.scrollX)
  if(window.scrollY>1770&&window.scrollY<1886){
    setActive(1);
  }
  if(window.scrollY>1900&&window.scrollY<2050){
    setActive(2);
  }
  if(window.scrollY>2060&&window.scrollY<2170){
    setActive(3);
  }
  if(window.scrollY>2220&&window.scrollY<2360){
    setActive(4);
  }
 
}

const handleScrollx =(e)=>{

  if(e.target.scrollLeft>0){
    // console.log(e.target.scrollWidth/e.target.scrollLeft);

    if((e.target.scrollWidth/e.target.scrollLeft)<600&&(e.target.scrollWidth/e.target.scrollLeft)>60){
      setMactive(1)
    }

    if((e.target.scrollWidth/e.target.scrollLeft)<60&&(e.target.scrollWidth/e.target.scrollLeft)>15){
      setMactive(2)
    }
    if((e.target.scrollWidth/e.target.scrollLeft)<15&&(e.target.scrollWidth/e.target.scrollLeft)>3.5){
      setMactive(3)
    }
    if((e.target.scrollWidth/e.target.scrollLeft)<3.5&&(e.target.scrollWidth/e.target.scrollLeft)>2.6){
      setMactive(4)
    }

  }
  // console.log({
  //   scrollLeft:e.target.scrollLeft,
  //   scrollWidth:e.target.scrollWidth,
  //   offsetWidth:e.target.offsetWidth,
  // })
}




  return (
    <Wrapper bg1={require('../assets/Image/white-star1.svg').default} bg2={require('../assets/Image/white-star2.svg').default}  >
      
      <Controller>
          <Scene duration={350} pin triggerHook={0.15} indicators={false} >
      <div>
            
      <Heading  >
        <p>Become a POSP with CoverSure in just 4 simple steps?</p>
      </Heading>

      <ContentCont onScroll={(e)=>handleScrollx(e)} >
        
        <TimelineWrapper
         bg2={require('../assets/Image/white-star2.svg').default}
         activeSpin={require('../assets/Image/spin-circle.gif').default}
          >
          <span className="bg2" ></span>

          <div className="stepDesktop">
              <Stepper orientation="vertical"  >
                <Step active={active===1?true:false} expanded={true} onClick={()=>console.log(window.scrollY)} style={{paddingRight: '0'}}>
                  <StepLabel className={active===1?"stepLevel":""} sx={{color:'red'}} >Sign-up</StepLabel>
                  <StepContent>
                    <p className={active===1?"activeTitle title":'title'}>
                      Sign up by filling our POSP form given above, and our team will
                      get back to you shortly
                    <img src={require('../assets/Image/smile-emoji.png').default} alt="" />
                    </p>
                  </StepContent>
                </Step>
                <Step active={active===2?true:false} expanded={true} >
                  <StepLabel className={active===2?"stepLevel":""} >Complete your training</StepLabel>
                  <StepContent sx={{fontSize:'20px'}} >
                    <p className={active===2?"activeTitle title":'title'}>
                      Complete your 15 hours training with us
                    <img src={require('../assets/Image/scroll-emoji.png').default} alt="" />
                    </p>
                  </StepContent>
                </Step >
                <Step active={active===3?true:false} expanded={true} >
                  <StepLabel className={active===3?"stepLevel":""} >Complete your exam</StepLabel>
                  <StepContent>
                    <p className={active===3?"activeTitle title":'title'} >
                      Complete the prescribed examination ️
                    </p>
                  </StepContent>
                </Step>
                <Step active={active===4?true:false} expanded={true} >
                  <StepLabel className={active===4?"stepLevel":""} >Sign the agreement</StepLabel>
                  <StepContent style={{borderLeft:'none'}} >
                    <p className={active===4?"activeTitle title":'title'}>
                    Sign the agreeement with us and start your journey of becoming a millionaire! ✍️

                    </p>
                  </StepContent>
                </Step>
              </Stepper>
          </div>
       
          
          <div className="stepMobile"  >
                <Stepper alternativeLabel  >
                      <Step active={mactive===1?true:false} expanded={true}>
                          <StepLabel className="stepLevel" >
                              Sign-up
                              <p className='title'>
                              Sign up by filling our POSP form given above, and our team will
                              get back to you shortly.😊
                            </p>
                          </StepLabel>
                          
                      </Step>
                      <Step active={mactive===2?true:false} expanded={true}  >
                          <StepLabel className="stepLevel" >Complete your training
                          <p className='title'>
                            Complete your 15 hours training with us
                          </p>
                          </StepLabel>
                          
                      </Step >
                      <Step active={mactive===3?true:false} expanded={true}  >
                          <StepLabel className="stepLevel" >Complete your exam
                          <p className='title'>
                            Complete the prescribed examination 
                          </p>
                          </StepLabel>
                          
                      </Step>
                      <Step active={mactive===4?true:false} expanded={true}  >
                          <StepLabel className="stepLevel" >Sign the agreement
                            <p className='title'>
                              Sign the agreeement with us and start your journey of becoming a millionaire!
                            </p>
                          </StepLabel>
                          
                      </Step>
                  </Stepper>

          </div>
        </TimelineWrapper>
        <Demo>
            <img src={require('../assets/Image/sign-up-demo.gif').default} alt="" />
        </Demo>
      </ContentCont>
      </div>
      </Scene>
      </Controller>
    </Wrapper>
  );
};

export default SignupDemo;

const Wrapper = styled.div`
    background-color: #EDEDED;
    background-image: url(${props=>props.bg1});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 200px;
    padding: 70px 0;

    
    @media only screen and (max-width: 600px){
      padding: 20px 7px;
      overflow: auto;
      background-image: none;
      background-color: #fff;
    }
`;
const Heading = styled.div`
  z-index: 999;
  p {
    font-family: "Sora";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    color:#434552;
  }
  @media only screen and (max-width: 600px){
    p{
      font-size: 16px;
      text-align: left;
    }
  }
`;
const ContentCont = styled.div`
    display: flex;
    padding: 58px 90px;
    justify-content: space-evenly;
    
    @media only screen and (max-width: 600px){
      padding:12px 6px;
      overflow: auto;
      justify-content: flex-start ;
    }
`;
const TimelineWrapper = styled.div`
   
/* position: static; */
      /* background-image: url(${props=>props.bg2});
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 150px; */
    max-width: 650px;
    .bg2{
      background-image: url(${props=>props.bg2});
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 150px;
      height: 352px;
      left: 0;
      width: 323px;
      top: -100px;
      position: absolute;
      /* z-index: -2; */
    }
    .MuiStepper-root,.MuiStepLabel-label{
        font-family: 'Outfit';
    }
    /* .Mui-active{
      color:#54585B;
    } */
    .MuiStepLabel-root{
      padding:0;
    }
    .MuiStepLabel-label{
        font-size: 24px;
        font-weight: 500;
        padding-left:40px;
        padding-bottom:5px ;
        color:#76787B;
        @media only screen and (max-width: 600px){
          padding-left:0px;
        }
    }
    .stepDesktop{
      z-index: 999;
      .MuiSvgIcon-root.Mui-active{
          color:#0029F5;
          font-size:27px;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          padding: 7px;
          background-color: transparent;
          border: 7px solid #0029F5;
          /* margin-left: -15px; */
        }
        
        .stepLevel{
          .MuiStepLabel-iconContainer{
            border: 8px solid rgba(0, 41, 245, 0.08);
          padding: 5px;
          border-radius: 50%;
          margin-left: -25px;
          }
              }
      }
    .stepMobile{
      z-index: 999;
      .MuiSvgIcon-root.Mui-active{
        color:#0029F5;
        font-size:27px;
        /* height: 28px;
        width: 28px;
        border-radius: 50%;
        padding: 7px; */
        /* background-color: transparent;
        border: 7px solid #0029F5;
        margin-left: -15px; */
      }
    }
    .MuiStepLabel-label.Mui-active{
        color:#0029F5;
        font-weight: 600;     
        padding-left:15px;
    }
    /* .MuiStepLabel-iconContainer{
      background-color: green;
    } */

    /* .MuiCollapse-vertical{
      border:2px solid red;
    } */


    .MuiStepContent-root{
      
        font-size: 20px;
        border-left: 2px dashed #C4C4C4;
        color:#ADADAD;
        color:#54585B;
        padding-left:60px;
        
        .title{
          /* display:flex; */
          font-family: 'Outfit';
        font-style: normal;
        font-weight: 300;
          align-items: center;
          max-width: 340px;
          color:#C4C4C4;
          img{
            height: 22px;
          margin-bottom: -5px;
          margin-left: 4px;
          }

        }
        .activeTitle{
          color:#54585B;
          // margin-left: 24px;
          margin-top: -22px;
          
        }
    }
    
    .MuiStepConnector-line{
        border-left-style: dashed;
        border-left-width: 2px;
        border-top-width:2px;
        border-color:#C4C4C4;
        min-height: 70px
    }
    .stepMobile{
      display:none;
    }

    .stepDesktop{
      .MuiStepLabel-label{
        font-family: 'Outfit';
      }
      .MuiSvgIcon-root{
        .MuiStepIcon-text{
          font-family: 'Outfit';

        }
      }
    }


    @media only screen and (max-width: 600px){
      .bg2{
        display:none;
      }
      .stepDesktop{
        display:none;
      }
      .stepMobile{
        display:inline-block;
        /* overflow: auto; */
        .MuiStepper-alternativeLabel{
          min-width: 800px;
          text-align: left;
        }
      }
      .MuiStepConnector-line{
        border-top-style:dotted;
        border-left-style: none;
      }
      .MuiStepLabel-label{
            font-size:20px;
            color:#828282;
            /* color:red; */
            text-align: left !important;
            .title{
              color:#333333;
              font-size:16px;
              text-align: left;
            }
            .stepLevel{
              text-align: left;
            }
        }
        .MuiStepLabel-label.Mui-active{
            font-size:20px;
            text-align: left;
        }
        .MuiSvgIcon-root.Mui-active{
            font-size:15px;
        }
        .MuiSvgIcon-root.MuiStepIcon-root{
            font-size:25px;
        }
    }
`

const Demo = styled.div`
    /* width:400px; */
    img{
        object-fit: contain;
        width:100%;
        max-width:300px;
    }

    @media only screen and (max-width: 600px){
      display:none;
    }
`
