import React from 'react'
import { Box, Container, Grid, Typography } from "@mui/material";
import "./index.css";
import Footer from "../Footer";
import IndianFlag from "./indian-flag.svg";
import Header from '../Header';

const PrivacyAndPolicy = () => {
  return (
    <>
            <Header step={0} />
     <Box className="privacy-policy-root-container">
      <Container maxWidth="lg">
        <Box className="privacy-policy-root-wrap">
          <Typography className="policy-header" component={"h3"}>
            CLAYCOVE23 INSURANCE TECH PVT. LTD. <br /> TERMS & CONDITIONS (USER
            AGREEMENT)
          </Typography>
          <br />
          <br />
          <Typography className="policy-content" component={"p"}>
            Version Updated: 31st August, 2023 <br /> <br />
            Claycove23 Insurance Tech Pvt. Ltd. (herein referred to as CoverSure /
            the Company) owns and operates the website “www.coversure.in”
            (herein referred to as Website / Site) and the mobile application
            “CoverSure” (herein referred to as App). <br />
            <br /> The words &quot; CoverSure&quot;, &quot;we&quot;,
            &quot;our&quot; and &quot;us&quot; refers to “Claycove23 Insurance Tech Pvt.
            Ltd.”. The words &quot;you&quot;, &quot;your&quot; or
            &quot;yours&quot; refer to any person who is using, accessing or
            browsing or has used, accessed or browsed the Website and/or is
            using or accessing or has used or accessed any version of CoverSure
            mobile application (“App”). The below terms and conditions shall be
            applicable to the use or user of CoverSure website at
            www.coversure.in, as may be modified from time to time or any other
            website created by CoverSure (&quot;Site&quot;) and the use or user
            of the App. By accessing the Site and/or the App (“Resources”), you
            agree to be bound by the following terms and conditions (hereafter
            referred to as ‘User Agreement’), along with the terms and
            conditions as stated in our Privacy Policy:
          </Typography>

          <Typography className="policy-sub-header" component={"h5"}>
            Wherein you understand that:
          </Typography>
          <br />
          <ul className="policy-list-content">
            <li>
              In order to access our Resources, you may be required to provide
              certain information about yourself (such as identification,
              contact details, payment information, etc.) as part of the
              registration process, or as part of your ability to use the
              Resources. You agree that any information you provide will always
              be accurate, correct, and up to date and you are responsible for
              any material you may submit via the Site and/or App, including the
              legality, reliability, appropriateness, originality and copyright
              of any such material.
            </li>
            <br />
            <li>
              You are responsible for maintaining the confidentiality of any
              login information associated with any account you use to access
              our Resources. Accordingly, you are responsible for all activities
              that occur under your account/s
            </li>
            <br />
            <li>
              Accessing (or attempting to access) any of our Resources by any
              means other than through the means we provide, is strictly
              prohibited. You specifically agree not to access (or attempt to
              access) any of our Resources through any automated, unethical or
              unconventional means. Engaging in any activity that disrupts or
              interferes with our Resources, including the servers and/or
              networks to which our Resources are located or connected, is
              strictly prohibited.{" "}
            </li>
            <br />
            <li>
              Attempting to copy, duplicate, reproduce, sell, trade, or resell
              our Resources is strictly prohibited.{" "}
            </li>
            <br />
            <li>
              You are solely responsible for any consequences, losses, or
              damages that we may directly or indirectly incur or suffer due to
              any unauthorised activities conducted by you, as explained above,
              and may incur criminal or civil liability.
            </li>
            <br />
            <li>
              You own or otherwise control all the rights to the content you
              post; that the content is accurate; that use of the content you
              supply does not violate any provision herein and will not cause
              injury to any person or entity; and that you will indemnify the
              Company for all claims resulting from the content you supply.
            </li>
            <br />
            <li>
              The Company is not responsible if information made available on
              its Site and/or the App is not accurate, complete or current. The
              material on the Site and/or the App site is provided for general
              information only and should not be relied upon or used as the sole
              basis for making decisions without any consultation or more timely
              sources of information. Any reliance on the material on the Site
              and/or the App is at your own risk.
            </li>
            <br />
            <li>
              The Company is not responsible for prompt update of the status of
              your policy, you must confirm your policy status from the
              Insurance company/ies and the Company shall update your policy
              status in the intervening period of time.
            </li>
            <br />
            <li>
              The Company shall not be liable for any action taken by the User
              based on or relying on the information provided in or by the Site
              and/or the App.
            </li>
          </ul>
          <Typography className="policy-sub-header" component={"h5"}>
            Limited License
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            CoverSure grants you a limited license to access and make personal
            use of the Site and/or the App. You shall not modify, copy,
            distribute, transmit, display, perform, reproduce, publish, license,
            create derivative works from, transfer, or sell any information,
            software, products or services obtained from the Site and/or the
            App, also it is not permissible to post unauthorized hypertext links
            or transmit any content that you do not have a right to make
            available (such as the intellectual property of another party); or
            undertaking any such activity that in CoverSure&apos;s sole
            discretion is an unreasonable action.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Limitation of Warranties
          </Typography>
          <br />
          <ul className="policy-list-content">
            <li>
              {" "}
              The use of our Resources will meet your needs or requirements.
            </li>
            <br />
            <li>
              The use of our Resources will be uninterrupted, timely, secure or
              free from errors.
            </li>
            <br />
            <li>
              The information obtained by using our Resources will be accurate
              or reliable, and
            </li>
            <br />
            <li>
              Any defects in the operation or functionality of any Resources we
              provide will be repaired or corrected
            </li>
          </ul>
          <br />
          <Typography className="policy-content" component={"p"}>
            Your use of the Site and/or the App is at your sole risk. The
            company expressly disclaims all warranties of any kind, whether
            express or implied, including but not limited to the above
            mentioned. If you download any materials from the Site and/or the
            App, you do so at your own discretion and risk. You will be solely
            responsible for any damage to your computer system or loss of data
            that results from the download of any such materials. No advice or
            information, whether oral or written, obtained by you from the
            company or through or from the Site and/or the App shall create any
            warranty of any kind. The company does not make any warranties or
            representations regarding the use of the materials on the Site
            and/or the App.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Indemnification
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            You agree to indemnify and hold harmless the Company and its
            officers, directors, employees, representatives, agents, respective
            directors, and assigns from and against any liability or any claim
            or demand, including reasonable attorney fees and court costs, made
            by any third party due to or arising out of content submitted by the
            user, user’s use of the Resources, violation of this User Agreement,
            breach by the user of any of the representations and warranties
            herein, or user’s violation of any rights of another.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Limitation of liability
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            Under no circumstances, including but not limited to negligence,
            shall the Company, its employees and directors, its suppliers and
            its third party advisors be liable to the User for any direct,
            indirect, incidental, special or consequential damages or any
            damages whatsoever including punitive or exemplary (including, but
            not limited to, damages caused arising out of or in any way
            connected with the use/delivery/performance of the Site and/or the
            App. The User agrees that the Company shall not be liable for any
            damages arising from interruption, suspension or termination of the
            Site and/or the App whether such interruption, suspension or
            termination was justified or not, negligent or intentional,
            inadvertent or advertent. Applicable law may not allow the
            limitation or exclusion of liability or incidental or consequential
            damages, however, in no event shall the Company’s liability to the
            User for all damages, losses and causes of action (whether in
            contract or tort, including but not limited to, negligence) exceed
            the amount paid by the User, if any, for accessing the Site and/or
            the App.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Intellectual Property Rights
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            All content and materials including but not limited to text,
            graphics, website name, code, images and logos displayed on the Site
            and/or the App are registered/unregistered trademarks of the
            Company, its licensors or content providers, or other third parties.
            Nothing on this site shall be construed as granting, by implication,
            estoppel, or otherwise, any license or right to use any material
            displayed on the Site and/or the App without the owner’s prior
            written permission. The Company reserves all rights not expressly
            granted in and to the site and its content.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Payments Warranties
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            Please note that CoverSure is only collecting or assisting in
            collecting the premium deposit on behalf of the insurer you have
            chosen to buy the policy or renew the policy. The acceptance of the
            deposit as premium and final issuance of the policy is subject to
            the underwriting norms and discretion of the Insurer whose policy
            you have chosen to buy on which CoverSure has no control in the
            same. All payments with respect to refunds shall be the sole
            responsibility of the insurer as per the rules and regulations laid
            down by the IRDAI.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Privacy:
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            Your privacy is very important to us, which is why we have created a
            separate Privacy Policy in order to explain in detail how we
            collect, manage, process, secure, and store your private
            information. To read our privacy policy in its entirety, please
            click on the Privacy Policy link on the Site and/or App.
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Communications:
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            You expressly consent to receiving communication by way of SMS
            and/or E-mail or through WhatsApp from the Company or any third
            party in connection with the Resources or Your registration on the
            Site and/or App. If you are no longer interested in receiving
            communications and other information from us, please e-mail your
            request at: digital@coversure.in
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Changes
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            We reserve the right to modify the contents of the Site and/or the
            App at any time, but we have no obligation to update any information
            on the site. You agree that it is your responsibility to monitor
            changes thereon. Your use of the Site and/or the App constitutes
            agreement to all such changes. The Company may discontinue or change
            any product or service described or offered on the Site and/or on
            its App at any time
          </Typography>
          <Typography className="policy-sub-header" component={"h5"}>
            Redressal of Grievances{" "}
          </Typography>
          <br />
          <Typography className="policy-content" component={"p"}>
            If at any time you have questions or concerns with respect to the
            aforesaid Term & Conditions, please feel free to e-mail the Company
            at compliance@coversure.in or write us at below mentioned address.
            The Company shall seek to redress your grievance/complaint within 14
            days from the date of receipt of the grievance/complaint given there
            exist no further information/investigation pending. While
            registering a grievance/complaint, you are kindly requested to quote
            your account details for the Company’s reference to enable it to
            effectively resolve your grievance/complaint. <br />
            <br />
            Address: CoverSure. Cowrks 2nd Floor. Birla Centurion. Pandurang
            Budhkar Marg. Century Mills. Worli. Mumbai – 400030
          </Typography>
          <br />
          <br />
        </Box>
      </Container>
      <Box className="t-and-c-footer-section website-desktop">
        <Footer />
        {/* <Box className="copy-right-wrappers">
          <Typography className="copy-right-header" component={"p"}>
            Copyright 2023. CoverSure. All Rights Reserved.
          </Typography>
          <Box className="made-in-india-wrap">
            <Typography className="made-in-india-header" component={"p"}>
              Made in India
            </Typography>
            <Box className="made-in-india-img-wrap">
              <img src={IndianFlag} alt="" />
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
    </>
  );
};

export default PrivacyAndPolicy;