import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

const SaveArea = ({handleApprove}) => {
    return (
        <Wrapper>
            <Button variant='contained'
            size="small"
            onClick={()=>handleApprove()}
             >
                Save
            </Button>            
        </Wrapper>
    )
}

export default SaveArea

const Wrapper = styled.div`
    /* background-color: #EBEEF2; */
    
    display:flex;
    align-items: center;
    gap:8px;
    justify-content: end;
    padding: 7px 10px;
    font-family: 'Euclid Square';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126.3%;
    text-align: center;
    .MuiButton-contained{
        color: #FFFFFF;
        border-radius: 5px;
        text-transform: capitalize;
        padding: 4px 25px;
        font-family: 'Euclid Square';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        background-color: #349EFF;
        box-shadow: 0px 0px 1px 0px #1976d2a6;
        :hover{
            background-color: #2f92ed;
        }
    }
    
`