import React from 'react'
import styled from 'styled-components';
const CopyrightStrip = () => {
  return (
    <Wrapper>
        © 2021 FG&G Distribution Pvt. Ltd.        
    </Wrapper>
  )
}

export default CopyrightStrip

const Wrapper = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 30px;
    font-family: 'Outfit';
    color: #434552;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
`