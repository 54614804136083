import { GET_MASTER_LIST } from "../constants";

const initialState={
    accountType:[],
    storeType:[],
    reasons:[]
}

export const masterReducer =(state=initialState,action)=>{
    switch (action.type) {
        case GET_MASTER_LIST:
          return {
            ...state,
                  accountType:action.payload.accountType,
                  storeType:action.payload.storeType,
                  reasons:action.payload.reasons
          };
        default:
          return state;
      }

}