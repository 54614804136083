import React, { useState } from "react";
import styled from "styled-components";
import HeroSection from "./HeroSection";
import PospInfo from "./PospInfo";
import Benefits from "./Benefits";
import SignupDemo from "./SignupDemo";
import Faq from "./Faq";
import Header from "./Header";
import BasicInfo from "./BasicInfo";
import BankDetail from "./BankDetail";
import UploadDoc from "./UploadDoc";
import VerifyDetail from "./VerifyDetail";
import Footer from "./Footer";
import Success from "./Success";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Handshake from "./Handshake";
import { useFormik } from "formik";
import moment from "moment";
import {
  verifyPanDetails,
  pinCode,
  getIfscDetail,
  panAuth,
  panStatusCheck,
} from "../api/index";

const Homepage = () => {
  const [step, setStep] = useState(0);
  const [lastPin, setLastPin] = useState("");
  const [lastPan, setLastPan] = useState("");
  const [lastIfsc, setLastIfsc] = useState("");
  const [panErr, setPanErr] = useState("");
  const [managerDetail, setManagerDetail] = useState({})
  const theme = createTheme({
    typography: {
      fontFamily: ["Sora", "Outfit"].join(","),
    },
  });

  const formik = useFormik({
    initialValues: {
      applicantid: 0,
      panNumber: "",
      email: "",
      partnerid: "",
      partnername: "",
      mobNumber: "",
      otp: "",
      tnc: false,
      // initials: 0,
      firstName: "",
      lastName: "",
      dob: null,
      pinCode: "",
      cityName: "",
      state: "",
      address: "",
      eduQual: 0,
      othersEdu: "",
      accHolderName: "",
      accNumber: "",
      ifsc: "",
      bankName: "",
      accType: 0,
      panImage: "",
      eduCertImage: "",
      partneruserid: "",
      nomineeName: "",
      gender: "",
      managerUserId: null,
    },
    validate: (values) => {
      let errors = {};

      // ----------PAN VALIDATE---------
      if (!values.panNumber) {
        errors.panNumber = "Please enter PAN number";
      }
      if (values.panNumber) {
        if (values.panNumber.length < 10) {
          errors.panNumber = "Please enter a valid PAN number";
        }
        if (
          values.panNumber.length === 10 &&
          !/[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(values.panNumber)
        ) {
          errors.panNumber = "Please enter PAN number in correct format";
        }
        if (
          values.panNumber.length === 10 &&
          /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/gm.test(values.panNumber)
        ) {
          const verifyPan = async () => {
            try {
              const panA = await panAuth(values.panNumber);
                if(panA.data){
                    if(panA.data.responseCode === 102) {
                      errors.panNumber = panA.data.responseMessage;
                      setPanErr(panA.data.responseMessage);
                    }
                    if(panA.data.status===1){
                      formik.setFieldValue('firstName',panA.data.data.firstName);
                      formik.setFieldValue('lastName',panA.data.data.lastName);
                      sessionStorage.setItem('dob', moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                      // formik.setFieldValue('dob',moment(panA.data.data.dob, "DD-MM-YYYY").format("yyyy-MM-DD"))
                      errors.panNumber = "";
                      setPanErr("");                    
                    }
                }
              setLastPan(values.panNumber);
            } catch {
              console.log("panVerify error");
            }
            console.log(errors.panNumber);
          };
          if (lastPan !== values.panNumber) {
            // verifyPan();
          }
        }
      }

      // if(panErr) errors.panNumber=panErr;
      // -------email validate--------
      if (!values.email) {
        errors.email = "Please enter email address";
      }
      if (values.email) {
        if (
          !/[a-z0-9]{1,10}@[a-z0-9]{1,80}[.]{1,1}[a-z0-9]{2,80}/gm.test(
            values.email
          )
        ) {
          errors.email = "Please enter a valid email address";
        }
      }
      // -------mobile number validate------------

      if (!values.mobNumber) {
        errors.mobNumber = "Please enter mobile number";
      }
      if (values.mobNumber) {
        if (!/^[6-9][0-9]{9}$/i.test(values.mobNumber)) {
          errors.mobNumber = "Please enter a valid number";
        }
      }
      // ----------validate otp -----------
      if (!values.otp) {
        errors.otp = "Please enter OTP";
      }
      if (values.otp) {
        if (values.otp.length < 4) {
          errors.otp = "Please enter 4 digit otp";
        }
      }
      //--------validate tnc----------
      if (values.tnc === false) {
        errors.tnc = "Please agree to terns & conditions to continue";
      }

      //validate name initials

      // if (!values.initials) {
      //   errors.initials = "Please select Title";
      // }
      //------ validate firstName --------------
      // if(!values.firstName){
      //     errors.firstName = "First name is required";
      // }
      // if(values.firstName&&values.firstName.length<3){
      //     errors.firstName = "Please enter valid First Name";
      // }

      //---------validate lastName ------------
      // if(!values.lastName){
      //     errors.lastName = "Last name is required";
      // }
      // if(values.lastName&&values.lastName.length<3){
      //     errors.lastName = "Please enter valid Last Name";
      // }

      //----------- validate DOB ----------------
      if (!values.dob) {
        errors.dob = "Please enter DOB";
      }
      if (values.dob) {
        let date = new Date();
        let month = date.getMonth();

        let dt = date.getDate();
        if (dt < 10) {
          dt = "0" + dt;
        }
        let year = date.getFullYear() - 18;

        // if(values.dob&&new Date(year,month,dt).getTime()<new Date(values.dob).getTime()){
        //     errors.dob="Age should be more than 18 years";
        // }

        // console.log(moment(values.dob).format('DD-MM-yyyy'))

        if (!moment(values.dob).isValid()) {
          errors.dob = "Please enter a valid DOB";
        }

        if (
          new Date(year, month, dt).getTime() >= new Date(values.dob).getTime()
        ) {
          const panVerify = async () => {
            try {
              const { data } = await panStatusCheck(
                formik.values.panNumber,
                moment(values.dob).format("DD/MM/yyyy"),
                formik.values.firstName,
                formik.values.lastName
              );

              if (data && data.responseCode === 102)
                formik.setFieldError("dob", data.responseMessage);
              if (data && data.responseCode === 101)
                formik.setFieldError("dob", "");
            } catch (error) {
              console.log("pan verify error");
              formik.setFieldError(
                "dob",
                "Something went wrong, Please try again later"
              );
            }
          };
          // panVerify();
        }
      }

      //--------------validate pin code ---------------
      if (!values.pinCode) {
        errors.pinCode = "Please enter your pin code";
      }
      if (values.pinCode) {
        if (Number(values.pinCode) < 1) {
          errors.pinCode = "Please enter valid Pin Code";
        }
        if (values.pinCode.length < 6) {
          errors.pinCode = "Please enter complete Pin Code";
        }
        if (values.pinCode && values.pinCode.length === 6) {
          const getPinData = async () => {
            try {
              const { data: { data } } = await pinCode(values.pinCode);
              formik.setFieldValue("cityName", data.city);
              formik.setFieldValue("state", data.state);
              setLastPin(values.pinCode);
            } catch (error) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.responseCode === 202
              ) {
                formik.setFieldError(
                  "pinCode",
                  error.response.data.responseMessage.toLowerCase()
                );
              }
              console.log("pincode detail fetching error");
            }
          };
          if (values.pinCode !== lastPin) {
            getPinData();
          }
        }
      }
      //----------Validate city name-----------
      if (!values.cityName) {
        errors.cityName = "Please provide city name";
      }

      //----------Validate state --------------
      if (!values.state) {
        errors.state = "Please provide state name";
      }
      //---------validate address --------------
      if (!values.address) {
        errors.address = "Please enter your address";
      }
      if (!values.nomineeName) {
        errors.nomineeName = "Please provide Nominee Full Name";
      }
      if (!values.gender) {
        errors.gender = "Please select gender";
      }

      if (!values.eduQual || values.eduQual === 0 || values.eduQual == 5) {
        if (values.eduQual == 5 && !values.othersEdu) {
          errors.eduQual = "Please enter Education Qualification";
        } else if (values.eduQual != 5 && values.eduQual === 0) {
          errors.eduQual = "Please select Education Qualification";
        }
      }
      //-------validate ifsc code ----------------

      if (!values.ifsc) {
        errors.ifsc = "Please enter IFSC Code";
      }

      if (values.ifsc) {
        if (!/^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(values.ifsc)) {
          errors.ifsc = "Please enter IFSC Code in correct format";
        } else if (values.ifsc.length < 11) {
          errors.ifsc = "Please enter valid IFSC Code";
        }
      }

      // if (
      //   values.ifsc &&
      //   values.ifsc.length === 11 && values?.accNumber && values.accNumber.length === 8 &&
      //   /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}/gm.test(values.ifsc)
      // ) {
      //   const fetchIfsc = async () => {
      //     try {
      //       const { data } = await getIfscDetail(values.accNumber, values.ifsc);
      //       formik.setFieldValue("bankName", data.bank);
      //       setLastIfsc(values.ifsc);
      //     } catch (error) {
      //       console.log("ifsc detail fetch error");
      //     }
      //   };
      //   if (values.ifsc !== lastIfsc) {
      //     fetchIfsc();
      //   }
      // }

      //----------validate acc holder name----------
      if (!values.accHolderName) {
        errors.accHolderName = "Please enter Account Holder Name";
      }

      if (values.accHolderName && values.accHolderName.length < 3) {
        errors.accHolderName = "Please enter complete Account Holder Name";
      }
      //--------------validate account number---------
      if (!values.accNumber) {
        errors.accNumber = "Please enter Account Number";
      }
      if (values.accNumber && values.accNumber.length < 6) {
        errors.accNumber = "Please enter valid Account Number";
      }

      //--------bank name----------
      if (!values.bankName) {
        errors.bankName = "Bank name is Required field";
      }
      //---------validate bank account type----------
      if (values.accType == 0) {
        errors.accType = "Please select Account Type";
      }

      //-------- validate pan Image--------
      if (!values.panImage) {
        errors.panImage = "Please select a file to upload";
      }

      if (!values.eduCertImage) {
        errors.eduCertImage = "Please select a file to upload";
      }

      return errors;
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {/* <Header progress={(100)/(progress?progress:0)} /> */}
        <Header step={step} setStep={setStep} />
        

        {step === 0 && (
          <HeroSection formik={formik} step={step} setStep={setStep} />
        )}

        {step === 1 && (
          <BasicInfo step={step} formik={formik} setStep={setStep} />
        )}
        {step === 2 && (
          <BankDetail formik={formik} step={step} setStep={setStep} />
        )}
        {step === 3 && (
          <UploadDoc formik={formik} step={step} setStep={setStep} setManagerDetail={setManagerDetail}/>
        )}
        {step === 4 && (
          <VerifyDetail formik={formik} step={step} setStep={setStep} managerDetail={managerDetail}/>
        )}
        {step === 5 && (
          <Success step={step} setStep={setStep} formik={formik} />
        )}

        {step === 0 && (
          <>
            <PospInfo />
            <Benefits />
            <SignupDemo />
            <Handshake />
            <Faq />
            <Footer />
          </>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default Homepage;

const Wrapper = styled.div`
  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c9c6c6;
    border-radius: 20px;
  }
`;
