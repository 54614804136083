import React from 'react';
import styled from 'styled-components';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button, IconButton  } from '@mui/material';
import {useNavigate} from 'react-router-dom';
import edit from './edit.svg'
const ViewHeading = ({name,formik, isEdit, setIsEdit, submitHandle, setUpdateEducation, userRole}) => {

    const navigate = useNavigate();

    return (
        <Wrapper>
            <div className="iconWrapper">
                <IconButton onClick={()=>navigate(-1)} >
                    <ArrowBackIosNewIcon  />
                </IconButton >
            </div>
            <div className="headingText">
                <p>
                {/* {`${formik.values.firstName} ${formik.values.lastName}`} */}
                {name?name:'Jane Doe'}
                </p>
                { userRole === 2 ?
                    isEdit ? 
                    <div style={{display: 'flex'}}>
                        <Button className='save-button' onClick={()=>{
                           submitHandle()
                            }}>Save Changes</Button> 
                        <Button className='discard-button' onClick={()=>{
                            setUpdateEducation(formik.values.eduQual);
                            setIsEdit(false)
                            }}>Discard</Button>
                    </div> :  
                    <Button className='edit-button' onClick={()=>setIsEdit(true)}><img src={edit} alt=""/>Edit</Button> : ''
                }
                
            </div>

            
        </Wrapper>
    )
}

export default ViewHeading

const Wrapper = styled.div`
    background-color: #EBEEF2;
    height:100px;
    display:flex;
    align-items: center;
    gap:8px;
    padding: 0 6%;
    .iconWrapper{
        .MuiIconButton-root{
           padding:0;
           :hover{
               .MuiSvgIcon-root{
                   background-color: #333333;
                   color:white;
                }
                
           }
           
        }
        margin-right: 30px;
        cursor: pointer;
        .MuiSvgIcon-root{
            font-size: 18px;
            border-radius: 50%;
            background-color: white;
            padding: 7px;
            color:black;
            ont-weight: 600;
        }
    }
    .headingText{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #333333;
        }
        .edit-button{
            background: #4B4B4B;
            color: white;
            text-transform: capitalize;
            padding: 10px;
            width: 81px;
            display: flex;
            justify-content: space-between;
            
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }
        .save-button{
            background: #13BB3A;
            color: white;
            text-transform: capitalize;
            padding: 10px;
            width: fit-content;
            display: flex;
            justify-content: space-between;
            margin-right: 10px;
            
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }
        .discard-button{
            background: #1A1A1C;
            color: white;
            text-transform: capitalize;
            padding: 10px;
            width: fit-content;
            display: flex;
            justify-content: space-between;
            
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
        }
    }
`