import React from 'react';
import styled from 'styled-components';
import { Grid,} from '@mui/material';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useSelector } from 'react-redux';
import moment from 'moment';



const RejectedReason = ({formik}) => {

    const reason = useSelector(state=>state.masterList.reasons);

   

    return (
        <Wrapper>
           
            <MainContent>
                <Grid container  >
                        <Grid item container spacing={0} md={5.5} className='rejectionReason'  >

                            <Grid item md={12} >
                                <div className="heading">
                                    <img src={require('../../../assets/Image/reject-reason-icon.svg').default} alt="" className="titleIcon" />
                                    <p>
                                        Reason for Rejection <span style={{color:'red'}} >*</span>
                                    </p>
                                </div>
                            </Grid>


                            <Grid item md={12} >
                                
                                <Select
                                    fullWidth
                                    size='small'
                                    placeholder='- Select a Reason for Rejection - '
                                    disabled
                                    {...formik.getFieldProps('rejReason')}
                                >
                                    {/* <MenuItem value={0} disabled >- Select a Reason for Rejection - </MenuItem>
                                    <MenuItem value={1} divider={true} >Mismatch with PAN DOB</MenuItem>
                                    <MenuItem value={2} divider={true} >Email ID is incorrect</MenuItem>
                                    <MenuItem value={3} divider={true} >DOB Mismatch with Education certificate DOB</MenuItem>
                                    <MenuItem value={4} divider={true} >PAN Card document is incorrect</MenuItem>
                                    <MenuItem value={5}  >Education Certificate is incorrect</MenuItem> */}

                                        <MenuItem value={0} disabled >- Select a Reason for Rejection - </MenuItem>
                                    {
                                        reason&&reason.map((res,index)=>(
                                            <MenuItem key={res.reasonId} value={res.reasonId} divider={true} >{res.reasonname}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                        <div className="rejDateCont">
                            <p className="rejDateTitle">
                                Rejection Date:
                            </p>
                            <p className="rejDate">
                                {moment(formik.values.rejDate).format('LL')}
                            </p>
                        </div>
                            
                        </Grid>
                       
                        {/* GRID CONTAINER 2 END */}

                </Grid>
            </MainContent>



        </Wrapper>
    )
}

export default RejectedReason

const Wrapper = styled.div`

    /* width:40%; */
    /* background-color:white;
    padding: 13px 18px;
    border-radius:12px;
    box-shadow: 0px 0px 3px 0px #7e7b7b8a; */
    .heading{
        display:flex;
        align-items: center;
        gap:0 5px;
        margin-bottom:15px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
        }
        .titleIcon{
            height:25px;
            object-fit: contain;
        }
    }
    


`

const MainContent = styled.div`
    padding:10px;
    transition: all 1s ease-in-out;
    .MuiOutlinedInput-root{
        font-size:16px;
    }
    .MuiInputLabel-root{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #7C7C7C;
        padding-bottom:7px;
    }
    .MuiOutlinedInput-input.Mui-disabled{
        -webkit-text-fill-color:#333333;
        color:#333333;
    }
    .viewTitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        color: #00A1FC;
        padding-bottom:10px;
        cursor: pointer;
    }
    .panImageCont{
        width:100%;
        img{
            width:100%;
            object-fit: contain;
        }
    }
    .certImageCont{
        width:100%;
        img{
            width:100%;
            object-fit: contain;
        }
    }

    .actionArea,.rejectionReason{
        background-color:white;
        padding: 21px 24px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0px #7e7b7b8a;
    }
    .actionArea{
        .MuiButton-outlined{
            border:none;
            border-radius: 5px;
        }
    .MuiSvgIcon-root{
            color:white;
            font-size: 30px;
        }
        .action2{
            .MuiInputLabel-root{
                margin-top:30px;
                white-space: normal;
            }
        }
    }
    .rejDateCont{
        display:flex;
        .rejDateTitle{
            margin-top: 10px;
            margin-right: 10px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 100%;
            color:#7C7C7C;
        }
        .rejDate{
            margin-top: 10px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 100%;
            color:#7C7C7C;
        }
    }
    
`