import Loader from './components/Loader';
import { useSelector } from 'react-redux';
import {useState} from 'react';
import styled from 'styled-components';
import {Route,Routes} from 'react-router-dom';
import Homepage from './components/Homepage';
import ErrorPage from './components/ErrorPage';
import Login from './components/admin/Login';
import PendingPosp from './components/admin/PendingPosp';
import RejectedPosp from './components/admin/RejectedPosp';
import ApprovedPosp from './components/admin/ApprovedPosp';
import BulkUpload from './components/admin/BulkUpload/BulkUpload';
import DownloadAppointment from './components/DownloadAppointment';
import ViewApproved from './components/admin/viewPosp/ViewApproved';
import ViewRejected from './components/admin/viewPosp/ViewRejected';
import ViewPending from './components/admin/viewPosp/ViewPending';
import UploadDocRej from './components/UploadDocRej';
import UpdateDetail from './components/UpdateDetails';
import AdminHome from './components/admin/AdminHome';
import VerifyDetail from './components/VerifyDetailold';
import PrivacyAndPolicy from './components/privacy-policy/page';
import DownloadReport from './components/admin/DownloadReport';


function App() {
  const {loader} = useSelector((state)=>state)
  const [showSearch, setShowSearch] = useState(false);
  
  return (

    <Wrapper>
     {loader.isLoading && <Loader is_loading={loader.isLoading} />}
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/download-appointment' element={<DownloadAppointment />} />
        <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
        <Route path='/upload-document' element={<UploadDocRej />} />
        <Route path='/update-details' element={<UpdateDetail />} />
        <Route path='/verify-details' element={<VerifyDetail />} />
        <Route path='/admin' element={<AdminHome />} >      
            <Route path='login' element={<Login />} />
            <Route path='pending'  >
                <Route path='' element={<PendingPosp setShowSearch={setShowSearch} showSearch={showSearch} />} />
                <Route path=':penId' element={<ViewPending/>} />
            </Route>
            <Route path='rejected'  >
                <Route path='' element={<RejectedPosp setShowSearch={setShowSearch} showSearch={showSearch} />} />
                <Route path=':rejId' element={<ViewRejected/>} />
              </Route>
            <Route path='approved' >
                <Route path='' element={<ApprovedPosp setShowSearch={setShowSearch} showSearch={showSearch} />} />
                <Route path=':appId' element={<ViewApproved/>} />
            </Route>
            <Route path='bulk-upload' >
                <Route path='' element={<BulkUpload />} />
            </Route>
            <Route path='bulk-upload' >
                <Route path='' element={<BulkUpload />} />
            </Route>
            <Route path='download-report' >
                <Route path='' element={<DownloadReport />} />
            </Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
        
      </Routes>
     

      
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div`

  .MuiLinearProgress-determinate{
      height:8px;
   
    background-color: #2959E5;
    .MuiLinearProgress-bar{
      background-color: #2959E5;
      border-radius: 12px;
     
    }
  }

  @media only screen and (max-width: 600px){
    
    .MuiLinearProgress-determinate{
        height:4px;
        background-color: #2959E5;
        .MuiLinearProgress-bar{
            background-color: #2959E5;
            border-radius: 0px;
        }
    }

  }
`
