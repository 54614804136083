/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState} from 'react';
import styled from 'styled-components';
import { Grid,InputLabel,Button, TextField, Typography} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getManagerList, updatePosp } from '../../../api';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994, type: 'L1 Associate', state: 'Mumbai' },
    { title: 'The Godfather', year: 1972, type: 'L2 Associate', state: 'Chennai' },
    { title: 'The Godfather: Part II', year: 1974, type: 'Manager', state: 'Mumbai' },
    { title: 'The Dark Knight', year: 2008, type: 'Vice President', state: 'Mumbai' },
    { title: '12 Angry Men', year: 1957, type: 'L1 Associate', state: 'Mumbai' },
    { title: "Schindler's List", year: 1993, type: 'L2 Associate', state: 'Chennai' },
    { title: 'Pulp Fiction', year: 1994, type: 'Manager', state: 'Mumbai' },
    {
      title: 'The Lord of the Rings: The Return of the King',
      year: 2003, type: 'Vice President', state: 'Mumbai'
    },
    { title: 'The Good, the Bad and the Ugly', year: 1966, type: 'L1 Associate', state: 'Mumbai' },
    { title: 'Fight Club', year: 1999, type: 'L2 Associate', state: 'Chennai' },
    {
      title: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001, type: 'Manager', state: 'Mumbai'
    },
    {
      title: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980, type: 'Vice President', state: 'Mumbai'
    },
    { title: 'Forrest Gump', year: 1994, type: 'L1 Associate', state: 'Mumbai' },
    { title: 'Inception', year: 2010, type: 'L2 Associate', state: 'Chennai' },
    {
      title: 'The Lord of the Rings: The Two Towers',
      year: 2002, type: 'Manager', state: 'Mumbai'
    },
  ];
const ApproveReject = ({formik,accept,setAccept,reject,setReject}) => {

    const params = useParams();
    const reason = useSelector(state=>state.masterList.reasons);
    const [mangerList, setMangerList] = useState([])

    const handleApprove=async(appStatus,rejReason)=>{
        try {
            const {data} = await updatePosp(params.penId,appStatus,rejReason);
            
        } catch (error) {
            console.log('Please select the valid Rejection reason');
        }
    }

      useEffect(async()=>{
        try {
            const {data} = await getManagerList();
            setMangerList(data.data)
        } catch (error) {
            alert('Something went wrong, please try again later');
        }
          
      },[])

      console.log('mangerList', mangerList)

    return (
        <Wrapper>
            {/* <div className="heading">
                <img src={require('../../../assets/Image/smile-emoji.png').default} alt="" className="titleIcon" />
                <p>
                  Approve / Reject?
                </p>
            </div> */}
            <MainContent>
                <Grid container spacing={0} >

                
                        {/* GRID CONTAINER 1 START */}
                        <Grid container item md={6}
                        // direction={{md:'column'}}
                       
                         className='actionArea' >

                            {/* CONTAINER 1 BOX 1 START HERE */}
                            <Grid item container md={12}
                            
                              >
                                    <Grid item md={12} >
                                        <div className="heading">
                                            <img src={require('../../../assets/Image/smile-emoji.png').default} alt="" className="titleIcon" />
                                            <p>
                                            Approve / Reject?
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item md={3.5} >
                                        <InputLabel>
                                            Approve POSP:
                                        </InputLabel>
                                        <Button variant='outlined' 
                                        fullWidth style={{backgroundColor:'#57BD5F'}}
                                        onClick={()=>{
                                            // formik.setFieldValue('approve',4);
                                            setAccept(2);
                                            setReject(0)
                                        }
                                        }
                                        >
                                            {/* <DoneIcon /> */}
                                            {
                                                (accept===2||accept===3)?
                                                // formik.values.accept>=4?
                                                <img src={require('../../../assets/Image/done-grey.svg').default} alt="" />:
                                                <img src={require('../../../assets/Image/done-white.svg').default} alt="" />

                                            }
                                            
                                        </Button> 
                                    </Grid>
                                    <Grid item md={5} >
                                        <InputLabel style={{textAlign:'center',marginTop:'32px'}} >
                                            OR
                                        </InputLabel>
                                    </Grid>

                                    {
                                        (accept!==3)&&
                                        // formik.values.accept<4&&
                                    <Grid item md={3.5}>
                                        <InputLabel>
                                          Reject POSP:
                                        </InputLabel>
                                        <Button variant='outlined'
                                        fullWidth style={{backgroundColor:'#FF0000'}}
                                        onClick={()=>{
                                            setReject(2);
                                            setAccept(4);
                                        }
                                        }
                                        >
                                            {/* <CloseIcon  /> */}
                                            {
                                                (reject===2||reject===3)?
                                                <img src={require('../../../assets/Image/cross-grey.svg').default} alt="" />:
                                                <img src={require('../../../assets/Image/cross-white.svg').default} alt="" />
                                            }
                                        </Button> 
                                    </Grid>
                                    }
                            </Grid>
                             {/* CONTAINER 1 BOX 1 END HERE */}

                              {/* CONTAINER 1 BOX 2 START HERE */}
                              {
                                  (accept===2||accept===3||reject===3)&&
                                //   formik.values.accept>=4&&
                              <Grid item container md={12}
                              direction={{md:'row'}}
                              className='action2'
                               >
                                    <Grid item md={3.5} >
                                        <InputLabel>
                                            Approve POSP for Business:
                                        </InputLabel>
                                        <Button variant='outlined' 
                                        fullWidth style={{backgroundColor:'#57BD5F'}}
                                        onClick={()=>{
                                            // formik.setFieldValue('approve',5);
                                            setAccept(3);
                                            setReject(0)
                                        }
                                        }
                                        >
                                            {/* <DoneIcon /> */}
                                            {
                                                accept===3?
                                                // formik.values.accept>=5?
                                                <img src={require('../../../assets/Image/done-grey.svg').default} alt="" />:
                                                <img src={require('../../../assets/Image/done-white.svg').default} alt="" />
                                            }
                                        </Button> 
                                    </Grid>
                                    <Grid item md={5}>

                                    </Grid>

                                    {/* <Grid item md={3.5} >
                                        <InputLabel>
                                          Reject POSP for Business:
                                        </InputLabel>
                                        <Button variant='outlined'
                                        fullWidth style={{backgroundColor:'#FF0000'}}
                                        onClick={()=>{
                                            setReject(3);
                                            setAccept(2);
                                        }
                                        }
                                        >
                                            {
                                                reject===3?
                                                <img src={require('../../../assets/Image/cross-grey.svg').default} alt="" />:
                                                <img src={require('../../../assets/Image/cross-white.svg').default} alt="" />
                                            }
                                        </Button> 
                                    </Grid> */}

                              </Grid>


                              }
                              {/* CONTAINER 1 BOX 2 END HERE */}

                            
                        </Grid>
                        {/* GRID CONTAINER 1 END */}
                        <Grid item md={0.5} >

                        </Grid>

                        {/* GRID CONTAINER 2 START */}
                        {
                            reject===2&&
                        <Grid item container spacing={0} md={5.5} className='rejectionReason'  >

                            <Grid item md={12} >
                                <div className="heading">
                                    <img src={require('../../../assets/Image/reject-reason-icon.svg').default} alt="" className="titleIcon" />
                                    <p>
                                        Reason for Rejection <span style={{color:'red'}} >*</span>
                                    </p>
                                </div>
                            </Grid>


                            <Grid item md={12} >
                                <InputLabel>
                                    Select a Reason for Rejection:
                                </InputLabel>
                                <Select
                                    fullWidth
                                    size='small'
                                    placeholder='- Select a Reason for Rejection - '
                                    {...formik.getFieldProps('rejReason')}
                                >
                                    {/* <MenuItem value={0} disabled >- Select a Reason for Rejection - </MenuItem>
                                    <MenuItem value={1} divider={true} >Mismatch with PAN DOB</MenuItem>
                                    <MenuItem value={2} divider={true} >Email ID is incorrect</MenuItem>
                                    <MenuItem value={3} divider={true} >DOB Mismatch with Education certificate DOB</MenuItem>
                                    <MenuItem value={4} divider={true} >PAN Card document is incorrect</MenuItem>
                                    <MenuItem value={5} >Education Certificate is incorrect</MenuItem> */}

                                    <MenuItem value={0} disabled >- Select a Reason for Rejection - </MenuItem>
                                    {
                                        reason&&reason.map((res,index)=>(
                                            <MenuItem key={res.reasonId} value={res.reasonId} divider={true} >{res.reasonname}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </Grid>
                            
                        </Grid>
                        }
                        {
                            (accept===3||reject===3) && <Grid item container spacing={0} md={5.5} className='rejectionReason'  >

                            <Grid item md={12} >
                                <div className="heading">
                                    <img src={require('../../../assets/Image/reject-reason-icon.svg').default} alt="" className="titleIcon" />
                                    <p>
                                    Select Manager <span style={{color:'red'}} >*</span>
                                    </p>
                                </div>
                            </Grid>


                            <Grid item md={12} >
                                <InputLabel>
                                Select manager from list
                                </InputLabel>
                                <Autocomplete
                                    options={mangerList}
                                    value={mangerList.find(manager => manager.userName === formik.values.managerUserId) || null}
                                    onChange={(event, newValue) => {
                                      formik.setFieldValue('managerUserId', newValue ? newValue.userName : null);
                                    }}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    renderOption={(props, option) => (
                                      <li {...props} style={{ borderBottom: props['data-option-index'] < mangerList.length - 1 ? '1px solid #C4C4C4' : 'none' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                          <Typography sx={{ fontSize: '16px', fontWeight: '400', color: '#333333' }} variant="body1">
                                            {`${option.firstName} ${option.lastName} - ${option.role}`}
                                          </Typography>
                                          <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: '400', color: '#333333' }}>
                                            {option.city ? option.city : ''}
                                          </Typography>
                                        </Box>
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        placeholder="Select manager from list"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={require('../../../assets/Image/search.svg').default} alt="" />
                                            </InputAdornment>
                                            ),
                                        }}
                                        />
                                    )}
                                    clearOnEscape
                                />
                                {/* <Select
                                    fullWidth
                                    size="small"
                                    placeholder="- Select manager from list -"
                                    // multiple
                                    {...formik.getFieldProps('manager')}
                                    // value={formik.values.manager} // Make sure this is an array
                                    // onChange={handleChange}
                                    // renderValue={(selected) => selected.join(', ')} 
                                >
                                    <MenuItem value={0} disabled >- Select manager from list -</MenuItem>
                                    <MenuItem value={1} divider={true} >Mismatch with PAN DOB</MenuItem>
                                    <MenuItem value={2} divider={true} >Email ID is incorrect</MenuItem>
                                    <MenuItem value={3} divider={true} >DOB Mismatch with Education certificate DOB</MenuItem>
                                    <MenuItem value={4} divider={true} >PAN Card document is incorrect</MenuItem>
                                    <MenuItem value={5} >Education Certificate is incorrect</MenuItem>

                                    {/* <MenuItem value={0} disabled >- Select manager from list - </MenuItem>
                                    {
                                        reason&&reason.map((res,index)=>(
                                            <MenuItem key={res.reasonId} value={res.reasonId} divider={true} >{res.reasonname}</MenuItem>
                                        ))
                                    } */}

                                {/* </Select> */} 
                            </Grid>
                            
                        </Grid>
                        }
                        {/* GRID CONTAINER 2 END */}

                </Grid>
            </MainContent>



        </Wrapper>
    )
}

export default ApproveReject

const Wrapper = styled.div`

    /* width:40%; */
    /* background-color:white;
    padding: 13px 18px;
    border-radius:12px;
    box-shadow: 0px 0px 3px 0px #7e7b7b8a; */
    .heading{
        display:flex;
        align-items: center;
        gap:0 5px;
        margin-bottom:15px;
        p{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 100%;
            color:#333333;
        }
        .titleIcon{
            height:22px;
            object-fit: contain;
        }
    }
    


`

const MainContent = styled.div`
    padding:10px;
    transition: all 1s ease-in-out;
    .MuiOutlinedInput-root{
        font-size:16px;
        border-radius: 12px !important;
    }
    .MuiAutocomplete-input{
        padding: 0px 4px 0px 6px !important;
    }
    .MuiInputLabel-root{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: #7C7C7C;
        padding-bottom:7px;
    }
    .viewTitle{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 100%;
        color: #00A1FC;
        padding-bottom:10px;
        cursor: pointer;
    }
    .panImageCont{
        width:100%;
        img{
            width:100%;
            object-fit: contain;
        }
    }
    .certImageCont{
        width:100%;
        img{
            width:100%;
            object-fit: contain;
        }
    }

    .actionArea,.rejectionReason{
        background-color:white;
        padding: 21px 24px;
        border-radius: 10px;
        box-shadow: 0px 0px 3px 0px #7e7b7b8a;
    }
    .actionArea{
        .MuiButton-outlined{
            border:none;
            border-radius: 5px;
            padding:10px 0px;
        }
    .MuiSvgIcon-root{
            color:white;
            font-size: 24px;
        }
        .action2{
            .MuiInputLabel-root{
                margin-top:30px;
                white-space: normal;
                
            }
        }
    }
    
`